<template>
  <div class="errPage-container">
    <el-button class="pan-back-btn" @click="back">
      返回登录页
    </el-button>
    <el-row>
      <el-col :span="12">
        <h1 class="text-jumbo text-ginormous">
          Oops!
        </h1>
        <h2 style="font-size:30px;">你没有权限去该页面</h2>
        <h3 style="font-size:20px;">请联系管理员</h3>
      </el-col>
      
    </el-row>
    <el-dialog :visible.sync="dialogVisible" title="随便看">
      <img :src="ewizardClap" class="pan-img">
    </el-dialog>
  </div>
</template>

<script>
import errGif from '@/assets/401_images/401.gif'

export default {
  name: 'Page401',
  data() {
    return {
      errGif: errGif + '?' + +new Date(),
      ewizardClap: 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646',
      dialogVisible: false
    }
  },
  methods: {
    back() {
      sessionStorage.clear()
      window.location.href = vueConfig.jqUrl + "vue/web/login"
    }
  }
}
</script>

<style lang="scss" scoped>
  .errPage-container {
    width: 800px;
    max-width: 100%;
    margin: 100px auto;
    .pan-back-btn {
      background: rgb(30, 137, 229);
      color: #fff;
      border: none!important;
    }
    .pan-gif {
      margin: 0 auto;
      display: block;
    }
    .pan-img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    .text-jumbo {
      font-size: 120px;
      font-weight: 700;
      color: rgb(30, 137, 229);
    }
    .list-unstyled {
      font-size: 40px;
      li {
        padding-bottom: 5px;
      }
      a {
        color: rgb(30, 137, 229);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
