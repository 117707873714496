<template>
  <div class="home">
    <div class="mask" id="mask"></div>
    <el-container>
      <el-header v-show="menuList" style="min-width: 1200px">
        <div class="top-logo">
          <img :src="imgConfig" alt="" class="logo" />
        </div>
        <el-menu :default-active="activeMenu" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#1e89e5" text-color="#333" active-text-color="#fff" :collapse="false" ref="headerMenu">
          <el-menu-item :index="item.name" v-for="(item, key) in arr" :key="key">
            <img :src="item.meta.icon" alt="" class="icon" />
            {{$t(`menu.${item.name}`)}}
          </el-menu-item>
        </el-menu>
        <div class="logout" style="z-index:1;">
          <div v-if="redwitchFlag&&iconFlag" class="red-style"></div>
          <el-popover v-if="iconFlag &&arr.length>0" class="familyTitle" ref="waringInfo" width="260" placement="bottom" trigger="click" popper-class="logoutAchange">
            <svg slot="reference" class="icon" aria-hidden="true" style="z-index: 2; cursor: pointer; margin-top: 5px" @click="goWanningInfo">
              <use xlink:href="#icon-tixing"></use>
            </svg>
            <p v-if="warningList.length == 0">{{$t('el.cascader.noData')}}</p>
            <p class="text-style" v-for="(item,index) in warningList" :key="index" style="text-align: left;color:#000;margin:5px 0;" >{{index+1}}、{{item.serviceType=='gateway'?"网关服务":item.serviceType}}-{{item.warningContent}} <span @click="lookRouter">查看</span> </p>
            <!-- <el-table :data="gridData">
              <el-table-column width="100" property="name" label="姓名"></el-table-column>
              <el-table-column width="100" property="address" @click="goWanningInfo">查看</el-table-column>
            </el-table> -->
          </el-popover> 
          
          <el-popover v-if="iconFlag &&arr.length>0" class="familyTitle" :content="$t('menu.Home1')" placement="top-start" trigger="hover" popper-class="logoutAchange">
            <svg slot="reference" class="icon" aria-hidden="true" style="z-index: 2; cursor: pointer; margin-top: 5px" @click="doumps">
              <use xlink:href="#icon-shuju"></use>
            </svg>
          </el-popover>

          <!-- <i class="el-icon-data-line" @click="doumps"></i> -->
          <i class="el-icon-user-solid"></i>
          <div class="center">
            <label id="currUser" :title="userName" style="cursor: pointer">{{
              userName
            }}</label>
            <input type="hidden" id="loginName" value="superemm" />
          </div>
          <el-popover placement="top-start" trigger="hover" popper-class="logoutAchange">
            <p class="changePass sitehover" @click="editpass"   style="cursor: pointer">
              {{$t('MainHome.Editpass')}}
            </p>
            <!-- <p class="changePass sitehover" @click="product" style="cursor: pointer">
              产品说明
            </p>
            <p class="changePass sitehover" @click="book" style="cursor: pointer">
              操作手册
            </p>
            <p class="changePass sitehover" @click="file" style="cursor: pointer">
              政策文件
            </p> -->
            <!-- <p class="layout sitehover" @click="LanguageSetting" style="cursor: pointer">
              {{$t('MainHome.LanguageSetting')}}
            </p> -->
            <p class="layout sitehover" @click="logout" style="cursor: pointer">
              {{$t('MainHome.Logout')}}
            </p>
            <i class="el-icon-s-tools" slot="reference" style="cursor: pointer"></i>
          </el-popover>
        </div>
        <Eldialog :title="$t('MainHome.Editpass')" :width="'405px'" :num="'200px'" :dialogVisible="statusVisiblemm" @handleClose="handleClosePass" @heightCustom="heightCustom" :flagbtn="true" :determineBtn="true" @determine="determinePass">
          <div class="box-corn" style=" margin-top:20px">
            <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm changePasswordForm">
              <el-form-item :label="$t('MainHome.OldPassword')" prop="oldPassword" class="formItemPass">
                <el-input type="password" v-model="form.oldPassword" clearable size="small" style="width: 250px;" class="passinputs"></el-input>
              </el-form-item>
              <el-form-item :label="$t('MainHome.NewPassword')" prop="newPassword1"  class="formItemPass">
                <el-input type="password" v-model="form.newPassword1" clearable size="small" style="width: 250px;" class="passinputs"></el-input>
              </el-form-item>
              <el-form-item :label="$t('MainHome.ConfirmPassword')" prop="newPassword2"  class="formItemPass">
                <el-input v-model="form.newPassword2" clearable type="password" size="small" style="width: 250px;" class="passinputs"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </Eldialog>
        <Eldialog :title="$t('MainHome.LanguageSetting')" :width="'260px'" :num="'65px'" :dialogVisible="LanguageFlag" @handleClose="handleCloseLanguage" :flagbtn="false" :determineBtn="false" @determine="determineLanguage">
          <div class="box-corn" style=" margin-top:10px">
            <el-select v-model="selectValue" style="width:180px;" @change="langChange" placeholder="请选择">
              <el-option
                v-for="item in LanguageOptions"
                :key="item.value"
                :label="$t(`MainHome.LanguageOptions.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </Eldialog>
        <!-- 保存成功的弹框 -->
        <dialog-info :dialogInfo="Successdialog" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
        <!-- <el-dialog
          title="成功信息"
          :visible.sync="Successdialog"
          :show-close="true"
          :close-on-click-modal="true"
          :close-on-press-escape="false"
          width="15%"
          style="margin-top: 10%"
          id="success"
          @close="sendMsg"
          center
        >
          <span style="font-size: 18px">保存成功</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="sendMsg" type="primary" :disabled="isDisabled"
              >确定（{{ Sencond }}）</el-button
            >
          </span>
        </el-dialog> -->
        <!-- 隐藏中英文切换 -->
        <!-- <el-select
          v-model="value"
          placeholder="请选择"
          size="small"
          @change="onChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
      </el-header>
      <!-- 二级菜单 -->
      <el-container>
        <el-aside v-show="menuList" width="187px" class="center-left" :style="!flag ? 'width:187px' : 'width:0px'" v-if="Array.isArray(childChildren) && childChildren.length">
          <el-menu :default-active="slideMenuActive" @select="slideSelect" style="
              height: 100%;
              width: 186px;
              overflow: hidden;
              border-right: solid 1px #e6e6e6;
            " :default-openeds="childArr" :collapse="flag" :collapse-transition="false" background-color="#fff" text-color="#000000" active-text-color="#fff" ref="menus" @close="handelClose" @open="handleOpen">
            <div v-for="(item, key) in childChildren" :key="key">
              <el-menu-item
                ref="slideMenu"
                :index="key + ''"
                v-show="item.children.length == 0"
                :key="key + ''"
                @click="setPath(item, key, $event)"
                @mouseover.native="mouseOver(key)"
                @mouseleave.native="mouseLeave(key)"
              >
                <!-- {{key}} {{menuKeys}} {{mouseKeys}} -->
                <img
                  :src="
                    key === menuKeys || key === mouseKeys
                      ? item.meta.icon
                      : item.meta.icon1
                  " alt="" class="icons" />
                <span>{{ $t(`menu.${item.name}`)}}</span>
              </el-menu-item>

              <el-submenu :index="key + ''" style="width: 186px" v-show="item.children.length > 0">
                <template slot="title">
                  <!-- {{key}} {{menuKeys}} {{mouseKeys}} -->
                  <img
                    :src="
                      key === menuKeys || key === mouseKeys
                        ? item.meta.icon
                        : item.meta.icon1
                    " alt="" class="icons" />
                  <span>{{ $t(`menu.${item.name}`) }} </span>
                </template>
                <el-menu-item :index="key + '-' + k + ''" v-for="(it, k) in item.children" :key="k" style="width: 186px" @click.native="
                    setPaths(item.children[k], key + '-' + k + '', key, $event)
                  " @mouseleave.native="mouseLeave">
                  {{ $t(`menu.${it.name}`) }}
                </el-menu-item>
              </el-submenu>
            </div>
          </el-menu>
          <p :class="hideicons" @click="showSlidebar">
            <i class="el-icon-s-fold"></i>
          </p>
        </el-aside>
        <authority v-if="arr.length == 0" />
        <el-main v-else class="center-right" style="
            padding-bottom: 60px;
            position: relative;
            overflow: -Scroll;
            overflow-x: hidden;
          ">
          <router-view :key="dateKey"></router-view>
        </el-main>
      </el-container>

      <el-footer style="z-index: 1000">{{ this.copyText }}</el-footer>
      <!-- <el-footer></el-footer> -->
    </el-container>
  </div>
</template>
<script src="../assets/iconfont/iconfont.js"></script>
<script>
import socketApi from "../utils/websoket";
const Base64 = require('js-base64').Base64
import authority from './error-page/401.vue'
import routerRoutes from '../utils/router'
import locale from '../langs/locale'
import utils from '../utils/index.js'
import dialogInfo from '@/components/promptMessage/index'
import Eldialog from '@/components/elDialog' //弹框
export default {
  components: { dialogInfo, authority,Eldialog },

  data() {
    var validatePass = (rule, value, callback) => {
      let reg =
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/
      if (value === '') {
        callback(new Error(this.$t('MainHome.Error')))
      } else if (!reg.test(value)) {
        callback(new Error(this.$t('MainHome.Error1')))
      } else {
        if (this.form.newPassword2 !== '') {
          this.$refs.form.validateField('newPassword2')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      let reg =
        /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/
      if (value === '') {
        callback(new Error(this.$t('MainHome.Error2')))
      } else if (!reg.test(value)) {
        callback(new Error(this.$t('MainHome.Error1')))
      } else if (value !== this.form.newPassword1) {
        callback(new Error(this.$t('MainHome.Error4')))
      } else if (value === this.form.oldPassword) {
        callback(new Error(this.$t('MainHome.Error5')))
      } else {
        callback()
      }
    }
    return {
      selectValue: "",
      LanguageOptions:[
        {
          value: "zh",
          label: "中文简体",
        },
        {
          value: "en",
          label: "English",
        },
        {
          value: "tw",
          label: "中文繁體",
        },
      ],
      statusVisiblemm: false, //修改密码
      redwitchFlag:false,
      warningList:[],
      address: vueConfig.thePathaddress,
      iconFlag: true, //默认显示图标
      menuList: true,
      activeMenu: 'SourceHome',
      year: new Date().getFullYear(),
      dateKey: new Date().getTime(),
      // imgConfig: vueConfig.imgConfig,
      copyText: infoConfig.copyright, //底部版权归属
      imgConfig:
        vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1) +
        '/msp/protected/config/logo/logo.jpg',
      num: '0',
      titleInfo: '',
      count: 0,
      Successdialog: false, //控制弹出
      Sencond: 5, //设置初始倒计时
      isDisabled: false,
      time: null,
      userName: '',
      quanFlag: true,
      menuKeys: 0,
      mouseKeys: 0,
      quanFlag: true,
      content: '',
      activeIndex: '0',
      sliderIndex: '0',
      slideMenuActive: '0',
      userSlideMenuActive: '0',
      arr: [],
      childChildren: [],
      childArr: ['1', '2', '3', '4', '5'],
      flag: false,
      LanguageFlag:false,
      hideicons: 'close',
      iframeWin: {},
      url: '',
      count: 0,
      luyouFlag: true,
      options: [
        {
          value: '1',
          label: '中文'
        },
        {
          value: '2',
          label: 'English'
        },
        {
          value: '3',
          label: '繁體中文'
        }
      ],
      value: '1',
      formLabelWidth: '80px',
      form: {
        oldPassword: '',
        newPassword1: '',
        newPassword2: ''
      },
      rules: {
        oldPassword: [
          { required: true, trigger: 'blur', message: this.$t('MainHome.rules.oldPassword') }
        ],
        newPassword1: [
          { required: true, message: this.$t('MainHome.rules.newPassword'), trigger: 'blur' },
          {
            min: 8,
            max: 32,
            message: this.$t('MainHome.rules.MessagePort'),
            trigger: 'blur'
          },
          { validator: validatePass, trigger: 'blur' }
        ],
        newPassword2: [
          { required: true, message: this.$t('MainHome.rules.confirmPassword'), trigger: 'blur' },
          {
            min: 8,
            max: 32,
            message: this.$t('MainHome.rules.MessagePort'),
            trigger: 'blur'
          },
          { validator: validatePass2, trigger: 'blur', required: true }
        ]
      }
    }
  },
  watch: {
    $route: {
      handler() {
        let roleId = sessionStorage.getItem('roleId')
        if (roleId == 4) {
          this.$router.replace("equipmentManagement")
        }
        console.log(this.$route.name,"this.$route.name--------------397");
        if (this.$route.name == "situational") {
          this.menuList = false;
        } else if (this.$route.name == "configuration") {
          this.menuList = false;
        } else {
          this.menuList = true
        }

        if (this.$route.name == 'Behavior') {
          this.activeMenu = 'DeviceManage'

          let data = this.arr.find((item) => item.name == 'DeviceManage')
          data.children.forEach((item, index) => {
            if (item.name == 'Behavior') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name == 'equipmentManagement') {
          this.activeMenu = 'SelfServiceDesk'
          this.slideMenuActive = '0'
        }
        if (this.$route.name == 'SourceHome') {
          this.activeMenu = 'SourceHome'
        }
        if (this.$route.name == 'Delivery') {
          this.activeMenu = 'Delivery'
          this.slideMenuActive = '0'//分配管理高亮
          this.mouseKeys = 0
          this.menuKeys = 0
        }
        if (this.$route.name == 'DeliveryHistory') {
          this.mouseKeys = 1
          this.menuKeys = 1
        }
        if (this.$route.name == 'ExecResult') {
          this.mouseKeys = 2
          this.menuKeys = 2
        }
        if (this.$route.name == 'document') {
          this.mouseKeys = 0
          this.menuKeys = 0
        }
        if (this.$route.name == 'documentcategory') {
          this.mouseKeys = 1
          this.menuKeys = 1
        }
        if (this.$route.name == 'Notice') {
          this.mouseKeys = 2
          this.menuKeys = 2
        }

        if (this.$route.name == 'Running') {
          this.activeMenu = 'SystemSettings'
          this.slideMenuActive = '0'
        }
        if (this.$route.name == 'App') {
          this.activeMenu = 'AppManage'
          this.slideMenuActive = '0'
          this.mouseKeys = 0
          this.menuKeys = 0
        }
        //应用商店合规检测跳转事件
        routerRoutes(this)
        if (this.$route.name == 'User') {
          this.activeMenu = 'UserManage'
          this.slideMenuActive = '0-0'
        }
        if (this.$route.name == 'Device') {
          this.activeMenu = 'DeviceManage'
          this.slideMenuActive = '0-0'  //6.1.3设备管理分为设备管理、设备组，原有一级菜单设备管理内容放置在二级菜单设备管理中，新增【设备组】菜单
          this.mouseKeys = 0
          this.menuKeys = 0
        }
        if (this.$route.name == "DevicePolicy") {
          this.activeMenu = "DeviceManage";
          this.slideMenuActive = "1-0";
          let data = this.arr.find((item) => item.name == "DeviceManage");
          let deviceData=data.children.find((item) => item.name == "Device");
          console.log(deviceData,"deviceData")
          if(deviceData){
           this.activeMenu = "DeviceManage";
           this.slideMenuActive = "1-0";
          }else if(deviceData==undefined){
           this.activeMenu = "DeviceManage";
           this.slideMenuActive = "0-0";
          }
        }
        if (this.$route.name == "ClientPolicy") {
          //合规策略
          this.activeMenu = "DeviceManage";
          this.slideMenuActive = "1-1";
        }
        if (this.$route.name == "document") {
          //文档管理
          this.activeMenu = "ContentManage";
          this.slideMenuActive = "0";
        }
        if (this.$route.name == 'Notice') {
          //通知管理
          this.activeMenu = 'ContentManage'
          this.slideMenuActive = '2'
          let data = this.arr.find((item) => item.name == "ContentManage");
          let documentData=data.children.find((item) => item.name == "Document");
          let documentCategoryData=data.children.find((item) => item.name == "DocumentCategory");
          if(documentData && documentCategoryData){
            this.activeMenu = "ContentManage";
            this.slideMenuActive = "2";
          }else if(documentData == undefined || documentCategoryData == undefined){
           this.activeMenu = "ContentManage";
           this.slideMenuActive = "1";
          }else if(documentData == undefined && documentCategoryData == undefined){
           this.activeMenu = "ContentManage";
           this.slideMenuActive = "0";
          }
        }

        if (this.$route.name == 'Home2') {
          this.slideMenuActive = '0'
          this.activeMenu = 'SecurityMonitor'
        }
        if (this.$route.name == 'GDment') {
          this.activeMenu = 'MobileOperations'
          this.slideMenuActive = '0'
        }
        if (this.$route.name == 'DataReport') {
          this.activeMenu = 'ReportManagement'
        }
        if (this.$route.name == 'WarningInfo') {
          this.activeMenu = 'SecurityMonitor'
          let data = this.arr.find((item) => item.name == 'SecurityMonitor')
          data.children.forEach((item, index) => {
            if (item.name == 'WarningInfo') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name == 'ViolationMonitor') {
          this.activeMenu = 'SecurityMonitor'
          let data = this.arr.find((item) => item.name == 'SecurityMonitor')
          data.children.forEach((item, index) => {
            if (item.name == 'ViolationMonitor') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name == "AppAttackMonitor") {
          this.activeMenu = "SecurityMonitor";
          let data = this.arr.find((item) => item.name == "SecurityMonitor");
          data.children.forEach((item, index) => {
            if (item.name == 'AppAttackMonitor') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name === "App") {
          this.activeMenu = "AppManage";
          let data = this.arr.find((item) => item.name == "AppManage");
          data.children.forEach((item, index) => {
            if (item.name === "App") {
              this.slideMenuActive = index + "";
              this.mouseKeys = index;
              this.menuKeys = index;
            }
          });
        }
        if (this.$route.name == "AppSensitiveMonitor") {
          this.activeMenu = "SecurityMonitor";
          let data = this.arr.find((item) => item.name == "SecurityMonitor");
          data.children.forEach((item, index) => {
            if (item.name == 'AppSensitiveMonitor') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name == 'SafetyEvent') {
          this.activeMenu = 'SecurityMonitor'
          let data = this.arr.find((item) => item.name == 'SecurityMonitor')
          data.children.forEach((item, index) => {
            if (item.name == 'SafetyEvent') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name == 'SDKBehaviour') {
          this.activeMenu = 'SecurityMonitor'
          let data = this.arr.find((item) => item.name == 'SecurityMonitor')
          data.children.forEach((item, index) => {
            if (item.name == 'SDKBehaviour') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name == 'RunInfo') {
          this.activeMenu = 'SecurityMonitor'
          let data = this.arr.find((item) => item.name == 'SecurityMonitor')
          data.children.forEach((item, index) => {
            if (item.name == 'RunInfo') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name == 'Accessbehavior') {
          this.activeMenu = 'SecurityMonitor'
          let data = this.arr.find((item) => item.name == 'SecurityMonitor')
          data.children.forEach((item, index) => {
            if (item.name == 'Accessbehavior') {
              this.slideMenuActive = index + ''
              this.mouseKeys = index
              this.menuKeys = index
            }
          })
        }
        if (this.$route.name == 'DevicePolicy') {
          //设备策略
          this.activeMenu = 'DeviceManage'
          this.slideMenuActive = '1-0'
        }
        if (this.$route.name == 'ClientPolicy') {
          //合规策略
          this.activeMenu = 'DeviceManage'
          this.slideMenuActive = '1-1'
        }
        this.arr.forEach((item) => {
          if (item.name == this.activeMenu) {
            this.childChildren = item.children
            console.log(this.childChildren)
          }
        })
      }
    }
  },

  created() {
    window.addEventListener('message', this.handleMessage, false)
    this.content = vueConfig.jqUrl
    this.jumpContent = vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1)
  },

  beforeMount() {},

  mounted() {
    // 这里加载菜单
    this.arr = []
    this.getpath(0, this.arr)
    setTimeout(() => {
      let roleId = sessionStorage.getItem('roleId')
      this.userName = sessionStorage.getItem('userName')
      if (roleId == 4) {

        this.iconFlag = false
        ;
        // (this.activeMenu = 'equipmentManagement'), (this.slideMenuActive = '0')
        this.activeMenu = 'SelfServiceDesk'
        this.slideMenuActive = '0'
        // (this.activeMenu = 'SystemSettings'), (this.slideMenuActive = '0')
        this.handleSelect('SelfServiceDesk')
      }else if (roleId == 3) {
        this.iconFlag = false
        ;(this.activeMenu = 'SystemSettings'), (this.slideMenuActive = '0')
        this.handleSelect('SystemSettings')
      } else if (roleId == 2) {
        this.iconFlag = false
        ;(this.activeMenu = 'ManageLogs'), (this.slideMenuActive = '0')
        this.handleSelect('ManageLogs')
      } else if (roleId == 1) {
        this.iconFlag = true
        this.activeMenu = 'SourceHome'
        this.latestFunction()
      } else {
        let urlPath = JSON.parse(sessionStorage.getItem('urlPath'))
        if(urlPath.name == 'Running' || urlPath.name == 'About' || urlPath.name == 'BehaviorLevel'||urlPath.name == 'AndroidConfig'
        ||urlPath.name == 'SystemWarning' || urlPath.name == 'S6000Config'||urlPath.name == 'SecurityConfig' ||urlPath.name == 'CustomizedConfig'
        ||urlPath.name == 'License' || urlPath.name == 'Contact'){
         this.activeMenu = 'SystemSettings'
          this.slideMenuActive = '0'
          this.handleSelect('SystemSettings')
        }else {
          this.activeMenu = urlPath.name
          this.handleSelect(urlPath.name)
          this.slideMenuActive = '0'
        }
      }
    }, 100)
    socketApi.initWebSocket(this.getsocketResult);
    // this.iframeWin = this.$refs.iframe.contentWindow
  },
  beforeDestroy() {//销毁的生命周期
	   socketApi.closeWebSocket();
  },
  methods: {
    //修改密码
    editpass(){
      this.statusVisiblemm=true
    },
    LanguageSetting(){
      this.LanguageFlag = true
    },
    heightCustom() {// 高度
      this.height.height = this.num
    },
    handleClosePass(){
      
      this.statusVisiblemm=false
      this.form={}
      this.$refs['form'].resetFields()
    },
    handleCloseLanguage(){
      this.LanguageFlag = false
    },
    determineLanguage() {
      this.LanguageFlag = false
    },
    determinePass(){
      this.changePass('form')
    },
    lookRouter(){
      this.$refs['waringInfo'].doClose()
      //判断一级 用户管理 名称是否能找到
      let ViolationName = this.$store.state.selectList.find(item=>item.name=="SecurityMonitor")
      if(ViolationName?.name){
        ViolationName.children.forEach((element) => {
          if (element.name.indexOf('WarningInfo') != -1) {
            this.$router.push({
              name: 'WarningInfo',
              query: {}
            })
          } else {
            return false
          }
        })
      }
      // this.$router.push('/WarningInfo')
    },
    // socket信息返回接受函数
	   getsocketResult(data) {
	     if(data.type == "alarm") {
         this.latestFunction()
       }
       if(data.type == "logOut") {
          this.logoutFunction()
       }
	   },
     async logoutFunction(){
      console.log("退出")
        this.$message({
          message: this.$t('MainHome.Error6'),
          type: 'warning'
        })
        const res = await this.$axios.post(
          '/httpServe/logout',
          {},
          true
        )
        sessionStorage.clear()
        setTimeout(() => {
            window.location.href = vueConfig.jqUrl + "vue/web/login"
        }, 3000);
     },
     async latestFunction(){
        const res = await this.$axios.get("/httpServe/alarm/latest?size=5", true);
        console.log(res.data.data)
        let warningListSession = JSON.parse(sessionStorage.getItem('warningList'))
        if(!warningListSession) {
          this.redwitchFlag = true
        }
        if(warningListSession&&warningListSession.length>0){
          warningListSession.forEach((item,index)=>{
          if(item.id !==res.data.data[index]?.id) {
            this.redwitchFlag = true
          }
        })
        }
        if(res.data.data&&res.data.data.length>0) {
          this.warningList = res.data.data;
          sessionStorage.setItem('warningList',JSON.stringify(res.data.data));
        }
     },
	   //发送socket信息
	   websocketSend(data) {
	     socketApi.sendSock(data);
	   },
    doumps() {
      this.$router.push('/situational')
    },
    goWanningInfo(){
      this.redwitchFlag = false
    },
    handleClose() {
      this.Successdialog = false
    },
    determine() {
      this.Successdialog = false
    },
    //点击选择语言
    //语言切换
    langChange(e) {
      // console.log(e)
      localStorage.setItem("language", e);
      this.$i18n.locale = e;
    },
    // 点击二级路由显示数据
    handleMessage(event) {
      if (event.data.params && event.data.params.type == 'open') {
        this.maskFlag = true
        var mask = document.getElementById('mask')
        mask && (mask.style.display = 'block')
        // 多层模态框
        this.count++
      } else {
        this.count--
        if (this.count <= 0) {
          this.maskFlag = false
          var mask = document.getElementById('mask')
          mask && (mask.style.display = 'none')
          this.count = 0
        }
      }
      var data = event.data
      if (data.cmd && data.cmd == 'clickMenu') {
        if (data.params) {
          var params = data.params
          this.$axios.setOneTimeParam('menu', params) // this.$parent.$parent.$parent.$parent.clickMenu();
          this.clickMenu()
        }
      }
    },
    setPath(item, key, index, even) {
      console.log(key,this.mouseKeys,this.menuKeys)
      if (item.isVue == 1) {
        this.menuKeys = key
        window.sessionStorage.setItem('pathUrl', item.path)
        return
      }
      this.menuKeys = index;
      if (!even?.hasOwnProperty("target")) {
        return false;
      }
      if (even?.target.getAttribute("class") == "el-menu-item is-active") {
        return false;
      }
      if (item.path) {
        this.url = item.path
      } else if (item.children.length > 0) {
        this.url = item.children[0].url
      }
      // 点击二级路由高亮
      this.$router.push(item.path)
      this.slideMenuActive = key + '-' + '0'
    },
    setPaths(item, key, index, even) {
      console.log(item, key, index, even)
      if (item.isVue == 1) {
        this.menuKeys = index
        window.sessionStorage.setItem('pathUrl', item.path)
        return
      }
      this.menuKeys = index
      if (!even.hasOwnProperty('target')) {
        return false
      }
      if (even.target.getAttribute('class') == 'el-menu-item is-active') {
        return false
      }
      if (item.path) {
        this.url = item.path
      } else if (item.children.length > 0) {
        this.url = item.children[0].url
      }
      // 点击二级路由高亮
      this.$router.push(item.path)
      this.slideMenuActive = key + '-' + '0'
    },
    getpath(parentId, arr) {
      this.$axios
        .post('/httpServe/menu/selectList', { parentId: parentId }, true, false)
        .then((res) => {
          // console.log(res)
          res.data.forEach((item) => {
            var newMenu = {
              path: item.name,
              name: item.name,
              isVue: item.isVue || 0,
              url: item.url,
              params: item.params,
              meta: {
                title: locale.locale('menu.' + item.name)
              },
              children: []
            }
            if (item.icon) {
              if (0 == parentId) {
                newMenu.meta['icon'] = this.address + '/images/' + item.icon
              } else {
                newMenu.meta['icon'] =
                  this.address + '/images/menu/white/' + item.icon
                newMenu.meta['icon1'] =
                  this.address + '/images/menu/default/' + item.icon
              }
            }
            if (item.parent == '1') {
              this.getpath(item.id, newMenu.children)
            }
            arr.push(newMenu)
          })
          this.$store.commit('updateSelect', this.arr)
          console.log(this.arr[0])
          sessionStorage.setItem('urlPath', JSON.stringify(this.arr[0]))
        })
        .catch((err) => {})
      
    },
    //鼠标移入
    mouseOver(key) {
      this.mouseKeys = key+"";
    },
    //鼠标离开
    mouseLeave() {
      this.$nextTick(()=>{
        this.mouseKeys = "";
      })
    },
    // 点击一级(用户管理)显示数据
    handleSelect(key, keyPath, keyObjcet, sliderIndex) {
      this.menuKeys = 0
      //切换页面删除本地和vuex中分配管理的数据
      this.$store.state.SelectAssignData = []
      sessionStorage.removeItem('complianceList')
      sessionStorage.removeItem('vuex')
      console.log(key)
      this.activeMenu = key
      this.childChildren = []
      this.arr.forEach((item) => {
        if (item.name == key) {
          this.childChildren = item.children
        }
      })
      if (Boolean(sessionStorage.getItem('Authorization'))) {
        //判断提示登录失效
        var strings = sessionStorage.getItem('Authorization').split('.')
        var userinfo = JSON.parse(
          decodeURIComponent(
            escape(
              window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))
            )
          )
        )
        var time = new Date().getTime() + ''
        time.substring(0, 10)
        if (time.substring(0, 10) > userinfo.exp) {
          sessionStorage.clear()
          this.$router.push('/login')
          this.$message.error(this.$t('MainHome.Error7'))
        }
      }
      // this.slideMenuActive = '0'
      this.luyouFlag = true
      this.activeIndex = key
      if (this.childChildren.length == 0) {
        this.$router.push('SourceHome')
        return
      }
      if (this.childChildren[0].children.length > 0) {
        this.slideMenuActive = '0-0'
        if (this.childChildren[0].children) {
          console.log(this.childChildren[0].children[0].name)
          this.$router.push(this.childChildren[0].children[0].name)
        } else {
          this.$router.push(this.childChildren[0].name)
        }
      } else {
        this.sliderIndex = sliderIndex || 0
        // this.sliderIndex = this.menuKeys;
        // this.sliderIndex = this.mouseKeys;
        this.slideMenuActive = '' + this.sliderIndex
        var item = this.childChildren[this.sliderIndex]
        if (item) {
          this.$router.push(item.name)
          this.url = item.url
          var p = item.params
        }
      }
    },
    // 点击二级(用户管理)显示数据
    slideSelect(key, keyPath, item) {
      console.log(key, keyPath, '点击二级')
      let that = this
      that.dateKey = new Date().getTime()
      this.luyouFlag = false
      if (this.$route.query.falgKeypath) {
        if (key === undefined && keyPath === undefined) {
          this.slideMenuActive = this.$route.query.keyPath[0]
          this.url = this.childChildren[this.$route.query.key].url
          p = this.childChildren[this.$route.query.key].params
          this.$router.push(this.childChildren[this.$route.query.key].path)
          query: {
            t: +new Date() // 保证每次点击路由的query项都是不一样的，确保会重新刷新view
          }

          return false
        }
      }
      var p = ''
      if (keyPath !== undefined && keyPath.length == 1) {
        this.slideMenuActive = keyPath[0]
        this.url = this.childChildren[key].url
        p = this.childChildren[key].params
        this.$router.push(this.childChildren[key].path)
      } else {
        this.slideMenuActive = keyPath[1]
        var k1 = keyPath[0]
        var k2 = keyPath[1].substring(2)
        this.url = this.childChildren[k1].children[k2].url
        p = this.childChildren[k1].children[k2].params
        this.$router.push(this.childChildren[k1].children[k2].path)
      }
    },
    handelClose(key, keyPath) {
      this.$refs.menus.close(keyPath)
    },
    handleOpen(key, keyPath) {
      this.$refs.menus.open(keyPath)
    },
    // 侧边栏显示隐藏
    showSlidebar() {
      this.flag = !this.flag
      if (this.flag) {
        this.hideicons = 'open'
      } else {
        this.hideicons = 'close'
      }
    },
    // 修改密码  鼠标移入移出
    changePass() {
      this.$refs.form.validate((valid) => {
        console.log(valid,"valid")
        if (valid) {
          this.statusVisiblemm=false
          // 请求接口   /loginUser/changePwd
          this.$axios
            .post(
              '/httpServe/changePwd',
              {
                userName: this.userName,
                passWord: utils.common.encrypt(
                  this.form.oldPassword,
                  'SM4',
                  $.getSecretKey(this.userName)
                ),
                newPassword: utils.common.encrypt(
                  this.form.newPassword1,
                  'SM4',
                  $.getSecretKey(this.userName)
                )
              },
              true
            )
            .then((res) => {
              if (res.data.status == 1) {
                this.$message({
                  message: this.$t('MainHome.Success'),
                  type: 'success',
                  offset: 100
                })
                this.$refs.form.clearValidate()
                this.form = {}
              } 
              if (res.data.status == 2) {
                this.$message({
                  message: this.$t('MainHome.Error8')+res.data.errorMsg+this.$t('MainHome.Error9'),
                  type: 'error',
                  offset: 100
                })
                this.$refs.form.clearValidate()
                this.form = {}
              }
              if (res.data.status == 3) {
                this.$message({
                  message: this.$t('MainHome.Error10')+res.data.errorMsg+this.$t('MainHome.Error11'),
                  type: 'error',
                  offset: 100
                })
                this.$refs.form.clearValidate()
                this.form = {}
              }
            })
        } else {
          return false
        }
      })
    },
    // 关闭当前页面
    // sendMsg() {
    //   this.Successdialog = false;
    //   window.clearInterval(this.time);
    //   setTimeout(() => {
    //     this.Sencond = 5;
    //   }, 1000);
    // },
    // 退出
    logout() {
      this.$confirm(this.$t('MainHome.MessagePort'), this.$t('public.PromptMessage'), {
        confirmButtonText: this.$t('public.Verify'),
        cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      }).then(async() => {
        const res = await this.$axios.post(
          '/httpServe/logout',
          {},
          true
        )
        // console.log(res)
        // window.location.href = "about:blank";
        // window.close();
        sessionStorage.clear()
        this.$router.push('/login')
      })
    },
    // 产品说明
    async product() {
      var params = {
        // type: 'protected',
        path: 'public/模板文档/产品说明.docx'
      }
      //  /httpServe/sysFile/fileDown
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        params,
        true
      )
      // let url = Base64.decode(res.data)
      // console.log(url,'----') 
      // location.href = url
      await this.$axios.downloadFile(res.data)
    },
    // 操作手册
    async book() {
      var params = {
        // type: 'protected',
        path: 'public/模板文档/操作手册.docx'
      }
      //  /httpServe/sysFile/fileDown
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        params,
        true
      )
      await this.$axios.downloadFile(res.data)
    },
    // 政策文件
    async file() {
      var params = {
        // type: 'protected',
        path: 'public/模板文档/GB_T 41391-2022 信息安全技术 移动互联网应用程序（App）收集个人信息基本要求.pdf'
      }
      //  /httpServe/sysFile/fileDown
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        params,
        true
      )
      await this.$axios.downloadFile(res.data)
    },
    clickMenu(id) {
      var getIndex = function (arr, val) {
        var index = -1
        arr.forEach((item, jndex) => {
          if (item.name === val) {
            index = jndex
            return false
          }
        })
        return index
      }

      var menu = this.$axios.getOneTimeParam('menu')
      var menuOrg = menu
      var menuArr = menu.split('/')
      if (menuArr.length > 0) {
        var index = getIndex(this.arr, menuArr[0])
        menuArr[0] = index
        if (index >= 0 && menuArr.length > 1) {
          menuArr[1] = getIndex(this.arr[index].children, menuArr[1])
        }
      }

      if (menuArr[0] < 0 || menuArr[1] < 0) {
        return false
      }

      if (menuArr.length > 0) {
        if (menuArr[0] == this.activeIndex) {
          // this.setPath(this.arr[menuArr[0]].children[menuArr[1]], "" + menuArr[1]);
          this.slideSelect(menuArr[1], ['' + menuArr[1]], {})
          // this.$ref.slideMenu.children[menuArr[1]];
        } else {
          this.handleSelect(menuArr[0], undefined, undefined, menuArr[1])
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px;
}
.text-style {
  position: relative;
  font-weight: bold;
  span{
    display: inline-block;
    font-weight: 500;
    position: absolute;
    right:0;
    top: 0;
    color:rgb(30, 137, 229);
    cursor: pointer;
  }
}
.logout {
  position: relative;
}
.red-style {
  background: red;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: none;
  position: absolute;
  left: 35px;
  top: 5px;
}
// 齿轮设置
::v-deep .icon{
  width: 1.5em;
  height: 1.5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.home {
  width: 100%;
  height: 100%;
  display: flex;
}
iframe {
  position: relative;
  z-index: 999;
}
.el-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
/* 头部 */
.el-header {
  height: 70px !important;
  width: 100%;
  background: #1e89e5;
  display: flex;
  padding: 0 !important;
}
.top-logo {
  height: 70px;
  width: 200px;
}
.logo {
  width: 183px;
  height: 70px;
}
.el-header .el-menu {
  flex: 1;
  border-bottom: none !important;
  display: flex;
  text-align: center;
}
.el-header .el-menu .el-menu-item {
  width: 125px;
  font-size: 16px;
  height: 70px !important;
  color: #fff !important;
  line-height: 70px !important;
  border-bottom: none !important;
  font-weight: bold;
  padding: 0;
}
.el-menu-item.is-active {
  background: #0f67b5 !important;
}
.home .el-dialog  {
  border-radius: 6px !important;
  position: relative;
  margin: 300px auto !important;
}
.home .el-dialog__header  {
  background: #1e89e5;
  padding: 5px 20px !important;
}
.home .el-dialog__headerbtn  {
  position: absolute;
  top: 10px !important;
  right: 20px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 16px;
}
.home .el-form-item__label  {
  font-size: 12px !important;
}
.home .el-dialog__body  {
  padding: 0 !important;
  margin-top: 10px !important;
}
.home .el-dialog__title,
.home .el-dialog__headerbtn .el-dialog__close  {
  color: #fff !important;
  font-size: 14px !important;
}
.home .el-form-item  {
  margin-bottom: 0 !important;
}

.home .el-input__inner  {
  width: 200px !important;
}
.home .el-form-item__content  {
  line-height: 0 !important;
}
.home .el-dialog__footer  {
  padding: 10px 20px !important;
  text-align: right;
  box-sizing: border-box;
}
/* 侧边栏 */
.el-aside {
  background: #f8f8f8 !important;
  color: #333;
  width: 187px;
}
.el-aside .el-menu .el-menu-item {
  height: 36px !important;
  line-height: 36px !important;
  font-size: 12px;
  border-bottom: 1px solid #e7e7e7 !important;
  border-top: 1px solid #fff;
}
.el-aside .el-menu .el-menu-item .icons {
  margin-right: 15px;
}
.el-aside .el-menu .el-menu-item:hover {
  background: #3a91ef !important;
  color: #fff !important;
  font-weight: bold;
}
.el-aside .el-menu .el-menu-item.is-active {
  background: url('../images/sec-selectedbg.png') !important;
  color: #fff !important;
  font-weight: bold;
}
/* 22222 */
.el-menu-item span,
.el-submenu__title span {
  height: 36px !important;
  line-height: 36px !important;
}
/* 侧边栏显示隐藏 */
.open {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: rotate(180deg);
  z-index: 9999;
  color: #3a91ef;
  font-size: 20px;
}
.close {
  position: absolute;
  top: 50%;
  left: 180px;
  z-index: 2;
  color: #3a91ef;
  font-size: 20px;
}
.el-main {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #333;
  padding: 0 !important;
}
/* 底部 */
.el-footer {
  height: 30px !important;
  background: #1e89e5;
  text-align: center;
  line-height: 30px;
  border-top: 1px solid #abdba5;
  color: #ffffff;
  font-size: 12px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
/* 遮罩层 */
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: #333;
  background: #aaaaaa url('~@/images/ui-bg_flat_0_aaaaaa_40x100.png') 50% 50%
    repeat-x;
  display: none;
  opacity: 0.3;
  z-index: 999;
}
.el-submenu__icon-arrow {
  display: none;
}
.el-icon-arrow-down:before {
  display: none;
}
.el-submenu .el-submenu__title {
  font-size: 12px !important;
  border-bottom: 1px solid #e7e7e7 !important;
  height: 36px;
  line-height: 36px;
}
.el-submenu .el-submenu__title:hover {
  background: #3a91ef !important;
}
.el-submenu.is-active .el-submenu__title {
  background: url('../images/sec-selectedbg.png') !important;
  border-top: 1px solid #fff;
  color: #fff !important;
  font-weight: bold;
}
.el-aside .el-menu .el-submenu .el-menu-item {
  color: #6f6f70 !important;
  padding-left: 52px !important;
  border-bottom: 1px solid #e7e7e7 !important;
  min-width: 186px !important;
}
.el-aside .el-menu .el-submenu .el-menu-item:hover {
  background: #c5dffa !important;
}
.el-aside .el-menu .el-submenu .el-menu-item.is-active {
  background: #c5dffa !important;
  color: #1e89e5 !important;
}
.el-submenu .el-submenu__title .icons {
  margin-right: 15px;
}
.el-select {
  width: 100px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}

</style>
<style lang="scss">
.el-button--primary {
  background-color: #1e89e5 !important;
  border-color: #1e89e5 !important;
}
</style>
