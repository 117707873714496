export default {
    el:{
        colorpicker: {
            confirm: 'OK',
            clear: 'Clear'
          },
          datepicker: {
            now: 'Now',
            today: 'Today',
            cancel: 'Cancel',
            clear: 'Clear',
            confirm: 'OK',
            selectDate: 'Select date',
            selectTime: 'Select time',
            startDate: 'Start Date',
            startTime: 'Start Time',
            endDate: 'End Date',
            endTime: 'End Time',
            prevYear: 'Previous Year',
            nextYear: 'Next Year',
            prevMonth: 'Previous Month',
            nextMonth: 'Next Month',
            year: '',
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            week: 'week',
            weeks: {
              sun: 'Sun',
              mon: 'Mon',
              tue: 'Tue',
              wed: 'Wed',
              thu: 'Thu',
              fri: 'Fri',
              sat: 'Sat'
            },
            months: {
              jan: 'Jan',
              feb: 'Feb',
              mar: 'Mar',
              apr: 'Apr',
              may: 'May',
              jun: 'Jun',
              jul: 'Jul',
              aug: 'Aug',
              sep: 'Sep',
              oct: 'Oct',
              nov: 'Nov',
              dec: 'Dec'
            }
          },
          select: {
            loading: 'Loading',
            noMatch: 'No matching data',
            noData: 'No data',
            placeholder: 'Select'
          },
          cascader: {
            noMatch: 'No matching data',
            loading: 'Loading',
            placeholder: 'Select',
            noData: 'No data'
          },
          pagination: {
            goto: 'Go to',
            pagesize: '/page',
            total: 'Total {total}',
            pageClassifier: ''
          },
          messagebox: {
            title: 'Message',
            confirm: 'OK',
            cancel: 'Cancel',
            error: 'Illegal input'
          },
          upload: {
            deleteTip: 'press delete to remove',
            delete: 'Delete',
            preview: 'Preview',
            continue: 'Continue'
          },
          table: {
            emptyText: 'No Data',
            confirmFilter: 'Confirm',
            resetFilter: 'Reset',
            clearFilter: 'All',
            sumText: 'Sum'
          },
          tree: {
            emptyText: 'No Data'
          },
          transfer: {
            noMatch: 'No matching data',
            noData: 'No data',
            titles: ['List 1', 'List 2'], // to be translated
            filterPlaceholder: 'Enter keyword', // to be translated
            noCheckedFormat: '{total} items', // to be translated
            hasCheckedFormat: '{checked}/{total} checked' // to be translated
          },
          image: {
            error: 'FAILED'
          },
          pageHeader: {
            title: 'Back' // to be translated
          },
          popconfirm: {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          },
          empty: {
            description: 'No Data'
          }
    },
    //公用
    public: {
        page:'Page',//页
        total:'Total',//总数
        records:'Records',//记录
        skip:'Skip',//跳转
        New:'New',//新增按钮
        Editor:'Editor',//编辑
        Inquire:'Inquire',//查询按钮
        Transfer:'Transfer',//转移
        Outofservice:'Out of service',//停用
        Enable:'Enable',//启用
        Save:'Save',//保存
        Size:'Size',//大小
        Name:'Name',//姓名
        Delete:'Delete',//删除
        Verify:'Verify',//确定
        Cancel:'Cancel',//取消
        Derive:'Derive',//导出
        Unlock:'Unlock',//解锁
        Details:'Details',//详情
        Section:'Section',//部门
        Controls:'Controls',//操作
        Position:'Position',//位置
        Username:'Username',//用户名
        Forbidden:'Forbidden',//禁用
        Installed:'Installed',//已安装
        Executed:'Executed',//已执行
        HaveRead:'Have read',//已阅读
        Assigned:'Assigned',//已分配
        Toleadinto:'To lead into',//导入
        Distribution:'Distribution',//分配
        OperatingSystem:'Operating system',//操作系统
        ApplicationName:'Application name',//应用名称
        StartTime:'Start time',//开始时间
        ModificationTime:'Modification time',//修改时间
        ReportingTime:'Reporting time',//上报时间
        GenerationTime:'Generation time',//产生时间
        SystemVersion:'System version',//系统版本
        ApplicationVersion:'Application version',//应用版本
        DeviceName:'Device name',//设备名称
        Behaviorlevel:'Behavior level',//行为级别
        BehaviorType:'Behavior type',//行为类型
    },
    //路由
    menu: {
        /*Asset: "系统资产",*/
        SourceHome: "Home",
        UserManage: "User",
        DeviceManage: "Equipment",
        ContentManage: "Content",
        AppManage: "Apply",
        PolicyManage: "策略管理",
        DevicePolicy: "Device policy",
        SystemStatus: "系统状态",
        SystemSettings: "系统配置",
        LogManage: "事件管理",
        Help: "帮助",
        Debugging: "系统调试",
        NetworkDataBackup: "流量备份",
        CategoryManage: "分类管理",
        IDCard: "身份证号",
        BankCard: "银行卡号",
        Cell: "手机号码",
        Keyword: "关键字",
        Regex: "正则表达式",
        FileAttr: "文件属性",
        FileHash: "文件指纹",
        Semantic: "语义分析",
        LevelManage: "分级管理",
        WhiteSheet: "白名单",
        WSConnect: "网络连接",
        WSServer: "内部服务器",
        FileType: "文件类型",
        Advanced: "高级设置",
        LogQuery: "事件查询",
        SysLogQuery: "管理员事件",
        SysLogFile: "流量分析日志",
        LogStatistics: "风险分析",
        NetworkSettings: "网络设置",
        StaticRoute: "静态路由",
        Upgrade: "系统升级",
        Backup_Restore: "备份/恢复",
        PowerSettings: "电源设置",
        FactoryDefault: "恢复出厂",
        ByCategory: "按策略",
        ByLevel: "按级别",
        About: "关于",
        License: "授权激活",
        Tool: "工具下载",
        Contact: "联系我们",
        PortMap: "端口映射",
        Clean: "定时清理",
        Graphic: "风险分析",
        Graphic_Level: "按级别统计",
        Graphic_Category: "按策略统计",
        Graphic_OutAddr: "按外部地址统计",
        Graphic_InAddr: "按内部地址统计",
        File_match: "匹配文件",
        File_Nmatch: "未匹配文件",
        File_Unknown: "未检测文件",
        Resource: "资源管理",
        FileInfo: "文件信息",
        MailInfo: "邮件信息",
        DBHash: "数据库指纹",
        Rule: "策略管理",
        Sms_Template: "短信模板",
        Mail_Template: "邮件模板",
        Code: "代码生成",
        Admin: "Admin management",
        Administrator: "Administrator",
        Role: "Role",
        Employee: "员工管理",
        UserInfoList: "User management",
        Groups: "Section",
        User: "User",
        Device: "Equipment management",
        DeviceGroup: "Device group",
        Document: "Document management",
        DocumentCategory: "Document category",
        SetVisible: "设置可见范围",
        SettledVisible: "已设范围",
        App: "App store",
        AppPolicy: "Application strategy",
        AppCategory: "Application category",
        AppLib: "Application library",
        WaterConfig: "Interface watermarking",
        WaterResolution:"Dark watermark resolution",
        BwList: "黑白名单",
        IOSBw: "iOS",
        AndroidBw: "Android",
        WifiBw: "WiFi",
        Notice: "Notification management",
        StorageManage: "存储管理",
        DataManage: "数据集",
        PStatd: "协议分析",
        PFileParse: "文件分析",
        Table: "数据表",
        Field: "字段管理",
        DB: "数据库设置",
        EmailTemplate: "邮件模板",
        SmsTemplate: "短信模板",
        Version: "Terminal version",
        TimedTask: "定时任务",
        PolicyList: "策略列表",
        GeoFence: "Geofencing",
        ManageLogs: "日志审计",
        AlarmLog: "告警日志",
        ClientLog: "终端日志",
        ManageLog: "管理日志",
        ArchiveLog: "日志归档",
        InternetRecords: "上网记录",
        UrlAccessRecord: "网址访问记录",
        ClientPolicy: "Compliance strategy",
        IOS: "iOS应用",
        Android: "Android应用",
        sandbox: "安全沙箱",
        normalApp: "未做防护",
        UrlLibrary: "网址库",
        UrlList: "网址总列表",
        NoTypeUrlList: "未分类列表",
        BaseConfig: "基线策略",
        GroupConfig: "组策略",
        Safety: "安全域名分类",
        Violation: "违规域名分类",
        Running: "运行配置",
        DBConfig: "数据库配置",
        PushConfig: "分配配置",
        SmsGatewayConfig: "短信网关",
        EmailGatewayConfig: "邮件网关",
        SystemDefined: "自定义配置",
        DeliveryHistory: "Distribution history",
        DeliveryManage: "DeliveryManage",
        Delivery: "Distribution",
        ExecResult: "Execution result",
        Reinforce: "加固配置",
        AppConfig: "客户端配置",
        HttpUrl: "服务器地址",
        IosCertificateManage: "iOS证书管理",
        IOSOTA: "iOS OTA",
        UserIdea: "用户反馈",
        BackupAndRestore: "备份与恢复",
        BackupRestore: "备份/恢复",
        AndroidCertificateManage: "Android证书管理",
        AppReinforce: "Application hardening",
        Scan: "Application detection",
        Home1: "安全态势",
        SecurityMonitor: "Monitor",
        ViolationMonitor: "Violation",
        AppAttackMonitor: "Threatening behavior",
        AppSensitiveMonitor: "Sensitive behavior",
        "Scan&Fasten": "检测加固",
        CustomConfig: "页面自定义",
        Syslog: "系统日志",
        SecurityConfig: "安全配置",
        AndroidConfig: "Android配置",
        IosConfig: "iOS配置",
        CustomizedConfig: "定制化配置",
        Upgrade: "系统升级",
        S6000Config: "对外接口配置",
        SystemWarning:"系统预警配置",
        SensitiveWords: "Sensitive lexicon",
        Behavior: "Audit information",
        TimeFence: "Time fence",
        SafetyAnalysis: "Security incident",
        SDKMonitor: "SDK monitoring",
        BannedConfig: "Blocking configuration",
        WhiteList: "White list",
        SDKBehaviour: "SDK behavior",
        Interdiction: "Act of suppression",
        DangerIP:'Risk IP',
        RunInfo: "Operational information",
        Accessbehavior: "Access behavior",
        BehaviorLevel: "行为级别配置",
        Home2: "Alarm information",
        WarningInfo:"Early warning information",
        ThreatDefinition: "Threat definition",
        SafetyEvent: "Security incident",
        MobileOperations: "MobileOperations",
        CrashAnalysis: "Crash message",
        SecurityEvent: "Security incident",
        PolicyConfig: "Policy configuration",
        Index: "首页",
        deviceClientLog: "Client log",
        GDment: "Work order management",
        ClientHelp: "User help configuration",
        RunManagement: "运营管理",
        ArtResult: "技术成果",
        ResultsCategory: "成果类别",
        InstitutionalNorm: "制度规范",
        SpecificationCategory: "规范类别",
        workFlowManagement: "workFlowManagement",
        workFlowSet: "workFlowSet",
        H5Reinforce: "H5Reinforce",
        CertificateManage: "Certificate management",
        ApplyForapproval: "ApplyForapproval",
        MyApplication: "MyApplication",
        MyForapproval: "MyForapproval",
        ReportManagement: "Statement",
        DataReport: "Data report",
        ReportTemplate: "Create a report",
        developmentCenter: "开发中心",
        developmentCategory: "开发类别",
        SafetyScan: "Safety detection",
        H5Scan: "H5检测",
        SdkScan: "SDK检测",
        ScanLevel: "Detection level",
        ScanAnalysis: "Detection and analysis",
        SafetyReinforce: "Security hardening",
        SDKReinforce: "SDK hardening",
        AccessConfiguration: "Gateway",
        GatewayResourceManagement: "Gateway configuration",
        ServiceResourceManagement: "Business resource",
        GatewayAccessLog: "网关访问日志",
        VirusInfo:"Virus information",
        SelfServiceDesk:"自助服务台",
        equipmentManagement:"设备管理",
    },
    //首页
    home: {
        top: {
            deviceSum: "Equipment total",
            on_line: "online",
            off_line: "offline",
            userSum: "Users total",
            average: "normal",
            lock: "lock",
            dead : "inactive",
            vioTotal: "Violation total",
            todayViolationCount: "That day",
            total:'total',
            few: "individual ",
            bench:'bench',
            throughs:'Throughs'
        },
        // 中间
        middle: {
            SystemUsage: 'System usage',
            DeviceActiveTrend: 'Device Active Trend',
            PlatformContentStatistics: 'Platform Content Statistic',
            cpuUseRate: 'CPU utilization',
            used: 'used',
            gross: 'gross',
            MemoryUsage: 'Memory usage',
            DiskUsage: 'Disk usage',
            appSum: 'App total',
            strategySum: 'Strategy total',
            documentsSum: 'Archive total',
            noticeSum: 'Inform total',
            choose:'Please select'
        },
        third: {
            CompStatus: 'Compliance status distribution',
            ViolationTrend: 'Violation trend',
            DistributionViola: 'Distribution of violations',
        }
    },
    //用户管理
    UserManage:{

    },
    //设备管理
    DeviceManage:{

    },
    //应用管理
    AppManage:{
        //应用商店
        AppManagePage: {
            安装类型:'Installation type',
            普通应用:'General application',
            必装应用:'Mandatory application'
        },
    },
    //内容管理
    ContentManage:{
    //应用商店
    AppManage: {
        安装类型:'Installation type',
        普通应用:'General application',
        必装应用:'Mandatory application'
    },
    //网关配置
    GatewayResourceManagement: {
        NewGatewayBtn:'NewGateway',
        inquireBtn:'Inquire',
        deleteBtn:'Delete',
        placeholder:'Please enter a gateway name',
        GatewayName:'Gateway name',
        GatewayAddress:'Gateway address',
        Port:'Port',
        Protected:'Protected service resources',
        ModificationTime:'Modification time',
        Controls:'Controls'
    },
  },
  violation: {
    reason_0: "设备越狱",
    reason_1: "系统版本",
    reason_2: "应用黑名单",
    reason_3: "应用白名单",
    reason_4: "",
    reason_5: "地理围栏",
    reason_6: "",
    reason_7: "流量异常",
    reason_8: "SIM卡变更",
    reason_9: "违规外联",
    reason_10: "设备管理器未激活",
    reason_11: "证书变更",
    reason_12: "必装应用",
    //16系统
    reason_16: "系统功能",
    reason_22: "应用黑白名单",
    reason_99: "其他违规",
    reason_33: "WIFI黑白名单",
    reason_36: "系统功能",
    reason_13: "SD卡变更",
    reason_14: "设备电量",
    reason_15: "存储空间",
  },
  InterTypeObj:{//上网方式
    0: "无网络",
    1: "WIFI",
    2: "2G",
    3: "3G",
    4: "4G",
    5: "5G",
    1000:"以太网",
    "无网络": "无网络",
    "WIFI": "WIFI",
    "2G": "2G",
    "3G": "3G",
    "4G": "4G",
    "5G": "5G",
    "以太网":"以太网",
  },
}