import { log } from "console";

const commonCollection = {

  // 处理数据转为 tree 数据--------menu
  combination(data, zhData) {
    // 英文转中文字典
    if (!zhData) zhData = new Map();
    // 存储临时数据 Map
    const map = new Map();
    // 父节点名
    const parentsTxt = "parents";
    // 根节点名
    const rootNode = "0";
    // 在临时数据 Map 新建父节点存储空间
    map.set(parentsTxt, new Map());
    // 父节点数据
    const parents = map.get(parentsTxt);
    // 最终 tree 的数据
    const treeData = [];
    // 深拷贝
    const copy = (o) => {
      return JSON.parse(JSON.stringify(o));
    };
    // 节点信息
    const nodeInfo = (d) => {
      d.label = zhData.get(d.name) || d.name;
      return d;
    };
    // 数据节点归纳，对数据进行节点划分
    for (const d of data) {
      // 暂存节点数据
      const node = nodeInfo(d);
      // 父节点
      const parent = String(d.parentId);
      // 当 node.parent 为 1 时，放入父节点存储空间，否者为子节点
      if (Number(node.parent)) {
        parents.set(node.id, node);
      } else {
        // 存储子节点，有子节点空间就存进去，没有就创建子节点空间
        if (map.get(node.parentId)) {
          map.get(node.parentId).push(node);
        } else {
          map.set(node.parentId, [node]);
        }
      }
    }
    // 给所有父节点添加子节点
    for (const nodeId of parents.keys()) {
      if (map.get(nodeId)) {
        parents.get(nodeId).children = map.get(nodeId);
      }
    }
    // 存储节点不为 0 的节点
    const removeNodes = [];
    // 找到根节点
    for (const parentId of parents.keys()) {
      // 当前节点
      const node = parents.get(parentId);
      // 当节点不为根节点，存储进 removeNodes
      if (String(node.parentId) != rootNode) {
        // 给不为根节点的节点找父节点
        if (parents.get(node.parentId)) {
          // 深拷贝赋值，防止后续删除掉该节点
          if (parents.get(node.parentId).children) {
            parents.get(node.parentId).children.push(copy(node));
          } else {
            parents.get(node.parentId).children = [copy(node)];
          }
        }
        // 存储不为根的节点
        removeNodes.push(parentId);
      }
    }
    // 删除父节点中不为根的节点
    for (const removeId of removeNodes) {
      parents.delete(removeId);
    }
    // 组合树数据
    for (const parentId of parents.keys()) {
      const parentsTree = copy(parents.get(parentId));
      //排序sn字段-----节点排序
      parentsTree.children = parentsTree.children ?
        parentsTree.children.sort((a, b) => a.sn - b.sn) :
        parents.get(parentId);

      treeData.push(parentsTree);
    }
    return treeData;
  },
  //百分比计算
  getPercent(num, total) {
    console.log(num, total);
    /// <summary>
    /// 求百分比
    /// </summary>
    /// <param name="num">当前数</param>
    /// <param name="total">总数</param>

    total = parseFloat(total);
    if (isNaN(num) || isNaN(total)) {
      if (num != null) {
        num = parseFloat(num);
      } else if (num == null) {
        return (num = 0 + "%");
      }
      if (total != null) {
        total = parseFloat(total);
      } else if (total == null) {
        return (total = 0 + "%");
      }
      return "N/A";
    }
    return total <= 0 ? "0%" : Math.round((num / total) * 10000) / 100.0 + "%";
  },
  //毫秒的时长转换
  // formatSeconds(value) {
  //   let secondTime = parseInt(value*1000);// 秒
  //   let minuteTime = 0;// 分
  //   let hourTime = 0;// 小时
  //   let dayTime = 0; // 天
  //   let mouthTime = 0; // 月
  //   let yearTime = 0; //年
  //   if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
  //       //获取分钟，除以60取整数，得到整数分钟
  //       minuteTime = parseInt(secondTime / 60);
  //       //获取秒数，秒数取佘，得到整数秒数
  //       secondTime = parseInt(secondTime % 60);
  //       //如果分钟大于60，将分钟转换成小时
  //       if(minuteTime > 60) {
  //           //获取小时，获取分钟除以60，得到整数小时
  //           hourTime=parseInt(minuteTime/60);
  //           //获取小时后取佘的分
  //           minuteTime=parseInt(minuteTime%60);
  //           if(hourTime>24){
  //               // 获取天数，获取小时除以24，得到整数天数
  //               dayTime=parseInt(hourTime/24);
  //               // 获取天数后取余的小时
  //               hourTime=parseInt(hourTime%24);
  //               // 一个月按30天算
  //               if(dayTime>30){
  //                   // 获取月数，天数除以30，得到整月数
  //                   mouthTime=parseInt(dayTime/30);
  //                   // 获取月数取余后的天数
  //                   dayTime=parseInt(dayTime%30);
  //                   if(mouthTime>12){
  //                       // 获取年数，月数除以12，得到整年数
  //                       yearTime=parseInt(mouthTime/12);
  //                       // 获取年数取余后的月数
  //                       mouthTime=parseInt(mouthTime%12);
  //                   }
  //               }
  //           }
  //       }
  //   }
  //   var result = "" + parseInt(secondTime) + "秒"
  //   if(minuteTime > 0) {
  //       result = "" + parseInt(minuteTime) + "分" + result;
  //   }
  //   if(hourTime > 0) {
  //       result = "" + parseInt(hourTime) + "小时" + result;
  //   }
  //   if(dayTime > 0) {
  //       result = "" + parseInt(dayTime) + "天" + result;
  //   }
  //   if(mouthTime > 0) {
  //       result = "" + parseInt(mouthTime) + "月" + result;
  //   }
  //   if(yearTime > 0) {
  //       result = "" + parseInt(yearTime) + "年" + result;
  //   }
  //   console.log(result)
  //   return result

  // },
  formatSeconds(second_time) {
    var days = second_time / 1000 / 60 / 60 / 24;
    var daysRound = Math.floor(days);
    var hours = second_time / 1000 / 60 / 60 - 24 * daysRound;
    var hoursRound = Math.floor(hours);
    var minutes =
      second_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
    var minutesRound = Math.floor(minutes);
    var seconds = parseInt(
      second_time / 1000 -
      24 * 60 * 60 * daysRound -
      60 * 60 * hoursRound -
      60 * minutesRound
    );
    var times =
      // daysRound +
      // '天' +
      // ":" +
      daysRound * 24 + hoursRound + '小时';
    // +
    // // ":" +
    // minutesRound +
    // '分' +
    // // ":" +
    // seconds +
    // '秒'
    return times;
  },
  //毫秒转换天时分秒（运行信息页面使用版本）
  formatDuring(t) {
    const HOUR = 1000 * 60 * 60;
    const d = parseInt(t / (HOUR * 24));
    const h = parseInt((t % (HOUR * 24)) / HOUR);
    const m = parseInt((t % HOUR) / (1000 * 60));
    const s = parseInt((t % (1000 * 60)) / 1000);
    let formatDuring = "";
    d && (formatDuring += `${d}天`);
    h && (formatDuring += `${h}小时`);
    m && (formatDuring += `${m}分`);
    s && (formatDuring += `${s}秒`);
    if (s < 1 || s == 0) {
      formatDuring = `0秒`
    }
    return formatDuring;
  },
  //时长转换-------按毫秒计算
  SecondToDate(str) {
    const days = parseInt(str / (1000 * 60 * 60 * 24));
    const hours = parseInt((str % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = parseInt((str % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = (str % (1000 * 60)) / 1000;
    const month = parseInt(days / 30);
    const oYear = parseInt(days / 365);
    const oYears = oYear > 0 ? oYear + "年 " : " ";
    const oMonths = month > 0 ? month + "月 " : " ";
    const oDays = days > 0 ? days + "天 " : " ";
    const oHours = hours > 0 ? hours + "小时" : " ";
    const oMins = minutes > 0 ? minutes + "分钟" : " ";
    const oSens = seconds > 0 ? seconds + "秒" : " ";
    const oTime = oYears + oMonths + oDays + oHours + oMins + oSens;
    return oTime;
  },
  // 流量格式化
  formatFlow(flow) {
    if (flow == null || flow == 0) {
      return 0 + "B";
    } else if (flow < 1024) {
      return flow + "B";
    } else if (flow < 1024 * 1024) {
      return (flow / 1024).toFixed(2) + "KB";
    } else if (flow < 1024 * 1024 * 1024) {
      return (flow / 1024 / 1024).toFixed(2) + "MB";
    } else {
      return (flow / 1024 / 1024 / 1024).toFixed(2) + "GB";
    }
  },
  // 应用大小
  formatFlowSize(limit) {
    let data = Number(limit);
    var size = "";
    if (data < 0.1 * 1024) {
      //小于0.1KB，则转化成B
      size = data.toFixed(2) + "B";
    } else if (data < 0.1 * 1024 * 1024) {
      //小于0.1MB，则转化成KB
      size = (data / 1024).toFixed(2) + "KB";
    } else if (data < 0.1 * 1024 * 1024 * 1024) {
      //小于0.1GB，则转化成MB
      size = (data / (1024 * 1024)).toFixed(2) + "MB";
    } else {
      //其他转化成GB
      size = (data / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }

    var sizeStr = size + ""; //转成字符串
    var index = sizeStr.indexOf("."); //获取小数点处的索引
    var dou = sizeStr.substr(index + 1, 2); //获取小数点后两位的值
    if (dou == "00") {
      //判断后两位是否为00，如果是则删除00
      return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
    }
    return size;
  },
  //CST格式时间转正常‘yyyy-MM-dd’
  cstDateFormat(date, format) {
    date = new Date(date);
    date.setHours(date.getHours() - 14);
    var dset = {
      "M+": date.getMonth() + 1, // 月
      "d+": date.getDate(), // 日
      "H+": date.getHours(), // 时
      "m+": date.getMinutes(), // 分
      "s+": date.getSeconds(), // 秒
      "q+": Math.floor((date.getMonth() + 3) / 3), // 刻钟
      S: date.getMilliseconds(), // 毫秒
    };

    if (/(y+)/.test(format)) {
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }

    for (var i in dset) {
      if (new RegExp("(" + i + ")").test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1 ?
            dset[i] :
            ("00" + dset[i]).substr(("" + dset[i]).length)
        );
      }
    }
    return format;
  },
  //xxxx-xx-xx
  UTCformat(utc) {
    var date = new Date(utc),
      year = date.getFullYear(),
      month =
        date.getMonth() + 1 > 9 ?
          date.getMonth() + 1 :
          "0" + parseInt(date.getMonth() + 1),
      day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
      hour = date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
      minutes =
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
      seconds =
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
    var res = year + "-" + month + "-" + day + " ";
    return res;
  },
  //一个月前日期
  getAnyMonth(symbol, n) {
    var symbol = symbol || "-";
    var nowDate = new Date();
    nowDate.setMonth(nowDate.getMonth() + n);
    var year = nowDate.getFullYear();
    var month = nowDate.getMonth() + 1;
    var day = nowDate.getDate();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return year + symbol + month + symbol + day;
  },
  //当前日期
  getDate() {
    var date = new Date();
    var year = date.getFullYear();
    var mon = date.getMonth() + 1;
    var day = date.getDate();
    return (
      year +
      "-" +
      (mon < 10 ? "0" + mon : mon) +
      "-" +
      (day < 10 ? "0" + day : day)
    );
  },

  //字符串中的逗号和空格替换成换行
  strReplace(str) {
    console.log(str);
    if (str != null) {
      if (str.indexOf(",") > -1) {
        return str.replace(/,/g, "\n");
      } else if (str.indexOf(" ") > -1) {
        return str.replace(/\s+/g, "\n");
      } else {
        return str;
      }
    }
  },
  //字符串中的空格替换成逗号
  getStr(str) {
    console.log(str);
    if (str != null) {
      if (str.indexOf(" ") > -1) {
        return str.replace(/\s+/g, ",");
      } else {
        return str;
      }
    }
  },
  // 比特每秒转换单位
  formatBytes(flow) {
    console.log(flow, '-----miao');
    if (flow == null || flow == 0) {
      console.log(flow, "null");
      return '0';
    }
    else if (flow < 1024 * 1024) {
      console.log((flow / 1024).toFixed(2) + "KB", "KB");
      return (flow / 1024).toFixed(2) + "KB";
    }
    else if (flow < 1024 * 1024 * 1024) {
      return (flow / 1024 / 1024).toFixed(2) + "MB";
    }
    else if (flow < 1024 * 1024 * 1024 * 1024) {
      return (flow / 1024 / 1024 / 1024).toFixed(2) + "GB";
    }
  },
  // 比特每秒转换单位
  // formatKbs(flow) {
  //   console.log(flow < 1024 * 1024,"flow");
  // if (flow == null || flow == 0) {
  //   return '0' ;
  // } else  if (flow < 1024 * 1024) {
  //     console.log((flow / 1024).toFixed(2));
  //     return (flow / 1024).toFixed(2) + "kb/s";
  //   }
  // },k
  // 流速转换单位
  formatKbs(size) {
    var data = "";
    if (size == null || size == 0) {
      data = '0';
    } else if (size < 0.1 * 1024) { //如果小于0.1KB转化成B  
      data = size.toFixed(2) + "b/s";
      console.log(data, "data");
    } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB  
      data = (size / 1024).toFixed(2) + "kb/s";
    } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB  
      data = (size / (1024 * 1024)).toFixed(2) + "ms/s";
    } else { //其他转化成GB
      data = (size / (1024 * 1024 * 1024)).toFixed(2) + "gb/s";
    }
    var sizestr = data + "";
    var len = sizestr.indexOf("\.");
    // console.log(len,"len");
    var dec = sizestr.substr(len + 1, 2);
    // console.log(dec,"dec");
    if (dec == "00") {//当小数点后为00时 去掉小数部分
      // console.log(sizestr,"sizestr");
      let result = sizestr.slice(0, len + 1) + sizestr.slice(len + 3)
      // console.log(result.replace(/\./, ''),"result");
      return result.replace(/\./, '')
    }

    return sizestr;
  },
  //判断两个对象是否来自相同的操作系统
  isSameOS(obj1, obj2) {
    return obj1.scopeStr === obj2.scopeStr;
  },
  // 判断对象数组中是否存在操作系统相同的元素
  hasDuplicates(array) {
    // 使用 filter 方法获取所有操作系统相同的元素
    let duplicates = array.filter((obj, index) => {
      return (
        array.findIndex((obj2) => obj.scopeStr === obj2.scopeStr) !== index
      );
    });
    // 如果 duplicates 数组的长度不为0，说明存在操作系统相同的元素 如果存在操作系统相同的元素，将输出 true，否则输出 false
    return duplicates.length > 0;
  },
  // console.log(hasDuplicates(objects)); // 如果存在操作系统相同的元素，将输出 true，否则输出 false
  // 筛选第一个操作系统为Android和第一个操作系统为iOS的元素
  filterFirstScope(arr) {
    let firstAndroidDevice;
    let firstIOSDevice;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].scopeStr === "Android") {
        firstAndroidDevice = arr[i];

        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].scopeStr === "iOS") {
        firstIOSDevice = arr[i];
        break;
      }
    }
    let newArray = [];
    if (firstAndroidDevice != undefined) {
      newArray.push(firstAndroidDevice);
    } else {
      return newArray;
    }
    if (firstIOSDevice != undefined) {
      newArray.push(firstIOSDevice);
    } else {
      return newArray;
    }
    console.log(
      newArray,
      "筛选第一个操作系统为Android和第一个操作系统为iOS的元素的newArray"
    );
    return newArray;
  },
  // 筛选出具有唯一操作系统的对象
  filterScope(data) {
    const osMap = new Map();
    // 将操作系统作为键，将对应的对象作为值存储在Map中
    data.forEach((obj) => osMap.set(obj.scopeStr, obj));

    // 筛选出具有唯一操作系统的对象
    const uniqueData = Array.from(osMap.values());
    console.log(uniqueData, "筛选出具有唯一操作系统的对象uniqueData");
    return uniqueData;
  },
  //根据后端导出转换方法做处理--------审计详情电话tab
  getDuration(duration) {
    // 假设duration是一个字符串，表示秒数  
    /* 这里应该是duration的值 */;

    // 检查duration是否为null或空字符串  
    if (duration === null || duration === "") {
      return "0" + getI18nString("second");
    }

    // 计算小时、分钟和秒  
    var h = Math.floor(parseInt(duration) / 3600);
    var m = Math.floor((parseInt(duration) % 3600) / 60);
    var s = parseInt(duration) % 60;

    // 格式化输出  
    return (h > 0 ? h + this.getI18nString("hour") : "") + (m > 0 ? m + this.getI18nString("minute") : "") + s + this.getI18nString("second");
  },
  // 假设这个函数用于获取国际化字符串  
  getI18nString(key) {
    // 这里应该是实际的国际化逻辑，根据key返回对应的字符串  
    // 例如，可以是一个对象映射，或者调用API获取翻译  
    var translations = {
      "hour": "小时",
      "minute": "分钟",
      "second": "秒"
    };
    return translations[key] || key;
  },
  //2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;LINUX:网卡Mac; Windows:网卡Mac---------仅限安全监测页面的详情
  //定义一个方法接收一个包含系统版本的字符串，然后解析并返回系统的名称
  getSystemName(version) {
    // 定义一个简单的映射来匹配系统名称
    const systemMap = {
      Android: /^Android/,
      Linux: /^Linux/,
      Windows: /^Windows/,
      iOS: /^iOS/,
    };

    // 遍历映射并找到匹配的系统名称
    for (let key in systemMap) {
      // console.log('包含系统版本的字符串：',systemMap[key]);
      if (systemMap[key].test(version)) {
        // console.log('最终解析出返回系统的名称：',key);
        return key;
      }
    }

    // 如果没有匹配到，则返回未知
    return 'Unknown';
  },
};

export default commonCollection;
