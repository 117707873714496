<template>
  <el-dialog
    v-dialogDrag
    id="detailBox"
    :num="num"
    :title="title"
    :width="width"
    :flagbtn="flagbtn"
    :append-to-body="true"
    :visible="dialogVisible"
    style="margin-top: 0%"
    :before-close="handleClose"
    :determineBtn="determineBtn"
    :cancelBtn="cancelBtn"
    :close-on-click-modal="false"
    
  >
    <div :style="height" class="box-corn">
      <slot></slot>
    </div>
    <div v-if="flagbtn" slot="footer" class="diailog-footer">
      <el-button
        v-if="determineBtn"
        size="small"
        type="primary"
        class="btn-determine"
        @click="determine"
        >{{btnTitle=='确定'?$t('public.Verify'):btnTitle}}</el-button
      >
      <el-button
        style="margin-left:5px;"
        v-if="cancelBtn"
        size="small"
        class="btn-cancel"
        @click="cancel"
        >{{$t('public.Cancel')}}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    btnTitle:{
      type: String,
      default: '确定',
    },
    dialogVisible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "详情"
    },
    width: {
      type: String,
      default: "655px"
    },
    // 默认469px
    num: {
      type: String,
      default: ""
    },
    flagbtn: { //是否开启确定取消
      type: Boolean,
      default: false
    }, 
    determineBtn: { //确定展示
      type: Boolean,
      default: false
    }, 
    cancelBtn: { //取消展示
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      height: {
        height: ""
      }
    };
  },
  created() {
    this.heightCustom();
  },
  watch: {
    dialogVisible(val) {
      this.dialogVisible = val;
    }
  },
  methods: {
    heightCustom() {
      this.height.height = this.num;
    },
    handleClose() {
      this.$emit("handleClose", false);
    },
    determine() { // 确定回调事件
      this.$emit("determine", false);
    },
    cancel() {
      this.$emit("handleClose", false);
    }
  }
};
</script>
<style lang="scss" scoped>
.box-corn {
  overflow: auto;
  overflow-x: hidden;
  // scrollbar-width: none;
  // overflow-y: hidden;
}
.dialog-footer {
  height: 40px;
  position: relative;
}
</style>
