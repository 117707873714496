import axios from "axios";

// 创建一个axios
const instance = axios.create({
  baseURL:vueConfig.thePathaddress,//会在发送请求的时候拼接在url参数的前面
  timeout: 1800000,
  headers: {
   
  }
})
// 请求拦截  所有的网络请求都会先走这个方法
instance.interceptors.request.use(config => {
  console.log(config);
  return config
}, err => {
  console.log(err);
})

// 响应拦截   所有网络请求数据之后都会先执行  状态码响应的处理  404  401 500
instance.interceptors.response.use(
  function (response) {
    console.group('全局响应拦截');
    console.log(response.data,'娟娟减少一层data并且加解密为false')
    console.groupEnd();
    return response;
  },
  function (err) {
    if (err.response.status === 401 ) {
      // 401 说明 token 验证失败
      // 可以直接跳转到登录页面，重新登录获取 token
      location.href = '/login'
    }
    return Promise.reject(err)
  }
);

export default instance
