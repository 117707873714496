/**
 *  jQuery SM3 hash algorithm function
 *  Example: $.sm3(str);
 * 			 plain string: abcd
 * 			 encrypt string: fa3fe39ddc8fe2a26566e686baf9b3338d42bb99ea8fcbbb98da0ebd9eadf56a
 */

(function($) {
	var hexStr = "0123456789abcdef";

	function SM3() {
		this.chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
		// this.ivHexStr = "468653f0 874b0ab5 3353c0b4 ddac0ccc 6dd8ad7a 3c66723c 46b7e6ae bc7cdb3c";
		// this.iv = [70, -122, 83, -16, -121, 75, 10, -75, 51, 83, -64, -76, -35, -84, 12, -52, 109, -40, -83, 122, 60, 102, 114, 60, 70, -73, -26, -82, -68, 124, -37, 60];
		this.iv = [115, -128, 22, 111, 73, 20, -78, -71, 23, 36, 66, -41, -38, -118, 6, 0, -87, 111, 48, -68, 22, 49, 56, -86, -29, -115, -18, 77, -80, -5, 14, 78];
		this.tj15 = 2043430169;
		this.tj63 = 2055708042;
		this.firstPadding = [-128];
		this.zeroPadding = new Array(1);

		this.hash = function(source) {
			var m = this.padding(source);
			var n = m.length / 64;
			var vi = this.iv;
			var vi1 = null;
			for (var i = 0; i < n; i++) {
				var bi = m.slice(i * 64, (i + 1) * 64);
				vi1 = this.cf(vi, bi);
				vi = vi1;
			}

			return vi1;
		};

		this.cf = function(vi, bi) {
			var a = this.toInteger(vi, 0);
			var b = this.toInteger(vi, 1);
			var c = this.toInteger(vi, 2);
			var d = this.toInteger(vi, 3);
			var e = this.toInteger(vi, 4);
			var f = this.toInteger(vi, 5);
			var g = this.toInteger(vi, 6);
			var h = this.toInteger(vi, 7);

			var w = new Array(68);
			var w1 = new Array(64);

			var ss1;
			for (ss1 = 0; ss1 < 16; ss1++) {
				w[ss1] = this.toInteger(bi, ss1);
			}

			for (ss1 = 16; ss1 < 68; ss1++) {
				var x = w[ss1 - 16] ^ w[ss1 - 9] ^ this.rotl(w[ss1 - 3], 15);
				w[ss1] = this.p(x, 15, 23) ^ this.rotl(w[ss1 - 13], 7) ^ w[ss1 - 6];
			}

			for (ss1 = 0; ss1 < 64; ss1++) {
				w1[ss1] = w[ss1] ^ w[ss1 + 4];
			}

			for (var v = 0; v < 64; v++) {
				var x = this.rotl(a, 12) + e + this.rotl(this.t(v), v);
				ss1 = this.rotl((this.rotl(a, 12) + e + this.rotl(this.t(v), v)), 7);
				var ss2 = ss1 ^ this.rotl(a, 12);
				var tt1 = (this.ff(a, b, c, v) + d + ss2 + w1[v]) << 0;
				var tt2 = (this.gg(e, f, g, v) + h + ss1 + w[v]) << 0;
				d = c;
				c = this.rotl(b, 9) << 0;
				b = a;
				a = tt1;
				h = g;
				g = this.rotl(f, 19) << 0;
				f = e;
				e = this.p(tt2, 9, 17) << 0;
			}

			var var18 = this.write(a, b, c, d, e, f, g, h);
			for (var i = 0 ; i < var18.length; i++) {
				var18[i] ^= vi[i];
			}

			return var18;
		};

		this.toUnsign = function (i) {
			return (i > 127) ? (~0xFF | i) : i;
		};

		this.write = function () {
			var toUnsign = function (i) {
				return (i > 127) ? (~0xFF | i) : i;
			};

			var baos = new Array();
			for (var i = 0; i < arguments.length; i++) {
				baos.push(toUnsign(arguments[i] >>> 24));
				baos.push(toUnsign((arguments[i] & 0xFFFFFF) >>> 16));
				baos.push(toUnsign((arguments[i] & 0xFFFF) >>> 8));
				baos.push(toUnsign((arguments[i] & 0xFF) >>> 0));
			}

			return baos;
		}

		this.t = function(j) {
			if (0 <= j && j < 16) {
				return this.tj15;
			} else if (16 <= j && j < 64) {
				return this.tj63;
			} else {
				console.error("invalid j for constant Tj");
			}
		};

		this.ff = function(x, y, z, j) {
			if (0 <= j && j < 16) {
				return (x ^ y ^ z);
			} else if (16 <= j && j < 64) {
				return (x & y) | (x & z) | (y & z);
			} else {
				console.error("invalid j for ff");
				return 0;
			}
		};

		this.gg = function(x, y, z, j) {
			if (0 <= j && j < 16) {
				return (x ^ y ^ z);
			} else if (16 <= j && j < 64) {
				return (x & y) | (~x & z);
			} else {
				console.error("invalid j for gg");
				return 0;
			}
		};

		this.p = function(x, first, second) {
			return x ^ this.rotl(x, first) ^ this.rotl(x, second);
		};

		this.rotl = function(x, n) {
			n %= 32;
			return ((x << n) | (x >>> (32 - n))) >>> 0;
			// return i << n | i >>> -n;
		};

		this.toInteger = function(source, index) {
			var str = '';

			for (var i = 0; i < 4; i++) {
				str += this.chars[((source[index * 4 + i] & 240) >> 4)];
				str += this.chars[(source[index * 4 + i] & 15)];
			}

			return this.parseHex(str);
		};

		this.parseHex = function(str) {
			var r = 0;
			var len = str.length;
			var z = 0;
			for (var i = 0; i < len; i++) {
				var c = str.charCodeAt(i) - 48;

				r <<= 4;

				var b;

				// 'a' - 'f'
				if (c >= 49 && c <= 54) {
					b = c - 49 + 0xa;

					// 'A' - 'F'
				} else if (c >= 17 && c <= 22) {
					b = c - 17 + 0xa;

					// '0' - '9'
				} else {
					b = c;
				}

				r |= b;
				z |= b;
			}

			return r;
		}

		this.padding = function(source) {
			if (source == null) {
				return null;
			}

			if (source.length >= Number.MAX_SAFE_INTEGER) {
				throw new Error("src data invalid.");
			}

			var l = source.length * 8;
			var k = 448 - (l + 1) % 512;
			if (k < 0) {
				k += 512;
			}

			var bous = new Array();
			bous = bous.concat(source);
			bous = bous.concat(this.firstPadding);

			for (var i = k - 7; i > 0; i -= 8) {
				bous = bous.concat(this.zeroPadding);
			}

			bous = bous.concat(this.long2bytes(l));

			return bous;
		};

		this.long2bytes = function(l) {
			var bytes = new Array(8);
			for (var i = 0; i < 8; ++i) {
				var pos = (7 - i) * 8;
				// if (pos >= 32 && pos % 32 === 0) {
				if (pos >= 32) {
					bytes[i] = 0;
				} else {
					bytes[i] = this.toUnsign(l >>> pos);
				}
			}
			return bytes;
		};
	}

	var str2Byte = function(str) {
		var bytes = [];
		var len, c;
		len = str.length;
		for (var i = 0; i < len; i++) {
			c = str.charCodeAt(i);
			if (c >= 0x010000 && c <= 0x10FFFF) {
				bytes.push(((c >> 18) & 0x07) | 0xF0);
				bytes.push(((c >> 12) & 0x3F) | 0x80);
				bytes.push(((c >> 6) & 0x3F) | 0x80);
				bytes.push((c & 0x3F) | 0x80);
			} else if (c >= 0x000800 && c <= 0x00FFFF) {
				bytes.push(((c >> 12) & 0x0F) | 0xE0);
				bytes.push(((c >> 6) & 0x3F) | 0x80);
				bytes.push((c & 0x3F) | 0x80);
			} else if (c >= 0x000080 && c <= 0x0007FF) {
				bytes.push(((c >> 6) & 0x1F) | 0xC0);
				bytes.push((c & 0x3F) | 0x80);
			} else {
				bytes.push(c & 0xFF);
			}
		}
		return bytes;
	};

	var toHexStr = function(bytes) {
		if (bytes == null) {
			throw new Error("bytes is null.");
		}

		var str = "";
		for (var i = 0; i < bytes.length; i++) {
			str += hexStr[bytes[i] >> 4 & 15];
			str += hexStr[bytes[i] & 15];
		}
		return str;
	};

	$.extend({
		sm3: function(string) {
			var plainBytes, encryptBytes, encryptStr;

			plainBytes = str2Byte(string);

			encryptBytes = new SM3().hash(plainBytes);

			encryptStr = toHexStr(encryptBytes);
			if (encryptStr != null && encryptStr.trim().length > 0) {
				encryptStr = encryptStr.replace(/(\s*|\t|\r|\n)/g, "");
			}
			return encryptStr;
		},
        getSecretKey: function (str) {
			if(str){
				var secretkey = $.sm3(str);
				return secretkey.substring(0, 16);
			}else {
				return '1111111111111111'
			}
        },
		sign: function(ts, nonce, sessid, uri, param) {
			var str = "";
			str += ts + "\n";
			str += nonce + "\n";
			str += (sessid || "") + "\n";
			str += uri + "\n";
			param = param || "";
			if (typeof param == "string") {
				str += param;
			} else if (typeof param === "object") {
				str += JSON.stringify(param);
			}

			return $.sm3(str);
		},
		verifySign: function (sign, uri, param) {
			var ts, nonce, sessid, sg;
			ts = null;
			nonce = null;
			sessid = null;
			sg = null;
			$.each(sign.split(";"), function (i, item) {
				if (item.indexOf("ts=") === 0) {
					ts = item.substr(3);
				} else if (item.indexOf("nonce=") === 0) {
					nonce = item.substr(6);
				} else if (item.indexOf("udid=") === 0) {
					sessid = item.substr(5);
				} else if (item.indexOf("sg=") === 0) {
					sg = item.substr(3);
				}
			});

			if (!ts || !nonce || !sg) {
				return false;
			}
			
			return sg === $.sign(ts, nonce, sessid, uri, param);
		}
	});
})(jQuery);
