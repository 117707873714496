import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./utils/api/index";
import utils from "./utils";
import ElementUI from "element-ui";
// import 'echarts-wordcloud'
// import * as echarts from "echarts";//echarts图表引用
import echarts from "echarts";
// import 'echarts/map/js/china.js'
// vue.prototype.$echarts = echarts
Vue.use(echarts);
import uploader from 'vue-simple-uploader'
Vue.use(uploader)
import "element-ui/lib/theme-chalk/index.css";
import { message } from "@/utils/resetMessage";
Vue.prototype.$message = message;
import vueSse from "vue-sse"
Vue.prototype.$sse = vueSse;
import VueQriously from "vue-qriously";
Vue.use(VueQriously); //链接地址生成二维码
import "./assets/style/theme.css";
import VueI18n from "vue-i18n";
// import zh from '@/i18n/langs/zh'
// import en from '@/i18n/langs/en'
import zh from "../src/langs/zh";
import en from "../src/langs/en";
import tw from "../src/langs/tw";
import "./utils/directive.js"; // 拖拽弹窗，在需要用到拖拽功能的弹窗标签上加v-dialogDrag
import moment from "moment";
import "../src/assets/iconfont/iconfont.css";
import "../src/assets/iconfont/iconfont.js";
// 将自动注册所有组件为全局组件
import dataV from "@jiaminghi/data-view";

Vue.use(dataV);
import common from "./utils/common.js"; //数据转换方法公共合集
Vue.prototype.$common = common; //数据转换方法公共合集挂载全局
import filter from "./utils/filter.js"; //数据转换方法公共合集
Vue.prototype.$filter = filter; //数据转换方法公共合集挂载全局
router.beforeEach((to, from, next) => {
  if (Boolean(sessionStorage.getItem("Authorization"))) {
    //判断是否有token信息
    next();
  } else {
    if (to.path !== "/login" && to.path !== "/forgetPwd" && to.path !=="/blankBock") {
      //判断是否要跳到登录界面
      next({
        path: "/login",
      }); //防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页
    } else {
      next();
    }
  }
});
const browserInf = navigator.userAgent.toLowerCase();
window.onerror = function(message, source, lineno, colno, error) {  
  // 这里可以记录错误、发送报告等  
  // 但不要在这里显示错误给用户  
  // console.error('Global Error:', message, source, lineno, colno, error);  
  return true; // 表示错误已被处理，浏览器不会显示默认的错误信息  
};
/** 检查用户当前浏览器 */

let browserName = "";
if (browserInf.indexOf("edge") >= 0 && window) {
  browserName = "Edge";

  console.log(browserName, "Edge");
} else if (browserInf.indexOf("chrome") >= 0) {
  browserName = "Chrome";
  document.documentElement.className += "Chrome";
  console.log(browserName, "Chrome");
} else if (browserInf.indexOf("safari") >= 0) {
  browserName = "Safari";
  console.log(browserName, "Safari");
} else if (browserInf.indexOf("qqbrowser") >= 0) {
  browserName = "QQ";
  console.log(browserName, "QQ");
} else if (browserInf.indexOf("ubrowser") >= 0) {
  browserName = "UC";
  console.log(browserName, "UC");
} else if (browserInf.indexOf("firefox") >= 0) {
  browserName = "火狐";
  document.documentElement.className += "firefox";
  console.log(browserName, "火狐");
} else {
  browserName = "未知是什么浏览器";
  console.log(browserName, "未知是什么浏览器");
}
// 页面抖动关闭
ElementUI.Dialog.props.lockScroll.default = true;
const Base64 = require("js-base64").Base64;
Vue.prototype.validForbid = function (value) {
  value = value
    .replace(
      /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
      ""
    )
    .replace(/\s/g, "");
  return value;
};
Vue.prototype.Geography = function (value, number = 100) {
  // /[^a-zA-Z]/g
  // (/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '')    \u4E00-\u9FA5
  value = value
    .replace(
      /[`~!@#$%^&*()_\-+=<>?:"{}|./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’。、\d\u4E00-\u9FA5]/g,
      ""
    )
    .replace(/\s/g, "");
  if (value.length >= number) {
    this.$message({
      type: "error",
      message: `输入文件类型不能超过${number}个字符`,
    });
  }
  return value;
};
// 中文简体
moment.locale("zh-cn");
Vue.filter("dateformat", (dataStr, pattern = "YYYY-MM-DD HH:mm:ss") => {
  return moment(dataStr).format(pattern);
});
Vue.filter("dateformats", (dataStr, pattern = "YYYY-MM-DD HH:mm:ss") => {
  return moment(dataStr * 1).format(pattern);
});
Vue.filter("timepage", (dataStr, pattern = "YYYY-MM-DD") => {
  return moment(dataStr * 1).format(pattern);
});
Vue.use(VueI18n);

Vue.filter("PhoneFilter", (val, str, num) => {
  if (val == null || val == undefined || val.length == 0) {
    return "";
  }
  if (val.length <= 7) {
    return val.substring(0, 3) + "****";
  } else {
    return (
      val.substring(0, 3) + "****" + val.substring(val.length - 4, val.length)
    );
  }
  //  return val;
});
Vue.filter('hideMail',(val)=>{
  if(val== null || val.length < 1 || !val.includes("@")) {
    return val
}
let atIndex = val.indexOf('@');
let mailSuffix = "****" + val.substring(atIndex);
return atIndex <= 1 ? mailSuffix : val.charAt(0) + mailSuffix;
})
//实例化vue-i18n
const i18n = new VueI18n({
  locale:
    window.localStorage.getItem("language") === null
      ? "zh"
      : window.localStorage.getItem("language"), // 语言标识，设置默认语言
  messages: {
    zh: zh, // 简体中文
    en: en, // 英文
    tw: tw, // 繁体中文
  },
  silentTranslationWarn:true
});
Vue.use(ElementUI,{
  i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法
});
// console.log = function(){}
Vue.prototype.$axios = axios;
Vue.prototype.$utils = utils;
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
ElementUI.Dialog.props.lockScroll.default = false;
new Vue({
  router,
  store,
  i18n,
  Base64,
  render: (h) => h(App),
}).$mount("#app");
