let temsys = [{
  path: "/Running",
  name: "Running",
  meta: {
    title: "系统配置",
    icon: ""
  },
  component: () => import("../views/system/index.vue"),
  children: [{
      path: "/Running",
      name: "Running",
      meta: {
        title: "运行配置",
        icon: ""
      },
      component: () => import("../views/system/Running.vue")
    },
    {
      path: "/ClientLog",
      name: "ClientLog",
      meta: {
        title: "客户端配置",
        icon: ""
      },
      children: [{
        path: "/HttpUrl",
        name: "HttpUrl",
        meta: {
          title: "服务器地址",
          icon: ""
        },
        component: () => import("../views/system/ClientLog/HttpUrl.vue"),
      }, {
        path: "/AndroidConfig",
        name: "AndroidConfig",
        meta: {
          title: "Android配置",
          icon: ""
        },
        component: () => import("../views/system/ClientLog/AndroidConfig.vue"),
      }, {
        path: "/IosConfig",
        name: "IosConfig",
        meta: {
          title: "iOS配置",
          icon: ""
        },
        component: () => import("../views/system/ClientLog/IosConfig.vue"),
      }]
    },
    {
      path: "/S6000Config",
      name: "S6000Config",
      meta: {
        title: "对外接口配置",
        icon: ""
      },
      component: () => import("../views/system/S6000Config.vue")
    },
    {
      path: "/SecurityConfig",
      name: "SecurityConfig",
      meta: {
        title: "安全配置",
        icon: ""
      },
      component: () => import("../views/system/SecurityConfig.vue")
    },
    {
      path: "/CustomizedConfig",
      name: "CustomizedConfig",
      meta: {
        title: "定制化配置",
        icon: ""
      },
      component: () => import("../views/system/CustomizedConfig.vue")
    },
    // {
    //   path: "/deviceClientLog",
    //   name: "deviceClientLog",
    //   meta: {
    //     title: "客户端日志",
    //     icon: ""
    //   },
    //   component: () => import("../views/system/deviceClientLog.vue")
    // },
    {
      path: "/Syslog",
      name: "Syslog",
      meta: {
        title: "系统日志",
        icon: ""
      },
      component: () => import("../views/system/Syslog.vue")
    },
    {
      path: "/Upgrade",
      name: "Upgrade",
      meta: {
        title: "系统升级",
        icon: ""
      },
      component: () => import("../views/system/Upgrade.vue")
    },
    {
      path: "/BackupAndRestore",
      name: "BackupAndRestore",
      meta: {
        title: "备份与恢复",
        icon: ""
      },
      component: () => import("../views/system/BackupAndRestore.vue")
    },
    {
      path: "/License",
      name: "License",
      meta: {
        title: "授权激活",
        icon: ""
      },
      component: () => import("../views/system/License.vue")
    },
    {
      path: "/Contact",
      name: "Contact",
      meta: {
        title: "联系我们",
        icon: ""
      },
      component: () => import("../views/system/Contact.vue")
    },
    {
      path: "/About",
      name: "About",
      meta: {
        title: "关于",
        icon: ""
      },
      component: () => import("../views/system/About.vue")
    }
  ]
}]
export default temsys
