const ditor = [{
  path: "/ManageLogs",
  name: "ManageLogs",
  meta: {
    title: "日志审计",
    icon: ""
  },
  component: () => import("../views/auditor/ManageLog.vue"),
  children: [{
      path: "/ManageLog",
      name: "ManageLog",
      meta: {
        title: "管理日志",
        icon: ""
      },
      component: () => import("../views/auditor/ManageLog.vue")
    },
    {
      path: "/ClientLog",
      name: "ClientLog",
      meta: {
        title: "终端日志",
        icon: ""
      },
      component: () => import("../views/auditor/ClientLog.vue")
    },
    {
      path: "/AlarmLog",
      name: "AlarmLog",
      meta: {
        title: "告警日志",
        icon: ""
      },
      component: () => import("../views/auditor/AlarmLog.vue")
    },
    {
      path: "/ArchiveLog",
      name: "ArchiveLog",
      meta: {
        title: "日志归档",
        icon: ""
      },
      component: () => import("../views/auditor/ArchiveLog.vue")
    }
  ]
}]
export default ditor
