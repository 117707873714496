<template>
  <el-dialog
    center
    id="success"
    :width="width"
    title="提示信息"
    :visible.sync="dialogInfo"
    :show-close="true"
    :close-on-click-modal="true"
    :close-on-press-escape="false"
    style="margin-top: 10%"
    :before-close="handleClose"
    :destroy-on-close="true"
  >
    <span style="color: #606266;font-size: 14px;">{{ titleInfo }}</span>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="determine" type="primary" :disabled="isDisabled"
        >确定（{{ Sencond }}）</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogInfo: {
      type: Boolean,
      default: false,
    },
    titleInfo: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      Sencond: 5,
      isDisabled: false,
      time: null,
    };
  },
  watch: {
    Sencond(newval) { // 保存成功的倒计时弹框
      if (newval === 0) {
        window.clearInterval(this.time);
        this.Sencond = 1
        this.handleClose();
      }
    },
    dialogInfo(val) {
      this.dialogInfo = val;
      window.clearInterval(this.time);
      if (val) {
        this.Sencond = 5
        this.time = setInterval(() => {
          this.Sencond -= 1;
        }, 1000);
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose", false);
    },
    determine() { // 确定事件回调
      this.handleClose();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__header {
    text-align: left;
}
</style>
