function routerRoutes(that) {
  if (that.$route.name == 'AppCategory') {
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '1'
    that.mouseKeys = 1
    that.menuKeys = 1
  }
  if (that.$route.name == 'AppLib') {
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '2'
    that.mouseKeys = 2
    that.menuKeys = 2
  }
  if (that.$route.name == 'AppPolicy') { //应用策略
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-0'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'ThreatDefinition') { //威胁定义
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-1'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'SecurityEvent') { //安全事件
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-2'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'SDKMonitor') { //sdk监控
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-3'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'BannedConfig') { //封禁配置
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-4'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'WhiteList') { //白名单
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-5'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'WaterConfig') { //界面水印
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-6'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'SensitiveWords') { //敏感词库
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-8'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'WaterResolution') { //敏感词库
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '3-7'
    that.mouseKeys = 3
    that.menuKeys = 3
  }
  if (that.$route.name == 'Scan') { //应用检测
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '4-0'
    that.mouseKeys = 4
    that.menuKeys = 4
  }
  if (that.$route.name == 'H5Scan') { //h5检测
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '4-1'
    that.mouseKeys = 4
    that.menuKeys = 4
  }
  if (that.$route.name == 'ScanAnalysis') { //检测分析
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '4-2'
    that.mouseKeys = 4
    that.menuKeys = 4
  }
  if (that.$route.name == 'ScanLevel') { //检测等级
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '4-3'
    that.mouseKeys = 4
    that.menuKeys = 4
  }
  if (that.$route.name == 'AppReinforce') { //应用加固
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '5-0'
    that.mouseKeys = 5
    that.menuKeys = 5
  }
  if (that.$route.name == 'SDKReinforce') { //sdk加固
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '5-1'
    that.mouseKeys = 5
    that.menuKeys = 5
  }
  if (that.$route.name == 'H5Reinforce') { //H5加固
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '5-2'
    that.mouseKeys = 5
    that.menuKeys = 5
  }
  if (that.$route.name == 'CertificateManage') { //证书管理
    that.activeMenu = 'AppManage'
    that.slideMenuActive = '6'
    that.mouseKeys = 6
    that.menuKeys = 6
  }
  if (that.$route.name == 'GatewayResourceManagement') {
    that.activeMenu = 'AccessConfiguration'
    that.slideMenuActive = '0'
    that.mouseKeys = 0
    that.menuKeys = 0
  }
  if (that.$route.name == 'DataReport') {
    that.activeMenu = 'ReportManagement'
    that.slideMenuActive = '0'
    that.mouseKeys = 0
    that.menuKeys = 0
  }
}
export default routerRoutes;
