
import { log } from 'console';
import Vue from 'vue';

Vue.directive('dialogDrag', {
  bind(el, binding, vnode, oldVnode) {
    const dialogHeaderEl = el.querySelector('.el-dialog__header');
    const dragDom = el.querySelector('.el-dialog');
    dialogHeaderEl.style.cursor = 'move';
    // dialogHeaderEl.style.cssText += ';cursor:move;'
    dragDom.style.cssText += ';top:0px;'

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = (function () {
      if (window.document.currentStyle) {
        return (dom, attr) => dom.currentStyle[attr];
      } else {
        return (dom, attr) => getComputedStyle(dom, false)[attr];
      }
    })()

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft;
      const disY = e.clientY - dialogHeaderEl.offsetTop;

      const screenWidth = document.body.clientWidth; // body当前宽度
      const screenHeight = document.documentElement.clientHeight; // 可见区域高度(应为body高度，可某些环境下无法获取) 

      const dragDomWidth = dragDom.offsetWidth; // 对话框宽度
      const dragDomheight = dragDom.offsetHeight; // 对话框高度

      const minDragDomLeft = dragDom.offsetLeft;
      const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;

      const minDragDomTop = dragDom.offsetTop;
      const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight;

      // 获取到的值带px 正则匹配替换
      let styL = sty(dragDom, 'left');
      let styT = sty(dragDom, 'top');

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (styL.includes('%')) {
        styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100);
        styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100);
      } else {
        styL = +styL.replace(/\px/g, '');
        styT = +styT.replace(/\px/g, '');
      };

      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        // 边界处理
        if (-(left) > minDragDomLeft) {
          left = -(minDragDomLeft);
        } else if (left > maxDragDomLeft) {
          left = maxDragDomLeft;
        }

        if (-(top) > minDragDomTop) {
          top = -(minDragDomTop);
        } else if (top > maxDragDomTop) {
          top = maxDragDomTop;
        }

        // 移动当前元素
        dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`;
      };

      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      }
    }
  }
})

// v-dialogDragWidth: 弹窗宽度拖大 拖小
Vue.directive('dialogDragWidth', {
  bind(el, binding, vnode, oldVnode) {
    const dragDom = binding.value.$el.querySelector('.el-dialog')

    el.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - el.offsetLeft

      document.onmousemove = function (e) {
        e.preventDefault() // 移动时禁用默认事件

        // 通过事件委托，计算移动的距离
        const l = e.clientX - disX
        dragDom.style.width = `${l}px`
      }

      document.onmouseup = function (e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
})

Vue.directive('debounce', {
  inserted: function (el, binding) {
    let timer = null
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        binding.value()
      }, parseInt(binding.arg || 500))
    })
  }
})

// 自定义指令实现div拖拽功能
Vue.directive('dragControllerDiv', {
  bind(el, binding, vnode, oldVnode) {
    console.log(el,'自定义指令实现div拖拽功能   ------el')
    console.log( binding, '自定义指令实现div拖拽功能   ------binding')
    console.log( vnode, '自定义指令实现div拖拽功能   ------vnode')
    console.log( oldVnode,'自定义指令实现div拖拽功能   ------oldVnode')
    var dragPoint = el.getElementsByClassName('dragPoint')
    var leftTree = el.getElementsByClassName('leftTree')
    var leftTable = el.getElementsByClassName('leftTable')
    var leftBox = el
    console.log(dragPoint,'获取元素dragPoint');
    console.log(leftTree,'获取元素leftTree');
    console.log(leftTable,'获取元素leftTable');
    console.log(leftBox,'获取元素leftBox');
    for (let i = 0; i < dragPoint.length; i++) {
      // 鼠标按下事件
      dragPoint[i].onmousedown = function (e) {
        //颜色改变提醒
        // dragPoint[i].style.background = "#818181";
        var startX = e.clientX //鼠标按下时鼠标指针的横向坐标
        dragPoint[i].leftTree = dragPoint[i].offsetLeft
        // 鼠标拖动事件
        document.onmousemove = function (e) {
          // console.log(e, 'eeeee')
          var endX = e.clientX //鼠标拖动时鼠标指针的横向坐标
          var moveLen = dragPoint[i].leftTree + (endX - startX) // （endx-startx）=移动的距离。dragPoint[i].left+移动的距离=左边区域最后的宽度
          var maxT = leftBox.clientWidth - dragPoint[i].offsetWidth // 容器宽度 - 左边区域的宽度 = 右边区域的宽度
          if (moveLen < 232) moveLen = 232 // 左边区域的最小宽度为232px
          if (moveLen > maxT - 150) moveLen = maxT //右边区域最小宽度为150px
          dragPoint[i].style.leftTree = moveLen // 设置左侧区域的宽度
          for (let j = 0; j < leftTree.length; j++) {
            leftTree[j].style.width = moveLen + 'px'
            // leftTable[j].style.width =
            leftBox.clientWidth - moveLen - 10 + 'px'
          }
        }
        // 鼠标松开事件
        document.onmouseup = function (evt) {
          //颜色恢复
          // dragPoint[i].style.background = "#d6d6d6";
          document.onmousemove = null
          document.onmouseup = null
          dragPoint[i].releaseCapture && dragPoint[i].releaseCapture() //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
        }
        dragPoint[i].setCapture && dragPoint[i].setCapture() //该函数在属于当前线程的指定窗口里设置鼠标捕获
        return false
      }
    }
  },
});
