import {
  log
} from "console";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedstate from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    platform: '',
    sourcesFlag: '',
    selectList: [], //存储后端返回的菜单数据
    SelectAssignData: [],
    dialogVisibleTitle: '',//存储当前打开分配内容弹框
    groupId: "", //部门分类---获取整个树状图的groupId
    groupList: [], //按部门分配历史记录
    addGroupList: [], //按部门分配添加
    userList: [], //按用户分配历史记录
    userGropList: [], //按用户组分配历史记录---------V6.1.3
    associatUserList: [], //用户组页面关联用户勾选历史记录---------V6.1.3
    associatDeviceList: [], //设备组页面关联用户勾选历史记录---------V6.1.3
    associatDeviceListA: [],//设备组页面关联用户勾选数据---------V6.1.3
    addUserGropList: [], //按用户组分配添加---------V6.1.3
    addUserList: [], //按用户分配添加
    assUserListA: [], //用户组页面添加关联用户数据暂时存储----------V6.1.3
    assUserListB: [], //用户组页面添加关联用户数据最终----------V6.1.3
    assDeviceListA: [], //设备组页面添加关联设备数据暂时存储----------V6.1.3
    assDeviceListB: [], //设备组页面添加关联设备数据最终----------V6.1.3
    deviceList: [], //按设备分配历史记录
    deviceGroupList: [], //按设备组分配历史记录---------V6.1.3
    addDeviceList: [], //按设备分配添加
    addDevGropList: [], //按设备分配添加---------V6.1.3
    hisTime: "",
    groupTabs: [], //按部门分配tab页点击频率
    userGroupTabs: [], //按用户组分配tab页点击频率
    userTabs: [], //按用户分配tab页点击频率
    deviceGroupTabs: [], //按设备组分配tab页点击频率
    deviceTabs: [], //按设备分配tab页点击频率
    deledArr: [], //被删除的已勾选的分配内容数据
    // -----------------------------------------------------------------12.18
    groupRgihtStore: [],//部门右侧列表数据
    groupRgihtArray: [],//部门右侧数据存储----用于左侧回显
    //----------用户
    userRgihtStore: [],//用户右侧列表数据
    userRgihtArray: [],//用户右侧数据存储----用于左侧回显
    //----------设备
    deviceRgihtStore: [],//设备右侧列表数据
    deviceRgihtArray: [],//设备右侧数据存储----用于左侧回显
    //----------用户组
    userGropRgihtStore: [],//用户组右侧列表数据
    userGropRgihtArray: [],//用户组右侧数据存储----用于左侧回显
    //----------设备组
    deviceGropRgihtStore: [],//设备组右侧列表数据
    deviceGropRgihtArray: [],//设备组右侧数据存储----用于左侧回显
  },
  mutations: {
    //platformFlag
    platformFlag(state, params) {
      state.platform = params
    },
    //sourcesTwoFlag
    sourcesTwoFlag(state, params) {
      state.sourcesFlag = params
    },
    //拿到后端返回的菜单数据
    updateSelect(state, params) {
      state.selectList = [...params];
    },
    // 设备轨迹获取时间存储
    Historicaltime(state, params) {
      state.hisTime = params;
    },
    //获取按部门分配tab页点击频率
    groupTab(state, params) {
      state.groupTabs.push(params); //尾部添加一个
      state.groupTabs.shift(); //头部删除一个
    },
    //获取按用户组分配tab页点击频率
    userGroupTab(state, params) {
      state.userTabs.push(params); //尾部添加一个
      state.userTabs.shift(); //头部删除一个
    },
    //获取按用户分配tab页点击频率
    userTab(state, params) {
      state.userTabs.push(params); //尾部添加一个
      state.userTabs.shift(); //头部删除一个
    },
    //获取按设备组分配tab页点击频率
    deviceGroupTab(state, params) {
      state.deviceTabs.push(params); //尾部添加一个
      state.deviceTabs.shift(); //头部删除一个
    },
    //获取按设备分配tab页点击频率
    deviceTab(state, params) {
      state.deviceTabs.push(params); //尾部添加一个
      state.deviceTabs.shift(); //头部删除一个
    },
    //获取当前打开分配内容弹框
    setDialogVisibleTitle(state, params) {
      state.dialogVisibleTitle = params
    },
    //删除已勾选数据-----取消勾选所有已勾选数据时
    delSelectAssignData(state, params) {
      // 获取数组 B 中所有 id 的集合  
      const idsToRemove = new Set(params.map(item => item.id));
      // 使用 filter 方法过滤数组 A，移除与 idsToRemove 集合中 id 相同的数据  
      const matchingObjects = state.SelectAssignData.filter(item => !idsToRemove.has(item.id));
      state.SelectAssignData = matchingObjects
    },
    // 获取勾选分配数据的id
    getSelectAssignData(state, params) {
      const map = new Map();
      const uniqueArray = params.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.SelectAssignData = uniqueArray
    },
    //----------------------------部门---------------------------------
    //按部门分配历史记录
    groupDefaultChecked(state, params) {
      let arr = state.addGroupList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.groupRgihtStore = uniqueArray
      state.groupList = [...new Set(params)];
    },
    //按部门添加分配对象
    group_multiple(state, params) {
      state.addGroupList = [...new Set(params)];
    },
    //部门右侧(监听到新勾选的数据)
    groupRgiht(state, params) {
      let arr = state.groupList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.groupRgihtStore = uniqueArray
    },
    //部门右侧数据存储----用于左侧回显
    groupRgihtList(state, params) {
      state.groupRgihtArray = params
    },
    //部门右侧列表删除
    groupRgihtDelete(state, params) {
      state.groupRgihtStore.forEach((item, index) => {
        if (item.id == params) {
          state.groupRgihtStore.splice(index, 1);
        }
      });
      state.groupRgihtArray.forEach((item, index) => {
        if (item.id == params) {
          state.groupRgihtArray.splice(index, 1);
        }
      });
    },
    //单个删除按部门分配勾选的数据
    group_del(state, params) {
      state.groupList.forEach((item, index) => {
        if (item.id == params) {
          state.groupList.splice(index, 1);
        }
      });

      state.addGroupList.forEach((item, index) => {
        if (item.id == params) {
          state.addGroupList.splice(index, 1);
        }
      });
    },
    //全部删除按部门分配勾选的数据
    group_all(state, params) {
      state.groupList = [];
      state.addGroupList = [];
    },
    //----------------------------用户组---------------------------------
    //按用户组分配历史记录---------V6.1.3
    userGroupDefaultChecked(state, params) {
      let arr = state.addUserGropList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.userGropRgihtStore = uniqueArray
      state.userGropList = [...new Set(params)];
    },
    //按用户组添加分配对象
    userGroup_multiple(state, params) {
      state.addUserGropList = [...new Set(params)];
    },
    //用户组右侧(监听到新勾选的数据)
    userGropRgiht(state, params) {
      let arr = state.userGropList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.userGropRgihtStore = uniqueArray
    },
    //用户组右侧数据存储----用于左侧回显
    userGropRgihtList(state, params) {
      state.userGropRgihtArray = params
    },
    //用户组右侧列表删除
    userGropRgihtDelete(state, params) {
      state.userGropRgihtStore.forEach((item, index) => {
        if (item.id == params) {
          state.userGropRgihtStore.splice(index, 1);
        }
      });
      state.userRgihtArray.forEach((item, index) => {
        if (item.id == params) {
          state.userRgihtArray.splice(index, 1);
        }
      });
    },
    //单个删除按用户组分配勾选的数据---------V6.1.3用户组
    userGrop_del(state, params) {
      state.userGropList.forEach((item, index) => {
        if (item.id == params) {
          state.userGropList.splice(index, 1);
        }
      });
      state.addUserGropList.forEach((item, index) => {
        if (item.id == params) {
          state.addUserGropList.splice(index, 1);
        }
      });
    },
    //全部删除按用户组分配勾选的数据---------V6.1.3用户组
    userGrop_all(state, params) {
      state.userGropList = [];
      state.addUserGropList = [];
    },
    //----------------------------用户---------------------------------
    //按用户分配历史记录
    userDefaultChecked(state, params) {
      let arr = state.addUserList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.userRgihtStore = uniqueArray
      state.userList = [...new Set(params)];
    },
    //按用户添加分配对象
    user_multiple(state, params) {
      state.addUserList = [...new Set(params)];
    },
    //用户右侧(监听到新勾选的数据)
    userRgiht(state, params) {
      let arr = state.userList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.userRgihtStore = uniqueArray
    },
    //用户右侧数据存储----用于左侧回显
    userRgihtList(state, params) {
      state.userRgihtArray = params
    },
    //用户右侧列表删除
    userRgihtDelete(state, params) {
      state.userRgihtStore.forEach((item, index) => {
        if (item.id == params) {
          state.userRgihtStore.splice(index, 1);
        }
      });
      state.userRgihtArray.forEach((item, index) => {
        if (item.id == params) {
          state.userRgihtArray.splice(index, 1);
        }
      });
    },
    //单个删除按用户分配勾选的数据
    user_del(state, params) {
      state.userList.forEach((item, index) => {
        if (item.id == params) {
          state.userList.splice(index, 1);
        }
      });

      state.addUserList.forEach((item, index) => {
        if (item.id == params) {
          state.addUserList.splice(index, 1);
        }
      });
    },
    //全部删除按用户分配勾选的数据
    user_all(state, params) {
      state.userList = [];
      state.addUserList = [];
    },
    //----------------------------设备组---------------------------------
    //按设备组分配历史记录
    deviceGroupDefaultChecked(state, params) {
      let arr = state.addDevGropList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.deviceGropRgihtStore = uniqueArray
      state.deviceGroupList = [...new Set(params)];
    },
    //按设备组添加分配对象
    deviceGroup_multiple(state, params) {
      state.addDevGropList = [...new Set(params)];
    },
    //设备组右侧(监听到新勾选的数据)
    deviceGropRgiht(state, params) {
      let arr = state.deviceGroupList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.deviceGropRgihtStore = uniqueArray
    },
    //设备组右侧数据存储----用于左侧回显
    deviceGropRgihtList(state, params) {
      state.deviceGropRgihtArray = params
    },
    //设备组右侧列表删除
    deviceGropRgihtDelete(state, params) {
      state.deviceGropRgihtStore.forEach((item, index) => {
        if (item.id == params) {
          state.deviceGropRgihtStore.splice(index, 1);
        }
      });
      state.deviceGropRgihtArray.forEach((item, index) => {
        if (item.id == params) {
          state.deviceGropRgihtArray.splice(index, 1);
        }
      });
    },
    //单个删除按设备组分配勾选的数据---------V6.1.3设备组
    deviceGrop_del(state, params) {
      state.deviceGroupList.forEach((item, index) => {
        if (item.id == params) {
          state.deviceGroupList.splice(index, 1);
        }
      });
      state.addDevGropList.forEach((item, index) => {
        if (item.id == params) {
          state.addDevGropList.splice(index, 1);
        }
      });
    },
    //全部删除按设备组分配勾选的数据---------V6.1.3设备组
    deviceGrop_all(state, params) {
      state.deviceGroupList = [];
      state.addDevGropList = [];
    },
    //----------------------------设备---------------------------------
    //按设备分配历史记录---------V6.1.3
    deviceDefaultChecked(state, params) {
      console.log(state, params);
      let arr = state.addDeviceList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.deviceRgihtStore = uniqueArray
      state.deviceList = [...new Set(params)];
    },
    //按设备添加分配对象
    device_multiple(state, params) {
      state.addDeviceList = [...new Set(params)];
    },

    //设备右侧(监听到新勾选的数据)
    deviceRgiht(state, params) {
      let arr = state.deviceList.concat(
        params
      );
      const map = new Map();
      const uniqueArray = arr.filter((v) => {
        if (v && !map.has(v.id)) {
          map.set(v.id, true);
          return true;
        }
        return false;
      });
      state.deviceRgihtStore = uniqueArray
    },
    //设备右侧数据存储----用于左侧回显
    deviceRgihtList(state, params) {
      state.deviceRgihtArray = params
    },
    //设备右侧列表删除
    deviceRgihtDelete(state, params) {
      state.deviceRgihtStore.forEach((item, index) => {
        if (item.id == params) {
          state.deviceRgihtStore.splice(index, 1);
        }
      });
      state.deviceRgihtArray.forEach((item, index) => {
        if (item.id == params) {
          state.deviceRgihtArray.splice(index, 1);
        }
      });
    },
    //单个删除按设备分配勾选的数据
    device_del(state, params) {
      state.deviceList.forEach((item, index) => {
        if (item.id == params) {
          state.deviceList.splice(index, 1);
        }
      });
      state.addDeviceList.forEach((item, index) => {
        if (item.id == params) {
          state.addDeviceList.splice(index, 1);
        }
      });
    },
    //全部删除按设备分配勾选的数据
    device_all(state, params) {
      state.deviceList = []
      state.addDeviceList = []
    },

    //用户管理模块 ---------------------------------------------------------------------------------------
    //用户组页面添加关联用户数据暂时----------V6.1.3
    assUser_multipleA(state, params) {
      state.assUserListA = [...new Set(params)];
    },
    //用户组页面添加关联用户数据最终----------V6.1.3
    assUser_multipleB(state, params) {
      state.assUserListB = [...new Set(params)];
    },
    //设备组页面添加关联设备数据暂时----------V6.1.3
    assDevice_multipleA(state, params) {
      state.assDeviceListA = [...new Set(params)];
    },
    //设备组页面添加关联设备数据最终----------V6.1.3
    assDevice_multipleB(state, params) {
      state.assDeviceListB = [...new Set(params)];
    },
    //获取树状图的groupId
    getGroupId(state, params) {
      console.log(state, params);
      state.groupId = [...new Set(params)];
    },
    //删除数据
    delete(state, params) {
      state.SelectAssignData = [];
      state.deledArr = [];
      state.deviceList = []
      state.addDeviceList = []
      state.addDevGropList = []
      state.userList = []
      state.addUserList = []
    },
    //单个删除
    del(state, params) {
      // console.log(params, "vuex单个删除");
      state.SelectAssignData.forEach((item, index) => {
        if (item.id == params.id) {
          state.SelectAssignData.splice(index, 1);
        }
      });
    },
    //被删除的已勾选的分配内容数据
    deled(state, params) {
      state.deledArr.push(params);
      // console.log(state.deledArr, "state.deledArr");s
    },





    //单个删除用户组页面添加关联用户勾选的数据
    assUser_del(state, params) {
      state.associatUserList.forEach((item, index) => {
        if (item.id == params) {
          state.associatUserList.splice(index, 1);
        }
      });

      state.assUserListA.forEach((item, index) => {
        if (item.id == params) {
          state.assUserListA.splice(index, 1);
        }
      });
      // state.assUserListB = state.assUserListA;
    },
    //全部删除用户组页面添加关联用户勾选的数据---------V6.1.3设备组
    assUser_all(state, params) {
      state.assUserListB = [];
      state.associatUserList = [];
      state.assUserListA = [];
    },
    //单个删除设备组页面添加关联设备勾选的数据
    assDevice_del(state, params) {
      state.associatDeviceList.forEach((item, index) => {
        if (item.id == params) {
          state.associatDeviceList.splice(index, 1);
        }
      });
      state.assDeviceListA.forEach((item, index) => {
        if (item.id == params) {
          state.assDeviceListA.splice(index, 1);
        }
      });
      // state.assDeviceListB = state.assDeviceListA;
    },
    //全部删除设备组页面添加关联用户勾选的数据---------V6.1.3设备组
    assDevice_all(state, params) {
      state.associatDeviceList = [];
      state.assDeviceListA = [];
      state.assDeviceListB = []
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedstate({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          // 只储存state中的SelectAssignData
          SelectAssignData: val.SelectAssignData, //分配管理
          platform: val.platform, //存储时二次认证保存状态
          sourcesFlag: val.sourcesFlag, //存储时混合认证保存状态
        };
      },
    }),
  ],
});
