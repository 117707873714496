import zh from '../langs/zh'
import en from '../langs/en'
import tw from '../langs/tw'
var language = window.localStorage.getItem('language') || "zh";
const mapping = {
  // 表格联动飘红
  nameFilter(arr1, arr2) {
    // console.log(arr1, arr2, "arr");
    for (let item1 of arr1) {
      for (let item2 of arr2) {
        if (item1.name != item2.name) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  // 执行结果-执行内容多个v-if插槽
  reqType(a) {
    if (a == null || a == undefined || a == "") {
      return "";
    } else if (a != null || a != undefined || a != "") {
      let mapStr = {
        installconfig: "安装合规策略",
        installpolicy: "安装设备策略",
        message: "消息",
        removedevice: "强制注销",
        logOut: "强制注销",
        must_install_application: "安装应用",
        InstallApplication: "安装应用",
        extractLog: "提取日志",
        InstalledApplicationList: "软件信息",
        DeviceInformation: "硬件信息",
        DeviceLock: "设备锁屏",
        // DeviceUnLock: "设备解锁",
        removeappdata: "清除数据",
        InstallProfile: "安装配置文件",
        ManagedApplicationList: "受控软件",
        restartDevice: "设备重启",
        shutDown: "设备关机",
        RemoveApplication: "卸载应用",
        ClearPasscode: "清除密码",
        EnableLostMode:"设备丢失",
        DisableLostMode:"解除丢失",
        uploadlocation:"获取定位",
        EraseDevice:"恢复出厂",
        devicelost:"设备丢失",
        DeviceUnLock:'设备解锁',
        // deviceunbind:"设备解绑",
        // switchcontainer: "切换模式",
        // workStyle: "切换模式—工作模式",
        // lifeStyle: "切换模式—生活模式",
        // deviceruninfo: "设备运行信息",
      }[a];
      return mapStr;
    }
  },
  // 对外接口配置-----信息类型字段映射
  fomInfoType(value) {
    var str = ''
    let newArr = []
    let temArr = {
      // 0: '心跳信息',
      // 1: '统计信息',
      // 2: '告警信息',
      // 3: '管理日志',
      // 4: '终端日志',
      // 5: '告警日志',
      // 6: '网关访问日志'
    }
    if(language==='en'){
      temArr= en.System.S6000ConfigPage.InfoTypeObj;
    }else if(language==='tw'){
      temArr= tw.System.S6000ConfigPage.InfoTypeObj;
    }else if(language==='zh'){
      temArr=zh.System.S6000ConfigPage.InfoTypeObj;
    }
    var arr = value.split(',')
    let mapArr = arr
    for (const i of mapArr) {
      newArr.push(temArr[i])
    }
    let filterArr=newArr.filter(res=>{return res!=undefined})
    str = filterArr.join(',')
    return str
  },
  //设备管理ios基本信息-----上网方式映射
  surfInterType(v){
    console.log(v,"上网方式映射")
    if(v == "N/A"){
      return "N/A";
    }else if (v == null || v == undefined || v == "") {
      return "N/A";
    } else if (v != null || v != undefined || v != ""){
      let mapCodeStr = {};
      if(language==='en'){
        mapCodeStr= en.InterTypeObj;
      }else if(language==='tw'){
        mapCodeStr= tw.InterTypeObj;
      }else if(language==='zh'){
        mapCodeStr=zh.InterTypeObj;
      }
      return mapCodeStr[v];
    }
    // else{
    //   return v;
    // }
  },
};
export default mapping;
