/**
 * jQuery SM4 hash algorithm function
 * Example: $.sm4(str, secretKey, ivKey);
 */

(function($) {
  function SM4_Context(mode) {
    this.mode = mode || 1;
    this.isPadding = true;
    this.sk = new Array(32);
  }

  function SM4() {
    this.SM4_ENCRYPT = 1;
    this.SM4_DECRYPT = 0;

    var SboxTable = [
      BigInt(0xd6),
      BigInt(0x90),
      BigInt(0xe9),
      BigInt(0xfe),
      BigInt(0xcc),
      BigInt(0xe1),
      BigInt(0x3d),
      BigInt(0xb7),
      BigInt(0x16),
      BigInt(0xb6),
      BigInt(0x14),
      BigInt(0xc2),
      BigInt(0x28),
      BigInt(0xfb),
      BigInt(0x2c),
      BigInt(0x05),
      BigInt(0x2b),
      BigInt(0x67),
      BigInt(0x9a),
      BigInt(0x76),
      BigInt(0x2a),
      BigInt(0xbe),
      BigInt(0x04),
      BigInt(0xc3),
      BigInt(0xaa),
      BigInt(0x44),
      BigInt(0x13),
      BigInt(0x26),
      BigInt(0x49),
      BigInt(0x86),
      BigInt(0x06),
      BigInt(0x99),
      BigInt(0x9c),
      BigInt(0x42),
      BigInt(0x50),
      BigInt(0xf4),
      BigInt(0x91),
      BigInt(0xef),
      BigInt(0x98),
      BigInt(0x7a),
      BigInt(0x33),
      BigInt(0x54),
      BigInt(0x0b),
      BigInt(0x43),
      BigInt(0xed),
      BigInt(0xcf),
      BigInt(0xac),
      BigInt(0x62),
      BigInt(0xe4),
      BigInt(0xb3),
      BigInt(0x1c),
      BigInt(0xa9),
      BigInt(0xc9),
      BigInt(0x08),
      BigInt(0xe8),
      BigInt(0x95),
      BigInt(0x80),
      BigInt(0xdf),
      BigInt(0x94),
      BigInt(0xfa),
      BigInt(0x75),
      BigInt(0x8f),
      BigInt(0x3f),
      BigInt(0xa6),
      BigInt(0x47),
      BigInt(0x07),
      BigInt(0xa7),
      BigInt(0xfc),
      BigInt(0xf3),
      BigInt(0x73),
      BigInt(0x17),
      BigInt(0xba),
      BigInt(0x83),
      BigInt(0x59),
      BigInt(0x3c),
      BigInt(0x19),
      BigInt(0xe6),
      BigInt(0x85),
      BigInt(0x4f),
      BigInt(0xa8),
      BigInt(0x68),
      BigInt(0x6b),
      BigInt(0x81),
      BigInt(0xb2),
      BigInt(0x71),
      BigInt(0x64),
      BigInt(0xda),
      BigInt(0x8b),
      BigInt(0xf8),
      BigInt(0xeb),
      BigInt(0x0f),
      BigInt(0x4b),
      BigInt(0x70),
      BigInt(0x56),
      BigInt(0x9d),
      BigInt(0x35),
      BigInt(0x1e),
      BigInt(0x24),
      BigInt(0x0e),
      BigInt(0x5e),
      BigInt(0x63),
      BigInt(0x58),
      BigInt(0xd1),
      BigInt(0xa2),
      BigInt(0x25),
      BigInt(0x22),
      BigInt(0x7c),
      BigInt(0x3b),
      BigInt(0x01),
      BigInt(0x21),
      BigInt(0x78),
      BigInt(0x87),
      BigInt(0xd4),
      BigInt(0x00),
      BigInt(0x46),
      BigInt(0x57),
      BigInt(0x9f),
      BigInt(0xd3),
      BigInt(0x27),
      BigInt(0x52),
      BigInt(0x4c),
      BigInt(0x36),
      BigInt(0x02),
      BigInt(0xe7),
      BigInt(0xa0),
      BigInt(0xc4),
      BigInt(0xc8),
      BigInt(0x9e),
      BigInt(0xea),
      BigInt(0xbf),
      BigInt(0x8a),
      BigInt(0xd2),
      BigInt(0x40),
      BigInt(0xc7),
      BigInt(0x38),
      BigInt(0xb5),
      BigInt(0xa3),
      BigInt(0xf7),
      BigInt(0xf2),
      BigInt(0xce),
      BigInt(0xf9),
      BigInt(0x61),
      BigInt(0x15),
      BigInt(0xa1),
      BigInt(0xe0),
      BigInt(0xae),
      BigInt(0x5d),
      BigInt(0xa4),
      BigInt(0x9b),
      BigInt(0x34),
      BigInt(0x1a),
      BigInt(0x55),
      BigInt(0xad),
      BigInt(0x93),
      BigInt(0x32),
      BigInt(0x30),
      BigInt(0xf5),
      BigInt(0x8c),
      BigInt(0xb1),
      BigInt(0xe3),
      BigInt(0x1d),
      BigInt(0xf6),
      BigInt(0xe2),
      BigInt(0x2e),
      BigInt(0x82),
      BigInt(0x66),
      BigInt(0xca),
      BigInt(0x60),
      BigInt(0xc0),
      BigInt(0x29),
      BigInt(0x23),
      BigInt(0xab),
      BigInt(0x0d),
      BigInt(0x53),
      BigInt(0x4e),
      BigInt(0x6f),
      BigInt(0xd5),
      BigInt(0xdb),
      BigInt(0x37),
      BigInt(0x45),
      BigInt(0xde),
      BigInt(0xfd),
      BigInt(0x8e),
      BigInt(0x2f),
      BigInt(0x03),
      BigInt(0xff),
      BigInt(0x6a),
      BigInt(0x72),
      BigInt(0x6d),
      BigInt(0x6c),
      BigInt(0x5b),
      BigInt(0x51),
      BigInt(0x8d),
      BigInt(0x1b),
      BigInt(0xaf),
      BigInt(0x92),
      BigInt(0xbb),
      BigInt(0xdd),
      BigInt(0xbc),
      BigInt(0x7f),
      BigInt(0x11),
      BigInt(0xd9),
      BigInt(0x5c),
      BigInt(0x41),
      BigInt(0x1f),
      BigInt(0x10),
      BigInt(0x5a),
      BigInt(0xd8),
      BigInt(0x0a),
      BigInt(0xc1),
      BigInt(0x31),
      BigInt(0x88),
      BigInt(0xa5),
      BigInt(0xcd),
      BigInt(0x7b),
      BigInt(0xbd),
      BigInt(0x2d),
      BigInt(0x74),
      BigInt(0xd0),
      BigInt(0x12),
      BigInt(0xb8),
      BigInt(0xe5),
      BigInt(0xb4),
      BigInt(0xb0),
      BigInt(0x89),
      BigInt(0x69),
      BigInt(0x97),
      BigInt(0x4a),
      BigInt(0x0c),
      BigInt(0x96),
      BigInt(0x77),
      BigInt(0x7e),
      BigInt(0x65),
      BigInt(0xb9),
      BigInt(0xf1),
      BigInt(0x09),
      BigInt(0xc5),
      BigInt(0x6e),
      BigInt(0xc6),
      BigInt(0x84),
      BigInt(0x18),
      BigInt(0xf0),
      BigInt(0x7d),
      BigInt(0xec),
      BigInt(0x3a),
      BigInt(0xdc),
      BigInt(0x4d),
      BigInt(0x20),
      BigInt(0x79),
      BigInt(0xee),
      BigInt(0x5f),
      BigInt(0x3e),
      BigInt(0xd7),
      BigInt(0xcb),
      BigInt(0x39),
      BigInt(0x48)
    ];

    var FK = [
      BigInt(0xa3b1bac6),
      BigInt(0x56aa3350),
      BigInt(0x677d9197),
      BigInt(0xb27022dc)
    ];

    var CK = [
      0x00070e15n,
      0x1c232a31n,
      0x383f464dn,
      0x545b6269n,
      0x70777e85n,
      0x8c939aa1n,
      0xa8afb6bdn,
      0xc4cbd2d9n,
      0xe0e7eef5n,
      0xfc030a11n,
      0x181f262dn,
      0x343b4249n,
      0x50575e65n,
      0x6c737a81n,
      0x888f969dn,
      0xa4abb2b9n,
      0xc0c7ced5n,
      0xdce3eaf1n,
      0xf8ff060dn,
      0x141b2229n,
      0x30373e45n,
      0x4c535a61n,
      0x686f767dn,
      0x848b9299n,
      0xa0a7aeb5n,
      0xbcc3cad1n,
      0xd8dfe6edn,
      0xf4fb0209n,
      0x10171e25n,
      0x2c333a41n,
      0x484f565dn,
      0x646b7279n
    ];

    this.GET_ULONG_BE = function(b, i) {
      return (
        ((BigInt(b[i]) & 0xffn) << 24n) |
        ((BigInt(b[i + 1]) & 0xffn) << 16n) |
        ((BigInt(b[i + 2]) & 0xffn) << 8n) |
        (BigInt(b[i + 3]) & 0xffn & 0xffffffffn)
      );
    };

    this.PUT_ULONG_BE = function(n, b, i) {
      var t1 = 0xffn & (n >> 24n);
      var t2 = 0xffn & (n >> 16n);
      var t3 = 0xffn & (n >> 8n);
      var t4 = 0xffn & n;
      b[i] = t1 > 128n ? t1 - 256n : t1;
      b[i + 1] = t2 > 128n ? t2 - 256n : t2;
      b[i + 2] = t3 > 128n ? t3 - 256n : t3;
      b[i + 3] = t4 > 128n ? t4 - 256n : t4;
    };

    this.SHL = function(x, n) {
      return (x & 0xffffffffn) << BigInt(n);
    };

    this.ROTL = function(x, n) {
      return this.SHL(x, n) | (x >> (32n - BigInt(n)));
    };

    this.sm4Lt = function(ka) {
      var bb = 0;
      var c = 0;
      var a = new Array(4);
      var b = new Array(4);
      this.PUT_ULONG_BE(ka, a, 0);
      b[0] = this.sm4Sbox(a[0]);
      b[1] = this.sm4Sbox(a[1]);
      b[2] = this.sm4Sbox(a[2]);
      b[3] = this.sm4Sbox(a[3]);
      bb = this.GET_ULONG_BE(b, 0);
      c =
        bb ^
        this.ROTL(bb, 2) ^
        this.ROTL(bb, 10) ^
        this.ROTL(bb, 18) ^
        this.ROTL(bb, 24);
      return c;
    };

    this.sm4F = function(x0, x1, x2, x3, rk) {
      return x0 ^ this.sm4Lt(x1 ^ x2 ^ x3 ^ rk);
    };

    this.sm4CalciRK = function(ka) {
      var bb = 0;
      var rk = 0;
      var a = new Array(4);
      var b = new Array(4);
      this.PUT_ULONG_BE(ka, a, 0);
      b[0] = this.sm4Sbox(a[0]);
      b[1] = this.sm4Sbox(a[1]);
      b[2] = this.sm4Sbox(a[2]);
      b[3] = this.sm4Sbox(a[3]);
      bb = this.GET_ULONG_BE(b, 0);
      rk = bb ^ this.ROTL(bb, 13) ^ this.ROTL(bb, 23);
      return rk;
    };

    this.sm4Sbox = function(inch) {
      var i = inch & 0xffn;
      var retVal = SboxTable[i];
      return retVal > 128n ? retVal - 256n : retVal;
    };

    this.sm4_setkey_enc = function(ctx, key) {
      if (ctx == null) {
        console.error("ctx is null!");
        return false;
      }
      if (key == null || key.length != 16) {
        console.error("key error!");
        return false;
      }
      ctx.mode = this.SM4_ENCRYPT;
      this.sm4_setkey(ctx.sk, key);
    };

    this.sm4_setkey_dec = function(ctx, key) {
      if (ctx == null) {
        console.error("ctx is null!");
        return false;
      }
      if (key == null || key.length != 16) {
        console.error("key error!");
        return false;
      }
      var i = 0;
      ctx.mode = this.SM4_DECRYPT;
      this.sm4_setkey(ctx.sk, key);
      for (i = 0; i < 16; i++) {
        this.SWAP(ctx.sk, i);
      }
    };

    this.SWAP = function(sk, i) {
      var a = sk[i];
      sk[i] = sk[31 - i];
      sk[31 - i] = a;
    };

    this.sm4_setkey = function(SK, key) {
      var MK = new Array(4);
      var k = new Array(36);
      var i = 0;
      MK[0] = this.GET_ULONG_BE(key, 0);
      MK[1] = this.GET_ULONG_BE(key, 4);
      MK[2] = this.GET_ULONG_BE(key, 8);
      MK[3] = this.GET_ULONG_BE(key, 12);
      k[0] = MK[0] ^ FK[0];
      k[1] = MK[1] ^ FK[1];
      k[2] = MK[2] ^ FK[2];
      k[3] = MK[3] ^ FK[3];
      for (var i = 0; i < 32; i++) {
        k[i + 4] =
          k[i] ^ this.sm4CalciRK(k[i + 1] ^ k[i + 2] ^ k[i + 3] ^ CK[i]);
        SK[i] = k[i + 4];
      }
    };

    this.padding = function(input, mode) {
      if (input == null) {
        return null;
      }
      var ret = null;
      if (mode == this.SM4_ENCRYPT) {
        var p = parseInt(16 - (input.length % 16));
        ret = input.slice(0);
        for (var i = 0; i < p; i++) {
          ret[input.length + i] = p;
        }
      } else {
        var p = input[input.length - 1];
        ret = input.slice(0, input.length - Number(p));
      }
      return ret;
    };

    this.sm4_one_round = function(sk, input, output) {
      var i = 0;
      var ulbuf = new Array(36);
      ulbuf[0] = this.GET_ULONG_BE(input, 0);
      ulbuf[1] = this.GET_ULONG_BE(input, 4);
      ulbuf[2] = this.GET_ULONG_BE(input, 8);
      ulbuf[3] = this.GET_ULONG_BE(input, 12);
      while (i < 32) {
        ulbuf[i + 4] = this.sm4F(
          ulbuf[i],
          ulbuf[i + 1],
          ulbuf[i + 2],
          ulbuf[i + 3],
          sk[i]
        );
        i++;
      }
      this.PUT_ULONG_BE(ulbuf[35], output, 0);
      this.PUT_ULONG_BE(ulbuf[34], output, 4);
      this.PUT_ULONG_BE(ulbuf[33], output, 8);
      this.PUT_ULONG_BE(ulbuf[32], output, 12);
    };

    this.sm4_crypt_ecb = function(ctx, input) {
      if (input == null) {
        alert("input is null!");
      }
      if (ctx.isPadding && ctx.mode == this.SM4_ENCRYPT) {
        input = this.padding(input, this.SM4_ENCRYPT);
      }

      var i = 0;
      var length = input.length;
      var bous = new Array();
      for (; length > 0; length -= 16) {
        var out = new Array(16);
        var ins = input.slice(i * 16, 16 * (i + 1));
        this.sm4_one_round(ctx.sk, ins, out);
        bous = bous.concat(out);
        i++;
      }

      var output = bous;
      if (ctx.isPadding && ctx.mode == this.SM4_DECRYPT) {
        output = this.padding(output, this.SM4_DECRYPT);
      }
      for (var i = 0; i < output.length; i++) {
        if (output[i] < 0) {
          output[i] = output[i] + 256n;
        }
      }
      return output;
    };

    this.sm4_crypt_cbc = function(ctx, iv, input) {
      if (iv == null || iv.length != 16) {
        console.error("iv error!");
      }

      if (input == null) {
        console.error("input is null!");
      }

      if (ctx.isPadding && ctx.mode == this.SM4_ENCRYPT) {
        input = this.padding(input, this.SM4_ENCRYPT);
      }

      var i = 0,
        ins1,
        ivs;
      var length = input.length;
      var bous = new Array();
      if (ctx.mode == this.SM4_ENCRYPT) {
        var k = 0;
        for (var out, out1, ins; length > 0; length -= 16) {
          out = new Array(16);
          out1 = new Array(16);
          ins = input.slice(k * 16, 16 * (k + 1));

          for (i = 0; i < 16; i++) {
            ins1 = BigInt(ins[i] || 0);
            ivs = BigInt(iv[i] || 0);
            out[i] = ins1 ^ ivs;
          }

          this.sm4_one_round(ctx.sk, out, out1);
          iv = out1.slice(0, 16);
          bous = bous.concat(out1);
          k++;
        }
      } else {
        var temp = [];
        var k = 0;
        for (var out, out1, ins; length > 0; length -= 16) {
          out = new Array(16);
          out1 = new Array(16);
          ins = input.slice(k * 16, 16 * (k + 1));
          temp = ins.slice(0, 16);
          this.sm4_one_round(ctx.sk, ins, out);
          for (i = 0; i < 16; i++) {
            ins1 = BigInt(out[i] || 0);
            ivs = BigInt(iv[i] || 0);
            out1[i] = ins1 ^ ivs;
          }
          iv = temp.slice(0, 16);
          bous = bous.concat(out1);
          k++;
        }
      }

      var output = bous;
      if (ctx.isPadding && ctx.mode == this.SM4_DECRYPT) {
        output = this.padding(output, this.SM4_DECRYPT);
      }

      for (var i = 0; i < output.length; i++) {
        if (output[i] < 0) {
          output[i] = output[i] + 256n;
        }
      }
      return output;
    };
  }

  var str2Byte = function(str) {
    var bytes = [];
    var len, c;
    len = str.length;
    for (var i = 0; i < len; i++) {
      c = str.charCodeAt(i);
      if (c >= 0x010000 && c <= 0x10ffff) {
        bytes.push(((c >> 18) & 0x07) | 0xf0);
        bytes.push(((c >> 12) & 0x3f) | 0x80);
        bytes.push(((c >> 6) & 0x3f) | 0x80);
        bytes.push((c & 0x3f) | 0x80);
      } else if (c >= 0x000800 && c <= 0x00ffff) {
        bytes.push(((c >> 12) & 0x0f) | 0xe0);
        bytes.push(((c >> 6) & 0x3f) | 0x80);
        bytes.push((c & 0x3f) | 0x80);
      } else if (c >= 0x000080 && c <= 0x0007ff) {
        bytes.push(((c >> 6) & 0x1f) | 0xc0);
        bytes.push((c & 0x3f) | 0x80);
      } else {
        bytes.push(c & 0xff);
      }
    }
    return bytes;
  };

  var str2HByte = function(str) {
    var len, hexA;
    if (!str || (len = str.length) % 2 != 0) {
      return null;
    }

    len /= 2;

    hexA = [];
    for (var i = 0, st = 0, s, v; i < len; i++) {
      s = str.substr(st, 2);
      v = parseInt(s, 16);
      hexA.push(int2Byte(v));
      st += 2;
    }
    return hexA;
  };

  var int2Byte = function(i) {
    var b, c;
    b = i & 0xff;
    if (b < 128) {
      return b;
    }
    c = b % 128;
    return -1 * (128 - c);
  };

  var bytes2HexString = function(bytes) {
    var str = "";
    var num;
    for (var i = 0; i < bytes.length; i++) {
      num = bytes[i];
      if (num < 0) {
        num += 256;
      }
      num = num.toString(16);
      if (num.length == 1) {
        num = "0" + num;
      }

      str += num;
    }

    return str;
  };

  var byte2String = function(bytes) {
    if (!bytes) {
      return bytes;
    }

    if (typeof bytes === "string") {
      return bytes;
    }

    var str = "";
    for (var i = 0, b, v, s, len; i < bytes.length; i++) {
      b = bytes[i].toString(2);
      v = b.match(/^1+?(?=0)/);
      if (v && b.length == 8) {
        len = v[0].length;
        s = b.slice(7 - len);
        for (var st = 1; st < len; st++) {
          if (bytes[st + i]) {
            s += bytes[st + i].toString(2).slice(2);
          }
        }
        str += String.fromCharCode(parseInt(s, 2));
        i += len - 1;
      } else {
        if (v !== null) {
          str += String.fromCharCode(Number(bytes[i]));
        }
      }
    }
    return str;
  };

  /**
   * get key
   */
  function getKey(nonce, ts) {
    if (!nonce || !ts || nonce.length < 32) {
      return null;
    }

    var t, str;
    t = ts.substr(ts.length - 1);

    nonce = "&g;1?f0W/=R86f@*" + nonce;

    str = "";
    for (var i = 0; i < nonce.length; i++) {
      str += String.fromCharCode(t ^ nonce[i].charCodeAt());
    }

    return [str.substr(0, 16), str.substr(16, 16)];
  }

  $.extend({
    sm4: function(str, sKey, ivKey, dec) {
      var ctx, sm4, inBytes, outBytes;
      try {
        if (sKey == undefined) {
          console.error("sKey is not null!!!");
          return null;
        }

        ctx = new SM4_Context();
        sm4 = new SM4();

        if (undefined === dec) {
          sm4.sm4_setkey_enc(ctx, str2Byte(sKey));
          inBytes = str2Byte(str);
        } else {
          sm4.sm4_setkey_dec(ctx, str2Byte(sKey));
          inBytes = str2HByte(str);
        }

        if (ivKey == undefined) {
          outBytes = sm4.sm4_crypt_ecb(ctx, inBytes);
        } else {
          outBytes = sm4.sm4_crypt_cbc(ctx, str2Byte(ivKey), inBytes);
        }

        if (undefined === dec) {
          return bytes2HexString(outBytes);
        }
        return byte2String(outBytes);
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    sm4Nt: function(str, nonce, ts, dec) {
      var strs = getKey(nonce, ts);
      if (!strs || strs.length < 2) {
        console.error("getKey error!!!");
        return;
      }
      return $.sm4(str, strs[0], strs[1], dec);
    }
  });
})(jQuery);
