export default {
    el: {
        colorpicker: {
            confirm: '确定',
            clear: '清空'
        },
        datepicker: {
            now: '此刻',
            today: '今天',
            cancel: '取消',
            clear: '清空',
            confirm: '确定',
            selectDate: '选择日期',
            selectTime: '选择时间',
            startDate: '开始日期',
            startTime: '开始时间',
            endDate: '结束日期',
            endTime: '结束时间',
            prevYear: '前一年',
            nextYear: '后一年',
            prevMonth: '上个月',
            nextMonth: '下个月',
            year: '年',
            month1: '1 月',
            month2: '2 月',
            month3: '3 月',
            month4: '4 月',
            month5: '5 月',
            month6: '6 月',
            month7: '7 月',
            month8: '8 月',
            month9: '9 月',
            month10: '10 月',
            month11: '11 月',
            month12: '12 月',
            // week: '周次',
            weeks: {
                sun: '日',
                mon: '一',
                tue: '二',
                wed: '三',
                thu: '四',
                fri: '五',
                sat: '六'
            },
            months: {
                jan: '一月',
                feb: '二月',
                mar: '三月',
                apr: '四月',
                may: '五月',
                jun: '六月',
                jul: '七月',
                aug: '八月',
                sep: '九月',
                oct: '十月',
                nov: '十一月',
                dec: '十二月'
            }
        },
        select: {
            loading: '加载中',
            noMatch: '无匹配数据',
            noData: '无数据',
            placeholder: '请选择'
        },
        cascader: {
            noMatch: '无匹配数据',
            loading: '加载中',
            placeholder: '请选择',
            noData: '暂无数据'
        },
        pagination: {
            goto: '前往',
            pagesize: '条/页',
            total: '共 {total} 条',
            pageClassifier: '页'
        },
        messagebox: {
            title: '提示',
            confirm: '确定',
            cancel: '取消',
            error: '输入的数据不合法!'
        },
        upload: {
            deleteTip: '按 delete 键可删除',
            delete: '删除',
            preview: '查看图片',
            continue: '继续上传'
        },
        table: {
            emptyText: '暂无数据',
            confirmFilter: '筛选',
            resetFilter: '重置',
            clearFilter: '全部',
            sumText: '合计'
        },
        tree: {
            emptyText: '暂无数据'
        },
        transfer: {
            noMatch: '无匹配数据',
            noData: '无数据',
            titles: ['列表 1', '列表 2'],
            filterPlaceholder: '请输入搜索内容',
            noCheckedFormat: '共 {total} 项',
            hasCheckedFormat: '已选 {checked}/{total} 项'
        },
        image: {
            error: '加载失败'
        },
        pageHeader: {
            title: '返回'
        },
        popconfirm: {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
        },
        empty: {
            description: '暂无数据'
        }
    },
    //公用
    public: {
        page: '页', //页
        total: '共', //总数
        recordss: '条记录', //记录
        skip: '跳转', //跳转
        Tower: '台', //台
        New: '新增', //新增按钮
        Editor: '编辑', //编辑
        Inquire: '查询', //查询按钮
        Transfer: '转移', //转移
        Outofservice: '停用', //停用
        Enable: '启用', //启用
        Join: '加入',
        Save: '保存', //保存
        Size: '大小', //大小
        Name: '姓名', //姓名
        Phone: '电话', //电话
        Email: '邮箱', //邮箱
        Port: '端口', //端口
        Lock: '锁定', //锁定
        Normal: '正常', //正常
        Delete: '删除', //删除
        Verify: '确定', //确定
        Cancel: '取消', //取消
        Derive: '导出', //导出
        Unlock: '解锁', //解锁
        Details: '详情', //详情
        Section: '部门', //部门
        Version: '版本', //版本
        Controls: '操作', //操作
        Position: '位置', //位置
        Username: '用户名', //用户名
        Forbidden: '禁用', //禁用
        Download: '下载', //下载
        Installed: '已安装', //已安装
        Executed: '已执行', //已执行
        HaveRead: '已阅读', //已阅读
        Assigned: '已分配', //已分配
        DomainName: '域名', //域名
        ExternalIP: '外网IP', //外网IP
        IntranetIP: '内网IP', //内网IP
        ApplicationID: '应用ID', //应用ID
        Toleadinto: '导入', //导入
        Distribution: '分配', //分配
        ResourceName: '资源名称', //资源名称
        OperatingSystem: '操作系统', //操作系统
        ApplicationName: '应用名称', //应用名称
        StartTime: '开始时间', //开始时间
        EndTime: '结束时间', //结束时间
        ModificationTime: '修改时间', //修改时间
        ReportingTime: '上报时间', //上报时间
        GenerationTime: '产生时间', //产生时间
        SystemVersion: '系统版本', //系统版本
        PromptMessage: '提示信息', //提示信息
        SuccessfullySaved: '保存成功', //保存成功
        SuccessfullyDeleted: '删除成功', //删除成功
        SuccessfullyDeleteds: '批量删除成功', //批量删除成功
        ApplicationVersion: '应用版本', //应用版本
        DeviceName: '设备名称', //设备名称
        Behaviorlevel: '行为级别', //行为级别
        BehaviorType: '行为类型', //行为类型
        Eventlevel: '事件级别', //事件级别 v6.3.2新增设备事件
        EventType: '事件类型', //事件类型 v6.3.2新增设备事件
        AssociatedDevices: '关联设备', //关联设备
        SystemCategory: '系统类别', //系统类别
        TimeFrame: '时间范围', //时间范围
        LatitudeLongitude: '经纬度', //经纬度
        InternetMethod: '上网方式', //上网方式
        page: '页',
        total: '共',
        records: '条记录，显示',
        skip: '跳转',
        remark: '备注',
        appUpsuccessfully: "上传应用成功",
        appUpfail: "上传应用失败",
        ImportSuccessful: '导入成功',
        UploadSuccess: '上传文件成功',
        IMEIAndroid: '设备IMEI',// 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;Linux:网卡Mac; Windows:网卡Mac
        IMEIiOS: 'UDID',
        IMEIMac: '网卡Mac',
        IMEIUnknown: '设备标识码',
        NoData: '暂无数据', //暂无数据
    },
    //路由菜单
    menu: {
        /*Asset: "系统资产",*/
        SourceHome: "首页",
        UserManage: "用户管理",
        DeviceManage: "设备管理",
        ContentManage: "内容管理",
        AppManage: "应用管理",
        PolicyManage: "策略管理",
        DevicePolicy: "设备策略",
        SystemStatus: "系统状态",
        SystemSettings: "系统配置",
        LogManage: "事件管理",
        Help: "帮助",
        Debugging: "系统调试",
        NetworkDataBackup: "流量备份",
        CategoryManage: "分类管理",
        IDCard: "身份证号",
        BankCard: "银行卡号",
        Cell: "手机号码",
        Keyword: "关键字",
        Regex: "正则表达式",
        FileAttr: "文件属性",
        FileHash: "文件指纹",
        Semantic: "语义分析",
        LevelManage: "分级管理",
        WhiteSheet: "白名单",
        WSConnect: "网络连接",
        WSServer: "内部服务器",
        FileType: "文件类型",
        Advanced: "高级设置",
        LogQuery: "事件查询",
        SysLogQuery: "管理员事件",
        SysLogFile: "流量分析日志",
        LogStatistics: "风险分析",
        NetworkSettings: "网络设置",
        StaticRoute: "静态路由",
        Upgrade: "系统升级",
        Backup_Restore: "备份/恢复",
        PowerSettings: "电源设置",
        FactoryDefault: "恢复出厂",
        ByCategory: "按策略",
        ByLevel: "按级别",
        About: "关于",
        License: "授权激活",
        Tool: "工具下载",
        Contact: "联系我们",
        PortMap: "端口映射",
        Clean: "定时清理",
        Graphic: "风险分析",
        Graphic_Level: "按级别统计",
        Graphic_Category: "按策略统计",
        Graphic_OutAddr: "按外部地址统计",
        Graphic_InAddr: "按内部地址统计",
        File_match: "匹配文件",
        File_Nmatch: "未匹配文件",
        File_Unknown: "未检测文件",
        Resource: "资源管理",
        FileInfo: "文件信息",
        MailInfo: "邮件信息",
        DBHash: "数据库指纹",
        Rule: "策略管理",
        Sms_Template: "短信模板",
        Mail_Template: "邮件模板",
        Code: "代码生成",
        Admin: "管理员管理",
        Administrator: "管理员",
        Role: "角色",
        Employee: "员工管理",
        UserInfoList: "用户管理",
        Groups: "部门",
        User: "用户",
        UserGroup: "用户组",
        DeviceList: "设备管理",
        Device: "设备",
        DeviceGroup: "设备组",
        Document: "文档管理",
        DocumentCategory: "文档类别",
        SetVisible: "设置可见范围",
        SettledVisible: "已设范围",
        App: "应用商店",
        AppPolicy: "应用策略",
        AppCategory: "应用类别",
        AppLib: "应用库",
        WaterConfig: "界面水印",
        WaterResolution: "暗水印解析",
        BwList: "黑白名单",
        IOSBw: "iOS",
        AndroidBw: "Android",
        WifiBw: "WiFi",
        Notice: "通知管理",
        StorageManage: "存储管理",
        DataManage: "数据集",
        PStatd: "协议分析",
        PFileParse: "文件分析",
        Table: "数据表",
        Field: "字段管理",
        DB: "数据库设置",
        EmailTemplate: "邮件模板",
        SmsTemplate: "短信模板",
        Version: "终端版本",
        TimedTask: "定时任务",
        PolicyList: "策略列表",
        GeoFence: "地理围栏",
        ManageLogs: "日志审计",
        AlarmLog: "告警日志",
        ClientLog: "终端日志",
        ManageLog: "管理日志",
        ArchiveLog: "日志归档",
        InternetRecords: "上网记录",
        UrlAccessRecord: "网址访问记录",
        ClientPolicy: "合规策略",
        IOS: "iOS应用",
        Android: "Android应用",
        sandbox: "安全沙箱",
        normalApp: "未做防护",
        UrlLibrary: "网址库",
        UrlList: "网址总列表",
        NoTypeUrlList: "未分类列表",
        BaseConfig: "基线策略",
        GroupConfig: "组策略",
        Safety: "安全域名分类",
        Violation: "违规域名分类",
        Running: "运行配置",
        DBConfig: "数据库配置",
        PushConfig: "分配配置",
        SmsGatewayConfig: "短信网关",
        EmailGatewayConfig: "邮件网关",
        SystemDefined: "自定义配置",
        DeliveryHistory: "分配历史",
        DeliveryManage: "分配管理",
        Delivery: "分配管理",
        ExecResult: "执行结果",
        Reinforce: "加固配置",
        AppConfig: "客户端配置",
        HttpUrl: "服务器地址",
        IosCertificateManage: "iOS证书管理",
        IOSOTA: "iOS OTA",
        UserIdea: "用户反馈",
        BackupAndRestore: "备份与恢复",
        BackupRestore: "备份/恢复",
        AndroidCertificateManage: "Android证书管理",
        AppReinforce: "应用加固",
        Scan: "应用检测",
        Home1: "安全态势",
        SecurityMonitor: "安全监测",
        ViolationMonitor: "违规行为",
        AppAttackMonitor: "威胁行为",
        AppSensitiveMonitor: "敏感行为",
        "Scan&Fasten": "检测加固",
        CustomConfig: "页面自定义",
        Syslog: "系统日志",
        SecurityConfig: "安全配置",
        AndroidConfig: "Android配置",
        IosConfig: "iOS配置",
        WindowsConfig: "Windows配置",
        LinuxConfig: 'Linux配置',
        CustomizedConfig: "定制化配置",
        Upgrade: "系统升级",
        S6000Config: "对外接口配置",
        SystemWarning: "系统预警配置",
        SourceHome: "首页",
        SensitiveWords: "敏感词库",
        Behavior: "审计信息",
        TimeFence: "时间围栏",
        SafetyAnalysis: "安全事件",
        SDKMonitor: "SDK监控",
        BannedConfig: "封禁配置",
        WhiteList: "白名单",
        SDKBehaviour: "SDK行为",
        Interdiction: "封禁行为",
        DangerIP: '风险IP',
        RunInfo: "运行信息",
        Accessbehavior: "访问行为",
        BehaviorLevel: "行为级别配置",
        Home2: "告警信息",
        WarningInfo: "预警信息",
        ThreatDefinition: "威胁定义",
        DeviceEvents: "设备事件",
        SafetyEvent: "安全事件",
        MobileOperations: "移动运维",
        CrashAnalysis: "崩溃信息",
        SecurityEvent: "安全事件",
        PolicyConfig: "策略配置",
        Index: "首页",
        deviceClientLog: "客户端日志",
        GDment: "工单管理",
        ClientHelp: "用户帮助配置",
        RunManagement: "运营管理",
        ArtResult: "技术成果",
        ResultsCategory: "成果类别",
        InstitutionalNorm: "制度规范",
        SpecificationCategory: "规范类别",
        workFlowManagement: "流程管理",
        workFlowSet: "流程设置",
        H5Reinforce: "H5加固",
        CertificateManage: "证书管理",
        ApplyForapproval: "申请审批",
        MyApplication: "我的申请",
        MyForapproval: "我的审批",
        ReportManagement: "报表管理",
        DataReport: "数据报表",
        ReportTemplate: "创建报表",
        developmentCenter: "开发中心",
        developmentCategory: "开发类别",
        SafetyScan: "安全检测",
        H5Scan: "H5检测",
        SdkScan: "SDK检测",
        ScanLevel: "检测等级",
        ScanAnalysis: "检测分析",
        SafetyReinforce: "安全加固",
        SDKReinforce: "SDK加固",
        AccessConfiguration: "访问管理",
        GatewayResourceManagement: "网关配置",
        ServiceResourceManagement: "业务资源",
        GatewayAccessLog: "网关访问日志",
        VirusInfo: "病毒信息",
        SelfServiceDesk: "自助服务台",
        equipmentManagement: "设备管理",
    },
    //首页菜单路由修改密码
    MainHome: {
        Editpass: '修改密码',
        LanguageSetting: '语言设置',
        Logout: '退出登录',
        OldPassword: '旧密码：',
        NewPassword: '新密码：',
        ConfirmPassword: '确认密码：',
        LanguageOptions: {
            '中文简体': '中文简体',
            'English': 'English',
            '中文繁體': '中文繁體'
        },
        rules: {
            oldPassword: '请输入旧密码',
            newPassword: '请输入新密码',
            confirmPassword: '请输入确认密码',
            MessagePort: '长度在 8 到 32 个字符'
        },
        Error: '请输入密码',
        Error1: '8~32位，至少包含数字、字母、符号两种元素',
        Error2: '请再次输入密码',
        Error4: '两次输入密码不一致!',
        Error5: '新密码不能与原密码相同!',
        Error6: '你已被上级管理员停用即将跳转登录页',
        Error7: '当前登录已失效，请重新登录',
        Success: '修改密码成功',
        Error8: '旧密码错误，您还可以输入',
        Error9: '次',
        Error10: '旧密码错误次数超过限制，请于',
        Error11: '后再试',
        MessagePort: '确定退出系统?',
    },
    //首页
    home: {
        top: {
            deviceSum: "设备总数",
            on_line: "在线",
            off_line: "离线",
            online_unit: "在线设备",
            offline_unit: "离线设备",
            userSum: "用户总数",
            average: "正常",
            lock: "锁定",
            dead: "停用",
            vioTotal: "违规总数",
            todayViolationCount: "当天",
            violation: "违规",
            total: "总数",
            few: "个",
            bench: "台",
            throughs: "次",
            logout: "退出",
        },
        // 中间
        middle: {
            SystemUsage: "系统使用情况",
            DeviceActiveTrend: "设备活跃趋势",
            PlatformContentStatistics: "平台内容统计",
            cpuUseRate: "CPU使用率",
            used: "已使用",
            gross: "总量",
            MemoryUsage: "内存使用率",
            DiskUsage: "硬盘使用率",
            appSum: "应用总数",
            strategySum: "策略总数",
            documentsSum: "文档总数",
            noticeSum: "通知总数",
            choose: "请选择",
            inlineEquipment: "在线设备", //在线设备
            offlineEquipment: "离线设备", //离线设备
            当天: '当天', //当天
            '近7天': '近7天', //近7天
            '近30天': '近30天', //近30天
            '近90天': '近90天', //近90天
        },
        //底部
        third: {
            CompStatus: "合规状态分布", //合规状态分布----title
            normalEquipment: '正常设备', //正常设备
            violatingEquipment: '违规设备', //违规设备
            ViolationTrend: "违规行为趋势", //违规行为趋势----title
            violationsNum: '违规数量', //违规数量
            DistributionViola: "违规行为分布", //违规行为分布----title
            otherData: "查看其它违规行为", ///查看其它违规行为
            noData: '暂无数据'
        }
    },
    //用户管理
    UserManage: {
        // 用户
        // 用户
        user: { //用户状态
            UserStatus: {
                用户状态: '用户状态',
                正常: '正常',
                锁定: '锁定',
                停用: '停用'
            },
            username: { //用户名
                用户名: '用户名',
                姓名: '姓名',
                电话: '电话'
            },
            SetAsAdministrator: '设置为管理员', //按钮
            UserList: { //用户列表 
                sex: '性别',
                UserStatus: '用户状态',
                ResetPassword: '重置密码', //操作提示语
                confirmMessage: '确定删除选中的用户?', //操作列删除提示语
                delMessage: '删除选中用户失败，用户存在绑定设备', //操作列删除提示语 
                LockMessage: '确定解锁选中的用户?', //解锁提示语
                LockErrorMessage: '解锁选中用户失败', //解锁提示语
                LockSuccessfulMessage: '解锁成功', //解锁提示语
                ImportSuccessfully: '导入用户成功', //导入提示语
                enable: '确定启用选中的用户?', //启用提示语
                OutOfService: '确定停用选中的用户?', //启用提示语
                userTingSuccess: '用户停用成功',//停用成功
                userTingError: '用户停用失败',//停用失败
                userQiSuccess: '用户启用成功',//启用成功
                userQiError: '用户启用失败',//启用失败
                PasswordSuccessful: '重置密码成功', //列表重置密码成提示语
                //关联 弹框列表
                DeviceStatus: '设备状态',
                male: '男',
                female: '女',
                newConstruction: '新建', // 新建
                PendingRegistration: '等待注册', // 等待注册
                Join: '加入', // 加入
                Active: "已激活",
                Notactive: "未激活",
                SignOUT: '注销', // 注销
                BinDingState: '绑定状态', // 绑定状态
                Bind: '绑定', // 绑定
                Untie: '解绑', // 解绑
                lastLoginTime: '最近登录时间',
                PleaseEnterTheDeviceName: '请输入设备名称'
            },
            placeholder: '请输入部门名称', //左侧树形结构上的提示语
            addList: { //   *****新增页面*****
                username: '用户名：',
                name: '姓名：',
                telephone: '电话：',
                mailbox: '邮箱：',
                sex: '性别：',
                cipher: '密码：',
                NewPassword: '新密码：',
                ConfirmPassword: '确认密码：',
                TheDefaultPasswordIs: '默认密码是',
                DefaultPassword: '默认密码',
                Custom: '自定义',
                male: '男',
                female: '女',
                note: '注：',
                saveError: '保存失败，超过用户授权数', //保存提示语 
                saveRepeat: '保存失败，用户名已存在', //保存提示语
            },
            TransferPopUp: { //   *****转移弹框*****
                SelectDepartment: '选择部门', //转移弹框title
                Transfer: '转移',
                TransferSuccess: '转移成功', //转移提示语
                pleaseSelectDepartment: '请选择部门', //转移为空提示语 
            },
            SetAdministrator: { //   *****设置为管理员页面*****
                username: '用户名：',
                name: '姓名：',
                telephone: '电话：',
                mailbox: '邮箱：',
                sex: '性别：',
                male: '男',
                female: '女',
                role: '角色：',
                section: '部门：',
                ModifySection: '修改部门',
                AdministrativeDepartment: '管理部门：',
                RevisionManagement: '修改管理部门',
                pleaseSelectDepartment: '请选择部门', // 为空时提示
                pleaseSelectAdministration: '请选择管理部门', // 为空时提示
                //弹框title
                SelectDepartment: '选择部门', //转移弹框title
                SelectAdministration: '选择管理部门', //转移弹框title

            },
            ToLeadInto: { //   *****导入
                AllowEntry: '允许录入',
                document: '文件：',
                browse: '浏览',
                DownloadTemplate: '下载模板',
                isNot: '否',
                Is: '是',
                sex: '性别',
                remark: '备注',
                UploadSuccessfully: '上传成功',
                UploadFileSize: '上传文件大小不能超过 100MB!',
                PleaseXlsOrXlsxFile: '请上传xls或xlsx文件',
                ListOfUsers: '用户列表：',
                message: "导入用户模板有误",
            },
            popReset: { //重置密码弹框
                ResetTitle: '重置密码',
                submit: '提交',
                NewPassword: '新密码:',
                ConfirmPassword: '确认密码:',
            },

        },
        // 用户组
        userGroup: {
            placeholder: "请输入用户组名称", //提示语 
            name: "用户组名称",//用户组名称
            userCount: "关联用户",//关联用户
            delMessage: "确定删除选中的用户组？",//确定删除选中的用户组？
            delError: "该组关联其他部门用户，无法删除",//该组关联其他部门用户，无法删除
            userName: "用户",//用户
            chooseUser: "选择用户",//选择用户
            nameStr: "最大输入20个字符",//最大输入20个字符
            sameName: "保存失败，用户组名称已存在",//保存失败，用户组名称已存在
            Error1: "用户组名称不能为空",
            placeholderName: "请输入用户名",//请输入用户名
            normal: "正常",//正常
            locked: "锁定",//锁定
            notUsed: "停用",//停用
        },
        // 部门
        section: {
            leftTree: { //左侧
                TreeCue: "请输入部门名称", //左侧树形结构提示语
            },
            rightBig: { //右侧
                placeholder: "请输入部门名称", //提示语 
                DepartmentList: { //部门列表
                    DepartmentName: '部门名称',
                    SuperiorDepartment: '上级部门',
                    Description: '描述',
                },
                addList: { //新增
                    DepartmentName: '部门名称：', //部门名称表头 
                    Description: '描述：', //描述表头 
                    saveError: '保存失败，名称已被使用', //新增提示语
                    delConfirmMessage: '确定删除选中部门?', //删除提示语
                    confirmMessage: '确定删除选中的用户?', //操作列删除提示语
                    delLose: '删除失败，包含子部门', //删除失败提示语
                    delLoseTwo: '删除失败，包含用户或管理员', //删除失败提示语
                }
            }
        },
        //管理员
        adminPage: {
            Records: '条记录',
            用户状态: '用户状态', //用户状态
            正常: '正常', //正常
            锁定: '锁定', //锁定
            停用: '停用', //停用
            男: '男',
            女: '女',
            SetAsRegularUser: '设置为普通用户', //设置为普通用户
            gender: '性别', //性别
            male: '男', //男
            female: '女', //女
            role: '角色', //角色
            ManageDepartment: '管理部门', //管理部门
            AssociatedDevices: '关联设备', //关联设备
            UserStatus: '用户状态', //用户状态
            // --修改密码弹窗-------------
            resePass: '重置密码', //重置密码
            submit: '提交', //提交
            NewPass: '新密码', //新密码：
            ConfirmPass: '确认密码', //确认密码：
            // --设备关联弹窗------------------
            placeholder: "请输入设备名称", //
            DeviceStatus: '设备状态', //设备状态
            newBuilt: '新建', //新建
            WaitingRegistra: '等待注册', //等待注册
            join: '加入', //加入
            Active: "已激活",
            Notactive: "未激活",
            deactivete: '取消激活', //取消激活
            logOff: '注销', //注销
            BindingState: '绑定状态', //绑定状态
            Unbinding: '解绑', //解绑
            binding: '绑定', //绑定
            BindLastLoginTime: '最近登录时间', //最近登录时间
            // --新增管理员页面---------------------
            SendingType: '发送方式', //发送方式：
            mail: '邮件', //邮件
            SelectDepartment: '选择部门', //选择部门
            SelectManageDepartment: '选择管理部门', //选择管理部门
            password: '密码', //密码：
            DefaultPass: '默认密码', //默认密码
            custom: '自定义', //自定义
            pour: '注', //注
            DefaultPassIs: '默认密码是', //默认密码是
            loginNameVoid: '请输入用户名',
            userNameVoid: '请输入姓名',
            loginNameReg: '用户名由4~32个字符，支持字母、数字或下划线组成', //设备状态
            NotSupported: '不支持空格及特殊字符',
            phoneVoid: '请输入电话',
            phoneReg: '请输入正确的手机号',
            phoneStr: '最长输入12个字符，仅支持数字',
            emailVoid: '请输入邮箱',
            emailReg: '邮箱必须是正确的email',
            userRoleVoid: '请选择角色',
            newPasswordVoid: '请输入新密码',
            newPasswordReg: '8~32位，至少包含数字、字母、符号两种元素。',
            passwordVoid: '请输入密码',
            passwordReg: '8~32位，至少包含数字、字母、符号两种元素。',
            ConfirmPassVoid: '请输入确认密码',
            PassRepetition: '两次输入密码不一致!',
            PleaseSelectDepartment: '请选择部门',
            PleaseSelectManageDepartment: '请选择管理部门',
            UserAlreadyExists: '保存失败，用户名已存在',
            AddAdminFailed: '新增管理员失败',
            OrdinaryUsers: '确定要设置为普通用户？',
            DetermineInfo: '确定信息',
            SuccessfullySetUser: '设置普通用户成功',
            sureDeactivate: '确定要停用选中的管理员？',
            DeactivateAdminSuccess: '管理员停用成功',
            DeactivateAdminError: '管理员停用失败',//停用失败
            sureEnable: '确定要启用选中的管理员？',
            enabledAdminSuccess: '管理员启用成功',
            enabledAdminError: '管理员启用失败',//启用失败
            passModifySuccess: '密码修改成功',
            ReminderInfo: '提示信息',
            sureUnlocking: '确定解锁选中的管理员？',
            UnlockSelecFailed: '解锁选中管理员失败',
            SuccessUnlockeSelecAdmin: '解锁选中管理员成功',
            boundDevPresent: '删除选中管理员失败，管理员存在绑定设备',
            delMessage:'删除管理员信息失败',
            sureDelete: '确定要删除选中的管理员?',
        },
        //角色
        rolePage: {
            placeholder: '请输入角色名称',
            RoleName: '角色名称', //角色名称
            describe: '描述', //描述
            founder: '创建人', //创建人
            ReminderInfo: '提示信息',
            ConfirmDeleteSelectRole: '确定删除选中的角色?', //
            DeleteFailedIncludAdmin: '删除失败,包含管理员', //
            MenuPermissions: '菜单权限', //
            Saving: '保存中', //
            SaveFailed: '保存失败',
            NotSupported: '不支持空格及特殊字符',
            RoleNameVoid: '请输入角色名称', //
            MenuPermissVoid: '请选择菜单权限', //
        }
    },
    //设备管理
    DeviceManage: {
        //设备管理
        Device: {
            // 下拉选
            OperatSystem: { // 操作系统
                操作系统: '操作系统',
                iOS: 'iOS',
                Android: 'Android',
                Linux: 'Linux',
                Windows: 'Windows'
            },
            equipownership: { // 设备归属
                设备归属: '设备归属',
                企业: '企业',
                个人: '个人'
            },
            Devicestatus: { // 设备状态
                设备状态: '设备状态',
                // 新建: '新建',
                // 加入: '加入',
                未激活: '未激活',
                已激活: '已激活',
                注销: '注销',
                停用: '停用'
            },
            Complianstatus: { // 合规状态
                合规状态: '合规状态',
                正常: '正常',
                违规: '违规'
            },
            Markerstate: { // 标记状态
                标记状态: '标记状态',
                未标记: '未标记',
                已找回: '已找回',
                已丢失: '已丢失',
                已报废: '已报废'
            },
            Onlinestate: { // 在线状态
                在线状态: '在线状态',
                在线: '在线',
                离线: '离线'
            },
            Onlinefrequen: { // 在线频度
                在线频度: '在线频度',
                '1天内在线': '1天内在线',
                '7天内在线': '7天内在线',
                '30天内在线': '30天内在线',
                '90天内在线': '90天内在线',
                '1天内离线': '1天内离线',
                '7天内离线': '7天内离线',
                '30天内离线': '30天内离线',
                '90天内离线': '90天内离线'
            },
            Devicename: { // 设备名称
                设备名称: '设备名称',
                系统版本: '系统版本',
                UDID: 'UDID',
                用户名: '用户名',
                姓名: '姓名',
                部门: '部门',
            },
            Tablelinkage: { //表格联动
                设备名称: '设备名称',
                UDID: 'UDID',
                用户名: '用户名',
                姓名: '姓名',
                部门: '部门',
                设备组: '设备组',
                操作系统: '操作系统',
                系统版本: '系统版本',
                设备归属: '设备归属',
                设备状态: '设备状态',
                流程状态: '流程状态',
                绑定状态: '绑定状态',
                合规状态: '合规状态',
                在线状态: '在线状态',
                隧道状态: '隧道状态',
                标记状态: '标记状态',
                健康度: '健康度',
                激活时间: '激活时间',
                最近登录时间: '最近登录时间',
                操作: '操作',
            },
            DeviceImport: { // 导入
                Documents: '文件',
                Choose: '选择',
                DownloadTemplate: '下载模板',
                DeviceList: '设备列表',
                AllowImport: '允许导入',
                Notes: '备注',
                Pleaseupload: '请上传xls或xlsx文件',
                Uploadsuccessful: '上传成功',
                Cannotexceed: '上传文件大小不能超过 100MB!',
                IMEIUDIDMAC: 'IMEI/UDID/网卡MAC',
                message: "导入设备模板有误",
            },
            Deviceexport: { // 导出
                Onoffline: '在/离线时长',
                OnofflineDays: '在/离线天数',
                Goodhardware: '硬件串行号',
                CPUinstructionset: 'CPU指令集',
                CPUinstructiontwo: 'CPU指令集2',
                Userinformation: '用户信息',
                Gender: '性别',
            },
            Newlyadded: { // 新增
                Pleasechoose: '请选择',
                Selectuser: '选择用户',
                IMEIalreadyexists: 'IMEI已存在',
                MessageError13: '请填写正确的网卡MAC地址，格式：XX-XX-XX-XX-XX-XX',// v6.2.0新增

            },
            Individuality: { // 新增
                企业: '企业',
                个人: '个人',
            },
            Addauser: { // 选择用户查询
                用户名: "用户名",
                姓名: "姓名",
            },
            // 按钮
            Installapplica: '安装应用', // 安装应用
            Uninstallapplica: '卸载应用', // 卸载应用
            Markloss: '标记丢失', // 标记丢失
            Markretrieval: '标记找回', // 标记找回
            Markobsolescence: '标记报废', // 标记报废
            Lockingdevice: '锁定设备', // 锁定设备
            Unlockdevice: '解锁设备', // 解锁设备
            Equipmentunty: '设备解绑', // 设备解绑
            Cleardata: '清除数据', // 清除数据
            Forcedcancell: '强制注销', // 强制注销
            Safetytunnel: '安全隧道', // 安全隧道
            // 列表
            UDID: 'UDID', // UDID
            Equipmentownership: '设备归属', // 设备归属
            Firm: '企业', // 企业
            Personage: '个人', // 个人
            Unknown: '未知', // 未知
            EquipmentStatus: '设备状态', // 设备状态
            askStatus: '流程状态', //流程状态
            NotAppliedFor: '未申请', //未申请
            PendingApproval: '待审批', //待审批
            inApproval: '审批中', //审批中
            Passed: '已通过', //已通过
            rejected: '已拒绝', //已拒绝
            repealed: '已废除', //已废除
            revoked: '已撤销', //已撤销
            Fail: '未通过', //未通过
            AutomaticApproval: '自动审批', //自动审批
            newconstruction: '新建', // 新建
            Pendingregistration: '等待注册', // 等待注册
            Join: '加入', // 加入
            SignOUT: '注销', // 注销
            Bindingstate: '绑定状态', // 绑定状态
            Bind: '绑定', // 绑定
            Untie: '解绑', // 解绑
            Compliancestatus: '合规状态', // 合规状态
            Getoutofline: '违规', // 违规
            Signstate: '标记状态', // 标记状态
            Nnlabeled: '未标记', // 未标记
            Recovered: '已找回', // 已找回
            Lost: '已丢失', // 已丢失
            Scrapped: '已报废', // 已报废
            Onlinesta: '在线状态', // 在线状态
            Offline: '离线', // 离线
            Online: '在线', // 在线
            Tunnelstatus: '隧道状态', // 隧道状态
            Healthdegree: '健康度', // 健康度
            jiuoTime: '激活时间', // 激活时间

            lastLoginTime: '最近登录时间', // 最近登录时间
            // 操作  弹出框
            Setascompliant: '设为合规', // 设为合规
            Factoryrecovery: '恢复出厂', // 恢复出厂
            Extractlog: '提取日志', // 提取日志
            Settings: '设置', // 设置
            Installationapp: { // 安装应用 --- 弹框
                Installationapplication: '安装应用',
                Install: '安装',
                Pleaseenter: '请输入应用名称',
                appName: '应用名称',
                ApplicationID: '应用ID',
                Edition: '版本',
                Common: '共',
                Records: '条记录',
                // 下发命令 --- 提示语
                Selectanitem: '请至少选中一项',
                Installationcommand: '是否下发安装应用命令？',
                Successfulinstallation: '下发安装应用命令成功',
            },
            Uninstallapp: { // 卸载应用 --- 弹框
                Uninstallapplication: '卸载应用',
                Unload: '卸载',
                // 下发命令 --- 提示语
                Unloadcommand: '是否下发卸载应用命令？',
                Uninstallsuccessfully: '下发卸载应用命令成功'
            },
            Safetytun: { // 安全隧道 --- 弹框
                Safetytunnel: '安全隧道',
            },
            Multipledelete: { // 删除
                Confirmornot: '此操作将删除设备上的安全桌面或使设备退出登录，确定删除选中的设备？',
                Singledelete: '此操作将删除设备上的安全桌面或使设备退出登录，确定删除选中设备？',
                // 批量解绑
                Batchuntying: '确定解绑选中的设备？',
                Unbindsuccessfully: '设备解绑成功',
                // 停用
                Disableddevice: '确定停用选中的设备？',
                Successfuldeactivation: '设备停用成功',
                // 启用
                Enabledevice: '确定启用选中的设备？',
                Enabledsuccessfully: '设备启用成功',
                // 丢失
                Inconformity: '没有符合此项操作的设备',
                Lossdetermination: '确定标记丢失？',
                Lostsuccess: '标记丢失成功',
                // 合规
                Compliancedetermination: '确定设置该设备为合规？',
                Compliancesuccess: '设置合规状态成功',
                // 标记找回
                Retrieveconfirm: '确定标记找回？',
                Findsuccess: '标记找回成功',
                // 标记报废
                Scrapdetermination: '确定标记报废？',
                Successfulscrapping: '标记报废成功',
                // 锁定设备
                Lockdetermination: '此操作将使设备锁屏，确定锁定设备？',
                Locksuccess: '锁定设备命令下发成功',
                // 解锁设备
                Unlockdetermine: '此操作将使设备解锁，确定解锁设备？',
                Unlockingsucceeded: '解锁设备命令下发成功',
                // 设备解绑
                UnbindOK: '确定解绑选中的设备？',
                Unbindsuccessfully: '设备解绑成功',
                // 清除数据
                ClearOK: '此操作将清除设备上受保护应用的业务数据，确定清除数据？',
                Successfullycleared: '清除数据命令下发成功',
                // 强制注销
                LogoffOk: '此操作将强制设备退出登录，确定强制注销？',
                Loggedsuccessfully: '强制注销命令下发成功',
                // 安全隧道
                TunnelOK: '确定启用安全隧道？',
                Tunnelsuccessful: '启用安全隧道成功',
                TunnelDisable: '确定禁用安全隧道？',
                TunDisableOK: '禁用安全隧道成功',
                // 恢复出厂
                ExfactoryOK: '此操作将把设备恢复至出厂设置状态，确定恢复出厂？',
                FactorySuccess: '恢复出厂命令下发成功',
                // 提取日志
                LogOK: '确定提取日志？',
                Logsuccessful: '提取日志命令下发成功',
            },
            //新增页面
            ManualApproval: '手动审批：', //手动审批
            ManualApprovalContent: '勾选后设备注册需要通过申请审批', //勾选后设备注册需要通过申请审批
            // 详情页面 --- tab名称
            Basicinformation: '基本信息',
            HardwareInformation: '硬件信息',
            ApplicationInformation: '应用信息',
            ApplicationThreats: '应用威胁',
            Policyinformation: '策略信息',
            Riskinformation: '风险信息',
            Auditinformation: '审计信息',
            Devicetrajectory: '设备轨迹',
            Devicecommands: '设备命令',
            BasicNews: { // 基本信息
                Devicejailbreak: '设备越狱',
                PrisonBreak: '已越狱（Root）',
                Bluetooth: '蓝牙',
                Close: "关闭",
                Open: "开启",
                WLANnetwork: "WLAN网络",
                Mobilenetwork: "移动网络",
                PersonalHotspot: "个人热点",
                Systempositioning: "系统定位",
                USBdebugging: "USB调试",
                Simulatedposition: "允许模拟位置",
                Simulator: "模拟器",
                Equipmentforgery: "设备伪造",
                Forge: "伪造",
                Systemtime: "系统时间",
                Abnormal: "异常",
                Lockscreen: "锁屏密码",
                CustomROM: "定制ROM",
                CloudPhone: "云手机",
                Devicestorage: "设备存储空间",
                AvailableStorage: "设备可用存储空间",
                Clientversion: "客户端版本",
                DeviceManager: "设备管理器",
                Active: "已激活",
                Notactive: "未激活",
                Outofcontrol: "设备脱离管控",
                Manufacturer: "设备厂商",
                EquipmentModel: "设备型号",
                Serialnumber: "序列号",
                Operatingsystem: "操作系统",
                Systemname: "系统名称",
                Batterylevel: "电池电量",
                Memoryusage: "内存使用",
                Netplay: "上网方式",
                Phonenumber: "手机号码",
                Storageusage: "存储使用",
                SDKversion: "SDK版本",
                Timezone: "时区",
                Storagespace: "存储空间",
                Availablestoragespace: "可用存储空间",
                Totalmemory: "全部内存",
            },
            Hardware: { // 硬件信息
                Resolution: '屏幕分辨率',
                SIMcountrycode: 'SIM国家代码',
                Currentoperator: '当前运营商网络',
                Networkoperator: '当前网络运营商',
                OperatorCode: '网络运营商代码',
                SettingsVersion: '运营商设置版本',
                Countrycode: '网络运营商国家代码',
                Gsm: '手机制式',
                Network: '网络类型',
                Deviceparameter: '设备参数',
                Phonefirm: '手机厂商',
                HardwareName: '硬件名称',
                Softwareversion: '软件版本号',
                MobileUserID: '手机用户ID',
                Hardwaremanufacturer: '硬件制造商',
                SystemCustomizer: '系统定制商',
                Hardwareserial: '硬件串行号',
                Voicemessage: '语音信息号码',
                OperatorName: '网络运营商名称',
                Suppliercountry: '网络供应商国别',
                Basestation: '基站位置',
                Basestationnumber: '基站编号',
                Equipmentsernumber: '设备序列号',
                Networkoperator: '网络运行商',
                Devicefirmware: '设备固件版本',
                Physicaladdress: '蓝牙物理地址',
                Registeredoperator: '注册的运营商',
                Allowroaming: '是否允许漫游',
                WiFiphysicaladdress: 'WiFi物理地址',
                SIMoperatorcode: 'SIM运营商代码',
                CPUarchitecture: 'CPU架构',
                Motherboard: '主板',
                HaveaSIMcard: '是否有SIM卡',
                Correct: '是',
                Deny: '否',
                SIMcardcountry: 'SIM卡国别',
                SIMcardstatus: 'SIM卡状态',
                NoSIMcard: '无SIM卡',
                Good: '良好',
                Notready: '未就绪',
                Error: '出错',
                Islimited: '被限制',
                SIMcardserial: 'SIM卡序列号',
                SIMcardsupplier: 'SIM卡供应商',
                BUILDtime: 'BUILD时间',
                BUILDdescription: 'BUILD描述标签',
                CPUusagerate: 'CPU使用率',
                CPUmodel: 'CPU型号：',
                CPUarchitecture2: 'CPU架构2',
                MobileDeviceID: '手机设备ID（IMEI）',
                Visibleversionstring: '用户可见的版本字符串',
                WIFIsignalstrength: 'WIFI信号强度',
                NetworkcardMAC: '网卡MAC',
                BluetoothMAC: '蓝牙MAC',
                Bypressure: '按压力度(hPa)',
                Behaviortrace: '行为轨迹',
                Acceleratiosensor: '加速度传感器(m/s²)',
                Temperaturesensor: '温度传感器(℃)',
                Gyroscope: '陀螺仪(rad/h)',
                // v6.2.0新增翻译
                WiFiIP: "WiFi IP",
                WiFiMAC: "WiFi MAC",
                Modemfirmwareversion: "调制解调器固件版本",
                EthernetIP: "以太网 IP",
                EthernetMAC: "以太网 MAC",
                EASdevice: "网卡 MAC",
                CPUinstructionset: 'CPU指令集',
            },
            Application: { // 应用信息
                SerialNumber: "序号",
                Applicationtype: "应用类型",
                Protectiveapp: "防护应用",
                Ordinaryapp: "普通应用",
                Installationmode: '安装模式',
                // v6.2.0新增翻译
                Processname: '进程名称',
                Installationpath: '安装路径',
                Installationtime: '安装时间',
                FileName: '文件名称', //文件名称
            },
            AppThreats: { // 应用威胁
                TerrorizeType: {
                    安全桌面: "安全桌面",
                },
                Threattype: '威胁类型',
                Locationfraud: '位置欺诈',
                Domainnamefraud: '域名欺诈',
                Injectionattack: '注入攻击',
                Debuggingbehavior: '调试行为',
                Memorytampering: '内存篡改',
                Programplugin: '程序外挂',
                Applicationcrack: '应用破解',
                HTTPShijacking: 'HTTPS劫持',
                Httpproxy: 'HTTP代理',
                AppMultipleOpen: '应用多开',
                Frameworkattacks: '框架攻击',
                Trebleaccount: '高频更换账号',
                TrebleIP: '高频更换IP',
                Trebleposition: '高频更换位置',
                Treblestartup: '高频启动应用',
                Riskapplication: '风险应用',
                ROOTJailbreak: 'ROOT/越狱',
                Framesoftware: '框架软件',
                Rootcertificate: '根证书异常',
                CustomROM: '定制ROM',
                Systemtimeanomaly: '系统时间异常',
                Locknotenabled: '锁屏密码未开启',
                RiskIP: '风险IP',
                ScreenshotRelease: '截屏阻断解除',
                Tall: '高',
                Centre: '中',
                Low: '低',
                Threatstatus: '威胁状态',
            },
            Policyinfo: { // 策略信息
                Policyname: '策略名称',
                Policyclassification: '策略分类',
                Compliancestrategy: '合规策略',
                Devicepolicy: '设备策略',
                Updatetime: '更新时间',
                Policystate: '策略状态',
            },
            Riskinforma: { // 风险信息
                SelectDate: '选择日期',
                Cut: '类型',
                TypesofSelect: { // 类型
                    类型: "类型",
                    违规行为: "违规行为",
                    威胁行为: "威胁行为",
                    敏感行为: "敏感行为",
                    安全事件: "安全事件",
                },
                Typesof: { // 类型
                    Violation: "违规行为",
                    Threatening: "威胁行为",
                    Sensitive: "敏感行为",
                    Securityinci: "安全事件",
                },
                Behaviortype: { // 行为类型
                    行为类型: "行为类型",
                    设备越狱: "设备越狱",
                    SIM卡变更: "SIM卡变更",
                    SD卡变更: "SD卡变更",
                    违规外联: "违规外联",
                    地理围栏: "地理围栏",
                    系统版本: "系统版本",
                    流量异常: "流量异常",
                    设备电量: "设备电量",
                    存储空间: "存储空间",
                    必装应用: "必装应用",
                    应用黑白名单: "应用黑白名单",
                    WIFI黑白名单: "WIFI黑白名单",
                    系统功能: "系统功能",
                    注入攻击: "注入攻击",
                    调试行为: "调试行为",
                    域名欺诈: "域名欺诈",
                    应用破解: "应用破解",
                    HTTPS劫持: "HTTPS劫持",
                    HTTP代理: "HTTP代理",
                    框架软件: "框架软件",
                    'ROOT/越狱': "ROOT/越狱",
                    模拟器: "模拟器",
                    系统时间异常: "系统时间异常",
                    风险IP: "风险IP",
                    框架攻击: "框架攻击",
                    位置欺诈: "位置欺诈",
                    程序外挂: "程序外挂",
                    应用多开: "应用多开",
                    高频更换账号: "高频更换账号",
                    高频更换IP: "高频更换IP",
                    高频更换位置: "高频更换位置",
                    高频启动应用: "高频启动应用",
                    风险应用: "风险应用",
                    根证书异常: "根证书异常",
                    USB调试: "USB调试",
                    允许模拟位置: "允许模拟位置",
                    定制ROM: "定制ROM",
                    设备伪造: "设备伪造",
                    锁屏密码未开启: "锁屏密码未开启",
                    内存篡改: "内存篡改",
                    云手机: "云手机",
                    截屏阻断解除: "截屏阻断解除",
                    应用敏感行为: "应用敏感行为",
                    应用越权行为: "应用越权行为",
                    Windows: "Windows",
                    VPN: "VPN",
                    macOS: "macOS",
                },
                Rank: '行为级别',
                Actlevel: { // 行为级别
                    行为级别: '行为级别',
                    高: "高",
                    中: "中",
                    低: "低",
                },
                Numberofviolations: '违规行为数',
                Threateningbehavior: '威胁行为数',
                Sensitivebehavior: '敏感行为数',
                Securityincidents: '安全事件数',
                Violationtypenum: '违规类型数',
                Threattypenum: '威胁类型数',
                Sensitivetypenum: '敏感类型数',
                SecurityTypenum: '安全类型数',
                SIMcardchange: 'SIM卡变更',
                SDcardchange: 'SD卡变更',
                IllegalOutreach: '违规外联',
                Geofence: '地理围栏',
                Trafficanomaly: '流量异常',
                Devicepower: '设备电量',
                Storage: '存储空间',
                Mandatoryapp: '必装应用',
                BlackWhiteList: '应用黑白名单',
                WIFIblackwhitelist: 'WIFI黑白名单',
                Systemfunction: '系统功能',
                Applicasensitivebehavior: '应用敏感行为',
                Applicationofultravires: '应用越权行为',
            },
            Auditing: { // 审计信息
                Telephone: '电话',
                Textmessage: '短信',
                Chatmessage: '聊天信息',
                Networkaccess: '网络访问',
                Inputinformation: '输入信息',
                SelectDate: '选择日期',
                Pleaseselect: '请选择',
                Calltypes: { // 通话类型
                    电话: '电话',
                    短信: '短信',
                    聊天信息: '聊天信息',
                    网络访问: '网络访问',
                    输入信息: '输入信息',
                },
                Inputstate: { // 输入状态
                    状态: '状态',
                    允许: '允许',
                    禁止: '禁止',
                },
                Enterappname: { // 输入应用名称
                    应用名称: '应用名称',
                    设备名称: '设备名称',
                    用户名: '用户名',
                    敏感词: '敏感词',
                    词库名称: '词库名称',
                },
                Chatmessage: { // 聊天信息下拉控件
                    应用名称: "应用名称",
                    本人: "本人",
                    对话人: "对话人",
                    敏感词: "敏感词",
                    词库名称: "词库名称",
                },
                Contacts: '联系人',
                Callnumber: '通话号码',
                Area: '地区',
                Callduration: '通话时长',
                Calltype: '通话类型',
                Calling: '主叫',
                Called: '被叫',
                Talktime: '被叫',
                SMSnumber: '短信号码',
                SMScontent: '短信内容',
                SmsType: '短信类型',
                Sending: '发送',
                Receive: '接收',
                SMStime: '短信时间',
                Self: '本人',
                Interlocutor: '对话人',
                Content: '内容',
                Category: '类别',
                Text: '文本',
                Callingcard: '名片',
                Photograph: '照片',
                Document: '文件',
                Voice: '语音',
                Quote: '引用',
                Transfermoney: '转账',
                Redpacket: '红包',
                Expressionpackage: '表情包',
                Sendingposition: '发送位置',
                Audiovideochat: '音视频聊天',
                Sensitivewords: '敏感词',
                Lexicalname: '词库名称',
                Status: '状态',
                Type: '类型',
                Permit: '允许',
                Prohibit: '禁止',
                Accept: '接受',
                Send: '发送',
                Time: '时间',
                Website: '网址',
                Title: '标题',
                Accessstatus: '访问状态',
            },
            Trajectory: {
                GetLocation: '获取定位',
                Starttimenoendtime: '开始时间不能大于结束时间',
                Obtainconfirmation: '此操作将获取设备实时定位，确定要获取定位？',
                OPERATIONLOCK: '（此操作将把设备锁定）',
                Operationunlocking: '（此操作将把锁定设备进行解锁）',
                PasswordLock: '密码锁定',
                PassLockreset: '（此操作将把设备锁定，同时重置PIN码）',
                PlasPasslock: '请输入锁定密码',
                Clearpassword: '清除密码',
                AppClearpass: '（此操作将把设备上的锁定密码清空）',
                Clearbusiness: '（此操作将清除设备上受保护应用的业务数据）',
                Compulsorywithdrawal: '（此操作将强制设备退出登录）',
                Uninstallationcode: '卸载码',
                Generatecode: '（此操作将生成卸载码，仅支持合作厂商）',
                Generateuninstallationcode: '生成卸载码',
                Deviceloss: '设备丢失',
                MarkasMissing: '（此操作将把设备标记为丢失，并无法使用客户端。监督模式下为系统的丢失模式）',
                RestoreFactory: '（此操作将把设备恢复到出厂设置状态）',
                Operationlog: '（此操作将提取应用运行日志）',
                Silentinstall: '（此操作将静默安装指定的应用，仅支持合作厂商）',
                Pushinstall: '（此操作将向设备推送安装指定应用的命令）',
                Silentuninstall: '（此操作将静默卸载指定的应用，仅支持合作厂商）',
                PushUninstall: '（此操作将静默卸载指定的应用）',
                Releaseloss: '解除丢失',
                Losspatterns: '（此操作将把设备解除丢失模式，仅支持监督模式）',
                Deviceshutdown: '设备关机',
                Apphutdown: '（此操作将使设备关机，仅支持合作厂商）',
                Devicerestart: '设备重启',
                Apprestart: '（此操作将重启设备，仅支持合作厂商）',
                ApprestartClient: '（此操作将把设备标记为丢失，并无法使用客户端）',
                LockUnlock: '此操作将使锁定设备进行解锁，确定解锁设备？',
                Passwordconfirmation: '确定密码锁定？',
                Passwordsuccessful: '密码锁定命令下发成功',
                Clearthepassword: '确定清除密码？',
                Successfullyissued: '清除密码命令下发成功',
                Setlossmode: '此操作将设备设置为丢失模式，确定要设备丢失？',
                Issuedcommand: '设备丢失命令下发成功',
                Releasetheloss: '此操作将设备解除丢失模式，确定要解除丢失？',
                Toremovetheloss: '解除丢失命令下发成功',
                Deviceshutdowns: '确定设备关机？',
                Thedeviceshutdown: '设备关机命令下发成功',
                Restartthedevice: '确定设备重启？',
                Restartcommand: '设备重启命令下发成功',
            },
        },
        // 设备组管理
        DeviceGroup: {
            devGroupPlaceholder: '请输入设备组名称',//请输入设备组名称
            DeviceGroupName: '设备组名称',//设备组名称
            AssociatedDevices: '关联设备', //关联设备
            Device: "设备",//添加设备
            Message: '最大输入20个字符',
            Error: '保存失败，设备组名称已存在',
            Error1: '设备组名称不能为空',
        },
        //设备策略
        DevicePolicy: {
            Tabs: {
                BasicInformation: '基本信息',
                Limit: '功能限制',
                password: '密码设置',
                wifSetting: 'WiFi设置',
                position: '设备轨迹',
                bw: '应用黑白名单',
                wifiBw: 'WiFi黑白名单',
                networkAccessBW: '网络访问黑白名单',
                blueToothBW: '蓝牙黑白名单 *',
                networkAppBW: '网络应用白名单 *',
                recentsBW: '通话黑白名单 *',
                ApplyWhite: '应用白名单*',
                SingleApplication: '单应用模式*',
                smsBW: '短信黑白名单 *',
                appInstallSourceBW: '应用安装源白名单 *',
            },
            SpanText: '* 代表仅合作厂商生效',
            placeholder: '请输入名称',
            AndroidBtn: '新增Android策略',
            iOSBtn: '新增iOS策略',
            Name: '名称',
            ExecutionAllocation: '执行/分配',
            placeholderDeviceName: '请输入设备名称',
            allocation: '策略分配执行情况',
            CheckDel: '确定删除选中策略？',
            CheckDel1: '确定删除选中的策略？',
            MessageError1: '请输入策略名称',
            MessageError2: '策略名称不能出现特殊字符',
            MessageError3: '请选择时间围栏',
            MessageError4: '请选择地理围栏',
            MessageError5: '请至少选择一项适用策略',
            MessageError6: '请至少选择一项限制内容',
            MessageError7: '请输入数字密码',
            MessageError8: '数字密码不能出现特殊字符',
            MessageError9: '数字密码长度为4',
            MessageError10: '数字密码必须是数字且为正整数',
            MessageError11: '请输入WiFi-SSID',
            MessageError12: '请输入MAC地址',
            MessageError13: '请填写正确的MAC地址',
            MessageError14: '请输入定位时间间隔',
            MessageError15: '定位时间间隔必须是数字且为正整数',
            MessageError16: '请输入打包上传定位个数',
            MessageError17: '打包上传定位个数必须是数字且为正整数',
            MessageError18: '应用列表名单为空',
            MessageError19: '请输入WiFi列表',
            MessageError20: 'WiFi列表存在重复内容',
            MessageError21: '请输入网址列表',
            MessageError22: '网络访问黑白名单存在重复内容',
            MessageError23: '请填写正确的域名地址',
            MessageError24: '请填写正确的IPv4地址',
            MessageError25: '请输入蓝牙列表',
            MessageError26: 'WiFi列表：存在重复内容',
            MessageError27: '请输入电话列表',
            MessageError28: '请填写正确的手机（电话）号码',
            MessageError29: '保存失败，名称已被使用',
            MessageError30: '仅支持1-90的正整数',
            MessageError31: '请输入最长密码有效期',
            MessageError32: '最长密码有效期必须是数字且为正整数',
            MessageError33: '请输入最近独特密码所需数量',
            MessageError34: '最近独特密码所需数量最小值是1，最大值是50',
            MessageError35: '请输入服务器地址',
            MessageError36: '请输入端口',
            MessageError37: '请输入ProxyPACURL',
            MessageError38: '策略对象至少选择一项',
            //新增安卓策略
            AndroidPolicy: {
                //基本信息
                BasicInformation: {
                    PolicyName: '策略名称',
                    placeholder: '请输入策略名称',
                    MessagePort: '最长输入30个字符，不支持空格及特殊字符',
                    Restrictions: '限制条件',
                    TimeFence: '时间围栏',
                    GeoFence: '地理围栏',
                    Name: '名称',
                    Applicables: '适用策略',
                    limit: '功能限制',
                    bw: '应用黑白名单',
                    wifibw: 'WiFi黑白名单',
                    networkAccessBW: '网络访问黑白名单',
                    blueToothBW: '蓝牙黑白名单 *',
                    networkAppBW: '网络应用白名单 *',
                    recentsBW: '通话黑白名单 *',
                    //时间围栏弹框
                    TimeFenceAlert: {
                        Title: '选择时间围栏',
                        TimeZone: '时区',
                        TimeFrame: '时间范围',
                        CycleTime: '时间周期',
                        RegularTime: '定时',
                        Custom: '自定义',
                        PermanentlyValid: '永久有效',
                        weeks: {
                            sun: '星期日',
                            mon: '星期一',
                            tue: '星期二',
                            wed: '星期三',
                            thu: '星期四',
                            fri: '星期五',
                            sat: '星期六'
                        },
                    },
                    //地理围栏弹框
                    GeoFenceAlert: {
                        Title: '选择地理围栏',
                        Longitude: '经纬度',
                        Graphical: '图形',
                        Circular: '圆形',
                        Rectangle: '矩形',
                        Range: '范围',
                        Rice: '米',
                        RangeOutside: '范围内外',
                        Internal: '内部',
                        External: '外部'
                    }
                },
                //功能限制
                limit: {
                    DataProtection: '数据保护',
                    SystemSettings: '系统设置',
                    NetworkSettings: '网络设置',
                    Communication: '通讯设置',
                    allowBluetooth: '禁用蓝牙',
                    allowCamera: '禁用摄像头',
                    allowScreenshots: '禁止截屏 *',
                    allowRecord: '禁止录音 *',
                    allowSharefile: '禁止文件分享 *',
                    allowCopyPaste: '',
                    allowADBPort: '禁用ADB端口 *',
                    allowUSB: '禁止USB传输数据 *',
                    allowPreBrowser: '禁止系统预装浏览器 *',
                    allowSDWrite: '禁止外置SD卡写入 *',
                    // allowLocation: '禁用定位服务 *',
                    allowLocation: '定位服务 *',
                    forceOn: '强制开启',//强制开启
                    allowNFC: '禁用NFC功能 *',
                    allowInfraRed: '禁用红外功能 *',
                    allowAddAccount: '禁止添加账户 *',
                    allowRestoreSettings: '禁止恢复出厂设置 *',
                    allowDeveloper: '禁止开启开发者选项 *',
                    allowUpdateDate: '禁止更改系统日期和时间设置 *',
                    allowAirplaneMode: '禁止飞行模式 *',
                    allowDropDownMenu: '禁止状态栏下拉菜单 *',
                    allowSafeMode: '禁用安全模式 *',
                    allowTurnOff: '禁止关机 *',
                    allowWifi: '禁用WIFI网络',
                    allowHotspot: '禁用个人热点',
                    allowWIFIDirect: '禁用WIFI直连 *',
                    allowDatalink: '禁用移动网络 *',
                    allowAPN: '禁用APN设置 *',
                    allowVPN: '禁用VPN功能 *',
                    allowPhone: '禁用通话功能 *',
                    allowMakeCall: '禁止拨打电话 *',
                    allowInboundCall: '禁止呼入电话 *',
                    allowNote: '禁用短信功能 *',
                    allowSendSMS: '禁用发送短信 *',
                    allowAccSMS: '禁用接收短信 *',
                    allowSecondSIM: '禁用第二张SIM卡 *',
                    allowWifiSet: '禁用设置WIFI网络参数 *',
                    allowSysUpdate: '禁止系统升级 *',
                    allowBackup: '禁用设备备份 *',
                    allowOtgStorage: '禁用OTG存储 *',
                    allowMtp: '禁用MTP数据传输 *',
                    allowed: '禁用',
                    allowDatalinks: '移动网络 *',
                    allowBluetoothShareNet: '禁止蓝牙共享网络 *',
                    allowUsbShareNet: '禁止USB共享网络 *',
                    allDelete: '全部删除',
                    application: '例外应用',
                    selectApp: '选择应用',
                },
                //密码设置
                PasswordSettings: {
                    PasswordType: '密码类型',
                    SpanText: '仅对Android6及以下设备有效',
                    PasswordComplexity: '密码复杂度',
                    PasswordLength: '密码最小长度',
                    PINCodeLength: 'PIN码长度',
                    DigitalPassword: '数字密码',
                    placeholder: '长度为4的数字密码',
                    passCodeType: {
                        复杂密码: '复杂密码',
                        简单密码: '简单密码'
                    },
                    passwordComplexity: {
                        '字母+数字': '字母+数字',
                        '字母+特殊符号': '字母+特殊符号',
                        '字母+数字+特殊符号': '字母+数字+特殊符号'
                    }
                },
                //WiFi设置
                WiFiSetup: {
                    lineType: '连接类型',
                    WiFiName: 'WiFi-SSID',
                    SpanText: '最长输入30个字符',
                    MACPosition: 'MAC地址',
                    SpanTextMac: 'MAC地址格式：XX-XX-XX-XX-XX-XX',
                    HideNetwork: '隐藏网络',
                    NetworkText: '网络未开放或未广播信号时启动',
                    Automatic: '自动连接',
                    AutomaticText: '自动连接WiFi',
                    SecurityType: '安全类型',
                    encryptionType: {
                        '无': '无',
                        'WPA / WPA2': 'WPA / WPA2',
                        'WEP': 'WEP'
                    },
                    SecurityText: '连接时使用的无线网络加密类型',
                    Password: '密码',
                    PasswordText: '用于鉴定无线网络的密码'
                },
                //设备轨迹
                Position: {
                    Frequency: '定位时间间隔',
                    FrequencyText: '分钟，最长输入4个字符，且为正整数',
                    Number: '打包上传定位个数',
                    NumberText: '最长输入4个字符，且为正整数',
                },
                //应用黑白名单
                ApplybwList: {
                    ListType: '名单类型',
                    bwType: {
                        '黑名单': '黑名单',
                        '白名单': '白名单'
                    },
                    AllApply: '所有应用',
                    NoReinforcedApply: '非加固应用',
                    ReinforcedApply: '加固应用',
                    PolicyObject: '策略对象',
                    ApplicationList: '应用列表',
                    Apply: '应用',
                    SelectApply: '选择应用',
                    placeholder: '请输入应用名称',
                },
                //WiFi黑白名单
                WiFibwList: {
                    ListType: '名单类型',
                    lineType: '连接类型',
                    WiFiList: 'WiFi列表',
                    WiFiListText: '多个请以回车换行分隔',
                    macStrTetx: 'BSSID格式为XX-XX-XX-XX-XX-XX',
                    macStrText1: '多个请以回车换行分隔',
                    WiFibwType: {
                        '黑名单': '黑名单',
                        '白名单': '白名单'
                    },
                },
                //网络访问黑白名单
                NetworkAccessBW: {
                    ListType: '名单类型',
                    WebsiteList: '网址列表',
                    networkStrText: '黑名单网址为域名格式',
                    networkStrText1: '白名单网址为IPv4格式',
                    networkStrText2: '多个请以回车换行分隔',
                    NetworkType: {
                        '黑名单': '黑名单',
                        '白名单': '白名单'
                    },
                },
                //网络应用白名单
                networkAppBW: {
                    ListType: '名单类型',
                    ApplicationList: '应用列表',
                    bwType: {
                        '白名单': '白名单'
                    },
                    Apply: '应用',
                    placeholder: '请输入应用名称',
                },
                //应用安装源白名单
                appInstallSourceBW: {
                    ListType: '名单类型',
                    ApplicationList: '应用列表',
                    bwType: {
                        '白名单': '白名单'
                    },
                    Apply: '应用',
                    placeholder: '请输入应用名称',
                },
                //蓝牙黑白名单
                blueToothBW: {
                    ListType: '名单类型',
                    BluetoothList: '蓝牙列表',
                    blueToothType: {
                        '黑名单': '黑名单',
                        '白名单': '白名单'
                    },
                    macStrTetx: 'MAC地址格式为XX:XX:XX:XX:XX:XX',
                    macStrText1: '多个请以回车换行分隔'
                },
                //通话黑白名单
                recentsBW: {
                    ListType: '名单类型',
                    RecentsList: '电话列表',
                    RecentsType: {
                        '黑名单': '黑名单',
                        '白名单': '白名单'
                    },
                    macStrTetx: '电话为3～12位全数字格式',
                    macStrText1: '多个请以回车换行分隔'
                }
            },
            //新增IOS策略
            IOSPolicy: {
                //基本信息
                BasicInformation: {
                    PolicyName: '策略名称',
                    placeholder: '请输入策略名称',
                    MessagePort: '最长输入30个字符，不支持空格及特殊字符',
                },
                //功能限制
                limit: {
                    Day: '天',
                    DataProtection: '数据保护',
                    SystemSettings: '系统设置',
                    NetworkSettings: '网络设置',
                    allowCamera: '禁用摄像头',
                    allowScreenSharp: '禁止屏幕捕捉 *',
                    allowPasscodeModification: '禁止设备修改密码 *',
                    allowBluetoothModification: '禁止修改蓝牙设置 *',
                    allowCloudBackup: '不允许iCloud备份 *',
                    allowFilesUSBDriveAccess: '“文件”App无法访问USB驱动器 *',
                    allowSafari: '不允许Safari *',
                    safariAllowAutoFill: '禁用Safari自动填充 *',
                    allowPasswordAutoFill: '禁用自动填充密码 *',
                    allowHostPairing: '禁止设备与未安装Configurator的主机配对 *',
                    allowApplication: '禁用App Store *',
                    allowAppRemoval: '禁止卸载应用程序 *',
                    allowVideoConferencing: '不允许FaceTime *',
                    allowEraseContentAndSettings: '禁用抹掉所有内容与设置 *',
                    allowAccountModification: '禁止账户修改 *',
                    allowDeviceNameModification: '禁止设备名修改 *',
                    allowKeyboardShortcuts: '禁用键盘快捷键 *',
                    allowNotificationsModification: '禁止修改通知设置 *',
                    allowDictation: '不允许听写输入 *',
                    allowFingerprintModification: '阻止用户修改 Touch ID 或 Face ID *',
                    allowNFC: '禁用NFC *',
                    forceDelayedSoftwareUpdates: '延迟系统升级 *',
                    allowUIConfigurationProfileInstallation: '禁止手动安装配置文件 *',
                    allowAssistant: '禁用Siri *',
                    allowAutomaticAppDownloads: '禁止自动下载应用程序 *',
                    allowAirDrop: '禁用AirDrop *',
                    allowAppCellularDataModification: '禁止修改应用蜂窝数据使用量 *',
                    allowPersonalHotspotModification: '禁用修改个人热点 *',
                    allowVPNCreation: '不允许创建VPN *',
                    forceWiFiPowerOn: '禁止修改WiFi设置 *',
                },
                //密码设置
                PasswordSettings: {
                    SimplePassword: '允许简单密码',
                    PasswordComplexity: '允许使用重复字符串序列、升序字符序列和降序字符序列',
                    PasswordLength: '要求字母数值',
                    RequireAlphaNumber: '要求密码包含至少一个字母',
                    DigitalPassword: '要求密码',
                    MinLengthPassword: '密码最小长度',
                    SpanTextMinLength: '允许的密码字符的最少数目',
                    MinComplexChars: '最少复杂字符数',
                    SpanText: '允许的非字母和数字字符的最少数目',
                    MaxPinAgeinDays: '最长密码有效期',
                    SpanTextMax: '密码有效期（单位：天）',
                    PinHistory: '最近独特密码所需数量',
                    SpanTextStory: '重新使用前唯一密码的数量（1-50）',
                    MaxFailedTimes: '最多失败次数',
                    SpanTextTimes: '在抹掉设备上的所有数据之前所允许的密码输入失败次数',
                },
                //WiFi设置
                WiFiSetup: {
                    lineType: '连接类型',
                    WiFiName: 'WiFi-SSID',
                    SpanText: '最长输入30个字符',
                    MACPosition: 'MAC地址',
                    SpanTextMac: 'MAC地址格式：XX-XX-XX-XX-XX-XX',
                    HideNetwork: '隐藏网络',
                    NetworkText: '网络未开放或未广播信号时启动',
                    Automatic: '自动连接',
                    AutomaticText: '自动连接WiFi',
                    SecurityType: '安全类型',
                    encryptionType: {
                        '无': '无',
                        'WPA / WPA2': 'WPA / WPA2',
                        'WEP': 'WEP'
                    },
                    SecurityText: '连接时使用的无线网络加密类型',
                    Password: '密码',
                    PasswordText: '用于鉴定无线网络的密码',
                    Agent: '代理',
                    proxyType: {
                        '无': '无',
                        '手动': '手动',
                        '自动': '自动'
                    },
                    proxySpanText: '用于此wifi连接的代理设置',
                    ServerAddress: '服务器地址',
                    SpanTextAddress: '代理服务器的完全有效地址',
                    Port: '端口',
                    SpanTextPort: '端口为0~65535',
                    ProxyUsername: '代理用户名',
                    SpanTextName: '用于连接到代理的用户名称',
                    proxyPassword: '代理用户密码',
                    SpanTextPassword: '连接代理时使用的密码'
                },
                //设备轨迹
                Position: {
                    Frequency: '定位时间间隔',
                    FrequencyText: '分钟，最长输入4个字符，且为正整数',
                    Number: '打包上传定位个数',
                    NumberText: '最长输入4个字符，且为正整数',
                },
                //应用白名单
                networkAppBW: {
                    ListType: '名单类型',
                    ApplicationList: '应用列表',
                    bwType: {
                        '白名单': '白名单'
                    },

                    Apply: '应用',
                    placeholder: '请输入应用名称',
                },
                //单应用模式
                SingleApplication: {
                    ApplicationList: '应用列表',
                    Apply: '应用',
                    SelectApply: '选择应用',
                    placeholder: '请输入应用名称',
                },
            }
        },
        // 合规策略
        ClientPolicy: {
            Tabs: {
                BasicInformation: '基本信息',
                Equipment: '设备越狱',
                Geographical: '地理围栏',
                SystemVersion: '系统版本',
                DeviceBattery: '设备电量',
                StorageSpace: '存储空间',
                SystemFunction: '系统功能',
                SimChange: 'SIM卡变更',
                SdChange: 'SD卡变更',
                ViolationOutreach: '违规外联',
                TrafficAnomaly: '流量异常',
                ApplicationInstall: '必装应用',
                BlackWhiteList: '应用黑白名单',
                WifiBlackWhiteList: 'WiFi黑白名单',
            },
            TriggeringCondition: '触发条件：',
            SerialNumber: '序号',
            Name: '名称',
            ResponseAction: '响应动作：',
            LayTime: '延迟时间：',
            LayTime1: '延迟时间',
            ResponseAction1: '响应动作',
            options: {
                告警提示: '告警提示',
                清除数据: '清除数据',
                强制注销: '强制注销',
                停用设备: '停用设备',
                恢复出厂: '恢复出厂',
            },
            Selectoptions: {
                告警提示: '告警提示',
                清除数据: '清除数据',
                强制注销: '强制注销',
                停用设备: '停用设备',
                恢复出厂: '恢复出厂',
                禁止访问: '禁止访问'
            },
            time: {
                分钟: '分钟',
                小时: '小时',
                日: '日'
            },
            ZeroMin: '0分钟',
            Min: '分钟',
            Hour: '小时',
            Day: '日',
            ResponseActionName0: '告警日志(标记为违规设备)',
            ResponseActionName1: '告警提示',
            ResponseActionName2: '清除数据',
            ResponseActionName3: '强制注销',
            ResponseActionName4: '停用设备',
            ResponseActionName5: '恢复出厂',
            ResponseActionName6: '禁止访问',
            MessageError1: '添加响应动作',
            MessageError2: '外联服务器不能为空',
            MessageError3: '请选择地理围栏',
            MessageError4: '请选择版本区间',
            MessageError5: '请选择最小版本',
            MessageError6: '请选择最大版本',
            MessageError7: '请输入每日流量',
            MessageError8: '请输入电量百分比',
            MessageError9: '请输入可用空间',
            MessageError10: '请选择连接类型',
            MessageError11: '应用列表名单为空',
            MessageError12: '请输入WiFi列表',
            MessageError13: '同一种动作只能存在一条',
            MessageError14: '请选择',
            MessageError15: '名称不支持特殊字符',
            MessageError16: '请输入延迟时间',
            MessageError17: '延迟时间必须是数字且为正整数',
            MessageError18: '外联服务器必须以http或https开头',
            MessageError19: '电量必须是数字且为正整数',
            MessageError20: '可用空间必须是数字且为正整数',
            MessageError21: 'WiFi列表存在重复内容',
            MessageError22: '请填写正确的MAC地址',
            MessageError23: '策略对象至少选择一项',
            // 设备越狱
            Equipment: {
                Message1: '当检测到设备越狱时，触发以下响应动作',
            },
            // 地理围栏
            Geographical: {
                Message1: '当检测到设备触发了地理围栏时，触发以下响应动作',
                Message2: '选择地理围栏',
                Shape: '图形',
                Circle: '圆形',
                Rectangle: '矩形',
                Meter: '米',
                Radius: '范围',
                Scope: '范围内外',
                Enter: '内部',
                Out: '外部',
            },
            // 系统版本
            SystemVersion: {
                VersionScope: '版本范围：',
                VersionRange: '版本区间：',
                between: '介于',
                minVersion: '高于',
                maxVersion: '低于',
                Message1: '当检测到设备版本落在设置区间时，触发以下响应动作',
            },
            // 设备电量
            DeviceBattery: {
                BatteryPercentage: '电量百分比：',
                Placeholder: '请输入电量百分比',
                Message1: '当检测到设备电量低于设置的值时，触发以下响应动作',
                MessageError1: '请输入电量百分比',
                MessageError2: '只可以输入一到两位数字',
            },
            // 储存空间
            StorageSpace: {
                AvailableSpace: '可用空间：',
                Placeholder: '请输入可用空间',
                Message1: '当检测到设备可用存储空间低于设置的值时，触发以下响应动作',
                MessageError1: '可用空间必须是数字且为正整数',
            },
            // 系统功能
            SystemFunction: {
                ConnectionType: '连接类型：',
                bluetooth: '蓝牙',
                wifi: 'WIFI网络',
                dataLink: '移动网络',
                hotspot: '个人热点',
                location: '系统定位',
                useWifi: '使用Wi-Fi',
                hotspotConnection: '个人热点被连接',
                Message1: '当检测到设备开启了系统功能中的开关时，触发以下响应动作',
            },
            // sim卡变更
            SimChange: {
                Message1: '当检测到设备上SIM卡发生变化时，触发以下响应动作',
            },
            // sD卡变更
            SdChange: {
                Message1: '当检测到设备上SD卡发生变化时，触发以下响应动作',
            },
            //违规外联
            ViolationOutreach: {
                Name: '外联服务器：',
                Message1: '以https或http开头',
                Message2: '当检测到设备可以连接到外联服务器时，触发以下响应动作',
                MessageError1: '外联服务器不能为空',
                MessageError2: '外联服务器必须以http或https开头',
            },
            //流量异常
            TrafficAnomaly: {
                Name: '每日流量：',
                Placeholder: '请输入每日流量',
                Message1: '当检测到设备每日流量超过设置的值时，触发以下响应动作',
                MessageError1: '流量异常必须是数字且为正整数',
            },
            //必装应用
            ApplicationInstall: {
                Message1: '当检测到设备上必装应用未安装时，触发以下响应动作',
            },
            //应用黑白名单
            BlackWhiteList: {
                ListType: '名单类型：',
                BlackList: '黑名单',
                WhiteList: '白名单',
                Target: '策略对象：',
                AllApps: '所有应用',
                NonReinforcedApps: '非加固应用',
                ReinforcedApps: '加固应用',
                AppList: '应用列表：',
                Application: '应用',
                Message1: '当检测到设备安装了黑名单中的应用时，触发以下响应动作',
                Message2: '当检测到设备安装了白名单以外的应用时，触发以下相应动作',
                Placeholder: '请输入应用名称',
                SelectApp: '选择应用',
            },
            //WIFI黑白名单
            WifiBlackWhiteList: {
                ListType: '名单类型：',
                BlackList: '黑名单',
                WhiteList: '白名单',
                ConnType: '连接类型：',
                WifiList: 'WiFi列表：',
                Message1: '多个请以回车换行分隔',
                Message2: 'BSSID格式为XX-XX-XX-XX-XX-XX',
                Message3: '当检测到设备连接了黑名单中的WIFI时，触发以下响应动作',
                Message4: '当检测到设备连接了白名单以外的WIFI时，触发以下响应动作',
                Placeholder: '请输入WiFi列表',
                MACPlaceholder: '请填写正确的MAC地址',
            },
        },
        // 安全事件
        Securityinc: {
            Longtermoffline: "长期离线",
            Offlineduration: "离线时长",
            eventLevel: "事件级别",
            Day: "天",
            ResponseAction: "响应动作",
            Missingtags: "标记丢失",
            Cleardata: "清除数据",
            high: "高",
            centre: "中",
            low: "低",
            Forcederegis: "强制注销",
            Deactivatedevice: "停用设备",
            RestoreFactory: "恢复出厂",
            Atleastoneitem: "响应动作至少选择一项",
            Offlinedura: "离线时长为1～9999的整数",
        },
        //时间围栏
        TimeFence: {
            Name: '名称',
            TimeZone: '时区',
            TimeFrame: '时间范围',
            CycleTime: '时间周期',
            RegularTime: '定时',
            Custom: '自定义',
            PermanentlyValid: '永久有效',
            placeholder: '最长输入20个字符',
            Hour: '小时',
            weeks: {
                sun: '星期日',
                mon: '星期一',
                tue: '星期二',
                wed: '星期三',
                thu: '星期四',
                fri: '星期五',
                sat: '星期六'
            },
            Rules: {
                name: '请输入名称', //请选择文件
                NameLength: '最长输入20个字符', //最长输入30个字符
            },
            Error1: '删除失败，时间围栏正在被设备策略使用',
            Error2: '确定删除选中时间围栏',
            CheckDel1: '开始时间大于结束时间',
            CheckDel2: '开始时间不能大于等于结束时间',
            CheckDel3: '开始时间和结束时间不能为空',
            CheckDel4: '保存失败，时间围栏名称重复',
        },
        // 地理围栏
        Geofence: {
            Name: '名称',
            Latitudelongitude: '经纬度',
            Graphical: '图形',
            Circular: '圆形',
            Rectangle: '矩形',
            Radius: '范围',
            Meter: '米',
            Insideandout: '范围内外',
            Interior: '内部',
            Without: '外部',
            FenceOK: '确定删除选中的地理围栏？',
            DeleteLose: '删除失败，地理围栏正在被设备/合规策略使用',
            Longitude: '经度',
            Character: '最长输入20个字符',
            Latitude: '纬度',
            Selectgraphic: '请选择图形',
            Graph: { // 图形
                圆形: "圆形",
                矩形: "矩形",
            },
            Radius: '半径',
            Inputcharacte: '最长输入7个字符',
            Length: '长',
            Width: '宽',
            Insideout: '请选择范围内外',
            RadiusInsideoutside: { // 范围内外
                内部: "内部",
                外部: "外部",
            },
            Maptype: '地图类型',
            Atlas: {
                普通: "普通",
                卫星: "卫星",
            },
            SelectMaptype: '请选择地图类型',
            Fencedarea: '围栏区域',
            Enterkeyword: '请输入关键字：',
            Notfound: '抱歉，未搜索到有效的结果。',
            NameAlreadyhave: '保存失败，名称已被使用',
        },
        //终端版本
        TerminalVersionPage: {
            placeholder: '请输入版本', //placeholder
            versionVoid: '例：xx.xx.xx', //'示例：xx.xx.xx'
            versionReg: '请输入正确的版本 示例：xx.xx.xx', //'请输入正确的版本 示例：xx.xx.xx'
            SaveFailedVerNumRepeat: '保存失败，版本号不能重复', //保存失败，版本号不能重复
            sureDeleteSeleVersion: '确定删除选中的版本?', //确定删除选中的版本
            SaveFailed: '删除失败', //删除失败
        },
        // 审计信息
        Auditing: {
            Telephone: '电话',
            Textmessage: '短信',
            Chatmessage: '聊天信息',
            Networkaccess: '网络访问',
            Inputinformation: '输入信息',
            SelectDate: '选择日期',
            Pleaseselect: '请选择',
            Phone: {// 电话
                Calltypes: {// 通话类型
                    通话类型: '通话类型',
                    主叫: '主叫',
                    被叫: '被叫',
                    未知: '未知',
                },
                Contacts: '联系人',
                Callnumber: '通话号码',
                Area: '地区',
                Callduration: '通话时长',
                Calltype: '通话类型',
                Calling: '主叫',
                Called: '被叫',
                Unknown: "未知",
                Talktime: '通话时间',
            },
            Message: {// 短信
                SmsType: {
                    短信类型: '短信类型',
                    发送: '发送',
                    接收: '接收',
                },
                Smsphone: '短信号码',
                Smscontent: '短信内容',
                Smstypesof: '短信类型',
                Sending: '发送',
                Receive: '接收',
                Smstime: '短信时间',
            },
            Chatmes: {// 聊天信息
                //信息类型
                infoType: {
                    类别: '类别',
                    文本: '文本',
                    名片: '名片',
                    照片: '照片',
                    文件: '文件',
                    语音: '语音',
                    引用: '引用',
                    转账: '转账',
                    红包: '红包',
                    表情包: '表情包',
                    发送位置: '发送位置',
                    音视频聊天: '音视频聊天',
                    共享位置: '共享位置',
                },
                //聊天状态
                chatStatus: {
                    状态: "状态",
                    允许: "允许",
                    禁止: "禁止",
                },
                //发送类型
                SendTypes: {
                    类型: "类型",
                    发送: "发送",
                    接收: "接收",
                },
                Appname: '应用名称',
                Self: '本人',
                Interlocutor: '对话人',
                Content: '内容',
                Category: '类别',
                Text: '文本',
                Callingcard: '名片',
                Photograph: '照片',
                Document: '文件',
                Voice: '语音',
                Quote: '引用',
                Transfermoney: '转账',
                Redpacket: '红包',
                Expressionpackage: '表情包',
                Sendingposition: '发送位置',
                Audiovideochat: '音视频聊天',
                Sensitivewords: '敏感词',
                Lexicalname: '词库名称',
                Status: '状态',
                Type: '类型',
                Permit: '允许',
                Prohibit: '禁止',
                Accept: '接受',
                Send: '发送',
                Time: '时间',
            },
            network: {// 网络访问
                //访问状态
                callType: {
                    访问状态: "访问状态",
                    允许: "允许",
                    禁止: "禁止",
                },
                Website: '网址',
                Title: '标题',
                Accessstatus: '访问状态',
                Permit: '允许',
                Prohibit: '禁止',
                Time: '时间',
            },
            Inputin: {// 输入信息
                //聊天状态
                chatStatus: {
                    状态: "状态",
                    允许: "允许",
                    禁止: "禁止",
                },
            }
        },
        //设备轨迹
        Position: {
            Frequency: '定位时间间隔',
            FrequencyText: '分钟，最长输入4个字符，且为正整数',
            Number: '打包上传定位个数',
            NumberText: '最长输入4个字符，且为正整数',
        },
        //应用白名单
        networkAppBW: {
            ListType: '名单类型',
            ApplicationList: '应用列表',
            bwType: {
                '白名单': '白名单'
            },

            Apply: '应用',
            placeholder: '请输入应用名称',
        },
        //单应用模式
        SingleApplication: {
            ApplicationList: '应用列表',
            Apply: '应用',
            SelectApply: '选择应用',
            placeholder: '请输入应用名称',
        }
    },
    //应用管理
    AppManage: {
        //应用商店
        AppManagePage: {
            page: { //当前页
                安装类型: '安装类型',
                普通应用: '普通应用',
                必装应用: '必装应用',
                安装模式: '安装模式',
                普通安装: '普通安装',
                安全域安装: '安全域安装',
                应用状态: '应用状态',
                未上架: '未上架',
                已上架: '已上架',
                已下架: '已下架',
                操作系统: '操作系统',
                iOS: 'iOS',
                Android: 'Android',
                Windows: 'Windows',
                Linux: 'Linux',
                placeholder: '请输入应用名称',
                placeholderDeviceName: '请输入设备名称',
                UploadAndroidApp: '上传Android应用',
                UploadiOSApp: '上传iOS应用',
                UploadH5App: '上传H5应用',
                Grounding: '上架',
                OffShelf: '下架',
                SecurityReinforcement: '安全加固',
                SecurityTesting: '安全检测',
                DownloadIOS: '下载iOS加固工具 /',
                DownloadSigningTool: '下载签名工具',
                InstallationType: '安装类型',
                OrdinaryApplications: '普通应用',
                MandatoryApplications: '必装应用',
                applicationType: '应用类别',
                ApplicationStatus: '应用状态',
                Notlisted: '未上架',
                Listed: '已上架',
                Installationmode: '安装模式',
                RemovedShelves: '已下架',
                DetectionScore: '检测分数',
                NotDetected: '未检测',
                Detecting: '检测中',
                ReinforcementStatus: '加固状态',
                Unreinforced: '未加固',
                ReinforcementFailure: '加固失败',
                Strengthening: '加固中',
                Reinforced: '已加固',
                ApplicationStrategy: '应用策略',
                ApplicationStrategylist: '应用策略列表',
                ApplicationDetails: '应用详情',
                AppreinforceInstallationSituation: '安装情况',
                InstallationAllocation: '安装/分配',
                DownloadApps: '下载应用',
                OriginalApplication: '原始应用',
                ReinforcementApplication: '加固应用',
                PolicyName: '请输入策略名称',
                // v6.2.0新增
                uploadingWin: '上传Windows应用',
                // v6.3.0新增
                uploadingLinux: '上传Linux应用'
            },
            UploadAndroidApp: { //上传安卓IOS应用
                SelectApplication: '选择应用：',
                placeholderSelect: '请选择应用',
                SelectApplicationBtn: '选择应用',
                applyName: '应用名称：',
                placeholderApplyName: '请输入应用名称',
                ApplicationID: '应用ID：',
                placeholderApplicationID: '请输入应用ID',
                VersionNumber: '版本号：',
                placeholderVersionNumber: '请输入版本号',
                ApplicationCategory: '应用类别：',
                placeholderApplicationCategory: '请选择',
                InstallationType: '安装类型：',
                Installationmode: '安装模式：', // v6.1.1版本新增
                Ordinaryinstall: '普通安装', // v6.1.1版本新增
                Securitydomain: '安全域安装', // v6.1.1版本新增
                Apptactics: '应用策略', // v6.1.1版本新增
                AndroidName: '名称', // v6.1.1版本新增
                MandatoryApplications: '必装应用',
                ApplicationIntroduction: '应用简介：',
                rules: {
                    appName: '请选择应用',
                    appcategoryid: '请选择应用类别',
                    name: '请输入应用名称',
                    packageName: '请输入应用ID',
                    versionName: '请输入版本号'
                },
                Error: '上传应用重复',
                Error1: '文件格式错误',
                Success: '上传应用成功，正在解析应用',
                Error2: '当前限制文件大小不能大于500M',
                Error3: '上传应用失败',
                Error4: '解析应用失败，请手动填写相关信息',
                Error5: '名称不支持特殊字符',
                Error6: '请输入正确版本号 示例X.X.X',
                Error7: '请输入正确应用ID ',
            },
            UploadH5App: { //上传H5应用
                SelectPicture: '选择图片：',
                placeholderSelectPicture: '请选择图片',
                SelectPictureBtn: '选择图片',
                Support: '支持png格式，大小不超过300KB，建议尺寸144*144像素',
                UrlAddress: 'URL地址：',
                placeholderUrlAddress: '请输入URL地址',
                Error: '请输入正确的URL地址',
                Error1: '请输入正确的域名，如：www.baidu.com',
                Error2: '请输入正确的ip地址',
                Error3: '请上传格式为png的图片',
                Error4: '当前限制图片大小不能大于300KB',
                Success: '上传图片成功',
                rules: {
                    appName: '请输入应用名称',
                    picture: '请选择图片',
                    urls: '请输入URL地址'
                }
            },
            DetailsPage: { //详情页面
                ApplicationCategory: '应用类别',
                ApplicationIntroduction: '应用简介'
            },
            ExportPage: { //导出页面
                DeviceInformation: '设备信息：',
                UserInformation: '用户信息：',
                AppInformation: '应用信息：',
                deviceName: '设备名称',
                healthDegree: '健康度',
                isViolation: '合规状态',
                isJailBreak: '设备越狱',
                status: '设备状态',
                bindStatus: '绑定状态',
                conStatus: '在线状态',
                battery: '电池电量',
                availMemorySize: '内存使用',
                availDataSize: '存储使用',
                version: '客户端版本',
                isActive: '设备管理器',
                deviceDay: '在/离线天数',
                deviceTime: '在/离线时长',
                networkOperatorName: '网络运营商名称',
                brand: '设备厂商',
                model: '设备型号',
                serial: '序列号',
                osId: '操作系统',
                osVersion: '系统版本',
                surfType: '上网方式',
                number: '手机号码',
                sdk: 'SDK版本',
                imei: 'IMEI',
                deviceUDID: 'UDID',
                signStatus: '标记状态',
                belong: '设备归属',
                lastLoginTime: '最近登录时间',
                loginName: '用户名',
                userName: '姓名',
                userSex: '性别',
                groupFullName: '部门',
                phone: '电话',
                email: '邮箱',
                appName: '应用名称',
                appId: '应用ID',
                versionName: '版本',
                appSize: '大小',
                installStatus: '安装情况'
            },
            SecurityPage: { //安全加固页面
                SecurityReinforcement: '安全加固',
                DexProtection: 'DEX文件保护',
                SOfile: 'SO文件',
                FileName: '文件名',
                Sqlite: 'Sqlite数据库文件保护',
                SharePreference: 'SharedPreference文件保护',
                Manifest: 'manifest反编译',
                MemoryDump: '内存防dump',
                IntegrityCheck: '完整性校验',
                SignatureVerification: '签名证书校验',
                SigningCertificate: '签名证书',
                Certificate: '证书名称',
                CertificateOne: '证书sha1',
                PlaceCertificateOne: '请输入证书sha1',
                SafetySandbox: '安全沙箱',
                // SecurityDomain: '安全域',
                SecurityDomain: '仅在安全桌面展示', //v6.1.1
                HideDesktopIcon: '隐藏桌面图标',
                SandboxStrategy: '沙箱策略',
                PolicyName: '策略名称',
                ReinforceBtn: '立即加固'
            },
            // 提示消息
            Error1: '删除失败，该应用正在进行合规检测',
            Error2: '该应用因不能解析无法进行安全加固',
            Error3: '选中的应用未进行检测，请先进行检测',
            Error4: '选中的应用未生成报告，请等待',
            CheckDel1: '确定上架当前应用?',
            CheckDel2: '确定下架当前应用?',
            CheckDel3: '下架成功',
            CheckDel4: '上架成功',
            CheckDel5: '确定检测选中的应用?',
            CheckDel6: '确定删除选中的应用?',
            CheckDel7: '正在检测应用请稍后...'
        },
        //应用类别
        ApplicationCategory: {
            placeholder: '请输入应用类别', //input 提示语
            appList: { //应用列表
                Apps: '应用类别', //应用类别表头
                Description: '描述', //描述表头
                AssociatedApplication: '关联应用', //操作列  悬浮提示 
            },
            addBtn: { //****新增\编辑****
                ClassName: '类别名称：',
                DeScript: '描述：',
                saveError: '保存失败，应用类别已存在', //保存失败提示语
                delLoses: '确定删除选中的应用类别?', //删除提示语
                saveErrorMessage: '该应用类别已有关联，不允许删除', //删除失败提示语
            },
            AssociationBounce: { //****关联弹框**** 
                placeholder: '请输入应用名称', //input 提示语
                relevanceTitle: '关联应用', //弹框title
                relevancePopUpList: { //关联弹框列表 
                    ApplicationName: '应用名称',
                    AppID: '应用ID',
                }
            }
        },
        // 应用库
        ApplicationLibrary: {
            AppLibAndroid: 'Android应用', //Android应用
            AppLibIOS: 'iOS应用', //iOS应用
            Placeholder: '请输入应用名称',
            Installation: '安装设备', //安装设备
            DeviceNamePlaceholder: '请输入设备名称',
            Rules: {
                NameLength: '最长输入 20 个字符', //应用名称长度校验
                Name: '请输入应用名称', //应用名称校验
                Id: '请输入应用ID', //id校验
                Version: '请输入版本', //版本校验
            },
            // 提示消息
            Error1: '保存失败，版本号不正确',
            Error2: '保存失败，应用ID已存在或版本相同',
            CheckDel: '确定删除选中的应用?',
        },
        //应用策略 
        ApplicationStrategy: {
            CurrentPage: { //当前页All
                placeholder: '请输入策略名称',
                addAndroid: '新增Android策略',
                addWindows: '新增Windows策略',
                addLinux: '新增Linux策略',
                addIOS: '新增iOS策略',
                PolicyList: { //应用策略列表
                    PolicyName: '策略名称',
                    AssociatedApplication: '关联应用', //列表关联 title
                    placeholderApp: '请输入应用名称', //列表input 提示语
                    appName: '应用名称', //关联应用 应用名称  表头
                    ApplicationPackageName: '应用包名', //关联应用 应用包名  表头
                    SaveFailure: '保存失败，策略名称重复', //新增保存 失败 提示语
                    DelConfirmPolicy: '确定删除选中的应用策略?', //删除 提示语
                    AssociatedDelConfirmPolicy: '该策略已关联应用，确定删除选中的应用策略?', //删除 提示语
                    delSuccess: '删除成功，该策略已失效', //删除 提示语
                    //校验
                    maximumLengthRule: '最长输入30个字符，不支持空格及特殊字符',
                    PleaseEnterAPolicyNameRule: '请输入策略名称',
                    notSpecialCharacterRule: '策略名称不能出现特殊字符',
                    WatermarkSelectAtLeastOneRule: '界面水印至少选择一项配置',
                    PleaseSelectAnInterfaceWatermarkRule: '请选择界面水印',
                    SecurityInputSelectAtLeastOneConfigurationRule: '安全输入至少选择一项配置',
                    ScreenCaptureRecordProtectionSelectAtLeastOneConfigurationRule: '截屏/录屏保护至少选择一项配置',
                    ClearDataSelectAtLeastOneConfigurationRule: '清除数据至少选择一项配置',
                    PleaseEnterACustomFileRule: '请输入自定义文件',
                    ConfigurePeriodicClearingRule: '请配置定期清除',
                    SelectFileTypeSelectAtLeastOneItemRule: '请选择文件类型至少选择一项',
                    ClearDataSelectAtLeastOneConfigurationItemRule: '清除数据至少勾选一项配置',
                    PleaseSelectTheFileTypeRule: '请勾选文件类型',
                    CVRule: '复制、粘贴保护至少选择一项配置',
                    SystemConfigOneRule: '系统权限限制至少选择一项配置',
                    BluetoothOneConfigurationRule: '蓝牙至少选择一项配置',
                    monitoringAConfigurationRule: '威胁监测至少选择一项配置',
                    ChatContentAConfigurationRule: '聊天内容至少选择一项配置',
                    PleaseSelectSensitiveThesaurusRule: '请选择敏感词库',
                    DisableSensitiveWordsAndAuditChatSelectAtLeastOneSensitiveWordForInputInformationRule: '禁止输入敏感词和审计聊天/输入信息敏感词至少选择一项',
                    PleaseSelectAGatewayResourceRule: '请选择网关资源',
                    SystemSharingSelectAtLeastOneItemRule: '系统分享至少勾选一项',
                    SelectAtLeastOneFileTypeRule: '至少选择一项文件类型',
                    PleaseSelectGeofencingRule: '请选择地理围栏',
                    NetworkSelectAtLeastOneConfigurationRule: '网络至少选择一项配置',

                },
                tacticsTab: {
                    PolicyName: '策略名称',
                    PolicyNameContent: '策略名称：',
                    DataProtection: '数据保护',
                    PrivacyProtection: '隐私保护',
                    NetworkSecurity: '网络安全',
                    ThreatMonitoring: '威胁监测',
                    CrashMonitoring: '崩溃监测',
                    BehaviorAudit: '行为审计',
                    AccessControl: '访问控制',
                },
                ProtectionDataTab: { //数据保护 tab
                    //--界面水印 
                    DataLeakagePrevention: '数据防泄漏：',
                    screenWater: '界面水印',
                    waterContent1: '应用界面显示水印，可对信息来源追溯。',
                    waterContent2: '明水印：简单直观，肉眼可见的数字水印。',
                    waterContent3: '暗水印：肉眼不可见，人为无感知的点阵水印。',
                    BrightWatermark: '明水印',
                    DarkWatermark: '暗水印',
                    Names: '名称',
                    pleaseInput: '请输入名称', //界面水印请输入名称
                    pleaseTitle: '选择界面水印', //界面水印弹框title
                    join: '加入', ////界面水印弹框title
                    //文件透明加解密
                    fileEncipher: '文件透明加解密',
                    fileContent1: '系统将自动对未加密的文件进行加',
                    fileContent2: '密，对已加密的文件自动解密，保',
                    fileContent3: '护文件内容安全',
                    //安全输入
                    SecureInput: '安全输入',
                    SecureContent: '输入密码可启用安全键盘，同时可避免三方输入法带来的信息泄露',
                    ThirdPartyInputMethodsAreProhibited: '禁止使用第三方输入法',
                    CollectionBehavior: '采集行为', //采集行为
                    ResponseAction: '响应动作：', //响应动作
                    AlarmMessage: '告警提示', //告警提示
                    ClearData: '清除数据', //清除数据
                    ExitApplication: '退出应用', //退出应用
                    DenyAccess: '禁止访问', //禁止访问 
                    SecurityKeyboard: '安全键盘', //安全键盘
                    //截屏/录屏保护
                    ScreenCaptureRecordProtection: '截屏/录屏保护',
                    TakeAScreenshotContent1: '防止通过截屏或录屏操作导致应',
                    TakeAScreenshotContent2: '用数据泄露',
                    TheSystemCannotCaptureOrRecordTheScreenOfThisApplication: '禁止系统对此应用的截屏/录屏操作',
                    CaptureBehaviorAndUploadScreenshotInformation: '采集行为并上传截屏信息',
                    //系统分享
                    SystemSharing: '系统分享',
                    shareContent1: '防止应用调用系统分享功能导致应用',
                    shareContent2: '数据泄露',
                    DisableFileSharingToUnprotectedApplications: '禁止分享文件到无防护应用',
                    FileType: '文件类型：',
                    AllFile: '所有文件',
                    Custom: '自定义',
                    FileName: '文件名称：',
                    contain: '包含',
                    DoNotShare: '禁止分享',
                    FileSharingEncryption: '文件分享加密',
                    PhotoSharingWithWatermark: '图片分享加水印',
                    //复制、粘贴保护
                    CVProtection: '复制、粘贴保护',
                    CVContent1: '防止应用数据通过复制、粘贴操作',
                    CVContent2: '导致应用数据泄露',
                    PreventTheCurrentApplicationDataFromBeingCopiedAndPastedToUnprotectedApplications: '禁止当前应用数据复制粘贴到无防护应用',
                    TheCurrentApplicationDataCannotBeCopiedAndPastedToAnyOtherApplication: '禁止当前应用数据复制粘贴到其他任何应用',
                    //清除数据
                    ClearDates: '清除数据',
                    ClearDateContent: '清除沙箱内数据，可以保证工作数据的安全',
                    RegularRemoval: '定期清除：',
                    timeStatus: {
                        小时: '小时',
                        天: '天',
                        分钟: '分钟',
                    },
                    //电子围栏
                    ElectronicDFence: '电子围栏',
                    PreventSensitiveDataLeakageWhenSandboxApplicationsAreUsedOoutsideTheFence: '防止沙箱应用在围栏范围外使用后，造成敏感数据泄漏',
                    Geofencing: '地理围栏：',
                    geoFencings: '地理围栏',
                    //地理围栏
                    SelectGeoFencing: '选择地理围栏',
                    graph: '图形',
                    roundness: '圆形',
                    rectangle: '矩形',
                    Radius: '范围',
                    meter: '米',
                    InsideAndOut: '范围内外',
                    interior: '内部',
                    without: '外部',

                },
                PrivacyProtectionTab: { //隐私保护 tab
                    //隐私保护 
                    PrivacyProtectionContent: '隐私保护：',
                    SystemPermissionRestriction: '系统权限限制',
                    SystemAuthorityContent1: '防止对系统功能的调用导致应用数',
                    SystemAuthorityContent2: '据泄露',
                    Call: '拨打电话：', //拨打电话
                    ReadTheCallLog: '读取通话记录：', //读取通话记录
                    ModifyDeleteCallHistory: '修改删除通话记录：', //修改删除通话记录
                    SendATextMessage: '发送短信：', //发送短信
                    ReadSMS: '读取短信：', //读取短信
                    ModifyDeleteSMSRecords: '修改删除短信记录：', //修改删除短信记录
                    ReadThePhoneNumber: '读取手机号：', //读取手机号
                    ReadTheContact: '读取联系人：', //读取联系人
                    ModifyDeleteContact: '修改删除联系人：', //修改删除联系人
                    RecordedAT: '录音：', //录音
                    TakePictures: '拍照摄像：', //拍照摄像
                    AccessMultimediaResources: '访问多媒体资源：', //访问多媒体资源
                    GetGeographicalLocation: '获取地理位置：', //获取地理位置
                    //监测第三方SDK
                    MonitoringThirdPartySDKS: '监测第三方SDK', //监测第三方SDK
                    monitorSDKContent1: '开启第三方SDK监控后，将上传第', //监测第三方SDK描述
                    monitorSDKContent2: '三方SDK行为信息，并在SDK行为', //监测第三方SDK描述
                    monitorSDKContent3: '列表展示', //监测第三方SDK描述

                    prohibit: '禁止', //禁止
                    CollectionBehavior: '采集行为', //采集行为
                    ResponseAction: '响应动作：', //响应动作
                    AlarmMessage: '告警提示', //告警提示
                    ClearData: '清除数据', //清除数据
                    ExitApplication: '退出应用', //退出应用
                    DenyAccess: '禁止访问', //禁止访问 

                },
                NetworkSecurityTab: { //网络安全 tab 
                    //网络安全控制 
                    NetworkSecurityControl: '网络安全控制：',
                    //网络
                    network: '网络',
                    networkContent1: '防止应用通过接入不安全的网络导',
                    networkContent2: '致应用数据泄露',
                    TurnOnOffMobileNetwork: '开启/关闭移动网络：',
                    UsingTheMobileWeb: '使用移动网络：',
                    TurnOnOffWiFiNetwork: '开启/关闭WiFi网络：',
                    UseWiFiNetwork: '使用WiFi网络：',
                    //蓝牙
                    Bluetooth: '蓝牙',
                    BluetoothContent1: '防止应用通过接入不安全的蓝牙导',
                    BluetoothContent2: '致应用数据泄露',
                    TurnBluetoothOnOff: '开启/关闭蓝牙：',
                    UsingBluetoothTransmission: '使用蓝牙传输：',

                    prohibit: '禁止', //禁止
                    CollectionBehavior: '采集行为', //采集行为
                    ResponseAction: '响应动作：', //响应动作
                    AlarmMessage: '告警提示', //告警提示
                    ClearData: '清除数据', //清除数据
                    ExitApplication: '退出应用', //退出应用
                    DenyAccess: '禁止访问', //禁止访问  
                },
                ThreatMonitoringTab: { //威胁监测 tab 
                    //威胁监测  
                    ThreatMonitoring: '威胁监测：',
                    ApplicationThreat: '应用威胁',
                    ApplicationThreatContent: '监测应用自身威胁情况',
                    InjectionAttack: '注入攻击：',
                    FrameAttack: '框架攻击：',
                    DebugBehavior: '调试行为：',
                    LocationFraud: '位置欺诈：',
                    DomainNameFraud: '域名欺诈：',
                    ProgramPlugIn: '程序外挂：',
                    ApplicationCracking: '应用破解：',
                    HTTPSHijacking: 'HTTPS劫持：',
                    HTTPProxy: 'HTTP代理：',
                    ApplicationMoreOpen: '应用多开：',
                    FrameworkSoftware: '框架软件：',
                    RiskApplication: '风险应用：',
                    MemoryTampering: '内存篡改：',
                    ROOTJailbreak: 'ROOT/越狱：',
                    USBDebugging: 'USB调试：',
                    simulator: '模拟器：',
                    SimulatedPositionAllowed: '允许模拟位置：',
                    TheRootCertificateIsAbnormal: '根证书异常：',
                    CustomROM: '定制ROM：',
                    EquipmentForgery: '设备伪造：',
                    TheScreenLockPasswordIisNotEnabled: '锁屏密码未开启：',
                    SystemTimeException: '系统时间异常：',
                    Windows: 'Windows：',
                    macOS: 'macOS：',
                    VPN: 'VPN：',
                    CloudPhone: '云手机：',
                    RiskIP: '风险IP：',
                    CollectionBehavior: '采集行为', //采集行为
                    ResponseAction: '响应动作：', //响应动作
                    AlarmMessage: '告警提示', //告警提示
                    ClearData: '清除数据', //清除数据
                    ExitApplication: '退出应用', //退出应用
                    DenyAccess: '禁止访问', //禁止访问  
                },
                CrashTestingTab: { //崩溃监测 tab
                    CrashMonitoring: '崩溃监测：',
                    RecordCrashInformation: '记录崩溃信息',
                    MonitorApplicationCrashes: '监测应用崩溃情况',
                },
                BehaviorAuditTab: { //行为审计 tab 
                    ConductAudit: '行为审计：',
                    ChatEnterInformation: '聊天/输入信息',
                    RecordChatMessagesOrClientInput: '记录聊天信息或客户端输入内容',
                    RecordChatInputInformation: '记录聊天/输入信息',
                    RecordChatEnterInformationSensitiveWords: '记录聊天信息或客户端输入内容',
                    ListOfThesaurus: ' 词库列表：',
                    DoNotEnterSensitiveWords: '禁止输入敏感词',
                    AuditChatEnterInformationSensitiveWords: '审计聊天/输入信息敏感词',
                    ResponseAction: '响应动作：', //响应动作
                    AlarmMessage: '告警提示', //告警提示
                    LexicalName: '词库名称',
                    SensitiveLexicon: '敏感词库', //敏感词库按钮添加
                    pleaseSensitiveLexicon: '选择敏感词库', //选择敏感词库弹框title
                    btnTitle: '加入', //加入title
                    placeholder: '请输入词库名称', //placeholder
                    SensitiveWords: '敏感词', //敏感词表头
                },
                AccessControlTab: { //访问控制 tab
                    AccessControl: '访问控制：',
                    SecurityGateway: '安全网关',
                    SecurityGatewayContent: '指定应用访问内网业务资源的安全网关',
                    ListOfAccessibleGateways: '可访问的网关列表',
                    GatewayName: '网关名称',
                    GatewayAddress: '网关地址',
                    port: '端口',
                    ProtectedServiceResources: '保护的业务资源',
                    AddGateway: '添加网关',
                    MaximumDailyRequestTrafficVolume: '日请求流量最大值',
                    MaximumTransmissionRate: '最大传输速率',
                    GatewayPopUp: '网关列表', //弹框title
                    PopUpJoin: '添加', //弹框加入title 
                    placeholderPopUp: '请输入网关名称', //弹框input 提示语
                }
            }
        },
        //威胁定义
        ThreatDefinition: {
            InjectionFlaws: '注入攻击', //注入攻击
            DomainNameFraud: '域名欺诈', //域名欺诈
            ProgramPlug: '程序外挂', //程序外挂
            ApplicationCrack: '应用破解', //应用破解
            HTTPSHijacks: 'HTTPS劫持', //HTTPS劫持
            HighFrequencyBehavior: '高频异常行为', //高频异常行为
            FrameSoft: '框架软件', //框架软件
            RiskApplication: '风险应用', //风险应用
            ThreatsIP: '风险IP', //风险IP
            Yphone: '云手机', //云手机
            NotSupported: '不支持空格及特殊字符', //不支持空格及特殊字符
            NameLength: '最长可输入30个字符', //最长输入30个字符
            SelectFile: '选择文件', //选择文件
            Support: '（支持文件拖拽）', //（支持文件拖拽）
            Drag: '将文件拖到此处，', //将文件拖到此处，
            Or: '或', //或
            Upload: '点击上传', //点击上传
            SelectOne: '只能选择 1 个文件', //只能选择 1 个文件
            Uploading: '正在上传，请稍后...', //正在上传，请稍后...
            Error: '文件上传失败', //文件上传失败
            SizeLimit: '上传文件大小不能超过 100MB!', //'上传文件大小不能超过 100MB!'
            UploadSuccess: '上传成功', //上传成功
            //注入攻击
            InjectionAttack: {
                Placeholder: '请输入名称', //请输入名称
                FilePlaceholder: '请输入文件名称', //请输入文件名称
                Name: '名称',
                FileName: '文件名称', //文件名称
                SameName: '保存失败，名称重复', //保存失败，名称重复
                CheckDelFile: '确定删除选中文件?', //确定删除选中文件？
            },
            //域名欺诈
            DomainFraud: {
                Placeholder: '请输入域名', //请输入域名
                IP: 'IP', //IP
                MoreIpPlaceholder: '输入多个IP需换行', //输入多个IP需换行
                CheckDomain: '请输入正确的域名，如：www.baidu.com', //请输入正确的域名，如：www.baidu.com
                CheckIP: '请输入正确的IP地址,如：192.168.0.1', //请输入正确的IP地址,如：192.168.0.1
                IpPlaceholder: '请输入IP', //请输入IP
                SameName: '保存失败，域名重复', //保存失败，域名重复
                SureToDel: '确定删除选中域名?', //确定删除选中域名?
            },
            //程序外挂
            ProgramPlugin: {
                Placeholder: '请输入外挂名称', //请输入外挂名称
                Name: '外挂名称', //外挂名称
                PackageName: '外挂包名', //外挂包名
                PackagePlaceholder: '请输入外挂包名', //请输入外挂包名
                SameName: '保存失败，外挂名称重复', //保存失败，外挂名称重复
                SureToDel: '确定删除选中外挂?', //确定删除选中外挂?
            },
            //应用破解
            ApplicationCracking: {
                Placeholder: '请输入应用名称', //请输入应用名称
                Size: '安装包大小', //安装包大小
                Signature: '应用签名信息', //应用签名信息
                Signaturemd5: '应用签名信息MD5', //应用签名信息MD5
                SureToDel: '确定删除选中应用?', //确定删除选中应用?
                SameName: '请勿上传相同应用', //请勿上传相同应用
                RequiredFile: '请上传apk或ipa文件', //请上传apk或ipa文件
                UploadFirst: '请先上传应用', //请先上传应用
            },
            //HTTPS劫持
            HTTPSHijack: {
                Placeholder: '请输入证书名称', //请输入证书名称
                Name: '证书名称', //证书名称
                Subject: '证书', //证书
                Modulus: '公钥', //公钥
                Validity: '有效期', //有效期
                UpdateTime: '上传时间', //上传时间
                SupportCer: '仅支持.cer文件', //仅支持.cer文件
                SureToDel: '确定删除选中证书?', //确定删除选中证书?
                SameName: '请勿上传相同证书', //请勿上传相同证书
                RequiredFile: '请上传cer文件', //请上传cer文件
                UploadFirst: '请先上传证书', //请先上传证书
            },
            //框架软件
            FrameSoftware: {
                Placeholder: '请输入框架名称', //请输入框架名称
                Name: '框架名称', //框架名称
                Feature: '框架特征', //框架特征
                FeaturePlaceholder: '请输入框架特征', //请输入框架特征
                Desc: '框架特征可以为应用名称、包名或Dylib库关键字', //框架特征可以为应用名称、包名或Dylib库关键字
                SameName: '保存失败，框架名称重复', //保存失败，框架名称重复
                SureToDel: '确定删除选中框架?', //确定删除选中框架?
            },
            // 风险应用
            RiskApp: {
                Placeholder: '请输入风险名称', //请输入风险名称
                Name: '风险名称', //风险名称
                Feature: '风险特征', //风险特征
                FeaturePlaceholder: '请输入风险特征', //请输入风险特征
                Desc: '风险特征可以为应用名称、包名或Dylib库关键字', //风险特征可以为应用名称、包名或Dylib库关键字
                SameName: '保存失败，风险名称重复', //保存失败，风险名称重复
                SureToDel: '确定删除选中风险?', //确定删除选中风险?
            },
            // 风险IP
            RiskIP: {
                Name: 'IP类型', //IP类型
                Configuration: '配置', //配置
                BlastingIP: '爆破IP', //爆破IP
                ThreatIP: '威胁IP', //威胁IP
                OffendingIP: '违规IP', //违规IP
                // --完整内容：-- 同IP同用户连续登录错误( )次后，判定为风险IP并锁定
                Desc1: '同IP同用户连续登录错误', //同IP同用户连续登录错误
                Desc2: '次后，判定为风险IP并锁定', //次后，判定为风险IP并锁定
                // --完整内容:-- 同IP( )小时内发生( )次违规风险后，判定为风险IP并锁定
                Desc3: '同IP', //同IP
                Desc4: '小时内发生', //小时内发生
                Desc5: '次违规风险后，判定为风险IP并锁定', //次违规风险后，判定为风险IP并锁定
                // --完整内容:-- 同IP( )小时内发生()次威胁风险后，判定为风险IP并锁定
                Desc6: '次威胁风险后，判定为风险IP并锁定', //次威胁风险后，判定为风险IP并锁定
                Enable: '停用启用', //停用启用
                BlastingIPConfiguration: '爆破IP配置', //爆破IP配置
                ThreatIPConfiguration: '威胁IP配置', //威胁IP配置
                OffendingIPConfiguration: '违规IP配置', //违规IP配置
                Hours: '小时', //小时
                Times: '次', //次
                Placeholder: '请输入时间范围', //请输入时间范围
                NumberPlaceholder: '仅支持1-9999的正整数', //仅支持1-9999的正整数
                OffendingNumber: '违规次数', //违规次数
                OffendingPlaceholder: '请输入违规次数', //请输入违规次数
                ThreatNumber: '威胁次数', //威胁次数
                ThreatPlaceholder: '请输入威胁次数', //请输入威胁次数
                BlastingNumber: '爆破次数', //爆破次数
                BlastingPlaceholder: '请输入爆破次数', //请输入爆破次数
            },
            // 云手机
            CloudPhone: {
                Placeholder: '请输入应用名称', //请输入应用名称
                AppPlaceholder: '请输入应用包名', //请输入应用包名
                SameName: '保存失败，应用名称重复', //保存失败，应用名称重复
                SameCategory: '保存失败,类别已存在', //保存失败,类别已存在
                SureToDel: '确定删除选中应用?', //确定删除选中应用?  
            },
            // 高频异常行为
            HighFrequencyAbnormalBehavior: {
                OrderNumber: '序号', //序号
                Name: '威胁类别', //威胁类别
                Unit: '监测时间范围', //监测时间范围
                Hour: '小时', //小时
                Minute: '分钟', //分钟
                Times: '次', //次
                Frequency: '行为出现次数', //行为出现次数
                UseDefault: '使用默认', //使用默认
                PleaseSelect: '请选择', //请选择
                SelectOptions: { //下拉框
                    小时: '小时',
                    分钟: '分钟',
                },
                Placeholder: '最长可输入4个字符', //最长可输入4个字符
            },
        },
        // 安全事件
        AppSecurityEvent: {
            PleEventName: '请输入事件名称',
            EventName: '事件名称',
            EventDescription: '事件描述',
            Tall: '高',
            Centre: '中',
            Low: '低',
            Enable: '停用启用',
            Condition: '环境状态',
            ROOTJailbreak: 'ROOT/越狱',
            USBDebugging: 'USB调试',
            Simulator: "模拟器",
            Simulatedposition: "允许模拟位置",
            CustomROM: "定制ROM",
            Equipmentforgery: "设备伪造",
            Systemtimeanomaly: '系统时间异常',
            Locknotenabled: '锁屏密码未开启',
            CloudPhone: "云手机",
            Satisfyany: "满足任意一勾选条件",
            Satisfyall: "满足全部勾选条件",
            Threatdimension: "威胁维度",
            NumberThreats: "威胁次数",
            Pleaseselect: '请选择',
            Injectionattack: '注入攻击',
            Frameworkattacks: '框架攻击',
            Debuggingbehavior: '调试行为',
            Locationfraud: '位置欺诈',
            Domainnamefraud: '域名欺诈',
            Programplugin: '程序外挂',
            Applicationcrack: '应用破解',
            HTTPShijacking: 'HTTPS劫持',
            Httpproxy: 'HTTP代理',
            AppMultipleOpen: '应用多开',
            Framesoftware: '框架软件',
            Riskapplication: '风险应用',
            Rootcertificate: '根证书异常',
            Memorytampering: '内存篡改',
            RiskIP: '风险IP',
            Occur: '发生',
            Order: '次',
            Threattypes: '威胁种类',
            Threatoccurrence: '威胁发生',
            Species: '种（及以上）',
            Containsthreats: '包含威胁',
            NaN: '无',
            AlarmMessage: '告警提示', //告警提示
            ClearData: '清除数据', //清除数据
            ExitApplication: '退出应用', //退出应用
            ResponseAction: "响应动作",
            Prohibitionmethod: "封禁方式",
            Continuouslockdown: "持续封禁",
            Pleaseselect: '请选择',
            Hour: '小时',
            Numberthreats: '威胁次数为1-9999次',
            Threatsoccur: '威胁发生为1~15种',
            Savefail: '保存失败,事件名称已存在',
            Savefailcontent: '保存失败,请填写所有内容',
            Pleaseconfigure: '请先配置环境状态或威胁维度',
            Delenevent: '确定删除选中的事件?',
        },
        //SDK监控
        SDKMonitorPage: {
            placeholder: '请输入SDK名称', //placeholder
            DownTemplate: '下载模板', //下载模板
            ImportSDKPermission: '导入SDK权限策略库', //导入SDK权限策略库
            SDKName: 'SDK名称', //SDK名称
            SDKPackageName: 'SDK包名', //SDK包名 
            SelectFile: '选择文件', //选择文件
            FileDrag: '（支持文件拖拽）', //（支持文件拖拽）
            DragFileHereOr: '将文件拖到此处，或', //
            ClickUpload: '点击上传', //
            SDKNameVoid: '请输入SDK名称', //
            SDKPackageNameVoid: '请输入SDK包名', //
            SDKNameAlreadyExists: '当前SDK名称已存在', //当前SDK名称已存在
            OnlyOneFile: '只能选择 1 个文件', //只能选择 1 个文件
            SaveFailed: '删除失败', //删除失败
            sureDeleteFile: '确定删除选中文件?', // 确定删除选中文件?
            importFileSucces: '导入文件成功', //导入文件成功
            fileAlreadyExist: '文件已存在', //文件已存在
            PleaseUpload: '请先上传文件', //请先上传文件
            UploadFileSize: '上传文件大小不能超过 100MB!', //上传文件大小不能超过 100MB!
            ImportSuccessful: '导入成功', //导入成功
            SDKPermissInfo: 'SDK权限信息', //SDK权限信息
            SDKPermissInfoVoid: '保存失败，请完善SDK权限信息', //保存失败，请完善SDK权限信息
            SDKPackageNameReg: '不能含有特殊字符', //不能含有特殊字符
            SDKNameStr: '最长输入20个字符', //最长输入20个字符
            SDKPackageNameStr: '最长输入50个字符', //最长输入50个字符
            ReadingSMSRecords: '读取短信记录', //读取短信记录
            ReadAddressBook: '读取通讯录', //读取通讯录
            ObtainGeographicLocation: '获取地理位置', //获取地理位置
            ReadExternalStorage: '读取外部存储', //读取外部存储
            ObtainDeviceHardwareInfo: '获取设备硬件信息', //获取设备硬件信息
            ObtainAppInsList: '获取应用安装列表', //获取应用安装列表
            photosVideos: '拍照、摄像', //拍照、摄像
            EnableWIFI: '开启WIFI', //开启WIFI
            EnableBluetooth: '开启蓝牙', //开启蓝牙
            prohibit: '禁止', //禁止
            CollectBehavior: '采集行为', //采集行为
            ResponseAction: '响应动作', //响应动作
            AlarmPrompt: '告警提示', //告警提示
            ClearData: '清除数据', //清除数据
            ExitApp: '退出应用', //退出应用
            ProhibitAccess: '禁止访问', //禁止访问
            RequiredFile: '请上传xls或xlsx文件',//请上传xls或xlsx文件
        },
        //SDK加固
        sdkStrengPage: {
            placeholder: '请输入名称',
            UploadFiles: '上传文件', //上传文件this.$t('AppManage.sdkStrengPage.UploadFiles')
            SDKReinforcement: 'SDK加固', //SDK加固  
            name: '名称', //名称  
            fileName: '文件名称', //文件名称  
            size: '大小', //大小  
            ReinforcementStatus: '加固状态', //加固状态  
            Unreinforced: '未加固',
            Reinforced: '已加固',
            Strengthening: '加固中',
            ReinforcementFailure: '加固失败',
            uploaded: '上传时间',
            reinforce: '加固', //加固this.$t('AppManage.sdkStrengPage.reinforce')
            DownloadFiles: '下载文件', //下载文件  
            OriginalFile: '原始文件', //原始文件  
            ReinforcementFile: '加固文件', //加固文件  
            SaveFailed: '删除失败', //删除失败
            sureDeleteSeleSDKFile: '确定删除选中的SDK文件?', //确定删除选中的SDK文件?this.$t('AppManage.sdkStrengPage.sureDeleteSeleSDKFile')
            OriginalFileEmpty: '下载失败,原始文件为空', //下载失败,原始文件为空this.$t('AppManage.sdkStrengPage.OriginalFileEmpty')
            ReinforcementFileEmpty: '下载失败,加固文件为空',
            sdkVersion: 'SDK版本', //SDK版本this.$t('AppManage.sdkStrengPage.sdkVersion')
            SelectFlie: '选择文件', //选择文件  
            placeholderSelect: '请选择文件',
            newPasswordVoid: '选择文件',
            placeholderVersion: '请输入SDK版本',
            sdkNameReg: '仅支持字母和下划线，最多输入30个字符', //仅支持字母和下划线，最多输入30个字符  
            sdkNameVoid: '请输入名称', //请输入名称  
            versionReg: '仅支持数字和点，最多输入20个字符', //仅支持数字和点，最多输入20个字符  
            versionVoid: '请输入SDK版本', //请输入SDK版本  
            fileNameReg: '名称不支持特殊字符', //名称不支持特殊字符
            fileNameVoid: '请选择文件', //请选择文件  
            LimitFileSize: '当前限制文件大小不能大于500M', //当前限制文件大小不能大于500M
            FileFormatError: '文件格式错误', //文件格式错误  
            FileUpSuccessfully: '上传文件成功',
            FileUploadFailed: '上传文件失败',
            fileAlreadyExists: '该文件已存在',
            SaveFailed: '保存失败', //保存失败
            JarPackageConfusion: 'jar包混淆', //jar包混淆  
            LineNumberRemoval: '行号移除', //行号移除  
            stringEncry: '字符串加密', //字符串加密  
            NumConfusion: '数字混淆', //数字混淆  
            ControlFlowConfusion: '控制流混淆', //控制流混淆  
            soFileReinfo: 'SO文件加固', //SO文件加固  
            fileTitle: '文件名', //文件名  
            ImmediateReinforcement: '立即加固',
            SelectAnItem: '请至少选择一项', //'请至少选择一项'
            selectJarPackage: '请选择jar包混淆配置', //请选择jar包混淆配置
            selectSoFile: '请选择SO文件', //请选择SO文件
            StrengtheningPleaseWait: '正在加固，请稍后...', //正在加固，请稍后...
        },
        //封禁配置
        BlockingConfiguration: {
            Equipment: '设备封禁', //设备封禁
            forbidden: '禁用', //禁用
            ListOfDevices: '设备列表：', //设备列表
            placeholder: '输入多个UDID需换行', //文本框提示语
            BlockingStrategy: '封禁策略：', //封禁策略 
            CollectionBehavior: '采集行为', //采集行为 
            AlarmMessage: '告警提示', //告警提示
            ClearData: '清除数据', //清除数据
            ExitApplication: '退出应用', //退出应用
            DisablePolicySelectAtLeastOneOption: '封禁策略至少选择一项',
            maximumUDID: '最多支持200个UDID',
            pleaseUDID: '请输入UDID'
        },
        //白名单
        whiteListPage: {
            DeviceWhitelist: '设备白名单', //设备白名单>
            devicesList: '设备列表', //设备列表>
            devPlaceholder: '输入多个UDID需换行', //输入多个UDID需换行>
            devUdidVoid: '请输入UDID', //>
            devUdidNum: '最多支持200个UDID', //最多支持200个UDIDthis
            devUdidUnEmpty: '设备列表不能为空', //设备列表不能为空this
            userList: '用户列表', //用户列表this
            userPlaceholder: '输入多个用户名需换行', //
            UserWhitelist: '用户白名单', //用户白名单
            UserReg: '用户名只支持英文（大小写）、数字或下划线组成', //用户名只支持英文（大小写）、数字或下划线组成
            UserVoid: '请输入用户名', //请输入用户名
            userUdidNum: '最多支持200个用户名', //最多支持200个用户名
            userUdidUnEmpty: '用户列表不能为空', //用户列表不能为空
        },
        // 界面水印
        InterfaceWatermark: {
            Placeholder: '请输入名称', //请输入名称
            Name: '名称', //名称
            WatermarkContent: '水印内容', //水印内容
            WatermarkSize: '水印大小', //水印大小
            WordTransparency: '文字透明度', //文字透明度
            WordColor: '文字颜色', //文字颜色
            DepartmentName: '部门名称', //部门名称
            LastFourDigits: '电话后四位', //电话后四位
            Custom: '自定义', //自定义
            EnterPlaceholder: '最长6个字符', //最长6个字符
            EnterLength: '自定义水印内容，最长6个字符', //自定义水印内容，最长6个字符
            SelectWatermarkContent: '请选择水印内容', //请选择水印内容
            NameLength: '最长输入20个字符', //最长输入20个字符
            SelectOptions: {
                用户名: '用户名',
                部门名称: '部门名称',
                电话后四位: '电话后四位',
                自定义: '自定义'
            },
            SureToDel: '确定删除选中的界面水印?', //确定删除选中的界面水印？
            SameName: '保存失败，名称已存在', //保存失败，名称已存在
            Used: '删除界面水印信息失败,界面水印被使用', //删除界面水印信息失败,界面水印被使用
            LineSpacing: "行间距",//行间距
            ColumnSpacing: "列间距",//列间距
            RotationAngle: "旋转角度",//旋转角度
            ContentPlaceholder: "请输入自定义内容",//请输入自定义内容
        },
        //暗水印解析
        WatermarkAnalysis: {
            Name: '名称',
            Close: '关闭',
            Previous: '上一张',
            NextSheet: '下一张',
            analysisBtn: '解析',
            placeholder: '请输入名称',
            CreationTime: '创建时间',
            UploadPictures: '上传图片',
            SelectPicture: '选择图片',
            ViewPicture: '查看图片',
            OriginalDrawing: '原图',
            placeholderPic: '请选择图片',
            Error1: '删除暗水印信息失败，暗水印被使用',
            Error2: '保存失败，名称已存在',
            Error3: '名称不支持特殊字符',
            Error4: '当前限制图片大小不能大于20M',
            Error5: '上传图片格式错误',
            Error6: '上传图片失败',
            postMessage: '支持png、jpg、jpeg、大小不超过20M',
            CheckDel: '确定删除选中的暗水印?',
            CheckDel1: '确定删除选中暗水印?',
            CheckDel2: '解析完成，请查看图片',
            CheckDel3: '正在解析请稍后',
            CheckDel4: '上传图片名称重复',
            CheckDel5: '上传图片成功'
        },
        //敏感词库
        SensitiveLexicon: {
            sensitivenessList: { //敏感词库列表
                optionSelect: { //下拉选
                    词库名称: '词库名称',
                    敏感词: '敏感词'
                },
                LexicalName: '词库名称',
                SensitiveWords: '敏感词',
                LexicalNameContent: '词库名称：',
                SensitiveWordsContent: '敏感词：',
                ThesaurusNme: '词库名称已存在',
                DeleteSelectedThesaurus: '确定删除选中的敏感词库?',
                DelPolicyUse: '删除失败，敏感词库正在被应用策略使用',
            }
        },
        //H5加固
        H5strengPage: {
            placeholder: '请输入文件名称', //请输入文件名称
            UploadFiles: '上传文件', //上传文件
            H5Reinforcement: 'H5加固', //H5加固
            fileName: '文件名称', //文件名称
            size: '大小', //大小
            ReinforcementStatus: '加固状态', //加固状态
            Unreinforced: '未加固', //未加固
            Reinforced: '已加固', //已加固
            Strengthening: '加固中', //加固中
            ReinforcementFailure: '加固失败', //加固失败
            uploaded: '上传时间', //上传时间
            reinforce: '加固', //加固
            DownloadFiles: '下载文件', //下载文件
            OriginalFile: '原始文件', //原始文件
            ReinforcementFile: '加固文件', //加固文件
            SaveFailed: '删除失败', //删除失败
            sureDeleteSeleH5File: '确定删除选中的H5文件?', //确定删除选中的SDK文件?
            OriginalFileEmpty: '下载失败,原始文件为空', //下载失败,原始文件为空
            ReinforcementFileEmpty: '下载失败,加固文件为空', //加固文件为空
            newPasswordVoid: '确定删除选中的H5文件?', //确定删除选中的H5文件?
            sureReinforceSeleH5File: '确定加固选中的文件?', //确定加固选中的文件?
            ReinforceSuccessful: '加固成功', //加固成功
            ReinforceFailure: '加固失败', //加固失败
            FileFormatError: '文件格式错误', //文件格式错误
            FileUpSuccessfully: '上传文件成功',
            FileUploadFailed: '上传文件失败',
        },
        // 应用加固
        Appreinforce: {
            Securityharden: "安全加固",
            DownloadiOS: "下载iOS加固工具",
            Downloadsignature: "下载签名工具",
            Plusstate: "加固状态",
            Unreinforced: '未加固',
            ReinforcementFailure: '加固失败',
            Strengthening: '加固中',
            Reinforced: '已加固',
            Apptactics: '应用策略',
            uploaded: '上传时间',
            Reinforcement: '加固详情',
            SOfile: 'SO文件',
            DownloadApps: '下载应用',
            OriginalApplication: '原始应用',
            ReinforcementApplication: '加固应用'
        },
        // 应用检测
        Appdetection: {
            PleaseApp: '请输入应用名称',
            UploadAndroidApp: '上传Android应用',
            UploadiOSApp: '上传iOS应用',
            SafetyScan: "安全检测",
            Scan: "应用检测",
            NotDetected: '未检测',
            Detecting: '检测中',
            Divide: '分',
            ApplyOk: '确定检测选中的应用？',
            Checkfirst: '选中的应用未进行检测，请先进行检测',
            Pleasewait: '选中的应用未生成报告，请等待',
            Fileempty: '原始文件为空',
            Fileformaterror: '文件格式错误',
            UploadSuccess: '上传文件成功',
            Appsuccess: '应用上传成功',
            Apperror: '应用上传失败',
            Betesting: '正在检测应用请稍后...',
            Failedtodelete: '删除失败，该应用正在进行合规检测',
            Selectedapp: '确定删除选中的应用?',
            Instacondi: '安装情况',
            Namedetail: { // ios 编辑
                SelectApp: '选择应用',
                PlaceSelect: '请选择应用',
                SelectApplication: '选择应用',
                Uploadappname: '上传应用名称重复',
                FileFormatError: '文件格式错误',
                Successfullyupload: '上传应用成功',
                Failedupload: '上传应用失败',
                Parsingapp: '解析应用失败，请重新上传应用',
                Cannotbelarge: '当前限制文件大小不能大于500M',
            },
            Shoptest: {
                Detectedsuccess: '检测状态：检测成功',
                Notdetected: '检测状态：未检测',
                Detecting: '检测状态：正在检测应用请稍后...',
                Retest: '重新检测',
                Startdetection: '开始检测',
                PDFreport: 'PDF报告',
                DOCreport: 'DOC报告',
                Detectionresult: '检测结果：APP目前处于',
                Highriskstate: '高危状态',
                Mediumcritical: '中危状态',
                Lowcritical: '低危状态',
                Safestate: '安全状态',
                Codetection: '共检测出',
                Abug: '个漏洞',
                AppReinforce: '应用加固',
                CreationTime: '创建时间',
                Fourcomponents: 'Android四大组件',
                Adjustable: '可调Activity',
                Adjustableser: '可调Service',
                Adjustablepro: '可调Provider',
                Adjustablerece: '可调Receiver',
                Signaturecer: '签名证书',
                URLaddress: 'URL地址',
                Noinfor: '未检测到任何信息',
                IPaddress: 'IP地址',
                Mailinfor: 'E-mail信息',
                Permissioninfor: '权限信息',
                Limitsof: '权限',
                Description: '描述',
                Jeopardize: '危害',
                Dangerclass: '危险等级',
                Unknown: "未知",
                Secure: "安全",
                Danger: "危险",
                Sensitivebe: "敏感行为",
                Description: "描述",
                Appall: "应用程序调用包含敏感行为的函数，包括发送短信、发送地理位置、拨打电话等，可能会导致用户隐私数据泄露等风险",
                Name: '名称',
                Detailedinfor: '详细信息',
                Detectingvulner: '检测漏洞',
                VulnerabilityName: '漏洞名称',
                Sphereof: '影响范围',
                VulnerabilityLevel: '漏洞等级',
                Mediumvu: '中危漏洞',
                Moderately: '中危',
                Lowriskvu: '低危漏洞',
                lowrisk: '低危',
                Highriskvu: '高危漏洞',
                Highrisk: '高危',
                Detailedinfor: '详情信息',
                Vulnerabilityprofile: '漏洞简介',
                Details: '详细信息(或运行时截图)',
                Solution: '解决方案',
                Behaviorprofile: '行为简介',
                Sensitivecontent: '敏感内容简介',
                Componentint: '组件简介',
                Callablecomponent: '可调用组件',
            },
        },
        // H5检测  v6.3.1新增
        H5Scan: {
            PleaseApp: '请输入应用名称',
            uploadfiles: '上传文件',
            SafetyScan: "安全检测",
            Scan: "检测分数",
            UploadH5App: { //上传H5应用
                SelectPicture: '上传文件：',
                placeholderSelectPicture: '请选择文件',
                SelectPictureBtn: '选择文件',
                Support: '支持上传zip格式文件',
                UrlAddress: 'URL地址：',
                placeholderUrlAddress: '请输入URL地址',
                Error: '请输入正确的URL地址',
                Error1: '请输入正确的域名，如：www.baidu.com',
                Error2: '请输入正确的ip地址',
                Error3: '请上传格式为zip的文件',
                Error4: '当前限制图片大小不能大于300KB',
                Success: '上传文件成功',
                rules: {
                    picture: '请选择文件',
                    appName: '请输入应用名称',
                    packageName: '请输入应用ID',
                    versionName: '请输入版本号',
                    urls: '请输入URL地址',
                    appcategoryid: '请选择应用类别',
                },
                Error5: '上传应用重复',
                Error6: '上传文件失败',
            },
            ApplicationIntroduction: '应用简介',
            H5appShoptest: {
                Detectedsuccess: '检测状态：检测成功',
                Notdetected: '检测状态：未检测',
                Detecting: '检测状态：正在检测应用请稍后...',
                Retest: '重新检测',
                Startdetection: '开始检测',
                PDFreport: 'PDF报告',
                DOCreport: 'DOC报告',
                Detectionresult: '检测结果：H5应用目前处于',
                Highriskstate: '高危状态',
                Mediumcritical: '中危状态',
                Lowcritical: '低危状态',
                Safestate: '安全状态',
                Codetection: '共检测出',
                Abug: '个漏洞',
                AppReinforce: '应用加固',
                CreationTime: '创建时间',
                Fourcomponents: 'Android四大组件',
                Adjustable: '可调Activity',
                Adjustableser: '可调Service',
                Adjustablepro: '可调Provider',
                Adjustablerece: '可调Receiver',
                Signaturecer: '签名证书',
                URLaddress: 'URL地址',
                Noinfor: '未检测到任何信息',
                IPaddress: 'IP地址',
                Mailinfor: 'E-mail信息',
                Permissioninfor: '权限信息',
                Limitsof: '权限',
                Description: '描述',
                Jeopardize: '危害',
                Dangerclass: '危险等级',
                Unknown: "未知",
                Secure: "安全",
                Danger: "危险",
                Sensitivebe: "敏感行为",
                Description: "描述",
                Appall: "应用程序调用包含敏感行为的函数，包括发送短信、发送地理位置、拨打电话等，可能会导致用户隐私数据泄露等风险",
                Name: '名称',
                Detailedinfor: '详细信息',
                Detectingvulner: '检测漏洞',
                VulnerabilityName: '漏洞名称',
                VulnerabilityLevel: '漏洞等级',
                Mediumvu: '中危漏洞',
                Moderately: '中危',
                Lowriskvu: '低危漏洞',
                lowrisk: '低危',
                Highriskvu: '高危漏洞',
                Highrisk: '高危',
                Detailedinfor: '详情信息',
                Vulnerabilityprofile: '漏洞简介',
                Details: '详细信息(或运行时截图)',
                Solution: '解决方案',
                Behaviorprofile: '行为简介',
                Sensitivecontent: '敏感内容简介',
                Componentint: '组件简介',
                Callablecomponent: '可调用组件',
            },
        },
        // 检测分析
        ScanAnalysis: {
            ExportReport: '导出报告', //导出报告
            LoopholeLevel: '漏洞级别占比', //漏洞级别占比
            LoopholeCounts: '漏洞数量分布', //漏洞数量分布
            LoopholeType: '漏洞类型分布', //漏洞类型分布
            LoopholeCountsTrend: '漏洞数量趋势', //漏洞数量趋势
            LoopholeTypeTrend: '漏洞类型趋势', //漏洞类型趋势
            ScanVersionNum: '检测版本数', //检测版本数
            ScanLeakNum: '检测漏洞数', //检测漏洞数
            ScanTypeNum: '漏洞类型数', //漏洞类型数
            LeakScanSort: '漏洞检测排名', //漏洞检测排名
            VersionGradeSort: '版本检测分数排名', //版本检测分数排名
            HighRisk: '高危', //高危
            MiddleRisk: '中危', //中危
            LowRisk: '低危', //低危
            SafetyScanning: '安全扫描', //安全扫描
            ComponentVulnerability: '组件漏洞', // 组件漏洞
            SourceCodeVulnerability: '源码漏洞', // 源码漏洞
            GeneralVulnerability: '通用漏洞', // 通用漏洞
            DataBreachRisk: '数据泄露风险', // 数据泄露风险
            SensitiveBehavioralRisk: '敏感行为风险', // 敏感行为风险
            SourceFileVulnerability: '源文件漏洞', // 源文件漏洞
            IdentityAuthenticationRisk: '身份认证风险', // 身份认证风险
            CommunicationTransmissionRisk: '通信传输风险', // 通信传输风险
            UnknownRisk: '未知风险', // 未知风险
            LeakType: '漏洞类型', //漏洞类型
            LeakName: '漏洞名称', //漏洞名称
            ScanNum: '检测次数', //检测次数
            NoData: '暂无数据', //暂无数据
            VersionNumber: '版本号', //版本号
            ScanGrade: '检测分数', //检测分数
            ScanDate: '检测日期', //检测日期
        },
        //检测等级
        Detectionlevel: {
            Tall: '高',
            Center: '中',
            Low: '低',
            SerialNumber: '序号',
            placeholder: '请输入漏洞名称',
            VulnerabilityName: '漏洞名称',
            VulnerabilityLevel: '漏洞等级',
            VulnerabilityDesc: '漏洞描述',
            DescMessage: '确定修改该漏洞等级?'
        },
        //证书管理
        CertificateManagePage: {
            placeholder: '请输入证书名称', //
            AddAndroidCertificate: '新增Android证书', //
            AddiOSCertificate: '新增iOS证书', //
            certificateName: '证书名称',
            briefIntroduction: '简介', //
            SaveFailedNameAlreadyExists: '保存失败,该名称已存在', //保存失败,该名称已存在
            sureDelSeleCer: '确定删除选中证书?', //确定删除选中证书?
            DeleteFailed: '删除失败', //
            UpCertificate: '上传证书', //
            UpPlaceholder: '请上传证书(xxx.keystore,xxx.jks)', //
            selectFile: '选择文件', //
            CertificatePass: '证书密码', //
            CertificateAlias: '证书别名', //
            AliasPass: '别名密码', //
            CertificateIntroduction: '证书简介', //
            cerNameVoid: '请输入证书名称', //
            cerNameReg: '不支持空格及特殊字符', //
            fileNameVoid: '请上传证书', //
            cerAliasNameVoid: '请输入证书别名', //
            SizeLimit: '当前限制文件大小不能大于500M', //当前限制文件大小不能大于500M
            FileFormatError: '文件格式错误,请上传keystore格式的文件', //文件格式错误,请上传keystore格式的文件
            unchangedKeepEmpty: ' 如密码无变化，保持该项为空', // 如密码无变化，保持该项为空
            UpDescriptionFile: '上传描述文件', //
            PleaseUploadFile: '请上传文件', //
            PleaseDesUploadFile: '请上传描述文件', //
            FileUpSuccessfully: '上传文件成功',
            FileUploadFailed: '上传文件失败',
            UploadpFormat: '文件格式错误,请上传p12格式的文件', //文件格式错误,请上传p12格式的文件
            PleaseUpMobileprovisionFormat: '文件格式错误,请上传mobileprovision格式的描述文件', //文件格式错误,请上传mobileprovision格式的描述文件
        },
    },
    //内容管理
    ContentManage: {
        // 通知管理
        Informmanage: {
            Placetitlecon: '请输入标题/内容',
            Title: '标题',
            Content: '内容',
            Publisher: '发布人',
            CreationTime: '创建时间',
            Readingassignment: '阅读/分配',
            PlaceDeviceName: '请输入设备名称',
            SaveError: '保存失败，已存在相同标题通知',
            SaveseleError: '确定删除选中通知?',
            TitleInfo: '文档阅读情况',
        },
        // 文档类别
        Documentcate: {
            PlaceDocumentcate: '请输入文档类别',
            Documentcategory: '文档类别',
            Description: '描述',
            Assdocument: '关联文档',
            Dailyoffice: '日常办公',
            Classname: '类别名称',
            Placeholder: '请输入名称',
            Name: '名称',
            Creator: '创建者',
            Adderror: '保存失败，文档类别已存在',
            DeleteOK: '确定删除选中文档类别?',
            Deletionfailure: '删除失败，不能删除有文档的类别',
        },
        DocumentManagement: {
            DocumentNav: '文档管理', //面包屑
            SelectPlaceholder: '请选择', //下拉框提示
            SelectOptions: { //下拉框
                有效期: '有效期',
                正常: '正常',
                已过期: '已过期'
            },
            InputPlaceholder: '请输入名称',
            NewDoc: '新增文档', //新增文档
            NewFolder: '新增文件夹', //新增文件夹
            Name: '名称', //名称
            DocType: '格式', //格式
            CategoryFullName: '类别', //类别
            Indate: '有效期', //有效期
            PermanentlyValid: '永久有效', //永久有效
            Custom: '自定义', //自定义
            Expired: '已过期', //已过期
            Creator: '创建者', //创建者
            Read: '阅读', //阅读
            SelectFolder: '选择文件夹', //选择文件夹
            SameFolder: '如有同名文件：', //如有同名文件：
            Cover: '覆盖', //覆盖
            Rename: '重命名', //重命名
            Close: '关闭', //关闭
            TitleInfo: '文档阅读情况', //文档阅读情况
            // 提示消息
            FileIsEmpty: '文件为空', //文件为空
            CheckDelFile: '确定删除选中文件?', //确定删除选中文件？
            CheckDelDoc: '确定删除选中文档?', //确定删除选中文档？
            ErrorDoc: '文件格式错误，请重新上传', //文件格式错误，请重新上传
            SameDoc: '保存失败，已存在相同名称的文档', //保存失败，已存在相同名称的文档
            TransferPurpose: '转移目的文件夹不能是当前文件夹', //转移目的文件夹不能是当前文件夹
            TransferSuccess: '转移成功', //转移成功
            FailureOfTransfer: '转移失败', //转移失败
            SelectFile: '选择文件', //选择文件
            Browse: '浏览', //浏览
            DeleteOnReaded: '阅读后是否删除', //阅读后是否删除
            isBurnAfterRead: { //阅读后是否删除下拉框
                是: '是',
                否: '否'
            },
            SelectAStartTime: '请选择开始时间', //请选择开始时间
            SelectAEndTime: '请选择结束时间', //请选择结束时间
            DocIntro: '文档简介', //文档简介
            // 表单校验
            Rules: {
                name: '请选择文件', //请选择文件
                docCategoryId: '请选择文档类别', //请选择文档类别
                FolderName: '请输入文件夹名称', //请输入文件夹名称
                NameLength: '最长输入30个字符', //最长输入30个字符
            },
            // 提示消息
            SizeLimit: '当前限制文件大小不能大于500M', //当前限制文件大小不能大于500M
            ErrorFile: '文件格式错误,请上传正确文件', //文件格式错误,请上传正确文件
            UploadSuccess: '上传文件成功', //上传文件成功
            UploadError: '上传文件失败', //上传文件失败
            EmptyTime: '开始时间和结束时间不能为空', //开始时间和结束时间不能为空
            GreaterThan: '开始时间不能大于结束时间', //开始时间不能大于结束时间
            FolderName: '文件夹名称', //文件夹名称
            NotSupported: '不支持空格及特殊字符', //不支持空格及特殊字符
        }
    },
    //分配管理
    Delivery: {
        // 分配管理
        distributionManagement: {
            AllocationContent: '分配内容', //分配内容
            AllocateByDepartment: '按部门分配', //按部门分配
            AssignByUser: '按用户分配', //按用户分配
            AssignByUserGroup: '按用户组分配', //按用户组分配
            AssignByDeviceGroup: '按设备组分配', //按设备组分配
            AssignByDevice: '按设备分配', //按设备分配
            SelectAllocateContent: '请选择分配内容', //请选择分配内容
            SelectAllocateObject: '请选择分配对象', //请选择分配对象
            AssignedSuccessfully: '分配成功', //分配成功
            select: '选择', //选择
            content: {
                设备策略: '设备策略', //设备策略
                合规策略: '合规策略', //合规策略
                应用程序: '应用程序', //应用程序
                文档内容: '文档内容', //文档内容
                通知内容: '通知内容', //通知内容
            },
            SerialNumber: '序号', //序号
            classification: '分类', //分类
            name: '名称', //名称
            version: '版本', //版本
            operatingSystem: '操作系统', //操作系统
            operate: '操作', //操作
            department: '部门', //部门
            devicePolicy: '设备策略', //设备策略
            CompStrategy: '合规策略', //合规策略
            MobileApp: '应用程序', //应用程序
            docContent: '文档内容', //文档内容
            NotContent: '通知内容', //通知内容
            SelectDevicePolicy: '选择设备策略', //选择设备策略
            SelectComplianceStrategy: '选择合规策略', //选择合规策略
            ChooseMobileApp: '选择应用程序', //选择应用程序
            SelectDocContent: '选择文档内容', //选择文档内容
            SelectNotContent: '选择通知内容', //选择通知内容
            join: '加入', //加入
            determine: '确定', //确定
            cancel: '取消', //取消
            SameComplianceStrategy: '已存在相同操作系统的合规策略', //已存在相同操作系统的合规策略
            compPolicyAlreadyExists: '该条合规策略已存在', //该条合规策略已存在
            SameDevicePolicy: '已存在相同操作系统的设备策略', //已存在相同操作系统的设备策略
            contentOperating: '已存在相同操作系统的设备策略',//已存在相同操作系统的设备策略
            DevicePolicyAlreadyExists: '该条设备策略已存在', //该条设备策略已存在
            devicePlaceholder: '请输入名称', //请输入名称
            notPlaceholder: '请输入标题', //请输入标题
            docPlaceholder: '请输入应用名称', //请输入应用名称
            devRigPlaceholder: '请输入设备名称/用户名/姓名', //请输入设备名称/用户名/姓名
            userRigPlaceholder: '请输入用户名/姓名', //请输入用户名/姓名
            groupPlaceholder: '请输入部门名称', //请输入部门名称
            devGroupPlaceholder: '请输入设备组名称',//请输入设备组名称
            userGroupPlaceholder: '请输入用户组名称',//请输入用户组名称
            notPlaceholder: '请输入标题', //请输入标题
            docPlaceholder: '请输入应用名称', //请输入应用名称
            UserGroupName: '用户组名称',//用户组名称
            DeviceGroupName: '设备组名称',//设备组名称
            Description: '描述',//描述
            ModificationTime: '修改时间', //修改时间
            creator: '创建者', //创建者
            docManagement: '文档管理', //文档管理
            title: '标题', //标题
            publisher: '发布人', //发布人
            CreationTime: '创建时间', //创建时间
            appName: '应用名称', //应用名称
            AppID: '应用ID', //应用ID
            DeviceName: '设备名称', //设备名称
            userName: '用户名', //用户名
            fullName: '姓名', //姓名
            DepartmentName: '部门名称', //部门名称
            SuperiorDepartment: '上级部门', //上级部门
            query: '查询', //查询
            Selected: '已选择：', //已选择：
            deleteAll: '全部删除', //全部删除
            userRigPlaceholder: '请输入用户名/姓名', //请输入用户名/姓名

        },
        // 执行结果
        enforcementresult: {
            SelectAStartTime: '请选择开始时间', //请选择开始时间
            SelectAEndTime: '请选择结束时间', //请选择结束时间
            Placeholder: "请输入设备名称", //请输入设备名称
            ReqType: '执行内容', //执行内容
            CmdResult: '执行结果', //执行结果
            Waiting: '等待中', //等待中
            Success: '成功', //成功
            DeliveryTime: '分配时间', //分配时间
            CheckDelAll: '确定删除所有记录?', //确定删除所有记录?
            CheckDel: '确定删除选中的记录?', //确定删除选中的记录？
            // ReqType 执行内容
            reqType: {
                installconfig: "安装合规策略",
                installpolicy: "安装设备策略",
                message: "消息",
                removedevice: "强制注销",
                logOut: "强制注销",
                must_install_application: "安装应用",
                InstallApplication: "安装应用",
                extractLog: "提取日志",
                InstalledApplicationList: "软件信息",
                DeviceInformation: "硬件信息",
                DeviceLock: "设备锁屏",
                // DeviceUnLock: "设备解锁",
                removeappdata: "清除数据",
                InstallProfile: "安装配置文件",
                ManagedApplicationList: "受控软件",
                restartDevice: "设备重启",
                shutDown: "设备关机",
                RemoveApplication: "卸载应用",
                ClearPasscode: "清除密码",
                EnableLostMode: "设备丢失",
                DisableLostMode: "解除丢失",
                uploadlocation: "获取定位",
                EraseDevice: "恢复出厂",
                devicelost: "设备丢失",
                DeviceUnLock: '设备解锁',
                stopService: "停用设备",
                userLogOut: "用户注销",
                // deviceunbind:"设备解绑",
                // switchcontainer: "切换模式",
                // workStyle: "切换模式—工作模式",
                // lifeStyle: "切换模式—生活模式",
                // deviceruninfo: "设备运行信息",
            },
        },
        // 分配历史
        DistributionHistory: {
            // 列表
            AssignName: '分配名称', //分配名称
            ConcreteType: '具体类型', //具体类型
            MobileApp: '应用程序', // 移动应用 修改为“应用程序”（v6.2.0)
            NoticeContent: '通知内容', // 通知内容
            ComplianceStrategy: '合规策略', // 合规策略
            DevicePolicy: '设备策略', // 设备策略
            DocumentContent: '文档内容', // 文档内容
            AllocationObject: '分配对象', //分配对象
            User: '用户', //用户
            UserGroup: '用户组',//用户组
            UserGroupName: '用户组名称',//用户组名称
            Device: '设备', //设备
            DeviceGroup: '设备组',//设备组
            DeviceGroupName: '设备组名称',//设备组名称
            AllocateTime: '分配时间', //分配时间
            Details: '详细信息', //详细信息
            // 通知内容
            NoticeTitle: '标题', //标题
            NoticeContent: '内容', //内容
            // 移动应用
            AppCategory: '应用类别', //应用类别
            Memo: '应用简介', //应用简介
            BlackList: '黑名单', //黑名单
            WhiteList: '白名单', //白名单
            PassCodeType: '复杂密码', //复杂密码
            PasswordComplexity: '字母+数字', //字母+数字
            Null: '无', //无
            forceDelayedSoftwareUpdates: '延迟系统升级',
            networkAppBW: '应用白名单*', //应用白名单*
            singleAppMode: '单应用模式*', //单应用模式*
        },
    },
    //流程管理
    Process: {
        //流程设置
        ProcessSettings: {
            Choose: "请选择流程类型",
            Placeholder: "请输入流程名称",
            Options: {
                "流程类型": "流程类型",
                "应用上架": "应用上架",
                "应用下架": "应用下架",
                "设备注册": "设备注册"
            },
            Name: "流程名称",
            WorkflowType: "流程类型",
            WorkflowType1: "应用上架",
            WorkflowType2: "应用下架",
            WorkflowType3: "设备注册",
            ApplicantSopeStr: "申请人员范围",
            ContentStr: "审批人",
            ApplicantStr: "申请人",
            Creator: "创建人",
            UpdateTime: "更新时间",
            Close: "关闭",
            StepScope: "审批节点",
            DelMessage: "确定删除选中流程?",
            DelError: "删除流程信息失败! 正在使用",
            ApplicantSope: "适用范围",
            Person: "指定人员",
            Design: "流程设计",
            FlowType: {
                "应用上架": "应用上架",
                "应用下架": "应用下架",
                "设备注册": "设备注册"
            },
            SetApproval: "设置审批人属性",
            ApprovalSet: "审批人设置",
            ChoosePerson: "选择人员",
            Sectionplaceholder: "输入部门名称",
            People: "人员",
            Peopleplaceholder: "输入用户名/姓名",
            NoAdmin: "该组织下没有管理员",
            SetApplicant: "设置申请人属性",
            ApplicantSet: "申请人设置",
            Over: "结束",
            EndBtn: '关闭',
            WorkFlowIncomplete: "流程图不完整",
            NodeIncomplete: "有节点不完整",
            Cancel: "该操作将废除正在审批中流程",
            SameName: "名称已存在!",
            SameApplicantSopeStr: "申请人范围已存在!",
            ChooseAPerson: "请选择申请人或审批人",
            ErrorMessage1: "申请人能且仅能指向一个审批人",
            ErrorMessage2: "申请人不能指向审批人之外的人",
            ErrorMessage3: "申请人不能被其他人员指向",
            ErrorMessage4: "审批人只能指向一个审批人或结束",
            ErrorMessage5: "审批人不能指向申请人",
            ErrorMessage6: "一个审批人不能被多个用户同时指向",
            ErrorMessage7: "不能由结束指向审批人",
            ErrorMessage8: "结束只能被一个人员指向",
            ErrorMessage9: "结束只能被一个人员指向",
        },
        //我的申请
        MyApplication: {
            ApplicationNo: '申请编号',
            Name: '名称',
            EndBtn: '关闭',
            ProcessTypes: '流程类型',
            ApprovalStatus: '审批状态',
            ApplicationTime: '申请时间',
            ApprovalTime: '审批时间',
            Resubmit: '重新提交',
            Revoke: '撤销',
            ApplicationListing: '应用上架',
            ApplicationRemoval: '应用下架',
            DeviceRegistration: '设备注册',
            PendingApproval: '待审批',
            InApproval: '审批中',
            Passed: '已通过',
            Rejected: '已拒绝',
            Repealed: '已废除',
            Revoked: '已撤销',
            DeviceBindingUser: '设备绑定用户',
            IMEIUDID: 'IMEI/UDID',
            Introduction: '功能简介',
            ApplicationDepartment: '申请部门',
            Telephone: '联系电话',
            Apply: '应用',
            SubmitApplication: '提交申请',
            MessagePort: '撤销成功',
            MessagePort1: '提交成功',
            MessagePort2: '编辑成功',
            ChooseMobileApp: '选择移动应用',
            Pleaseenter: '请输入应用名称',
            titleDetail: '申请详情',
            Error: '当前限制文件大小不能大于500M',
            Error1: '文件格式错误',
            Error2: '名称不支持特殊字符',
            Confirm: '确定提交申请',
            Confirm1: '确定重新提交申请?',
            Confirm2: '确定撤销?',
            Confirm3: '当前应用已有审批中的流程',
            rules: {
                askName: '请输入名称',
                askType: '请选择流程类型',
                appName: '请选择应用名称',
                appNames: '请选择应用',
                os: '操作系统不能为空',
                appPackage: '应用包名不能为空',
                appVersion: '应用版本不能为空',
                functionInt: '请输入功能简介',
                groupName: '请输入申请单位',
                phone: '请输入联系电话'
            },
            options: {
                '审批状态': '审批状态',
                '待审批': '待审批',
                '审批中': '审批中',
                '已拒绝': '已拒绝',
                '已通过': '已通过',
                '已审批': '已审批',
                '已撤销': '已撤销',
                '已废除': '已废除'
            },
            ProcessType: {
                '流程类型': '流程类型',
                '应用上架': '应用上架',
                '应用下架': '应用下架',
                '设备注册': '设备注册',
            },
            approveStatus: {
                '申请': '申请',
                '同意': '同意',
                '拒绝': '拒绝',
                '废除': '废除',
            },
            ApprovalNode: '审批节点',
            ApprovedBy: '可审批人',
            ActualApprover: '实际审批人',
            ApprovalOpinions: '审批意见',
            ApprovalDetails: '审批详情',
            OperateTime: '操作时间'
        },
        //我的审批
        MyApproval: {
            ApprovaBtn: '审批',
            Applicant: '申请人',
            ApprovaDetail: '审批详情：',
            ApproveOpinion: '审批意见：',
            approveStatus: {
                '同意': '同意',
                '拒绝': '拒绝',
            },
            options: {
                '已审批': '已审批'
            },
            rules: {
                ApproveDetail: '请填写审批详情'
            }
        }
    },
    //安全监测
    SecurityMonitor: {
        //告警信息
        alarmMessage: {
            types: { //类型 下拉框
                类型: '类型',
                威胁行为: '威胁行为',
                安全事件: '安全事件',
            },
            BehaviorLevel: { //行为级别 下拉框
                行为级别: '行为级别',
                高: '高',
                中: '中',
                低: '低',
            },
            alarmList: { //列表 
                Typeof: '类型',
                WIFISSID: 'WIFI-SSID',
                WIFIMAC: 'WIFI-MAC',
                high: '高',
                medium: '中',
                low: '低',
                LocationFraud: '位置欺诈',
                DomainNameFraud: '域名欺诈',
                InjectionAttack: '注入攻击',
                DebuggingBehavior: '调试行为',
                MemoryTampering: '内存篡改',
                ProgramPlugIn: '程序外挂',
                ApplicationCracking: '应用破解',
                HttpsHijacking: 'Https劫持',
                HttpProxy: 'Http代理',
                ApplicationMultiplex: '应用多开',
                FrameAttack: '框架攻击',
                HighFrequencyChangeAccount: '高频更换账号',
                HighFrequencyChangeIP: '高频更换IP',
                HighFrequencyChangePosition: '高频更换位置',
                HighFrequencyStartingApplication: '高频启动应用',
                RiskApplication: '风险应用',
                ROOTJailbreak: 'ROOT/越狱',
                FrameSoftware: '框架软件',
                USBDebugging: 'USB调试',
                simulator: '模拟器',
                SimulatedPositionAllowed: '允许模拟位置',
                RootCertificateException: '根证书异常',
                CustomROM: '定制ROM',
                TheScreenshotBlockIsRemovedProcedure: '截屏阻断解除',
                SystemTimeException: '系统时间异常',
                TheScreenLockPasswordPsNotEnabled: '锁屏密码未开启',
                VPN: 'VPN',
                macOS: 'macOS',
                Windows: 'Windows',
                CloudPhone: '云手机',
                RiskIP: '风险IP',
                PopupBox: { //弹出框
                    设备名称: '设备名称',
                    类型: '类型',
                    威胁类型: '威胁类型',
                    行为级别: '行为级别',
                    应用名称: '应用名称',
                    应用版本: '应用版本',
                    系统版本: '系统版本',
                    位置: '位置',
                    经纬度: '经纬度',
                    经纬度: '经纬度',
                    上网方式: '上网方式',
                    外网IP: '外网IP',
                    内网IP: '内网IP',
                    'WIFI-SSID': 'WIFI-SSID',
                    'WIFI-MAC': 'WIFI-MAC',
                    用户名: '用户名',
                    姓名: '姓名',
                    部门: '部门',
                    产生时间: '产生时间',
                    上报时间: '上报时间'

                }
            }
        },
        // 预警信息
        WarningInfo: {
            Placeholder: "选择日期", //选择日期
            WarningPlaceholder: "预警类型", //预警类型
            serviceType: "服务类型", //服务类型
            Gateway: '网关服务', //网关服务
            ServiceAddress: '服务地址', //服务地址
            ServicePort: '服务端口', //服务端口
            Memory: '内存', //内存
            HardDisk: '硬盘', //硬盘
            ServiceException: '服务异常', //服务异常
            WarningContent: '预警内容', //预警内容
            WarningTime: '预警时间', //预警时间
            SelectOptions: {
                预警类型: '预警类型',
                CPU: 'CPU',
                内存: '内存',
                硬盘: '硬盘',
                服务异常: '服务异常'
            }
        },
        // 违规行为
        Violation: {
            SelectDate: '选择日期',
            TypeOptions: { //行为类型
                行为类型: '行为类型',
                设备越狱: '设备越狱',
                SIM卡变更: 'SIM卡变更',
                SD卡变更: 'SD卡变更',
                违规外联: '违规外联',
                违规外联: '违规外联',
                地理围栏: '地理围栏',
                系统版本: '系统版本',
                流量异常: '流量异常',
                设备电量: '设备电量',
                存储空间: '存储空间',
                必装应用: '必装应用',
                应用黑白名单: '应用黑白名单',
                WIFI黑白名单: 'WIFI黑白名单',
                系统功能: '系统功能',
            },
            LevelOptions: { //行为级别
                行为级别: '行为级别',
                高: '高',
                中: '中',
                低: '低',
            },
            Tablelinkage: { //表格联动
                设备名称: '设备名称',
                行为类型: '行为类型',
                行为级别: '行为级别',
                系统版本: '系统版本',
                位置: '位置',
                经纬度: '经纬度',
                上网方式: '上网方式',
                外网IP: '外网IP',
                内网IP: '内网IP',
                'WIFI-SSID': 'WIFI-SSID',
                'WIFI-MAC': 'WIFI-MAC',
                用户名: '用户名',
                姓名: '姓名',
                部门: '部门',
                产生时间: '产生时间',
                上报时间: '上报时间',
                操作: '操作',
            },
            Tall: '高',
            Centre: '中',
            Low: '低',
            AppInfor: '应用信息',
            SdkVersion: '沙箱SDK版本',
            AppPackageName: '应用包名',
            AppMD5: '应用MD5',
            BasicInformation: '基本信息',
            EquipmentModel: "设备型号",
            DeviceIP: '设备IP',
            UserDepartment: '用户部门',
            EquipmentIMEI: '设备IMEI',
            PositionInfor: '位置信息',
            Country: '国家',
            City: '城市',
            Province: '省份',
            Address: '详细地址',
            RiskInfo: '风险信息',
            ResponseAction: "响应动作",
            JudgmentSteps: '研判步骤',
            ArrResuly: [ // 详情---响应动作
                { value: '0', label: '告警日志' },
                { value: '1', label: '告警提示' },
                { value: '2', label: '清除数据' },
                { value: '3', label: '强制注销' },
                { value: '4', label: '停用设备' },
                { value: '5', label: '恢复出厂' },
                { value: '6', label: '禁止访问' },
                { value: '7', label: '锁定' }
            ],
            BeiJing: "北京", //"北京"
            TianJin: "天津", //"天津"
            ShangHai: "上海", //"上海"
            ChongQing: "重庆", //"重庆"
            Beyondbord: "境外", //"重庆"
        },
        // 威胁行为
        Threatening: {
            TypeOptions: { // 行为类型
                行为类型: '行为类型',
                注入攻击: '注入攻击',
                框架攻击: '框架攻击',
                调试行为: '调试行为',
                位置欺诈: '位置欺诈',
                域名欺诈: '域名欺诈',
                程序外挂: '程序外挂',
                应用破解: '应用破解',
                HTTPS劫持: 'HTTPS劫持',
                HTTP代理: 'HTTP代理',
                应用多开: '应用多开',
                高频更换账号: '高频更换账号',
                高频更换IP: '高频更换IP',
                高频更换位置: '高频更换位置',
                高频启动应用: '高频启动应用',
                框架软件: '框架软件',
                风险应用: '风险应用',
                内存篡改: '内存篡改',
                根证书异常: '根证书异常',
                USB调试: 'USB调试',
                'ROOT/越狱': 'ROOT/越狱',
                允许模拟位置: '允许模拟位置',
                模拟器: '模拟器',
                定制ROM: '定制ROM',
                设备伪造: '设备伪造',
                锁屏密码未开启: '锁屏密码未开启',
                系统时间异常: '系统时间异常',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                云手机: '云手机',
                风险IP: '风险IP',
            },
            LevelOptions: { // 行为级别
                行为级别: '行为级别',
                低: '低',
                中: '中',
                高: '高',
            },
            Tablelinkage: { // 表格联动
                设备名称: '设备名称',
                行为类型: '行为类型',
                行为级别: '行为级别',
                应用名称: '应用名称',
                应用版本: '应用版本',
                系统版本: '系统版本',
                位置: '位置',
                经纬度: '经纬度',
                上网方式: '上网方式',
                外网IP: '外网IP',
                内网IP: '内网IP',
                'WIFI-SSID': 'WIFI-SSID',
                'WIFI-MAC': 'WIFI-MAC',
                用户名: '用户名',
                姓名: '姓名',
                部门: '部门',
                产生时间: '产生时间',
                上报时间: '上报时间',
                操作: '操作',
            },

            Tall: '高',
            Centre: '中',
            Low: '低',
            AppInfor: '应用信息',
            SdkVersion: '沙箱SDK版本',
            AppPackageName: '应用包名',
            AppMD5: '应用MD5',
            BasicInformation: '基本信息',
            EquipmentModel: "设备型号",
            DeviceIP: '设备IP',
            UserDepartment: '用户部门',
            EquipmentIMEI: '设备IMEI',
            PositionInfor: '位置信息',
            Country: '国家',
            City: '城市',
            Province: '省份',
            Address: '详细地址',
            RiskInfo: '风险信息',
            ResponseAction: "响应动作",
            BehaviorLevel: '行为级别',
            JudgmentSteps: '研判步骤',
            BeiJing: "北京",
            TianJin: "天津",
            ShangHai: "上海",
            ChongQing: "重庆",
            Beyondbord: "境外",
        },
        // 敏感行为
        Sensitivebehavior: {
            TypeOptions: { //行为类型
                行为类型: '行为类型', //行为类型
                应用敏感行为: '应用敏感行为', //应用敏感行为
                应用越权行为: '应用越权行为', //应用越权行为
            },
            LevelOptions: { //行为级别
                行为级别: '行为级别', //行为级别
                高: '高', //高
                中: '中', //中
                低: '低', //低
            },
            DevNameOptions: {
                应用名称: '应用名称', //应用名称
                应用版本: '应用版本', //应用版本
                设备名称: '设备名称', //设备名称
                姓名: '姓名', //姓名
                用户名: '用户名', //用户名
                部门: '部门', //部门
            },
            //表格联动
            Tablelinkage: {
                设备名称: '设备名称', //设备名称
                行为类型: '行为类型', //行为类型
                行为级别: '行为级别', //行为级别
                应用名称: '应用名称', //应用名称
                应用版本: '应用版本', //应用版本
                系统版本: '系统版本', //系统版本
                位置: '位置', //位置
                经纬度: '经纬度', //经纬度
                上网方式: '上网方式', //上网方式
                外网IP: '外网IP', //外网IP
                内网IP: '内网IP', //内网IP
                'WIFI-SSID': 'WIFI-SSID', //'WIFI-SSID'
                'WIFI-MAC': 'WIFI-MAC', //'WIFI-MAC'
                用户名: '用户名', //用户名
                姓名: '姓名', //姓名
                部门: '部门', //部门
                产生时间: '产生时间', //产生时间
                上报时间: '上报时间', //上报时间
                操作: '操作', //操作
            },
            BeiJing: "北京", //"北京"
            TianJin: "天津", //"天津"
            ShangHai: "上海", //"上海"
            ChongQing: "重庆", //"重庆"
            CheckFile: '查看文件', //查看文件
            AppInfo: '应用信息', //应用信息
            SdkVersion: '沙箱SDK版本', //沙箱SDK版本
            AppPackageName: '应用包名', //应用包名
            AppMD5: '应用MD5', //应用MD5
            BasicInfo: '基本信息', //基本信息
            EquipmentModel: '设备型号', //设备型号
            UserDepartment: '用户部门', //用户部门
            DeviceManufacturer: '设备厂商', //设备厂商
            ComStatus: '合规状态', //合规状态
            EquipmentIMEI: '设备IMEI', //设备IMEI
            DeviceIP: '设备IP', //设备IP
            PositionInfor: '位置信息', //基本信息
            Country: '国家', //国家
            Province: '省份', //省份
            City: '城市', //城市
            Address: '详细地址', //详细地址
            RiskInfo: '风险信息', //风险信息
            BehaviorLevel: '行为级别', //行为级别
            High: '高', //高
            Middle: '中', //中
            Low: '低', //低
            ResponseAction: '响应动作', //响应动作
            JudgmentSteps: '研判步骤', //研判步骤
        },
        // 访问行为
        Accessbe: {
            AccessResults: "访问结果",
            AccessResu: { // 访问结果
                访问结果: '访问结果',
                成功: '成功',
                失败: '失败',
            },
            Tablelinkage: { // 表格联动
                用户名: '用户名',
                姓名: '姓名',
                部门: '部门',
                设备名称: '设备名称',
                源设备地址: '源设备地址',
                源设备ID: '源设备ID',
                应用名称: '应用名称',
                应用包名: '应用包名',
                网关名称: '网关名称',
                网关地址: '网关地址',
                网关端口: '网关端口',
                资源名称: '资源名称',
                资源地址: '资源地址',
                资源端口: '资源端口',
                访问结果: '访问结果',
                访问时间: '访问时间',
                操作: '操作',
            },
            Sourcedevice: '源设备地址',
            SourceID: '源设备ID',
            AppPackageName: '应用包名',
            GatewayName: '网关名称',
            GatewayAddress: '网关地址',
            Gatewayport: '网关端口',
            ResourceName: '资源名称',
            ResourceAddress: '资源地址',
            ResourcePort: '资源端口',
            Success: '成功',
            Fail: '失败',
            Accessedtime: '访问时间',
            BusinessActive: "正常访问",
            Allbusiness: "全部业务",
            SelectAStartTime: '请选择开始时间', //请选择开始时间
            SelectAEndTime: '请选择结束时间', //请选择结束时间
            EndnoStart: '结束时间不能小于开始时间', //请选择结束时间
            AppInfor: '应用信息',
            AppPackageName: '应用包名',
            BasicInformation: '基本信息',
            UserDepartment: '用户部门',
            DeviceIP: '设备IP',
            EquipmentModel: "设备型号",
            EquipmentIMEI: '设备IMEI',
            Accessinfor: '访问信息',
        },
        //设备事件 v6.3.2新增设备事件以列表形式展示设备安全事件信息和事件详情
        DeviceEvents: {
            SelectDate: '选择日期',
            EventName: '事件类型', //默认选择事件类型
            TypeOptions: {
                事件类型: '事件类型',//默认选择事件类型
                长期离线: '长期离线', //长期离线
            },
            eventOptions: {
                事件级别: '事件级别', //事件级别默认选择事件级别
                高: '高', //高
                中: '中', //中
                低: '低', //低
            },
            //表格联动
            Tablelinkage: {
                设备名称: '设备名称', //设备名称
                事件类型: '事件类型', //事件类型
                事件级别: '事件级别', //事件级别
                系统版本: '系统版本', //系统版本
                位置: '位置', //位置
                经纬度: '经纬度', //经纬度
                上网方式: '上网方式', //上网方式
                外网IP: '外网IP', //外网IP
                内网IP: '内网IP', //内网IP
                'WIFI-SSID': 'WIFI-SSID', //'WIFI-SSID'
                'WIFI-MAC': 'WIFI-MAC', //'WIFI-MAC'
                用户名: '用户名', //用户名
                姓名: '姓名', //姓名
                部门: '部门', //部门
                产生时间: '产生时间', //产生时间
                操作: '操作', //操作
            },
            Tall: '高',
            Centre: '中',
            Low: '低',
            AppInfor: '应用信息',
            SdkVersion: '沙箱SDK版本',
            AppPackageName: '应用包名',
            AppMD5: '应用MD5',
            BasicInformation: '基本信息',
            EquipmentModel: "设备型号",
            DeviceIP: '设备IP',
            UserDepartment: '用户部门',
            EquipmentIMEI: '设备IMEI',
            PositionInfor: '位置信息',
            Country: '国家',
            City: '城市',
            Province: '省份',
            Address: '详细地址',
            RiskInfo: '风险信息',
            ResponseAction: "响应动作",
            JudgmentSteps: '研判步骤',
            ArrResuly: [ // 详情---响应动作
            { value: '0', label: '告警日志' },
            { value: '1', label: '告警提示' },
            { value: '2', label: '清除数据' },
            { value: '3', label: '强制注销' },
            { value: '4', label: '停用设备' },
            { value: '5', label: '恢复出厂' },
            { value: '6', label: '禁止访问' },
            { value: '7', label: '锁定' }
        ],
        },
        // 安全事件
        SafetyEvent: {
            EventName: '事件名称', //事件名称
            levelOptions: {
                行为级别: '行为级别', //行为级别
                高: '高', //高
                中: '中', //中
                低: '低', //低
            },
            //表格联动
            Tablelinkage: {
                设备名称: '设备名称', //设备名称
                事件名称: '事件名称', //事件名称
                行为级别: '行为级别', //行为级别
                应用名称: '应用名称', //应用名称
                应用版本: '应用版本', //应用版本
                系统版本: '系统版本', //系统版本
                位置: '位置', //位置
                经纬度: '经纬度', //经纬度
                上网方式: '上网方式', //上网方式
                外网IP: '外网IP', //外网IP
                内网IP: '内网IP', //内网IP
                'WIFI-SSID': 'WIFI-SSID', //'WIFI-SSID'
                'WIFI-MAC': 'WIFI-MAC', //'WIFI-MAC'
                用户名: '用户名', //用户名
                姓名: '姓名', //姓名
                部门: '部门', //部门
                产生时间: '产生时间', //产生时间
                封禁时间: '封禁时间', //封禁时间
                上报时间: '上报时间', //上报时间
                操作: '操作', //操作
            },
            BanTime: '封禁时间', //封禁时间
            EventRule: '事件规则', //事件规则
            BlockingMode: '封禁方式', // 封禁方式
        },
        //SDK行为
        SDKBehaviorPage: {
            dataPlaceholder: '选择日期', //选择日期
            selePlaceholder: '请选择', //请选择
            locPlaceholder: '位置', //位置
            //行为类型
            BehaviorType: {
                '行为类型': '行为类型', //'行为类型'
                '读取短信记录': '读取短信记录', //'读取短信记录'
                '读取通讯录': '读取通讯录', //'读取通讯录'
                '获取地理位置': '获取地理位置', //'获取地理位置'
                '读取外部存储': '读取外部存储', //'读取外部存储'
                '获取设备硬件信息': '获取设备硬件信息', //'获取设备硬件信息'
                '获取应用安装列表': '获取应用安装列表', //'获取应用安装列表'
                '拍照、摄像': '拍照、摄像', //'行为类型'
                '开启WIFI': '开启WIFI', //'行为类型'
                '开启蓝牙': '开启蓝牙', //'行为类型'
            },
            //查询下拉框
            SearchName: {
                设备名称: '设备名称', //设备名称
                SDK名称: 'SDK名称', //SDK名称
                应用名称: '应用名称', //应用名称
                应用版本: '应用版本', //应用版本
                用户名: '用户名', //用户名
                姓名: '姓名', //姓名
                部门: '部门', //部门
                系统版本: '系统版本', //系统版本
                外网IP: '外网IP', //外网IP
            },
            //表格联动
            Tablelinkage: {
                设备名称: '设备名称', //设备名称
                SDK名称: 'SDK名称', //SDK名称
                行为类型: '行为类型', //行为类型
                应用名称: '应用名称', //应用名称
                应用版本: '应用版本', //应用版本
                系统版本: '系统版本', //系统版本
                位置: '位置', //位置
                经纬度: '经纬度', //经纬度
                上网方式: '上网方式', //上网方式
                外网IP: '外网IP', //外网IP
                内网IP: '内网IP', //内网IP
                'WIFI-SSID': 'WIFI-SSID', //'WIFI-SSID'
                'WIFI-MAC': 'WIFI-MAC', //'WIFI-MAC'
                用户名: '用户名', //用户名
                姓名: '姓名', //姓名
                部门: '部门', //部门
                产生时间: '产生时间', //产生时间
                上报时间: '上报时间', //上报时间
                操作: '操作', //操作
            },
            DeviceName: '设备名称', //设备名称
            SDKName: 'SDK名称', //SDK名称
            Behaviortype: '行为类型', //行为类型
            appName: '应用名称', //应用名称
            AppVersion: '应用版本', //应用版本
            systemversion: '系统版本', //系统版本
            position: '位置', //位置
            latitudeLongitude: '经纬度', //经纬度
            InternetMethod: '上网方式', //上网方式
            ExternalIP: '外网IP', //外网IP
            IntranetIP: '内网IP', //内网IP
            WIFISSID: 'WIFI-SSID', //'WIFI-SSID'
            WIFIMAC: 'WIFI-MAC', //'WIFI-MAC'
            userName: '用户名', //用户名
            fullName: '姓名', //姓名
            department: '部门', //部门
            generationTime: '产生时间', //产生时间
            ReportingTime: '上报时间', //上报时间
            details: '详情', //详情
            AppInfor: '应用信息', //应用信息
            AppPackageName: '应用包名', //应用包名
            AppMD5: '应用MD5', //应用MD5
            BasicInfor: '基本信息', //基本信息
            EquipmentModel: '设备型号', //设备型号
            UserDepartment: '用户部门', //用户部门
            deviceManufacturer: '设备厂商', //设备厂商
            ComStatus: '合规状态', //合规状态
            EquipmentIMEI: '设备IMEI', //设备IMEI
            DeviceIP: '设备IP', //设备IP
            positionInfor: '位置信息', //基本信息
            country: '国家', //国家
            province: '省份', //省份
            city: '城市', //城市
            Address: '详细地址', //详细地址
            riskInfor: '风险信息', //风险信息
            BehaviorLevel: '行为级别', //行为级别
            tall: '高', //高
            centre: '中', //中
            low: '低', //低
            JudgmentSteps: '研判步骤', //研判步骤
        },
        // 病毒信息
        VirusInfo: {
            VirusesCount: '病毒应用数量', //病毒应用数量
            VirusInformation: '病毒信息', //病毒信息
            DetailedInfo: '详情信息', //详情信息
            NoVirus: '暂未扫描到病毒', // 暂未扫描到病毒
            //表格联动
            Tablelinkage: {
                设备名称: '设备名称', //设备名称
                病毒应用数量: '病毒应用数量', //病毒应用数量
                系统版本: '系统版本', //系统版本
                位置: '位置', //位置
                经纬度: '经纬度', //经纬度
                上网方式: '上网方式', //上网方式
                外网IP: '外网IP', //外网IP
                内网IP: '内网IP', //内网IP
                'WIFI-SSID': 'WIFI-SSID', //'WIFI-SSID'
                'WIFI-MAC': 'WIFI-MAC', //'WIFI-MAC'
                用户名: '用户名', //用户名
                姓名: '姓名', //姓名
                部门: '部门', //部门
                产生时间: '产生时间', //产生时间
                上报时间: '上报时间', //上报时间
                操作: '操作', //操作
            },
        },
        // 封禁行为
        Prohibited: {
            Tablelinkage: { // 表格联动
                设备名称: '设备名称',
                封禁类型: '封禁类型',
                应用名称: '应用名称',
                应用版本: '应用版本',
                系统版本: '系统版本',
                位置: '位置',
                经纬度: '经纬度',
                上网方式: '上网方式',
                外网IP: '外网IP',
                内网IP: '内网IP',
                'WIFI-SSID': 'WIFI-SSID',
                'WIFI-MAC': 'WIFI-MAC',
                用户名: '用户名',
                姓名: '姓名',
                部门: '部门',
                封禁时间: '封禁时间',
                上报时间: '上报时间',
                操作: '操作',
            },
            Prohibitiontype: "封禁类型",
            Equipmentlockdown: "设备封禁",
            BanTime: '封禁时间',
            AppInfor: '应用信息',
            SdkVersion: '沙箱SDK版本',
            AppPackageName: '应用包名',
            AppMD5: '应用MD5',
            BasicInformation: '基本信息',
            EquipmentModel: "设备型号",
            DeviceIP: '设备IP',
            UserDepartment: '用户部门',
            EquipmentIMEI: '设备IMEI',
            PositionInfor: '位置信息',
            Country: '国家',
            City: '城市',
            Province: '省份',
            Address: '详细地址',
            Blockedinfor: '封禁信息',
            Hitstrategy: '命中策略',
            JudgmentSteps: '研判步骤',
            Beyondbord: "境外",
        },
        //风险IP
        DangerIPPage: {
            dataPlaceholder: '选择日期', //选择日期
            selePlaceholder: '请选择', //请选择
            typePlaceholder: 'IP类型', //IP类型
            inputPlaceholder: '请输入IP地址', //请输入IP地址
            // IP类型
            typeData: {
                IP类型: 'IP类型', //IP类型
                爆破IP: '爆破IP', //爆破IP
                威胁IP: '威胁IP', //威胁IP
                违规IP: '违规IP', //违规IP
                自定义IP: '自定义IP', //自定义IP
            },
            IPAddress: "IP地址", //IP地址
            IPType: "IP类型", //IP类型
            ExplosionIP: '爆破IP', //爆破IP
            ThreatIP: '威胁IP', //威胁IP
            IllegalIP: '违规IP', //违规IP
            custom: '自定义', //自定义IP
            lockTime: '锁定时间', //锁定时间
            state: '状态', //状态
            IPReg: '请输入正确的IP地址,如：192.168.0.1', //'请输入正确的IP地址,如：192.168.0.1'?
            IPVoid: '请输入IP', //请输入IP
            sureDeleteSeleDangerIP: '确定删除选中IP地址?', //确定删除选中IP地址?
        },
        //运行信息
        RunInfoPage: {
            dataPlaceholder: '选择日期', //选择日期
            selePlaceholder: '请选择', //请选择
            //表格联动
            Tablelinkage: {
                应用名称: '应用名称', //应用名称
                应用版本: '应用版本', //应用版本
                设备名称: '设备名称', //设备名称
                用户名: '用户名', //用户名
                部门: '部门', //部门
                启动次数: '启动次数', //启动次数
                使用时长: '使用时长', //使用时长
                使用流量: '使用流量', //使用流量
                上报时间: '上报时间', //上报时间
                操作: '操作', //操作
            },
            devNameData: {
                应用名称: "应用名称", //应用名称
                设备名称: "设备名称", //设备名称
                用户名: "用户名", //用户名
                部门: "部门", //部门
            },
            details: '详情', //详情
            appName: '应用名称', //应用名称
            appVersion: '应用版本', //应用版本
            deviceName: '设备名称', //设备名称
            userName: '用户名', //用户名
            department: '部门', //部门
            numberStarts: '启动次数', //启动次数
            UsageDuration: '使用时长', //使用时长
            UsingTraffic: '使用流量', //使用流量
            ReportingTime: '上报时间', //上报时间
            appName: '应用名称', //应用名称
            AppVersion: '应用版本', //应用版本
            AppInfor: '应用信息', //应用信息
            AppPackageName: '应用包名', //应用包名
            AppMD5: '应用MD5', //应用MD5
            BasicInfor: '基本信息', //基本信息
            EquipmentModel: '设备型号', //设备型号
            UserDepartment: '用户部门', //用户部门
            deviceManufacturer: '设备厂商', //设备厂商
            ComStatus: '合规状态', //合规状态
            EquipmentIMEI: '设备IMEI', //设备IMEI
            DeviceIP: '设备IP', //设备IP
            systemversion: '系统版本', //系统版本
            userName: '用户名', //用户名
            fullName: '姓名', //姓名
            InternetMethod: '上网方式', //上网方式
            positionInfor: '位置信息', //基本信息
            country: '国家', //国家
            province: '省份', //省份
            city: '城市', //城市
            latitudeLongitude: '经纬度', //经纬度
            Address: '详细地址', //详细地址
            RunInfo: '运行信息', //运行信息
            generationTime: '产生时间', //产生时间
        },
    },
    //移动运维
    MobileOperations: {
        //工单管理
        WorkOderManagement: {
            WorkOrderList: { //工单管理表格
                WorkOrderNumber: '工单编号',
                ProblemName: '问题名称',
                status: '状态',
                SubmissionTime: '提交时间',
                UpdateTime: '更新时间',
                //处理、详情 弹框------公用
                ProblemDescription: '问题描述：',
                picture: '图片：',
                HandlingSuggestion: '处理意见：',
                ProblemNameContent: '问题名称：',
                ApplicationName: '应用名称：',
                PleaseEnterContent: '请输入内容',
                PleaseDescriptionOfTheProblem: '请输入问题描述',
                SubmitSuccessfully: '提交成功',
                WorkOrderDetails: '工单详情',
                WorkOrderStatus: '工单状态',
                handle: '处理',
                details: '详情',
                ToBeProcessed: '待处理',
                processed: '已处理',

            }
        },
        //用户帮助配置 
        UserHelpConfiguration: {
            option: {
                标题: '标题',
                应用名称: '应用名称',
            },
            UserHelpList: { //用户帮助表格
                title: '标题',
                creator: '创建者',
                UpdateTime: '更新时间',
                DELMessage: '确定删除选中的数据?',
                SaveMessage: '保存失败，帮助标题已存在',
                ReleaseSuccess: '发布成功',
            },
            AddUserHelp: { //新增
                SelectiveApplication: '选择应用：',
                PleaseSelectiveApplication: "请选择应用",
                HelpTitle: '帮助标题：',
                HelpContent: '帮助内容：',
                publish: '发布',
                Cancel: '取消',
            }
        },
        // 崩溃信息
        CrashInfor: {
            SelectDate: '选择日期',
            Tablelinkage: { // 表格联动
                设备名称: '设备名称',
                崩溃类型: '崩溃类型',
                应用名称: '应用名称',
                应用版本: '应用版本',
                系统版本: '系统版本',
                位置: '位置',
                经纬度: '经纬度',
                上网方式: '上网方式',
                外网IP: '外网IP',
                内网IP: '内网IP',
                'WIFI-SSID': 'WIFI-SSID',
                'WIFI-MAC': 'WIFI-MAC',
                用户名: '用户名',
                姓名: '姓名',
                部门: '部门',
                产生时间: '产生时间',
                上报时间: '上报时间',
                操作: '操作',
            },
            Crashtype: '崩溃类型',
            AppInfor: '应用信息',
            SdkVersion: '沙箱SDK版本',
            AppPackageName: '应用包名',
            AppMD5: '应用MD5',
            BasicInformation: '基本信息',
            EquipmentModel: "设备型号",
            DeviceIP: '设备IP',
            UserDepartment: '用户部门',
            EquipmentIMEI: '设备IMEI',
            PositionInfor: '位置信息',
            Country: '国家',
            City: '城市',
            Province: '省份',
            Address: '详细地址',
            CrashInfo: '崩溃信息',
            CrashContent: "崩溃内容",
            BeiJing: "北京",
            TianJin: "天津",
            ShangHai: "上海",
            ChongQing: "重庆",
            Beyondbord: "境外",
        },
        ClientLogs: {
            Status: '状态',
            Extracting: '提取中',
            Extracted: '已提取',
            ExtractionFailed: '提取失败',
            CreationTime: '创建时间',
            placeholder: '请输入设备名称',
            MessagePort: '确定删除选中的记录?'
        }
    },
    //报表管理
    ReportManagement: {
        DataReport: { //数据报表
            Placeholder: '请输入名称',
            Name: '名称',
            TypesOf: '类型',
            GenerationTime: '生成时间',
        },
        CreateReport: { //创建报表
            Placeholder: '请输入报表名称',
            ReportName: '报表名称',
            ReportType: '报表类型',
            ReportContent: '报表内容',
            Cycle: '周期',
            Single: '单次',
            Moon: '月',
            //报表内容字段对应
            deviceName: '设备名称',
            userName: '用户名',
            groupFullName: '部门',
            osVersion: '操作系统',
            violationCount: '违规行为数',
            dangerCount: '威胁行为数',
            sensitiveCount: '敏感行为数',
            eventCount: '安全事件数',
            sdkCount: 'SDK行为数',
            appName: '应用名称',
            appVersion: '应用版本',
            type: '类型',
            dangerType: '威胁类型',
            level: '行为级别',
            district: '位置',
            netType: '上网方式',
            outIp: '外网IP',
            ip: '内网IP',
            wifiSsid: 'WIFI-SSID',
            wifiMac: 'WIFI-MAC',
            time: '产生时间',
            createTime: '上报时间',
            DescMessage1: '风险类型，包括：违规行为、威胁行为、敏感行为、安全事件、SDK行为',
            DescMessage2: '威胁的具体行为类型，如注入攻击、框架攻击、调试行为、设备越狱、违规外联等',
            DescMessage3: '威胁客户端发生时间',
            DescMessage4: '威胁上报服务器时间'
        },
        reportType1: '设备威胁报表',
        reportType2: '应用威胁报表',
        reportType3: '威胁明细报表',
        reportType4: '运营报告',
        // 提示消息
        Error1: '请选择开始时间',
        Error2: '请选择结束时间',
        Error3: '开始时间不能大于结束时间',
        Error4: '保存失败，报表名称已存在',
        Error5: '报表名称不能为空',
        Error6: '请选择报表内容',
        Error7: '请输入报表名称',
        CheckDel2: '确定删除选中的数据报表?',
        CheckDel3: '修改成功',
        CheckDel4: '新增成功',
        CheckDel5: '确定删当前报表模板?',
        CheckDel6: '确定删除选中的应用?',
    },
    //访问管理
    AccessConfiguration: {
        //网关配置
        GatewayConfig: {
            Placeholder: '请输入网关名称',
            NewGatewayBtn: '新增',
            GatewayName: '网关名称',
            GatewayAddress: '网关地址',
            Protected: '保护的业务资源',
            ModificationTime: '修改时间',
            GatewayAssocia: '网关关联',
            SocialChat: '社交聊天',
            OfficeBusiness: '办公商务',
            Deletgateway: '确定删除选中的网关资源配置?',
            Placepost: '请输入端口',
            DayMaxvalue: '日请求流量最大值',
            individual: '个',
            Maxconnect: '最大并发连接数',
            ResourceName: '资源名称',
            ProtocolType: '协议类型',
            VirtualAddress: '虚拟地址',
            RealAddress: '真实地址',
            AddResources: '添加资源',
            Resourcelist: '资源列表',
            Placeholders: '请输入资源名称',
            DevNameOptions: {
                域名: '域名',
                IP: 'IP',
            },
            Onlysupports: '仅支持输入1-99999正整数',
            Address: '地址',
            PleaseIP: '请输入IP地址,如：192.168.0.1',
            Domainname: '域名',
            PleaseDomain: '请输入域名，如：www.baidu.com',
            PostNumber: '端口为1-65535的整数',
            PlaceService: '请选择业务资源',
            Association: '关联策略',
            PlacesAss: '请输入策略名称',
            Policyname: '策略名称',
            Common: '共',
            Records: '条记录',
        },
        // 业务资源
        ServiceResourceManagement: {
            documentList: '资源列表：',
            DownTemplate: '下载模板', //下载模板
            ImportSuccessfully: '导入资源管理成功',
            message: "导入资源模板有误",
            NewResource: '新增', //新增资源
            ProtocolType: '协议类型', //协议类型
            VirtualAddress: '虚拟地址', //虚拟地址
            RealAddress: '真实地址', //真实地址
            DomainName: '域名',
            ResRelation: '资源关联', //资源关联
            AssociatedGateway: '关联网关', //关联网关
            ResourceAddress: '资源地址', //资源地址
            GatewayName: '网关名称',
            GatewayAddress: '网关地址',
            CheckDel: '确定删除选中资源?', //确定删除选中资源？
            Placeholder: '请输入资源名称', //请输入资源名称
            dialogPlaceholder: '请输入网关名称', //请输入网关名称
            PleaseSelect: '请选择', //请选择
            DomainPlaceholder: '请输入域名', //请输入域名
            VirtualPlaceholder: '请输入虚拟IP地址', //请输入虚拟IP地址
            PleaseEnter: '请输入', //请输入
            IPSection: 'IP段', //IP段
            IPAddress: 'IP地址', // IP地址
            Address1: '地址，如：192.168.0.1', //地址，如：192.168.0.1
            Address2: '，如：www.baidu.com', //，如：www.baidu.com
            IPPlaceholder: '请输入IP地址', // 请输入IP地址
            ResPortPlaceholder: '请输入资源端口', //请输入资源端口
            OnlyPositiveInteger: '仅允许输入正整数', // 仅允许输入正整数
            NumericRange: '数值范围：1-65535', // 数值范围：1-65535
            EnterCorrectIP: '请输入正确的IP地址范围', // 请输入正确的IP地址范围
            ErrorIPScope: 'IP地址范围格式错误', // IP地址范围格式错误
            Nonsupport: '不支持纯数字的域名，如：www.baidu.com', // 不支持纯数字的域名，如：www.baidu.com
            IPScope: '请输入IP地址范围，如：192.168.0.1 ~ 192.168.255.254', //请输入IP地址范围，如：192.168.0.1 ~ 192.168.255.254
            ErrorIPAddress: '前一个IP地址不能大于等于后一个IP地址，如：192.168.0.1 ~ 192.168.255.254', //前一个IP地址不能大于等于后一个IP地址，如：192.168.0.1 ~ 192.168.255.254
            placeholderResIp: '真实IP地址', //真实IP地址
            DomainAddress: '域名地址', // 域名地址
            EnterRealIP: '请输入真实地址,如：192.168.0.1', //请输入真实地址,如：192.168.0.1
            AgTypeValue: '请选择协议类型', // 请选择协议类型
            EnterVirtual: '请输入虚拟地址,如：192.168.0.1', // 请输入虚拟地址,如：192.168.0.1
            PortRange: '端口为1-65535的整数', // 端口为1-65535的整数
            Total: '共', //共
            Records: '条记录', // 条记录
            NameLength: '最多支持输入30个字符', // 最多支持输入30个字符
            DomainEnter: '请输入域名，如：www.baidu.com', //请输入域名，如：www.baidu.com

        },
        VirusInfo: "病毒信息",
        SelfServiceDesk: "自助服务台",
        equipmentManagement: "设备管理",
    },
    System: {
        //Android配置
        AndroidConfig: {
            Title: '选择应用',
            Application: '应用',
            UpdateTime: '更新时间',
            VersionNumber: '版本号',
            DownloadApk: '下载apk',
            SelectFile: '选择文件',
            SelectApp: '选择应用',
            ForceUpdates: '是否强制更新',
            MessagePort: '强制用户更新版本后才能进入应用',
            RunSetup: '运行配置',
            placeholder: '请输入应用名称',
            RunningStatus: '配置安全桌面运行状态',
            Configuration: '采集配置',
            CheckBoxmessage1: '设置为默认桌面(合作厂商)',
            CheckBoxmessage2: '保持始终运行(合作厂商)',
            CheckBoxmessage3: '仅支持卸载码卸载(合作厂商)',
            CheckBoxmessage4: '分钟，5～1440的整数',
            DeviceInformation: '设备信息：',
            BasicInformation: '设备基本信息',
            BehaviorInformation: '设备行为信息',
            BrMessage1: '电池电量、内存使用、储存使用、',
            BrMessage2: '客户端版本、设备厂商、设备型',
            BrMessage3: '号、系统版本、上网方式、手机号',
            BrMessage4: '码、SDK版本、内核版本等设备基',
            BrMessage5: '本信息',
            BrMessage6: '电话、短信、网络访问',
            BrMessage7: '记录信息',
            CollectionCycle: '采集周期',
            Error: '名称不支持特殊字符',
            Error1: '应用未签名，上传失败！',
            Error2: '当前限制文件大小不能大于500M',
            Error3: '文件格式错误',
            Error4: '请输入5～1440的整数',
            CheckDel: '请选择应用',
            //安全桌面
            message: '消息通知',
            document: "文档内容",
            store: "应用商店",
            help: "使用帮助",
            settings: "个人设置",
            policy: "安全策略",
            score: "健康评分",
            access: "安全访问",
            browser: "安全浏览器",
            switchX: "切换桌面",
            appName: "应用名称",
            appID: "应用ID",
            otherApp: '其他应用：',
            SecureDesktops: {
                '消息通知': '消息通知',
                '文档内容': '文档内容',
                '应用商店': '应用商店',
                '使用帮助': '使用帮助',
                '个人设置': '个人设置',
                '安全策略': '安全策略',
                '健康评分': '健康评分',
                '安全访问': '安全访问',
                '安全浏览器': '安全浏览器',
                '切换桌面': '切换桌面',
            }
        },
        //IOS配置
        IosConfig: {
            UpdateTime: '更新时间',
            VersionNumber: '版本号',
            Downloadipa: '下载ipa',
            SelectFile: '选择文件',
            SelectApp: '选择应用',
            ForceUpdates: '是否强制更新',
            DescriptionFiles: '描述文件',
            MDM: 'MDM证书',
            CollectConfiguration: '采集配置',
            SecureDesktop: '安全桌面',
            AbmTokens: 'ABM令牌',
            AbmConfigs: 'ABM配置',
            MessagePort: '强制用户更新版本后才能进入应用',
            //描述文件
            DescriptionFile: {
                organization: '管理厂商名称',
                topic: 'MDMTopic值',
                url: "URL（https）",
                scepPayloadIdentifier: '文件标识',
                accessRights: 'MDM权限',
                checkOutWhenRemoved: '删除时CheckIn',
                yes: '是',
                rules: {
                    organization: '请输入管理厂商名称',
                    topic: '请输入MDMTopic值',
                    url: '请输入URL（https）',
                    scepPayloadIdentifier: '请输入文件标识',
                    accessRights: '请输入MDM权限'
                },
                //安全桌面
                message: '消息通知',
                document: "文档内容",
                store: "应用商店",
                help: "使用帮助",
                settings: "个人设置",
                policy: "安全策略",
                score: "健康评分",
                access: "安全访问",
                browser: "安全浏览器",
                switchX: "切换桌面",
            },
            //MDM证书
            MdmCertificate: {
                CertificateName: '证书名称',
                CertificateDesc: '证书简介',
                CertificateSpan: '用于唤醒苹果设备，与服务器通信',
                DowlonadCertificate: '下载证书',
                UploadCertificate: '上传证书',
                MDMCertificate: 'MDM证书',
                SelectFile: '选择文件',
                Password: '密码',
                CheckDel: '如密码无变化，保持该项为空',
                organizationVoid: '请输入管理厂商名称',//请输入管理厂商名称
                topicVoid: '请输入MDMTopic值',//请输入MDMTopic值
                urlVoid: '请输入URL（https）',//请输入URL（https）
                scepPayloadVoid: '请输入文件标识',//请输入文件标识
                accessRightsVoid: '请输入MDM权限',//请输入MDM权限
            },
            //采集配置
            ColleConfig: {
                DeviceInformation: '设备信息',
                BasicInformation: '设备基本信息',
                BrMessage1: '电池电量、内存使用、储存使用、',
                BrMessage2: '客户端版本、设备厂商、设备型',
                BrMessage3: '号、系统版本、上网方式、手机号',
                BrMessage4: '码、SDK版本、内核版本等设备基',
                BrMessage5: '本信息',
                CollectionCycle: '采集周期',
                CheckBoxmessage4: '分钟，5～1440的整数',
                Error: '请输入5～1440的整数'
            },
            //abm令牌
            AbmToken: {
                DowlonadCertificate: '下载证书',
                PublicCertificate: '公钥证书',
                UploadToken: '上传令牌',
                SelectFile: '选择文件',
                TokenValidityPeriod: '令牌有效期',
                Error: '请上传文件',
                Error1: '保存失败',
                Error2: '定义描述文件认证失败',
            },
            //abm配置
            AbmConfig: {
                RegisterProfile: '注册配置文件地址',
                ConfigurationFile: '填写MDM服务器配置文件下载地址',
                DownloadAddress: '请输入下载地址',
                AlloDeletion: '允许删除注册配置文件',
                DeletingRegistration: 'ABM设备删除注册配置文件后，将脱离',
                EnterpriseControl: '企业管控',
                AllowDevice: '允许设备配对',
                AllowABMregistered: '允许ABM注册的设备通过Apple Music和Apple Configurator 方式与其他设备',
                PairedSuccessfully: '配对，配对成功可能造成数据传输、数据被读取拷贝风险',
                SetAssistant: '设置助理',
                SelectSetAssistant: '选取在“设置助理”过程中跳过的步骤，',
                OnlyABMcontrolled: '仅支持ABM管控设备',
                ChangeCheckbox: {
                    Location: '定位服务',
                    Appearance: '外观',
                    Zoom: '缩放',
                    ScreenTime: '屏幕使用时间',
                    Passcode: '密码',
                    SIMSetup: '设置蜂窝移动网络',
                    AppleID: 'Apple ID ',
                    Biometric: '触控ID/面容ID',
                    Siri: 'Siri',
                    AppStore: 'App Store',
                    Privacy: '隐私',
                    Diagnostics: 'App分析',
                    Payment: 'Apple Pay',
                    Restore: '备份恢复',
                    SoftwareUpdate: '保持设备为最新版本',
                    DeviceToDeviceMigration: '从iOS设备迁移',
                    Accessibility: '从安卓设备迁移',
                    WatchMigration: '手表迁移',
                    MessagingActivationUsingPhoneNumber: 'iMessage 信息',
                    iMessageAndFaceTime: 'iMessage 信息与 FaceTime通话',
                    Safety: '安全性',
                    TermsOfAddress: '地址条款',
                    TOS: '条款与条件',
                    UpdateCompleted: '更新已完成',
                    RestoreCompleted: '恢复已完成',
                    Welcome: '开始使用'
                },
                Error: '请输入内容',
                Error1: '最多输入200字符',
                Error2: '设置助理至少配置一项'
            },
            //安全配置
            SecureDesktops: {
                'Label': '内置应用：',
                '消息通知': '消息通知',
                '文档内容': '文档内容',
                '应用商店': '应用商店',
                '使用帮助': '使用帮助',
                '个人设置': '个人设置',
                '安全策略': '安全策略',
                '健康评分': '健康评分',
                '安全访问': '安全访问',
                '切换桌面': '切换桌面',
            },
            Error: '名称不支持特殊字符',
            Error2: '当前限制文件大小不能大于500M',
            Error3: '文件格式错误',
            CheckDel: '请选择应用',
        },
        // Windows配置
        Windowsconfiguration: {
            Application: '应用',
            Configuration: '采集配置',
            Downloadexe: '下载exe',
            Messageexe: 'esm.tar.gz',
            BrMessage1: '电池电量、存储空间、可用存储空',
            BrMessage2: '间、客户端版本、设备厂商、设备',
            BrMessage3: '型号、系统版本等设备基本信息',
        },
        // Linux配置
        LinuxConfig: {
            Application: '应用：',
            LinuxArmApp: 'ARM应用',
            LinuxX86app: 'X86应用',
            LinuxConfig: '采集配置',
            Downloadexe: '下载应用',
            Messageexe: 'esm.tar.gz',
            BrMessage1: '电池电量、存储空间、可用存储空',
            BrMessage2: '间、客户端版本、设备厂商、设备',
            BrMessage3: '型号、系统版本等设备基本信息',
        },
        //服务器地址
        httpUrlPage: {
            EMMHttpUrl: 'EMM服务地址：', //EMM服务地址
            AssignServiceURL: '分配服务URL', //分配服务URL
            AssignHeartbeatAddress: '分配心跳地址：', //分配心跳地址
            AssignHeartbeatPort: '分配心跳端口：', //分配心跳端口
            HttpUrlVoid: '请配置EMM服务地址 http或者https', //请配置EMM服务地址 http或者https
            HttpUrlReg: 'EMM服务地址必须是正确的服务器地址', //EMM服务地址必须是正确的服务器地址
            allotUrlVoid: '请配置分配服务URL http或者https', //请配置分配服务URL http或者https
            heartUrlVoid: '请输入分配心跳地址', //请输入分配心跳地址
            heartPortsVoid: '请输入分配心跳端口', //请输入分配心跳端口
        },
        //安全配置
        SecurityConfigPage: {
            LoginSecurity: "登录安全", //登录安全-------tab页
            PasswordComplexity: "密码复杂度：", //密码复杂度：
            PasswordComplexityHolder: "数字、字母、符号至少两种或以上元素", //数字、字母、符号至少两种或以上元素
            minPasswordLength: "密码最小长度：", //密码最小长度：
            DefaultPassword: "默认密码：", //默认密码：
            inputNumber: "0~999的整数，设置为0时，不做限制", //0~999的整数，设置为0时，不做限制
            NumberIncorrectPasswords: "错误密码次数：", //错误密码次数：
            UserLockTime: "用户锁定时间：", //用户锁定时间：
            UserLockPlaceholder: "0~1440的整数", //0~1440的整数
            ValidityPeriod: "平台登录状态有效时期：", //平台登录状态有效时期：
            ClientValidityPeriod: "客户端登录状态有效期：", //客户端登录状态有效期：
            UserBoundDevice: "单用户绑定设备数：", //单用户绑定设备数：
            PreInputDeviceLogin: "仅允许预录入设备登录：", //仅允许预录入设备登录
            SwitchUsers: "允许客户端切换用户：", //允许客户端切换用户：
            correct: "是", //是
            deny: "否", //否
            ProhibitRiskIPLogin: "禁止风险IP登录：", //禁止风险IP登录：
            order: "次", //次
            minute: '分钟', //分钟
            hour: "小时", //小时
            tower: '台', //台
            defaultPassVoid: '请输入默认密码', //请输入默认密码
            defaultPassReg: '8~32位，至少包含数字、字母、符号两种元素', //8~32位，至少包含数字、字母、符号两种元素
            errorNumberVoid: '请输入错误密码次数', //请输入错误密码次数
            lockTimeVoid: '请输入用户锁定时间', //请输入用户锁定时间
            serverTimeoutVoid: '请输入平台登录状态有效期', //请输入平台登录状态有效期
            clientTimeoutVoid: '请输入客户端登录状态有效期', //请输入客户端登录状态有效期
            devMaxCountsVoid: '请输入单用户绑定设备数', //请输入单用户绑定设备数
            errorNumberToast: '错误密码次数为0-999的整数', //错误密码次数为0-999的整数
            serverTimeoutToast: '平台登录状态有效期为0-999的整数', //平台登录状态有效期为0-999的整数
            clientTimeoutToast: '客户端登录状态有效期为0-999的整数', //客户端登录状态有效期为0-999的整数
            lockTimeToast: '用户锁定时间为0-1440的整数', //用户锁定时间为0-1440的整数
            devMaxCountsToast: '单用户绑定设备数为0-999的整数', //单用户绑定设备数为0-999的整数
            // 登录认证配置
            LoginAuthenticaConfig: "登录认证配置", //登录认证配置-------tab页
            AuthenticationType: "认证类型：", //认证类型：
            AccountSecretAuthentication: "账密认证", //账密认证
            AuthenticationMethods: "认证方式", //认证方式
            AuthenticationMethod: "认证方式：", //认证方式
            AuthenticationSource: "认证源：", //认证源
            AuthenticationMethodOne: "认证方式：", //认证方式1
            AuthenticationSourceOne: "认证源一：", //"认证源1：
            MSPPlatformAccountSecret: "MSP平台账密", //MSP平台账密
            AuthenticationSourceTwo: "认证源二：", //"认证源2：
            MainAuthenticationMethod: "主认证方式：", //"主认证认证方式：
            MasterAuthenticationSource: "主认证源：", //"主认证认证源：
            SecondaryAuthenticationMethod: "二次认证方式：", //"二次认证认证方式：
            SecondaryAuthenticationSource: "二次认证源：", //"二次认证认证源：
            CertificationRules: "认证规则：", //"认证规则
            RulesPlaceholder: "满足以下任意条件，登录时需要二次认证", //满足以下任意条件，登录时需要二次认证
            EveryLogin: "每次登录", //每次登录
            EveryLoginTooltipFront: "用户每次登录触发二次认证，适用", //用户每次登录触发二次认证，适用
            EveryLoginTooltipAfter: "于web端和客户端", //于web端和客户端
            FirstLogin: "用户首次登录", //用户首次登录
            FirstLoginFront: "用户第一次登录触发二次认证，适用于", //用户每次登录触发二次认证，适用
            FirstLoginAfter: "客户端", //于web端和客户端
            NewDeviceLogin: "新设备登录", //新设备登录
            NewDeviceLoginFront: "用户登录未注册过的新设备触发二次", //用户登录未注册过的新设备触发二次
            NewDeviceLoginAfter: "认证，适用于客户端", //认证，适用于客户端
            RiskIPLogin: "风险IP登录", //风险IP登录
            RiskIPLoginFront: "用户登录设备的IP地址被判定为风", //用户登录设备的IP地址被判定为风
            RiskIPLogincenter: '险IP地址触发二次认证，适用于web', //险IP地址触发二次认证，适用于web
            RiskIPLoginAfter: "端和客户端", //端和客户端
            AbnormalTimePeriod: "异常时间配置", //异常时间配置
            AbnormalTimePeriodLogin: "异常时间段登录", //异常时间段登录
            AbnormalTimePeriodLoginFront: "用户登录设备的时间范围处于异常时", //用户登录设备的时间范围处于异常时
            AbnormalTimePeriodLogincenter: '间段触发二次认证，适用于web端和客', //间段触发二次认证，适用于web端和客
            AbnormalTimePeriodLoginAfter: "户端", //户端
            ConfigAbnormalTimePeriod: "配置异常时间段", //配置异常时间段
            timeFrame: "时间范围：", //时间范围：
            PermanentlyValid: "永久有效", //永久有效
            custom: "自定义", //自定义
            SelectAStartTime: '请选择开始时间', //请选择开始时间
            SelectAEndTime: '请选择结束时间', //请选择结束时间
            cycleTime: "时间周期：", //时间周期：
            SelectTimeZone: "请选择时区", //请选择时区
            regularTime: "定时：", //定时：
            Monday: "星期一", //星期一
            Tuesday: "星期二", //星期二
            Wednesday: "星期三", //星期三
            Thursday: "星期四", //星期四
            Friday: "星期五", //星期五
            Saturday: "星期六", //星期六
            Sunday: "星期日", //星期日
            domainNameViod: "请输入正确的IP地址，如: 192.168.0.1", //请输入服务器域名
            domainNameReg: "请填写一个域名，如：cloud.tencent.com", //请填写一个域名，如：cloud.tencent.com
            ipViod: "请输入服务器地址", //请输入服务器地址
            portViod: "请输入端口", //请输入端口
            standardDNViod: "请输入基准DN", //请输入基准DN
            accountViod: "请输入管理员账户", //请输入管理员账户
            passCodeViod: "请输入管理员密码", //请输入管理员密码
            userObjTypeViod: "请输入用户对象类型", //请输入用户对象类型
            accountFieldViod: "请输入用户名字段名称", //请输入用户名字段名称
            passFieldViod: "请输入用户密码字段名称", //请输入用户密码字段名称
            addressViod: "请输入IP地址", //请输入IP地址
            startGreaterEndToast: "开始时间大于结束时间", //开始时间大于结束时间
            startCannotEmptyToast: "开始时间不能为空", //开始时间不能为空
            StartGreaterEqual: "开始时间不能大于等于结束时间", //请输入用户对象类型
            endCannotEmptyToast: "结束时间不能为空", //结束时间不能为空
            CannotEmpty: "开始时间和结束时间不能为空", //开始时间和结束时间不能为空
            atLeastACondition: "认证规则至少选择一项条件", //认证规则至少选择一项条件
            CertificationObj: {
                账密认证: "账密认证", //账密认证
                混合认证: "混合认证", //混合认证
                二次认证: "二次认证", //二次认证
            },
            mspArr: {
                MSP平台账密: 'MSP平台账密', //MSP平台账密
            },
            SMSauth: {
                短信认证: '短信认证', //短信认证
                生物认证: '生物认证', //短信认证
            },
            // -----认证源配置
            AuthenticaSourceConfig: "认证源配置", //AuthenticaSourceConfig-----认证源配置
            AuthenticationSourceName: "认证源名称", //认证源名称
            AuthenticationSourceType: "认证源类型", //认证源类型
            localAuthentication: "本地认证", //本地认证
            AccountSecurityAuthentication: "帐密认证", //帐密认证
            SMSAuthentication: "短信认证", //短信认证
            MSPPlatformSMS: "MSP平台短信", //MSP平台短信
            sureDeleteAuthenticationSource: "确定删除该认证源?", //确定删除该认证源?
            SaveFailed: '删除失败', //删除失败
            DeleteNotAllowed: "该认证源正在使用，不允许删除", //该认证源正在使用，不允许删除
            HttpUrl: "服务器地址：", //服务器地址：
            ServerDomainName: "服务器域名：", //服务器域名：
            LDAPQueryObjectProperties: "LDAP查询对象属性：", //LDAP查询对象属性
            BaseDN: "基准DN：", //基准DN：
            AdministratorAccount: "管理员账户：",//管理员账户：
            AdministratorPassword: "管理员密码：",//管理员密码：
            UserNameFieldName: "用户名字段名称：",// 用户名字段名称：
            ADDomainImportSettings: 'AD域导入设置：', //AD域导入设置：
            authNameViod: '请输入认证源名称', //请输入认证源名称
            authNameStr: '最长输入30个字符', //最长输入30个字符
            PortRange: '端口最小值是1, 最大值是65535"', //端口最小值是1, 最大值是65535"
            authSourceAlreadyExists: '认证源名称已存在', //认证源名称已存在
            AuthmethodObj: {
                账密认证: '账密认证', //账密认证
            },
            //流程配置--------------
            flowPathConfig: '流程配置',  //流程配置
            AppReleaseReview: '应用发布审核：',//应用发布审核：
            LightReminder: '应用发布审核开启后，应用上架/下架必须进行审批流程',//应用发布审核开启后，应用上架/下架必须进行审批流程
        },
        //关于
        AboutPage: {
            productName: '产品名称：', //产品名称：
            productVersion: '产品版本：', //产品版本：
            customer: '客户信息：', //客户信息：
            NumberUsers: '用户数：', //用户数：
            unlimited: '无限制', //无限制
            unauthorized: '未授权', //未授权
            residue: '(剩余：', //剩余：
            NumberDevices: '设备数：', //设备数：
            SequenceNumber: '序列号码：', //序列号码：
            serviceLife: '使用期限：', //使用期限：
            ServicePeriod: '服务期限：', //产服务期限：
            Copyright: '版权所有：', //版权所有：
            individual: '个)', //个
            day: '天)', //天
        },
        //对外接口配置
        S6000ConfigPage: {
            IPAddress: 'IP地址', //IP地址
            port: '端口', //端口
            inforType: '信息类型', //信息类型
            Enable: '启用', //启用
            deny: '否', //否
            correct: '是', //是
            nameVoid: '请输入事件名称', //请输入事件名称
            nameReg: '最长输入20个字符IP地址', //最长输入20个字符
            memoVoid: '请输入事件描述', //请输入事件描述
            memoReg: '最长输入200个字符', //最长输入200个字符
            sureDeleteSeleconfig: '确定删除选中的配置项?', //确定删除选中的配置项?
            heartbeatMessage: '心跳信息', //心跳信息
            statistic: 'IP地址', //IP地址
            AlarmInfor: 'IP地址', //告警信息
            ManagementLog: 'IP地址', //管理日志
            TerminalLogs: 'IP地址', //终端日志
            AlarmLog: 'IP地址', //告警日志
            GatewayAccessLog: 'IP地址', //网关访问日志
            IPReg: '请输入正确的IP地址,如：192.168.0.1', //请输入正确的IP地址,如：192.168.0.1
            IPVoid: '请输入IP地址', //请输入IP地址
            portReg: '请输入端口', //请输入端口
            portVoid: '最长输入5个字符', //最长输入5个字符
            CustomTitle: '自定义标题', //自定义标题
            CertificateLimit: '保存失败，超过证书限制数', //   保存失败，超过证书限制数
            SelectInforType: '请至少选中一项信息类型', //请至少选中一项信息类型
            InfoTypeObj: {
                // 心跳信息: '心跳信息',
                // 统计信息: '统计信息',
                // 告警信息: '告警信息',
                // 管理日志: '管理日志',
                // 终端日志: '终端日志',
                // 告警日志: '告警日志',
                // 网关访问日志: '网关访问日志'
                0: '心跳信息', //心跳信息
                1: '统计信息', //统计信息
                2: '告警信息', //告警信息
                3: '管理日志', //管理日志
                4: '终端日志', //终端日志
                5: '告警日志', //告警日志
                6: '网关访问日志', //网关访问日志
            },
            //新增页面
            heartbeatMessage: '心跳信息', //心跳信息
            statistic: '统计信息', //统计信息
            AlarmInfor: '告警信息', //告警信息
            ManagementLog: '管理日志', //管理日志
            TerminalLogs: '终端日志', //终端日志
            AlarmLog: '告警日志', //告警日志
            GatewayAccessLog: '网关访问日志', //网关访问日志
        },
        //系统预警配置
        systemWarningPage: {
            ServiceType: '服务类型', //服务类型
            GatewayService: '网关服务', //网关服务
            WarningConditions: '预警条件', //预警条件
            WarningType: '预警类型', //预警类型
            CPU: 'CPU', //CPU
            Memory: '内存', //内存
            HardDisk: '硬盘', //硬盘
            ManagementServiceException: '管理服务异常', //管理服务异常
            GatewayServiceException: '网关服务异常', //网关服务异常
            ServiceException: '服务异常', //服务异常
            DeactivateEnable: '停用启用', //停用启用
            AlertConditionConfig: '预警条件配置', //预警条件配置
            UsageRate: '使用率高于', //使用率高于
            thresholdVoid: '请输入预警条件', //请输入预警条件
        },
        //运行配置
        RunConfig: {
            gateway: "短信网关", //短信网关
            MailGateway: "邮件网关", //邮件网关
            selectPlaceholder: "请选择", //请选择
            sms: "短信平台：", //短信平台：
            typeOptions: {
                金笛: '金笛',
                阿里云短信: '阿里云短信',
            },
            serverAddress: "服务器地址：", //服务器地址：
            Port: "端口：", //端口：
            accountNumber: "账号：", //账号：
            password: "密码：", //密码：
            Sign: "签名：", //签名：
            TestNumber: "测试号码：", //测试号码：
            AlarmReportingPhoneNumber: "告警报送手机号：", //告警报送手机号：
            passPlaceholder: "如密码无变化，保持该项为空", //如密码无变化，保持该项为空
            AKIDPlaceholder: "请输入阿里云秘钥", //请输入阿里云秘钥
            AKSecretPlaceholder: "请输入阿里云密钥", //请输入阿里云密钥
            SignPlaceholder: "请输入阿里云短信签名", //请输入阿里云短信签名
            reportToPlaceholder: "多个号码以英文逗号隔开", //多个号码以英文逗号隔开
            test: "测试", //测试
            AKIDVoid: "请输入AccessKey", //请输入AccessKey
            IPReg: '请输入正确的IP地址,如：192.168.0.1', //'请输入正确的IP地址,如：192.168.0.1'?
            IPVoid: '请输入IP地址', //请输入IP地址
            portReg: '请输入端口', //请输入端口
            AKSecretVoid: "请输入AccessKey Secret", //请输入AccessKey Secret
            SignVoid: "请输入签名", //请输入签名
            SignStr: "最多支持12个字符", //最多支持12个字符
            PortRange: '端口最小值是1, 最大值是65535"', //端口最小值是1, 最大值是65535"
            TestFailed: "测试失败，测试短信网关连接失败！", //测试失败，测试短信网关连接失败!
            check: '请查看填写的测试号码是否收到测试短信，若收到则测试成功！', //请查看填写的测试号码是否收到测试短信，若收到则测试成功！
            SMTPServiceAddress: 'SMTP服务地址：', //SMTP服务地址：
            WhetherToUseSSL: '是否使用SSL：', //是否使用SSL：
            EmailAccount: '邮箱账号：', //邮箱账号：
            EmailPassword: '邮箱密码：', //邮箱密码：
            TestReceivingEmail: '测试接收邮箱：', //测试接收邮箱：
            AlarmSubmissionEmail: '告警报送邮箱：', //告警报送邮箱：
            smtpUrlVoid: '请输入SMTP服务地址', //请输入SMTP服务地址
            emailVoid: '请正确填写邮箱账号', //请正确填写邮箱账号
            emailEffective: '请输入有效的邮箱账号', //请输入有效的邮箱账号
            TestEmailFailed: '测试失败，测试邮件网关连接失败！', //测试失败，测试邮件网关连接失败！
            checkEmail: '请查看填写的测试邮箱是否收到测试邮件，若收到则测试成功！', //请查看填写的测试邮箱是否收到测试邮件，若收到则测试成功！
        },
        //授权激活
        LicensePage: {
            requestCode: '请求码：', //请求码
            serialNumber: '序列号：', //序列号
            ActivationCode: '激活码：', //激活码
            ContactCustomerService: '请联系客服人员获取序列号与激活码', //请联系客服人员获取序列号与激活码
            activation: '激活', //激活
            serialNumberVoid: "序列号不能为空", //序列号不能为空
            ActivationCodeVoid: "激活码不能为空", //激活码不能为空
            ActivationFailed: "激活失败", //序列号不能为空
            ActivationSuccessful: "激活成功", //激活码不能为空
        },
        //行为级别配置
        BehaviorLevelConfig: {
            ViolationLevel: '违规级别', //违规级别
            ThreatLevel: '威胁级别', //威胁级别
            SerialNumber: '序号', //序号
            BehaviorType: '行为类型', //行为类型
            BehaviorLevel: '行为级别', //行为级别
            tall: '高', //高
            centre: '中', //中
            low: '低', //低
            describe: '描述', //描述
            ModificationTime: '修改时间', //修改时间
            sureUpBehaviorLevel: '确定修改该行为级别?', //确定修改该行为级别?
        },
        //定制化配置
        CustomizeConfigPage: {
            PageCustomization: "页面自定义", //页面自定义
            UserAgreementConfiguration: "用户协议配置", //用户协议配置
            EmailTemplateManagement: "邮件模板管理", //邮件模板管理
            SMSTemplateManagement: "短信模板管理", //短信模板管理
            EnterpriseLogo: "企业LOGO：", //企业LOGO
            SelectLocalImage: "选择本地图片", //选择本地图片
            imageSize: "*支持jpg格式，大小不超过300KB，建议尺寸183*70像素", //*支持jpg格式，大小不超过300KB，建议尺寸183*70像素
            PlatformName: "平台名称：", //平台名称
            PlatformNameVoid: "请输入平台名称", //请输入平台名称
            fileDoesNotExist: "保存失败，文件不存在", //保存失败，文件不存在
            LimitFileSize: '当前限制文件大小不能大于300KB', //当前限制文件大小不能大于300KB
            ErrorFile: '文件格式错误,请上传正确文件', //文件格式错误,请上传正确文件
            FileUpSuccessfully: '上传文件成功',
            FileUploadFailed: '上传文件失败',
            PopupTitle: "弹窗标题：", //弹窗标题
            PopupContent: "弹窗内容：", //弹窗内容：
            DetailsLinkLabel: "详情链接标题：", //详情链接标
            LicenseAgreement: "用户许可协议：", //用户许可协议
            PopupTitleVoid: "请输入弹窗标题", //请输入弹窗标题
            PopupContentVoid: "请输入弹窗内容", //短信模板管理
            DetailsLinkLabelVoid: "详情链接标题", //页面自定义
            LicenseAgreementVoid: "用户许可协议", //用户协议配置
            TemplateName: "模板名称", //模板名称
            Subject: "邮件标题", //邮件标题
            TemplateContent: "模板内容", //模板内容
            ModificationTime: "修改时间", //修改时间
            SaveFailed: '保存失败', //邮件模板管理
            TemplateId: "模板ID", //短信模板管理
            SubjectViod: '请输入邮件标题', //请输入邮件标题
            TemplateContentViod: '请输入模板内容', //请输入模板内容
            TemplateIdViod: '请输入模版ID', //请输入模版ID
        },
        //联系我们
        ContactPage: {
            CorporateName: '公司名称：', //公司名称：
            CompanyAddress: '公司地址：', //公司地址：
            PostalCode: '邮政编码：', //邮政编码：
            Telephone: '联系电话：', //联系电话：
            FaxNumber: '传真号码：', //传真号码：
            CompanyEmail: '公司邮箱：', //公司邮箱：
            CompanyWebsite: '公司网址：', //公司网址：
            FreeConsultationHotline: '免费咨询热线：', //免费咨询热线：
        },
    },
    auditor: {
        //管理日志
        ManagementLog: {
            LogLevel: { //日志级别
                日志级别: '日志级别',
                一般: '一般',
                重要: '重要',
                告警: '告警',
            },
            SystemConfiguration: { //系统配置
                系统配置: '系统配置',
                首页: '首页',
                用户管理: '用户管理',
                部门管理: '部门管理',
                设备管理: '设备管理',
                内容管理: '内容管理',
                应用管理: '应用管理',
                分配管理: '分配管理',
                安全监测: '安全监测',
                移动运维: '移动运维',
                报表管理: '报表管理',
                设备策略: '设备策略',
                日志管理: '日志管理',
            },
            ManagementLogStatistics: '管理日志统计',
            ManagementLogLit: { //管理日志列表
                OperationResult: '操作结果',
                LogLevel: '日志级别',
                IPAddress: 'IP地址',
                remark: '备注',
                time: '时间',
                Lose: '失败',
                Successful: '成功',
                normal: '一般',
                importance: '重要',
                GiveAnAlarm: '告警',
            },
            POPup: { //弹框
                UserLogStatistics: '用户日志统计',
                OperationLogStatistics: '操作日志统计',
            }
        },
        //终端日志
        TerminalLog: {
            placeholder: '请输入设备名称',
            TerminalList: { //终端列表 
                OperationResult: '操作结果',
                LogLevel: '日志级别',
                IPAddress: 'IP地址',
                remark: '备注',
                time: '时间',
                Lose: '失败',
                Successful: '成功',
                normal: '一般',
                importance: '重要',
                GiveAnAlarm: '告警',
            }
        },
        //告警日志 
        AlarmLog: {
            placeholder: '请输入设备名称',
            AlarmInList: { //告警日志列表
                AlarmContent: '告警内容',
                IPAddress: 'IP地址',
                remark: '备注',
                time: '时间',
            }
        },
        //日志归档
        LogArchiving: {
            ArchiveConfiguration: '归档配置',
            tableList: { //表格配置
                PleaseStartTime: '请选择开始时间',
                PleaseEndTime: '请选择结束时间',
                startTimeEndTime: '开始时间不能大于结束时间',
                sureDeleteSelectedRecord: '确定删除选中的记录?',
            },
            ADDList: {
                ArchivingMethod: '归档方式：',
                LocalArchive: '本地归档',
                DirectDiscard: '直接丢弃',
                RetentionTime: '保留时间：',
                oneMonth: '1个月',
                twoMonth: '2个月',
                threeMonth: '3个月',
                fourMonth: '4个月',
                fiveMonth: '5个月',
                sixMonth: '6个月',
                sevenMonth: '7个月',
                eightMonth: '8个月',
                nineMonth: '9个月',
                tenMonth: '10个月',
                elevenMonth: '11个月',
                twelveMonth: '12个月',
                thirteenMonth: '13个月',
                fourteenMonth: '14个月',
                fifteenMonth: '15个月',
                sixteenMonth: '16个月',
                seventeenMonth: '17个月',
                eighteenMonth: '18个月',
                nineteenMonth: '19个月',
                twentyMonth: '20个月',
                twentyOneMonth: '21个月',
                twentyTwoMonth: '22个月',
                twentyThereMonth: '23个月',
                twentyFourMonth: '24个月',
                Activate: '启用：',
            }
        }
    },
    //安全态势
    SecurityPosture: {
        noData: '暂无数据',
        day: '天',
        times: '次',
        Taiwan: '台',
        hours: '小时',
        minutes: '分',
        seconds: '秒',
        bounced: { // 配置图表、卡片弹框
            title: '选择图表',
            btnTitle: '确定',
            StatisticalDimension: '统计维度：',
            StatisticalContent: '统计内容：',
            ChartType: '图表类型：',
            placeholder: '请选择'
        },
        figure: { //各种图形
            PieChart: '饼图',
            RingGraph: '环形图',
            RoseDiagram: '玫瑰图',
            BarChart: '柱状图',
            LineChart: '折线图',
            WordCloud: '词云图',
            TheBarChart: '条形图',

        },
        PreviewPage: { //预览页面
            threatSwitch: ["当天", "近7天", "近30天", "近90天"],
            active: '当天',
            card: { //卡片
                LeftOne: '应用运行次数',
                LeftTwo: '应用威胁趋势',
                RightOne: '应用活跃Top5',
                RightTwo: '应用运行次数',
            },
            TheChart: { //图表 
                chartInfoLeft_1: "应用活跃趋势",
                chartInfoLeft_2: "应用活跃Top5",
                chartInfoRight_1: "应用威胁趋势",
                chartInfoRight_2: "应用威胁Top5",
            },
            tableList: { //表格 
                titleName: '应用威胁top5',
            },
            headerTitle: { //标头
                titleName: '移动安全管理平台安全态势',
            },
            PresentTime: { //当前时间
                week: '星期',
                day: '日',
                one: '一',
                two: '二',
                three: '三',
                four: '四',
                five: '五',
                six: '六',
            }
        },
        cardLeftOne: { //卡片左一
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appRunCountConf: '应用运行次数',
                appDangerCountConf: '应用威胁数',
                appEventCountConf: '安全事件数',
                sdkBehaviorCountConf: 'SDK行为数',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceSumCountConf: '设备总数',
                offLineCountConf: '离线设备数',
                onLineCountConf: '在线设备数',
                violationsSumCountConf: '违规总数',
                lostCountConf: '丢失设备数',
                scrapCountConf: '报废设备数',
                onLineRateConf: '设备在线率'
            },
            userInfo_statistical: { //用户统计内容
                addUserCountConf: '新增用户数',
                normalUserCountConf: '正常用户数',
                stopUsingUserCountConf: '停用用户数',
                lockUserCountConf: '锁定用户数',
                userSumCountConf: '用户总数',
            },
            gatewayInfo_statistical: { //网关统计内容
                deviceConnectCountConf: '连接设备数',
                accessAppCountConf: '访问应用数',
                userConnectCountConf: '连接用户数',
            },
        },
        cardLeftTwo: { //卡片左二
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appRunCountConf: '应用运行次数',
                appDangerCountConf: '应用威胁数',
                appEventCountConf: '安全事件数',
                sdkBehaviorCountConf: 'SDK行为数',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceSumCountConf: '设备总数',
                offLineCountConf: '离线设备数',
                onLineCountConf: '在线设备数',
                violationsSumCountConf: '违规总数',
                lostCountConf: '丢失设备数',
                scrapCountConf: '报废设备数',
                onLineRateConf: '设备在线率'
            },
            userInfo_statistical: { //用户统计内容
                addUserCountConf: '新增用户数',
                normalUserCountConf: '正常用户数',
                stopUsingUserCountConf: '停用用户数',
                lockUserCountConf: '锁定用户数',
                userSumCountConf: '用户总数',
            },
            gatewayInfo_statistical: { //网关统计内容
                deviceConnectCountConf: '连接设备数',
                accessAppCountConf: '访问应用数',
                userConnectCountConf: '连接用户数',
            },
        },
        cardRightOne: { //卡片右一
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appRunCountConf: '应用运行次数',
                appDangerCountConf: '应用威胁数',
                appEventCountConf: '安全事件数',
                sdkBehaviorCountConf: 'SDK行为数',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceSumCountConf: '设备总数',
                offLineCountConf: '离线设备数',
                onLineCountConf: '在线设备数',
                violationsSumCountConf: '违规总数',
                lostCountConf: '丢失设备数',
                scrapCountConf: '报废设备数',
                onLineRateConf: '设备在线率'
            },
            userInfo_statistical: { //用户统计内容
                addUserCountConf: '新增用户数',
                normalUserCountConf: '正常用户数',
                stopUsingUserCountConf: '停用用户数',
                lockUserCountConf: '锁定用户数',
                userSumCountConf: '用户总数',
            },
            gatewayInfo_statistical: { //网关统计内容
                deviceConnectCountConf: '连接设备数',
                accessAppCountConf: '访问应用数',
                userConnectCountConf: '连接用户数',
            },
        },
        cardRightTwo: { //卡片右二
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appRunCountConf: '应用运行次数',
                appDangerCountConf: '应用威胁数',
                appEventCountConf: '安全事件数',
                sdkBehaviorCountConf: 'SDK行为数',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceSumCountConf: '设备总数',
                offLineCountConf: '离线设备数',
                onLineCountConf: '在线设备数',
                violationsSumCountConf: '违规总数',
                lostCountConf: '丢失设备数',
                scrapCountConf: '报废设备数',
                onLineRateConf: '设备在线率'
            },
            userInfo_statistical: { //用户统计内容
                addUserCountConf: '新增用户数',
                normalUserCountConf: '正常用户数',
                stopUsingUserCountConf: '停用用户数',
                lockUserCountConf: '锁定用户数',
                userSumCountConf: '用户总数',
            },
            gatewayInfo_statistical: { //网关统计内容
                deviceConnectCountConf: '连接设备数',
                accessAppCountConf: '访问应用数',
                userConnectCountConf: '连接用户数',
            },
        },
        LeftOneCharts: { //左图一
            list: ['使用时长', '启动次数'],//应用活跃To5
            active: '使用时长', //选中样式
            threatSwitch: ['移动应用', '威胁类型'],//应用威胁Top5
            theDefault: '移动应用', //选中样式
            sysVersion: ["Android", "iOS", "Windows", "Linux"],//系统版本
            sysActive: "Android", //选中样式
            appInstall: ["Android", "iOS", "Windows"],//应用安装TOP5
            installActie: "Android", //选中样式
            value: '无数据', //默认选择值
            valueBusiness: '无数据', //默认选择值
            valueGateway: '无数据', //默认选择值
            businessData: ["正常访问", "异常访问"], //业务访问去世
            businessActive: "正常访问", //业务fa默认选择值
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appActiveTrendConf: '应用活跃趋势',
                appDangerTrendConf: '应用威胁趋势',
                appActiveTopConf: '应用活跃Top5',
                appDangerTopConf: '应用威胁Top5',
                appUseTimeAvgConf: '应用平均使用时长',
                appInstallTopConf: '应用安装top5',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceActiveTrendConf: '设备活跃趋势',
                violaBehaviorTrendConf: '违规行为趋势',
                violaBehaviorDistributeConf: '违规行为分布',
                newJoinDeviceConf: '新接入设备',
                systemVersionConf: '系统版本',
                newDeviceRate: '新设备占比',
                deviceActiveRate: '终端活跃度'
            },
            userInfo_statistical: { //用户统计内容
                userActiveTrendConf: '用户活跃趋势',
                useAppTopConf: '用户使用应用Top5',
                sensitiveWordsConf: '敏感词',
                noNetworkAccessConf: '用户网络访问黑名单',
                userActiveRateConf: '用户活跃度'
            },
            gatewayInfo_statistical: { //网关统计内容
                userAccessTopConf: '访问用户top5',
                deviceAccessTopConf: '访问设备top5',
                appAccessTopConf: '访问应用top5',
                sourceAccessTopConf: '访问资源top5',
                accessResultConf: '业务访问趋势',
                gatewayThroughputConf: '网关吞吐量',
                appFlowTopConf: '应用流量TOP5',
                devFlowTopConf: '设备流量TOP5',
                appFlowRateTopConf: '应用流速TOP5',
                devFlowRateTopConf: '设备流速TOP5',
                netDelayTrendConf: '网络延时趋势',
            }
        },
        LeftTwoCharts: { //左图二
            list: ['使用时长', '启动次数'],//应用活跃To5
            active: '使用时长', //选中样式
            threatSwitch: ['移动应用', '威胁类型'],//应用威胁Top5
            theDefault: '移动应用', //选中样式
            sysVersion: ["Android", "iOS", "Windows", "Linux"],//系统版本
            sysActive: "Android", //选中样式
            appInstall: ["Android", "iOS", "Windows"],//应用安装TOP5
            installActie: "Android", //选中样式
            value: '无数据', //默认选择值
            valueBusiness: '无数据', //默认选择值
            valueGateway: '无数据', //默认选择值
            businessData: ["正常访问", "异常访问"], //业务访问去世
            businessActive: "正常访问", //业务fa默认选择值
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appActiveTrendConf: '应用活跃趋势',
                appDangerTrendConf: '应用威胁趋势',
                appActiveTopConf: '应用活跃Top5',
                appDangerTopConf: '应用威胁Top5',
                appUseTimeAvgConf: '应用平均使用时长',
                appInstallTopConf: '应用安装top5',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceActiveTrendConf: '设备活跃趋势',
                violaBehaviorTrendConf: '违规行为趋势',
                violaBehaviorDistributeConf: '违规行为分布',
                newJoinDeviceConf: '新接入设备',
                systemVersionConf: '系统版本',
                newDeviceRate: '新设备占比',
                deviceActiveRate: '终端活跃度'
            },
            userInfo_statistical: { //用户统计内容
                userActiveTrendConf: '用户活跃趋势',
                useAppTopConf: '用户使用应用Top5',
                sensitiveWordsConf: '敏感词',
                noNetworkAccessConf: '用户网络访问黑名单',
                userActiveRateConf: '用户活跃度'
            },
            gatewayInfo_statistical: { //网关统计内容
                userAccessTopConf: '访问用户top5',
                deviceAccessTopConf: '访问设备top5',
                appAccessTopConf: '访问应用top5',
                sourceAccessTopConf: '访问资源top5',
                accessResultConf: '业务访问趋势',
                gatewayThroughputConf: '网关吞吐量',
            }
        },
        RightOneCharts: { //右图一
            list: ['使用时长', '启动次数'],//应用活跃To5
            active: '使用时长', //选中样式
            threatSwitch: ['移动应用', '威胁类型'],//应用威胁Top5
            theDefault: '移动应用', //选中样式
            sysVersion: ["Android", "iOS", "Windows", "Linux"],//系统版本
            sysActive: "Android", //选中样式
            appInstall: ["Android", "iOS", "Windows"],//应用安装TOP5
            installActie: "Android", //选中样式
            value: '无数据', //默认选择值
            valueBusiness: '无数据', //默认选择值
            valueGateway: '无数据', //默认选择值
            businessData: ["正常访问", "异常访问"], //业务访问去世
            businessActive: "正常访问", //业务fa默认选择值
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appActiveTrendConf: '应用活跃趋势',
                appDangerTrendConf: '应用威胁趋势',
                appActiveTopConf: '应用活跃Top5',
                appDangerTopConf: '应用威胁Top5',
                appUseTimeAvgConf: '应用平均使用时长',
                appInstallTopConf: '应用安装top5',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceActiveTrendConf: '设备活跃趋势',
                violaBehaviorTrendConf: '违规行为趋势',
                violaBehaviorDistributeConf: '违规行为分布',
                newJoinDeviceConf: '新接入设备',
                systemVersionConf: '系统版本',
                newDeviceRate: '新设备占比',
                deviceActiveRate: '终端活跃度'
            },
            userInfo_statistical: { //用户统计内容
                userActiveTrendConf: '用户活跃趋势',
                useAppTopConf: '用户使用应用Top5',
                sensitiveWordsConf: '敏感词',
                noNetworkAccessConf: '用户网络访问黑名单',
                userActiveRateConf: '用户活跃度'
            },
            gatewayInfo_statistical: { //网关统计内容
                userAccessTopConf: '访问用户top5',
                deviceAccessTopConf: '访问设备top5',
                appAccessTopConf: '访问应用top5',
                sourceAccessTopConf: '访问资源top5',
                accessResultConf: '业务访问趋势',
                gatewayThroughputConf: '网关吞吐量',
            }
        },
        RightTwoCharts: { //右图二
            list: ['使用时长', '启动次数'],//应用活跃To5
            active: '使用时长', //选中样式
            threatSwitch: ['移动应用', '威胁类型'],//应用威胁Top5
            theDefault: '移动应用', //选中样式
            sysVersion: ["Android", "iOS", "Windows", "Linux"],//系统版本
            sysActive: "Android", //选中样式
            appInstall: ["Android", "iOS", "Windows"],//应用安装TOP5
            installActie: "Android", //选中样式
            value: '无数据', //默认选择值
            valueBusiness: '无数据', //默认选择值
            valueGateway: '无数据', //默认选择值
            businessData: ["正常访问", "异常访问"], //业务访问去世
            businessActive: "正常访问", //业务fa默认选择值
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appActiveTrendConf: '应用活跃趋势',
                appDangerTrendConf: '应用威胁趋势',
                appActiveTopConf: '应用活跃Top5',
                appDangerTopConf: '应用威胁Top5',
                appUseTimeAvgConf: '应用平均使用时长',
                appInstallTopConf: '应用安装top5',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceActiveTrendConf: '设备活跃趋势',
                violaBehaviorTrendConf: '违规行为趋势',
                violaBehaviorDistributeConf: '违规行为分布',
                newJoinDeviceConf: '新接入设备',
                systemVersionConf: '系统版本',
                newDeviceRate: '新设备占比',
                deviceActiveRate: '终端活跃度'
            },
            userInfo_statistical: { //用户统计内容
                userActiveTrendConf: '用户活跃趋势',
                useAppTopConf: '用户使用应用Top5',
                sensitiveWordsConf: '敏感词',
                noNetworkAccessConf: '用户网络访问黑名单',
                userActiveRateConf: '用户活跃度'
            },
            gatewayInfo_statistical: { //网关统计内容
                userAccessTopConf: '访问用户top5',
                deviceAccessTopConf: '访问设备top5',
                appAccessTopConf: '访问应用top5',
                sourceAccessTopConf: '访问资源top5',
                accessResultConf: '业务访问趋势',
                gatewayThroughputConf: '网关吞吐量',
            }
        },
        tableList: { //表格
            more: '更多',
            TableDropDownBox: { //表格下拉框
                告警列表: '告警列表',
                违规行为: '违规行为',
                威胁行为: '威胁行为',
                安全事件: '安全事件',
                敏感行为: '敏感行为',
                SDK行为: 'SDK行为',
                运行信息: '运行信息',
            },
            AlarmInformationForm: { //告警信息表格 ----行为类型映射
                LocationFraud: '位置欺诈',
                DomainNameFraud: '域名欺诈',
                InjectionAttack: '注入攻击',
                DebuggingBehavior: '调试行为',
                MemoryTampering: '内存篡改',
                ProgramPlugIn: '程序外挂',
                ApplicationCracking: '应用破解',
                HttpsHijacking: 'Https劫持',
                HttpProxy: 'Http代理',
                ApplicationMultiplex: '应用多开',
                FrameAttack: '框架攻击',
                HighFrequencyChangeAccount: '高频更换账号',
                HighFrequencyChangeIP: '高频更换IP',
                HighFrequencyChangePosition: '高频更换位置',
                HighFrequencyStartingApplication: '高频启动应用',
                RiskApplication: '风险应用',
                ROOTJailbreak: 'ROOT/越狱',
                FrameSoftware: '框架软件',
                USBDebugging: 'USB调试',
                simulator: '模拟器',
                SimulatedPositionAllowed: '允许模拟位置',
                RootCertificateException: '根证书异常',
                CustomROM: '定制ROM',
                TheScreenshotBlockIsRemovedProcedure: '截屏阻断解除',
                SystemTimeException: '系统时间异常',
                TheScreenLockPasswordPsNotEnabled: '锁屏密码未开启',
                VPN: 'VPN',
                macOS: 'macOS',
                Windows: 'Windows',
                CloudPhone: '云手机',
                RiskIP: '风险IP',
                high: '高',
                medium: '中',
                low: '低',
            },
            violationType: { //违规行为表格 ----行为类型映射
                TypeOfBehavior: '行为类型',
                DeviceJailbreak: '设备越狱',
                SIMCardChange: 'SIM卡变更',
                SDCardChange: 'SD卡变更',
                IllegalOutreach: '违规外联',
                geofencing: '地理围栏',
                SystemVersion: '系统版本',
                FlowAnomaly: '流量异常',
                EquipmentPower: '设备电量',
                StorageSpace: '存储空间',
                MandatoryApplication: '必装应用',
                ApplicationBlacklistAndWhitelist: '应用黑白名单',
                WIFIBlackAndWhiteList: 'WIFI黑白名单',
                SystemFunction: '系统功能',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                CloudPhone: '云手机',
            },
            ThreateningBehavior: { //威胁行为表格 ----行为类型映射
                BehaviorType: '行为类型',
                InjectionAttack: '注入攻击',
                FrameAttack: '框架攻击',
                DebuggingBehavior: '调试行为',
                LocationFraud: '位置欺诈',
                DomainNameFraud: '域名欺诈',
                ProgramPlugIn: '程序外挂',
                ApplicationCracking: '应用破解',
                HttpsHijacking: 'Https劫持',
                HttpProxy: 'Http代理',
                ApplicationMultiplex: '应用多开',
                HighFrequencyChangeAccount: '高频更换账号',
                HighFrequencyChangeIP: '高频更换IP',
                HighFrequencyChangePosition: '高频更换位置',
                HighFrequencyStartingApplication: '高频启动应用',
                FrameSoftware: '框架软件',
                RiskApplication: '风险应用',
                RootCertificateException: '根证书异常',
                USBDebugging: 'USB调试',
                ROOTJailbreak: 'ROOT/越狱',
                SimulatedPositionAllowed: '允许模拟位置',
                simulator: '模拟器',
                CustomROM: '定制ROM',
                SystemTimeException: '系统时间异常',
                TheScreenLockPasswordPsNotEnabled: '锁屏密码未开启',
                MemoryTampering: '内存篡改',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                CloudPhone: '云手机',
                RiskIP: '风险IP',
            },
            SensitiveBehavior: { //敏感行为表格 ----行为类型映射
                BehaviorType: '行为类型',
                ApplicationSensitiveBehavior: '应用敏感行为',
                ApplicationOfUltraVires: '应用越权行为',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                CloudPhone: '云手机',
            },
            SecurityIncident: { //安全事件表格 
                EventName: '事件名称',
            },
            SDKList: { //SDK行为表格
                sdkName: 'SDK名称',
                BehaviorType: '行为类型',
                ReadSMSRecords: '读取短信记录',
                ReadAddressBook: '读取通讯录',
                GetGeographicalLocation: '获取地理位置',
                ReadStorage: '读取外部存储',
                ObtainHardware: '获取设备硬件信息',
                ObtainSoftware: '获取设备软件信息',
                picturesVideo: '拍照、摄像',
                TurnOnWIFI: '开启WIFI',
                EnableBluetooth: '开启蓝牙',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                CloudPhone: '云手机',
            },
            RunningInformation: { //运行信息表格
                NumberOfStarts: '启动次数',
                DurationOfUse: '使用时长',
                trafficOfUse: '使用流量',
                time: '次', //

            }
        },
        dispositionPage: { //配置设置页面
            threatSwitch: ["当天", "近7天", "近30天", "近90天"],
            active: '近90天',
            card: { //卡片
                LeftOne: '应用运行次数',
                LeftTwo: '应用威胁趋势',
                RightOne: '应用活跃Top5',
                RightTwo: '应用运行次数',
            },
            TheChart: { //图表 
                chartInfoLeft_1: "应用活跃趋势",
                chartInfoLeft_2: "应用活跃Top5",
                chartInfoRight_1: "应用威胁趋势",
                chartInfoRight_2: "应用威胁Top5",
            },
            tableList: { //表格 
                titleName: '应用威胁top5',
            },
            headerTitle: { //标头
                titleName: '移动安全管理平台安全态势',
            },
            PresentTime: { //当前时间
                week: '星期',
                day: '日',
                one: '一',
                two: '二',
                three: '三',
                four: '四',
                five: '五',
                six: '六',
            }
        },
        ConfigCardLeftOne: { //配置卡片左一
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appRunCountConf: '应用运行次数',
                appDangerCountConf: '应用威胁数',
                appEventCountConf: '安全事件数',
                sdkBehaviorCountConf: 'SDK行为数',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceSumCountConf: '设备总数',
                offLineCountConf: '离线设备数',
                onLineCountConf: '在线设备数',
                violationsSumCountConf: '违规总数',
                lostCountConf: '丢失设备数',
                scrapCountConf: '报废设备数',
                onLineRateConf: '设备在线率'
            },
            userInfo_statistical: { //用户统计内容
                addUserCountConf: '新增用户数',
                normalUserCountConf: '正常用户数',
                stopUsingUserCountConf: '停用用户数',
                lockUserCountConf: '锁定用户数',
                userSumCountConf: '用户总数',
            },
            gatewayInfo_statistical: { //网关统计内容
                deviceConnectCountConf: '连接设备数',
                accessAppCountConf: '访问应用数',
                userConnectCountConf: '连接用户数',
            },
        },
        ConfigCardLeftTwo: { //配置卡片左二
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appRunCountConf: '应用运行次数',
                appDangerCountConf: '应用威胁数',
                appEventCountConf: '安全事件数',
                sdkBehaviorCountConf: 'SDK行为数',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceSumCountConf: '设备总数',
                offLineCountConf: '离线设备数',
                onLineCountConf: '在线设备数',
                violationsSumCountConf: '违规总数',
                lostCountConf: '丢失设备数',
                scrapCountConf: '报废设备数',
                onLineRateConf: '设备在线率'
            },
            userInfo_statistical: { //用户统计内容
                addUserCountConf: '新增用户数',
                normalUserCountConf: '正常用户数',
                stopUsingUserCountConf: '停用用户数',
                lockUserCountConf: '锁定用户数',
                userSumCountConf: '用户总数',
            },
            gatewayInfo_statistical: { //网关统计内容
                deviceConnectCountConf: '连接设备数',
                accessAppCountConf: '访问应用数',
                userConnectCountConf: '连接用户数',
            },
        },
        ConfigCardRightOne: { //配置卡片右一
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appRunCountConf: '应用运行次数',
                appDangerCountConf: '应用威胁数',
                appEventCountConf: '安全事件数',
                sdkBehaviorCountConf: 'SDK行为数',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceSumCountConf: '设备总数',
                offLineCountConf: '离线设备数',
                onLineCountConf: '在线设备数',
                violationsSumCountConf: '违规总数',
                lostCountConf: '丢失设备数',
                scrapCountConf: '报废设备数',
                onLineRateConf: '设备在线率'
            },
            userInfo_statistical: { //用户统计内容
                addUserCountConf: '新增用户数',
                normalUserCountConf: '正常用户数',
                stopUsingUserCountConf: '停用用户数',
                lockUserCountConf: '锁定用户数',
                userSumCountConf: '用户总数',
            },
            gatewayInfo_statistical: { //网关统计内容
                deviceConnectCountConf: '连接设备数',
                accessAppCountConf: '访问应用数',
                userConnectCountConf: '连接用户数',
            },
        },
        ConfigCardRightTwo: { //配置卡片右二
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appRunCountConf: '应用运行次数',
                appDangerCountConf: '应用威胁数',
                appEventCountConf: '安全事件数',
                sdkBehaviorCountConf: 'SDK行为数',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceSumCountConf: '设备总数',
                offLineCountConf: '离线设备数',
                onLineCountConf: '在线设备数',
                violationsSumCountConf: '违规总数',
                lostCountConf: '丢失设备数',
                scrapCountConf: '报废设备数',
                onLineRateConf: '设备在线率'
            },
            userInfo_statistical: { //用户统计内容
                addUserCountConf: '新增用户数',
                normalUserCountConf: '正常用户数',
                stopUsingUserCountConf: '停用用户数',
                lockUserCountConf: '锁定用户数',
                userSumCountConf: '用户总数',
            },
            gatewayInfo_statistical: { //网关统计内容
                deviceConnectCountConf: '连接设备数',
                accessAppCountConf: '访问应用数',
                userConnectCountConf: '连接用户数',
            },
        },
        ConfigLeftOneCharts: { //配置左图一
            list: ['使用时长', '启动次数'],//应用活跃To5
            active: '使用时长', //选中样式
            threatSwitch: ['移动应用', '威胁类型'],//应用威胁Top5
            theDefault: '移动应用', //选中样式
            sysVersion: ["Android", "iOS", "Windows", "Linux"],//系统版本
            sysActive: "Android", //选中样式
            appInstall: ["Android", "iOS", "Windows"],//应用安装TOP5
            installActie: "Android", //选中样式
            value: '无数据', //默认选择值
            valueBusiness: '无数据', //默认选择值
            valueGateway: '无数据', //默认选择值
            businessData: ["正常访问", "异常访问"], //业务访问去世
            businessActive: "正常访问", //业务fa默认选择值
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appActiveTrendConf: '应用活跃趋势',
                appDangerTrendConf: '应用威胁趋势',
                appActiveTopConf: '应用活跃Top5',
                appDangerTopConf: '应用威胁Top5',
                appUseTimeAvgConf: '应用平均使用时长',
                appInstallTopConf: '应用安装top5',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceActiveTrendConf: '设备活跃趋势',
                violaBehaviorTrendConf: '违规行为趋势',
                violaBehaviorDistributeConf: '违规行为分布',
                newJoinDeviceConf: '新接入设备',
                systemVersionConf: '系统版本',
                newDeviceRate: '新设备占比',
                deviceActiveRate: '终端活跃度'
            },
            userInfo_statistical: { //用户统计内容
                userActiveTrendConf: '用户活跃趋势',
                useAppTopConf: '用户使用应用Top5',
                sensitiveWordsConf: '敏感词',
                noNetworkAccessConf: '用户网络访问黑名单',
                userActiveRateConf: '用户活跃度'
            },
            gatewayInfo_statistical: { //网关统计内容
                userAccessTopConf: '访问用户top5',
                deviceAccessTopConf: '访问设备top5',
                appAccessTopConf: '访问应用top5',
                sourceAccessTopConf: '访问资源top5',
                accessResultConf: '业务访问趋势',
                gatewayThroughputConf: '网关吞吐量',
                appFlowTopConf: '应用流量TOP5',
                devFlowTopConf: '设备流量TOP5',
                appFlowRateTopConf: '应用流速TOP5',
                devFlowRateTopConf: '设备流速TOP5',
                netDelayTrendConf: '网络延时趋势',
            }
        },
        ConfigLeftTwoCharts: { //配置左图二
            list: ['使用时长', '启动次数'],//应用活跃To5
            active: '使用时长', //选中样式
            threatSwitch: ['移动应用', '威胁类型'],//应用威胁Top5
            theDefault: '移动应用', //选中样式
            sysVersion: ["Android", "iOS", "Windows", "Linux"],//系统版本
            sysActive: "Android", //选中样式
            appInstall: ["Android", "iOS", "Windows"],//应用安装TOP5
            installActie: "Android", //选中样式
            value: '无数据', //默认选择值
            valueBusiness: '无数据', //默认选择值
            valueGateway: '无数据', //默认选择值
            businessData: ["正常访问", "异常访问"], //业务访问去世
            businessActive: "正常访问", //业务fa默认选择值
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appActiveTrendConf: '应用活跃趋势',
                appDangerTrendConf: '应用威胁趋势',
                appActiveTopConf: '应用活跃Top5',
                appDangerTopConf: '应用威胁Top5',
                appUseTimeAvgConf: '应用平均使用时长',
                appInstallTopConf: '应用安装top5',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceActiveTrendConf: '设备活跃趋势',
                violaBehaviorTrendConf: '违规行为趋势',
                violaBehaviorDistributeConf: '违规行为分布',
                newJoinDeviceConf: '新接入设备',
                systemVersionConf: '系统版本',
                newDeviceRate: '新设备占比',
                deviceActiveRate: '终端活跃度'
            },
            userInfo_statistical: { //用户统计内容
                userActiveTrendConf: '用户活跃趋势',
                useAppTopConf: '用户使用应用Top5',
                sensitiveWordsConf: '敏感词',
                noNetworkAccessConf: '用户网络访问黑名单',
                userActiveRateConf: '用户活跃度'
            },
            gatewayInfo_statistical: { //网关统计内容
                userAccessTopConf: '访问用户top5',
                deviceAccessTopConf: '访问设备top5',
                appAccessTopConf: '访问应用top5',
                sourceAccessTopConf: '访问资源top5',
                accessResultConf: '业务访问趋势',
                gatewayThroughputConf: '网关吞吐量',
            }
        },
        ConfigRightOneCharts: { //配置右图一
            list: ['使用时长', '启动次数'],//应用活跃To5
            active: '使用时长', //选中样式
            threatSwitch: ['移动应用', '威胁类型'],//应用威胁Top5
            theDefault: '移动应用', //选中样式
            sysVersion: ["Android", "iOS", "Windows", "Linux"],//系统版本
            sysActive: "Android", //选中样式
            appInstall: ["Android", "iOS", "Windows"],//应用安装TOP5
            installActie: "Android", //选中样式
            value: '无数据', //默认选择值
            valueBusiness: '无数据', //默认选择值
            valueGateway: '无数据', //默认选择值
            businessData: ["正常访问", "异常访问"], //业务访问去世
            businessActive: "正常访问", //业务fa默认选择值
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appActiveTrendConf: '应用活跃趋势',
                appDangerTrendConf: '应用威胁趋势',
                appActiveTopConf: '应用活跃Top5',
                appDangerTopConf: '应用威胁Top5',
                appUseTimeAvgConf: '应用平均使用时长',
                appInstallTopConf: '应用安装top5',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceActiveTrendConf: '设备活跃趋势',
                violaBehaviorTrendConf: '违规行为趋势',
                violaBehaviorDistributeConf: '违规行为分布',
                newJoinDeviceConf: '新接入设备',
                systemVersionConf: '系统版本',
                newDeviceRate: '新设备占比',
                deviceActiveRate: '终端活跃度'
            },
            userInfo_statistical: { //用户统计内容
                userActiveTrendConf: '用户活跃趋势',
                useAppTopConf: '用户使用应用Top5',
                sensitiveWordsConf: '敏感词',
                noNetworkAccessConf: '用户网络访问黑名单',
                userActiveRateConf: '用户活跃度'
            },
            gatewayInfo_statistical: { //网关统计内容
                userAccessTopConf: '访问用户top5',
                deviceAccessTopConf: '访问设备top5',
                appAccessTopConf: '访问应用top5',
                sourceAccessTopConf: '访问资源top5',
                accessResultConf: '业务访问趋势',
                gatewayThroughputConf: '网关吞吐量',
            }
        },
        ConfigRightTwoCharts: { //配置右图二
            list: ['使用时长', '启动次数'],//应用活跃To5
            active: '使用时长', //选中样式
            threatSwitch: ['移动应用', '威胁类型'],
            theDefault: '移动应用', //选中样式
            sysVersion: ["Android", "iOS", "Windows", "Linux"],//系统版本
            sysActive: "Android", //选中样式
            appInstall: ["Android", "iOS", "Windows"],//应用安装TOP5
            installActie: "Android", //选中样式
            value: '无数据', //默认选择值
            valueBusiness: '无数据', //默认选择值
            valueGateway: '无数据', //默认选择值
            businessData: ["正常访问", "异常访问"], //业务访问去世
            businessActive: "正常访问", //业务fa默认选择值
            dimension: { //维度
                appInfo: '应用',
                deviceInfo: '设备',
                userInfo: '用户',
                gatewayInfo: '网关',
            },
            appInfo_statistical: { //应用统计内容
                appActiveTrendConf: '应用活跃趋势',
                appDangerTrendConf: '应用威胁趋势',
                appActiveTopConf: '应用活跃Top5',
                appDangerTopConf: '应用威胁Top5',
                appUseTimeAvgConf: '应用平均使用时长',
                appInstallTopConf: '应用安装top5',
            },
            deviceInfo_statistical: { //设备统计内容
                deviceActiveTrendConf: '设备活跃趋势',
                violaBehaviorTrendConf: '违规行为趋势',
                violaBehaviorDistributeConf: '违规行为分布',
                newJoinDeviceConf: '新接入设备',
                systemVersionConf: '系统版本',
                newDeviceRate: '新设备占比',
                deviceActiveRate: '终端活跃度'
            },
            userInfo_statistical: { //用户统计内容
                userActiveTrendConf: '用户活跃趋势',
                useAppTopConf: '用户使用应用Top5',
                sensitiveWordsConf: '敏感词',
                noNetworkAccessConf: '用户网络访问黑名单',
                userActiveRateConf: '用户活跃度'
            },
            gatewayInfo_statistical: { //网关统计内容
                userAccessTopConf: '访问用户top5',
                deviceAccessTopConf: '访问设备top5',
                appAccessTopConf: '访问应用top5',
                sourceAccessTopConf: '访问资源top5',
                accessResultConf: '业务访问趋势',
                gatewayThroughputConf: '网关吞吐量',
            }
        },
        ConfigTableList: { //表格
            more: '更多',
            TableDropDownBox: { //表格下拉框
                告警列表: '告警列表',
                违规行为: '违规行为',
                威胁行为: '威胁行为',
                安全事件: '安全事件',
                敏感行为: '敏感行为',
                SDK行为: 'SDK行为',
                运行信息: '运行信息',
            },
            AlarmInformationForm: { //告警信息表格 ----行为类型映射
                LocationFraud: '位置欺诈',
                DomainNameFraud: '域名欺诈',
                InjectionAttack: '注入攻击',
                DebuggingBehavior: '调试行为',
                MemoryTampering: '内存篡改',
                ProgramPlugIn: '程序外挂',
                ApplicationCracking: '应用破解',
                HttpsHijacking: 'Https劫持',
                HttpProxy: 'Http代理',
                ApplicationMultiplex: '应用多开',
                FrameAttack: '框架攻击',
                HighFrequencyChangeAccount: '高频更换账号',
                HighFrequencyChangeIP: '高频更换IP',
                HighFrequencyChangePosition: '高频更换位置',
                HighFrequencyStartingApplication: '高频启动应用',
                RiskApplication: '风险应用',
                ROOTJailbreak: 'ROOT/越狱',
                FrameSoftware: '框架软件',
                USBDebugging: 'USB调试',
                simulator: '模拟器',
                SimulatedPositionAllowed: '允许模拟位置',
                RootCertificateException: '根证书异常',
                CustomROM: '定制ROM',
                TheScreenshotBlockIsRemovedProcedure: '截屏阻断解除',
                SystemTimeException: '系统时间异常',
                TheScreenLockPasswordPsNotEnabled: '锁屏密码未开启',
                VPN: 'VPN',
                macOS: 'macOS',
                Windows: 'Windows',
                CloudPhone: '云手机',
                RiskIP: '风险IP',
                high: '高',
                medium: '中',
                low: '低',
            },
            violationType: { //违规行为表格 ----行为类型映射
                TypeOfBehavior: '行为类型',
                DeviceJailbreak: '设备越狱',
                SIMCardChange: 'SIM卡变更',
                SDCardChange: 'SD卡变更',
                IllegalOutreach: '违规外联',
                geofencing: '地理围栏',
                SystemVersion: '系统版本',
                FlowAnomaly: '流量异常',
                EquipmentPower: '设备电量',
                StorageSpace: '存储空间',
                MandatoryApplication: '必装应用',
                ApplicationBlacklistAndWhitelist: '应用黑白名单',
                WIFIBlackAndWhiteList: 'WIFI黑白名单',
                SystemFunction: '系统功能',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                CloudPhone: '云手机',
            },
            ThreateningBehavior: { //威胁行为表格 ----行为类型映射
                BehaviorType: '行为类型',
                InjectionAttack: '注入攻击',
                FrameAttack: '框架攻击',
                DebuggingBehavior: '调试行为',
                LocationFraud: '位置欺诈',
                DomainNameFraud: '域名欺诈',
                ProgramPlugIn: '程序外挂',
                ApplicationCracking: '应用破解',
                HttpsHijacking: 'Https劫持',
                HttpProxy: 'Http代理',
                ApplicationMultiplex: '应用多开',
                HighFrequencyChangeAccount: '高频更换账号',
                HighFrequencyChangeIP: '高频更换IP',
                HighFrequencyChangePosition: '高频更换位置',
                HighFrequencyStartingApplication: '高频启动应用',
                FrameSoftware: '框架软件',
                RiskApplication: '风险应用',
                RootCertificateException: '根证书异常',
                USBDebugging: 'USB调试',
                ROOTJailbreak: 'ROOT/越狱',
                SimulatedPositionAllowed: '允许模拟位置',
                simulator: '模拟器',
                CustomROM: '定制ROM',
                SystemTimeException: '系统时间异常',
                TheScreenLockPasswordPsNotEnabled: '锁屏密码未开启',
                MemoryTampering: '内存篡改',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                CloudPhone: '云手机',
                RiskIP: '风险IP',
            },
            SensitiveBehavior: { //敏感行为表格 ----行为类型映射
                BehaviorType: '行为类型',
                ApplicationSensitiveBehavior: '应用敏感行为',
                ApplicationOfUltraVires: '应用越权行为',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                CloudPhone: '云手机',
            },
            SecurityIncident: { //安全事件表格 
                EventName: '事件名称',
            },
            SDKList: { //SDK行为表格
                sdkName: 'SDK名称',
                BehaviorType: '行为类型',
                ReadSMSRecords: '读取短信记录',
                ReadAddressBook: '读取通讯录',
                GetGeographicalLocation: '获取地理位置',
                ReadStorage: '读取外部存储',
                ObtainHardware: '获取设备硬件信息',
                ObtainSoftware: '获取设备软件信息',
                picturesVideo: '拍照、摄像',
                TurnOnWIFI: '开启WIFI',
                EnableBluetooth: '开启蓝牙',
                macOS: 'macOS',
                Windows: 'Windows',
                VPN: 'VPN',
                CloudPhone: '云手机',
            },
            RunningInformation: { //运行信息表格
                NumberOfStarts: '启动次数',
                DurationOfUse: '使用时长',
                trafficOfUse: '使用流量',
                time: '次', //

            }
        },
    },
    violation: { //违规行为
        reason_0: "设备越狱",
        reason_1: "系统版本",
        reason_2: "应用黑名单",
        reason_3: "应用白名单",
        reason_4: "",
        reason_5: "地理围栏",
        reason_6: "",
        reason_7: "流量异常",
        reason_8: "SIM卡变更",
        reason_9: "违规外联",
        reason_10: "设备管理器未激活",
        reason_11: "证书变更",
        reason_12: "必装应用",
        //16系统
        reason_16: "系统功能",
        reason_22: "应用黑白名单",
        reason_99: "其他违规",
        reason_33: "WIFI黑白名单",
        reason_36: "系统功能",
        reason_13: "SD卡变更",
        reason_14: "设备电量",
        reason_15: "存储空间",
    },
    InterTypeObj: { //上网方式
        0: "无网络",
        1: "WIFI",
        2: "2G",
        3: "3G",
        4: "4G",
        5: "5G",
        1000: "以太网",
        "无网络": "无网络",
        "WIFI": "WIFI",
        "2G": "2G",
        "3G": "3G",
        "4G": "4G",
        "5G": "5G",
        "以太网": "以太网",
    },

};