<template>
  <div id="app">
    <!-- 上海联通迁移--移动端ipad 和手机兼容 -->
    <div :style="cssObjISpcStyle">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  // 上海联通迁移--移动端ipad 和手机兼容
  data() {
    return {
      cssObjISpcStyle:{
        width:"",
        overflow:'auto',
        height:'100%'
      }
    }
  },
  // 2022-09-25    注释  用于i 国电投  UC认证
  created() {
    // 上海联通迁移--移动端ipad 和手机兼容
    let styleFlag = this.IsPC()
    if(styleFlag) {
      this.cssObjISpcStyle.width = ""
      this.cssObjISpcStyle.overflow = "hidden"
    }else {
      this.cssObjISpcStyle.width = "1900px"
      this.cssObjISpcStyle.overflow = "auto"
    }
    // ------------------------------------------------------
    let roleId = sessionStorage.getItem('roleId')
    if (roleId == 3) {
      if (this.$router.path !== '/Running') {
        this.$router.replace('Running')
      }
    } else if (roleId == 1) {
      console.log(3333333333333333333333333333333)
      if (this.$router.path !== '/SourceHome') {
        this.$router.replace('SourceHome')
      }
    } else if (roleId == 2) {
      if (this.$router.path !== '/ManageLog') {
        this.$router.replace('ManageLog')
      }
    }else if (roleId == 4) {
      console.log(3333333333333333333333333333333)
      if (this.$router.path !== '/equipmentManagement') {
        this.$router.replace('equipmentManagement')
      }
    } else {
      setTimeout(() => {
        let urlPath = JSON.parse(sessionStorage.getItem('urlPath'))
        if (urlPath.name == 'UserManage') {
          if (this.$router.path !== '/user') {
            this.$router.replace('user')
          }
        }
        if (urlPath.name == 'DeviceManage') {
          if (this.$router.path !== '/Device') {
            this.$router.replace('Device')
          }
        }
        if (urlPath.name == 'AppManage') {
          if (this.$router.path !== '/App') {
            this.$router.replace('App')
          }
        }
        if (urlPath.name == 'ContentManage') {
          if (this.$router.path !== '/document') {
            this.$router.replace('document')
          }
        }
      }, 100)
    }
  },
  // 上海联通迁移--移动端ipad 和手机兼容
  methods:{
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone","Macintosh",
        "iPad", "iPod"];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
          }
        }
        return flag;
    }
  }
}
</script>
<style>
::v-deep body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 16px;
  overflow: hidden;
  font-family: Microsoft YaHei;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
}
 
::v-deep .el-table th.gutter{ 
    /* 
    * 解决element-ui 表格篡位的问题 👇 
    */  
    display: table-cell!important; 
}
 
#app {
  font-family: Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

 .wrap {
            /* 背景色 */
            background: #123D8C !important;
            color: #fff !important;
            border: 1px solid #123D8C !important;
        }
 
        /* 下方是去除三角箭头的代码 */
        .wrap[x-placement^="top-start"] .popper__arrow::after {
            border-bottom-color: #123D8C !important;
            color: #fff !important;
        }

        .wrap[x-placement^="top-start"] .popper__arrow {
            border-bottom-color: #123D8C !important;
            color: #fff !important;
        } 
        /*//最外层div,修改背景色，边框 */
        .el-popover.monitor-yt-popover {
          background-color: #146ebd;
          border-color: #146ebd;
        }
         /*//修改title的颜色 */
        .wrap .el-popover__title {
          color: white;
        }
        /*//修改下面的小三角，树形名根据组件placement位置做响应修改 */
        .wrap .popper__arrow::after {
          border-top-color: #123D8C !important;
        }
.el-submenu .el-submenu__title {
  height: 36px;
  line-height: 36px;
  font-size: 12px;
  border-bottom: 1px solid #e7e7e7 !important;
}
.el-submenu.is-active .el-submenu__title {
  background: url('./images/sec-selectedbg.png') !important;
}
.breadtitle {
  margin-left: 10px;
  border-bottom: 1px solid #cdd1dd;
  height: 40px;
  line-height: 40px;
  color: #6d7a82;
  font-size: 16px;
}
/*侧边 三角 */
.el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  transform: rotateZ(180deg);
  /*display: none;*/
}
/* 背景分页的选中效果 */
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #eff0f1 !important;
  /* // 进行修改选中项背景和字体 */
  color: #3a91ef !important;
}
/* .el-pagination.is-background .el-pager li:not(.disabled) :hover {
    background-color: #327eca !important;
    /* // 进行修改选中项背景和字体 */
/* color: #3a91ef !important;
  } */
/* 分页 */
.el-pagination {
  text-align: right !important;
  margin-bottom: 100px;
}
.el-pagination .el-pagination__total {
  position: relative !important;
  float: left !important;
  margin-right: 10px;
  font-weight: 400;
  color: #606266;
}
.el-pagination .el-pagination__sizes {
  float: left !important;
  margin: 0 10px 0 0;
  font-weight: 400;
  color: #606266;
}
.el-table__empty-block {
  width: 18% !important;
}
.active {
  border-bottom: 0px solid blue !important;
  color: black;
}
/*按钮组*/
.el-button-group .el-button--primary:first-child {
  border-right-color: #e4e4e6 !important;
  z-index: inherit;
}
.el-button-group .el-button--primary:last-child {
  border-left-color: #e4e4e6 !important;
  z-index: inherit;
}
.el-button-group .el-button--primary:not(:first-child):not(:last-child) {
  border-left-color: #e4e4e6 !important;
  border-right-color: #e4e4e6 !important;
  z-index: inherit;
}
/* 确保按钮在hover状态下显示边框 */
.el-button-group .el-button--primary:not(:first-child):not(:last-child):hover {
  border-left-color: #e4e4e6 !important;
  border-right-color: #e4e4e6 !important;
  z-index: inherit;
}
.el-button-group .el-button--primary:first-child:hover {
  border-right-color: #e4e4e6 !important;
  z-index: inherit;
}
.el-button-group .el-button--primary:last-child:hover {
  border-left-color: #e4e0e0 !important;
  z-index: inherit;
}
.el-button-group .el-button--info:last-child {
  border-left-color: #e4e4e6 !important;
  z-index: inherit;
}
/*流程设置 // 右键菜单 */
div.mxPopupMenu {
  -webkit-box-shadow: 3px 3px 12px #c0c0c0;
  -moz-box-shadow: 3px 3px 12px #c0c0c0;
  box-shadow: 3px 3px 12px #c0c0c0;
  background: url('/assets/img/window.gif');
  position: absolute !important;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  cursor: default;
  display: inline-flex !important;
}
table.mxPopupMenu {
  border-collapse: collapse;
  margin-top: 1px;
  margin-bottom: 1px;
}
tr.mxPopupMenuItem {
  color: black;
  cursor: default;
}
td.mxPopupMenuItem.disabled {
  opacity: 0.2;
}
td.mxPopupMenuItem.disabled {
  _filter: alpha(opacity=20) !important;
}
tr.mxPopupMenuItemHover {
  background-color: #91cd5f;
  color: #ffffff;
}
td.mxPopupMenuItem {
  padding: 2px 8px 2px 8px;
  white-space: nowrap;
  font-family: Arial;
  font-size: 8pt;
  color: #9b3229;
}
td.mxPopupMenuIcon {
  background-color: #d0d0d0;
  padding: 2px 4px 2px 4px;
}
/* .el-tree-node.is-expanded>.el-tree-node__children {
    overflow: hidden;
    overflow-x: auto;
    height: 100%;
} */
.mxTooltip {
  -webkit-box-shadow: 3px 3px 12px #c0c0c0;
  -moz-box-shadow: 3px 3px 12px #c0c0c0;
  box-shadow: 3px 3px 12px #c0c0c0;
  background: #ffffcc;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  font-family: Arial;
  font-size: 8pt;
  position: absolute;
  cursor: default;
  padding: 4px;
  color: black;
}
/* div[role=treeitem] {
  height: 252px;
} */
/* message在同一位置弹出  reset elementUI message */
.el-message {
  top: 20px !important;
}
.el-message:not(:last-child) {
  visibility: hidden;
}
</style>
