import axios from './axios'
import utils from '..'
import {
  getUrl
} from '../tools'
const encrypt = false;
const Base64 = require('js-base64').Base64;
const oneTimeParam = {
  "unUse": ""
};
export default {
  get(url, params) {
    if(url == '/httpServe/alarm/latest?size=5') {
      let Authorization = sessionStorage.getItem('Authorization')
      let config = {
        headers: {
          "Authorization": Authorization
        }
      }
      return axios.get(getUrl(url, params),config)
    }else {
      return axios.get(getUrl(url, params))
    }
  },
  async post(url, params, encrypt, async) {
    if (encrypt) {
      if (async ==undefined) {
        async = true
      }
      return new Promise((resolve) => {
        utils.doAjaxAction(url, params, (data) => {
          resolve(data)
        }, async, 'POST', 'text')
      });
    } else {
      // return axios.post(url, params)

      let param = new FormData();
      var conf = {
        Headers: {
          "Content-Type": "application/json;application/x-www-form-urlencoded;charset=UTF-8;application/vnd.ms-excel;"
        }
      }

      return axios.post(url, param, conf);
    }

  },

  async upload(url, params,callback,fileName) {
    var ts, nonce, sessid, sg;
    ts = "" + new Date().getTime();
    nonce = utils.common.randomStr(32);
    sessid = utils.sessid || "";
    if (!fileName) {
      fileName = params.get("file")["name"];
    }
    sg = $.sign(ts, nonce, sessid, "", fileName);
    // return axios.post(url, param, conf);
    return axios.post(url, params, {
      headers: {
        "Authorization": sessionStorage.getItem("Authorization"),
        "sign": utils.common.sprintf("ts=%s;nonce=%s;udid=%s;sg=%s", ts, nonce, sessid, sg)
      },
      onUploadProgress: progressEvent => {
        // 原生获取上传进度的事件
        if (progressEvent.lengthComputable) {
         callback && callback(progressEvent);
        }
      }
    });
    // console.info(res);
    // console.info(res.data);
    // console.info(res["PromiseResult"]);
    // sign = res["request"].getResponseHeader("sign");
    // if (!sign) {
    //   console.log(url, "url");
    //   console.error("response sign is null!!!");
    //   return;
    // }

    // if (data.indexOf("\"") === 0) {
    //   data = data.substring(1, data.length - 1);
    // }

    // if (!$.verifySign(sign, "", data)) {
    //   console.log(url, "url");
    //   console.error("response sign failed!!!");
    //   return;
    // }

    // ts = 0;
    // $.each(sign.split(";"), function (i, item) {
    //   if (item.indexOf("ts=") === 0) {
    //     ts = item.substr(3);
    //   } else if (item.indexOf("nonce=") === 0) {
    //     nonce = item.substr(6);
    //   } else if (item.indexOf("udid=") === 0) {
    //     utils.sessid = item.substr(5);
    //   }
    // });
    // // 验证响应重放
    // if (utils.lastTs == 0) {
    //   utils.lastTs = ts || 0;
    // } else {
    //   if (ts + 10 * 1000 < utils.lastTs) {
    //     return false;
    //   }
    //   utils.lastTs = Math.max(ts, utils.lastTs);
    // }
    // if (nonce && ts) {

    //   // 解密
    //   data = $.sm4Nt(data,n once, ts, true);
    // }
    // return JSON.parse(data);

  },

  async postExport(url, params, async,userId) { 
    let Authorization = sessionStorage.getItem('Authorization');
    var conf = {
      headers: {
        //'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': Authorization
      },
      responseType: "blob",
    } 
    return axios.post(url, params, conf);
  },
  put(url, params, encrypt = true) {
    if (encrypt) {

    } else {}
  },
  del(url, params, encrypt = true) {
    if (encrypt) {

    } else {

    }
  },
  setOneTimeParam(key, value, jQ) {
    if (!jQ) {
      if (key) {
        oneTimeParam[key] = value;
      }
    } else {
      utils.oneTimeParam.set(key, value);
    }
  },
  getOneTimeParam(key, jQ) {
    var value = undefined;
    if (!jQ) {
      value = oneTimeParam[key];
      delete oneTimeParam[key];
      return value;
    }
    return utils.oneTimeParam.get(key);
  },
  postMessage(value) {
    document.getElementById("iframeBox").contentWindow.postMessage({
        oneTimeParam: value
      },
      "*"
    );
  },
  downloadFile(url, base64Decode) {
    // debugger;
    if (url && false !== base64Decode) {
      // debugger;
      url = Base64.decode(url);
      console.log(url,"url====");
    }
    if (!/^(http|https)/.test(url)) {
      if(url) {
        url = vueConfig.jqUrl.slice(0,vueConfig.jqUrl.length-1) + url
      }
    }
    // window.open(url)
    var myElement = document.createElement("a");
    //myElement.remove();
    myElement.href = url;
    myElement.target="_blank"
    myElement.style.display = "none";
    //myElement.click()
    // 挂载a标签
    document.body.appendChild(myElement)
    myElement.click()
    document.body.removeChild(myElement)
    // 释放blob URL地址
    window.URL.revokeObjectURL(url)
    //myElement.remove();
    //myElement.remove();
    // const iframe = document.createElement("iframe");
    // iframe.src = url;
    // iframe.style.display = "none"; 
    // iframe.style.height = 0; 
    // document.body.appendChild(iframe); 
    // // 定时删除节点
    // setTimeout(() => {
    //    document.body.removeChild(iframe);
    // }, 2000)
    // 导出数据
  },
  //文件下载或许实时进度
  downLoadAll(url,callback) {
    let downProgress = {};
    let uniSign = new Date().getTime() + ''; // 可能会连续点击下载多个文件，这里用时间戳来区分每一次下载的文件，也可以用生成器和迭代器实现文件的唯一标识
    axios.get(
      url, 
      { responseType: 'blob', headers: { "Content-Type": "application/json; charset=utf-8" },
      onDownloadProgress (progress) {
        downProgress = Math.round(100 * progress.loaded / progress.total) // progress对象中的loaded表示已经下载的数量，total表示总数量，这里计算出百分比
        callback(downProgress)
      }}).then( (res)=>{ // 文件流传输完成后，开启文件下载
        console.log(res)
        // if(data.downLoad){
        //   jsFileDownload(res.data,data.downLoad+'.'+data.url.replace(/.+\./,"")); // jsFileDownLoad是用来下载文件流的，下载插件：npm i js-file-download，import引入：import jsFileDownLoad from 'js-file-download'
        // } else {
        //   jsFileDownload(res.data, data.url.split('/')[data.url.split('/').length-1]);
        // }
    }).catch((e)=>{
      this.$message.error('该文件无法下载')
    })
  }
}
