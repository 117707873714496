import './jq/jQuery.md5'
import './jq/jQuery.sm3'
import './jq/jQuery.sm4'
import ElementUI from "element-ui";
var utils; // utils for kubei

utils = {};
utils.maxRowNum = 10; // show row number per page.

utils.scanReport =
{
    interval_id: null
};

utils.sessid = undefined;
utils.lastTs = 0;

// do ajax action function
utils.doAjaxAction = function (url, postData, callback, async, method, dataType) {
    // console.log("test12121");
    if (!utils.common.isString(url)) {
        return;
    }
    if (url.indexOf("?") > 0) {
        url += "&tm=" + $.now();
    } else {
        url += "?tm=" + $.now();
    }
    if (url.indexOf("/") !== 0) {
        url = "/" + url;
    }else {
        url = vueConfig.thePathaddress + url;
    }

    if (!callback) {
        callback = function () { };
    }

    if (undefined === async) {
        async = true;
    }

    // 参数转换
    if (!postData) {
        postData = "";
    } else {
        if (typeof postData === "object") {
            postData = JSON.stringify(postData);
        }
    }

    var ts, nonce;
    ts = "" + new Date().getTime();
    nonce = utils.common.randomStr(32);

    // postData = $.sm4Nt(postData, nonce, ts);

    $.ajax({
        type: method || "POST",
        async: async,
        dataType: dataType || 'json',
        url: url,
        data: { "json": postData },
        timeout: 600000,
        beforeSend: function (xhr) {
            var sessid, sg;
            sessid = sessionStorage.getItem("udidSign")?sessionStorage.getItem("udidSign"):'';//utils.sessid || "" 
            sg = $.sign(ts, nonce, sessid, "", postData);
            xhr.setRequestHeader("sign", utils.common.sprintf("ts=%s;nonce=%s;udid=%s;sg=%s", ts, nonce, sessid, sg));
            xhr.setRequestHeader("Authorization", sessionStorage.getItem("Authorization"));
        },
        success: function (data, textStatus, xhr) {
            var e_code, e_message, sign, ts, nonce, toLogin;
            utils.dialog.close("tip");
            toLogin = function (e_code) {
                switch (e_code) {
                    case 0XFFFD:
                        e_message = lang.tip.session_expired;
                        break;
                    case 0XFFFF:
                    case 0XFFEF:
                    case 0XFFEE:
                    case 0XFFED:
                        e_message = lang.tip.no_access;
                        break;
                    case 0XFFFE:
                        e_message = lang.tip.unknown;
                        break;
                    default:
                        e_message = data["errorMsg"];
                        break;
                }
                utils.dialog.warning2(e_message, function () {
                    utils.common.tologin();
                });
            };
            if (!data) {
                toLogin(0XFFFE);
                return;
            }

            e_code = data["errorCode"];
            if (e_code != undefined && e_code >= 0XFF00) {
                toLogin(e_code);
                return;
            }

            // 验证响应签名
            let arr = JSON.parse(data)
            if(arr.code == 401 ||arr.code==603) {
                let dom = document.createElement('div')
                dom.innerHTML = "登录已过期跳转登录页面"
                dom.style.width = "340px"
                dom.style.boxSizing = "border-box"
                dom.style.border = "1px solid #ebeef5"
                dom.style.position = "fixed"
                dom.style.left = "40%"
                dom.style.top = "20px"
                dom.style.transition = "translateX(-50%)"
                dom.style.overflow = "hidden"
                dom.style.padding = "15px 15px 15px 20px"
                dom.style.display = "flex"
                dom.style.alignItems = "center"
                dom.style.zIndex = "999"
                dom.style.backgroundColor = "#fef0f0"
                dom.style.borderColor = "#fde2e2"
                dom.style.borderRadius = "5px"
                dom.style.color = "#F56C6C"
                dom.style.fontSize = "14px"
                document.body.appendChild(dom)
                setTimeout(() => {
                    dom.style.display = "none"
                    sessionStorage.clear()
                    window.location.href = vueConfig.jqUrl + "vue/web/login"
                }, 3000);
            }
            sign = xhr.getResponseHeader("sign");
            if (!sign) {
                // console.log(url, "url");
                console.error("response sign is null!!!");
                return;
            }

            if (data.indexOf("\"") === 0) {
                data = data.substring(1, data.length - 1);
            }

            // if (!$.verifySign(sign, "", data)) {
            //     // console.log(url, "url");
            //     console.error("response sign failed!!!");
            //     return;
            // }

            ts = 0;
            $.each(sign.split(";"), function (i, item) {
                if (item.indexOf("ts=") === 0) {
                    ts = item.substr(3);
                } else if (item.indexOf("nonce=") === 0) {
                    nonce = item.substr(6);
                } else if (item.indexOf("udid=") === 0) {
                    utils.sessid = item.substr(5);
                }
            });

            // 验证响应重放
            if (utils.lastTs == 0) {
                utils.lastTs = ts || 0;
            } else {
                if (ts + 10 * 1000 < utils.lastTs) {
                    return false;
                }
                utils.lastTs = Math.max(ts, utils.lastTs);
            }
            if (nonce && ts) {

                // 解密
                // data = $.sm4Nt(data, nonce, ts, true);
            }

            data = JSON.parse(data);

            callback.call(this, data);
        },
        error: function (data, textStatus, errorThrown) {
            console.log(data)
            if (window.console) {
                console.error("doAjaxAction, error:" + JSON.stringify(data));
            }
        }
    });
};

// menu functions
utils.menu = {
    getListByPId: function (parentId, callback) {
        var params;
        params = {
            parentId: parentId
        };

        utils.doAjaxAction("/menu/selectList.do", params, function (data) {
            if (data.data) {
                callback.call(this, data.data);
            }
        }, false);
    }
};

// locale functions
utils.locale = {
    singleObj: function (htmlObj) {
        var key, localeArr, localeObj, localeStr;

        if (!htmlObj) {
            return;
        }

        localeStr = "";
        localeObj = lang;
        key = htmlObj.attr("key");
        if (key) {
            localeArr = key.split(".");
            for (var i = 0; i < localeArr.length; i++) {
                localeStr = localeArr[i];
                localeObj = localeObj[localeStr];

                if (typeof localeObj !== "object") {
                    localeStr = localeObj;
                    break;
                }
            }
        }
        switch (htmlObj[0].tagName.toLowerCase()) {
            case "img":
                htmlObj.attr("desc", localeStr);
                break;
            default:
                htmlObj.html(localeStr);
                break;
        }
        // htmlObj.html(localeStr);
    },
    bySelector: function (selector) {
        selector.each(function () {
            utils.locale.singleObj($(this));
        });
    }
};

// param functions
utils.param = {
    paramObj: $("#params"),
    isClear: true,
    clear: function (force) {
        if (force) {
            this.isClear = true;
        }
        if (this.isClear) {
            utils.param.paramObj.val("");
        }
    },
    setParams: function (params, isCover) {
        // params: object or string([name0:value0, name1:value1, ...], value is not array type).
        // isCover: boolean, default is true.
        var oldParamObj, newParamObj, newParam_arr, newParam, newParamName_arr, newParamName;

        if (!params) {
            return;
        }

        // handle params
        switch (typeof params) {
            case "object":
                newParamObj = params;
                break;
            case "string":
                newParamObj = {};
                newParam_arr = params.split(",");
                for (var i = 0; i < newParam_arr.length; i++) {
                    newParam = newParam_arr[i].split(":");
                    newParamObj[newParam[0]] = newParam[1];
                }
                break;
            default:
                newParamObj = {};
                break;
        }

        if (undefined == isCover) {
            isCover = true;
        }

        oldParamObj = utils.param.getParams();
        newParamName_arr = utils.common.getOwnPropNames(newParamObj);
        //newParamName_arr = Object.getOwnPropertyNames(newParamObj);
        for (i = 0; i < newParamName_arr.length; i++) {
            newParamName = newParamName_arr[i];
            if (isCover || undefined === oldParamObj[newParamName]) {
                oldParamObj[newParamName] = newParamObj[newParamName];
            }
        }

        var str = JSON.stringify(oldParamObj);
        utils.param.paramObj.val(str);
    },
    getParams: function () {
        var str, paramObj;
        str = utils.param.paramObj.val();
        try {
            paramObj = JSON.parse(str);
        } catch (e) {
            paramObj = {};
        }
        return paramObj;
    },
    getParamByName: function (name) {
        var params;
        params = utils.param.getParams();

        return (params && undefined !== params[name]) ? params[name] : "";
    },
    getAction: function () {
        return utils.param.getParamByName("action");
    },
    delParams: function () {
        var paramObj;
        if (arguments.length > 0) {
            paramObj = utils.param.getParams();
            for (var i = 0; i < arguments.length; i++) {
                if (arguments[i]) {
                    paramObj[arguments[i]] = undefined;
                }
            }

            utils.param.setParams(paramObj, true);
        }
    }
};

//page functions
utils.page = {
    contentObj: $("#content"),
    pos_arr: [],
    htmlEncode: function (text) {
        if (utils.common.isString(text)) {
            return text.replace(/&/g, '&amp;').replace(/\"/g, '&quot;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        }
        return text;
    },
    showHtmlObj: function (htmlObj, mode) {
        if (htmlObj) {
            if (!mode) {
                mode = "";
            }

            htmlObj.css("display", mode);
        }
    },
    hideHtmlObj: function (htmlObj) {
        utils.page.showHtmlObj(htmlObj, "none");
    },
    changePosition: function (text) {
        var pos_str, pos_arr, pos_obj, left_sec_menu;

        pos_arr = [];
        pos_obj = $("#position");

        pos_arr.push($("#header_menu li a.selected span").text());
        pos_arr.push($("#left_menu li.selected:first a span").text());
        left_sec_menu = $("#left_menu ul.sec_nav li.selected:first a span").text();
        if (left_sec_menu) {
            pos_arr.push(left_sec_menu);
        }
        if (text) {
            pos_arr.push(text);
        }

        pos_str = "<span>" + pos_arr.join("</span> &gt; <span>") + "</span>";

        pos_obj.html(pos_str);

        pos_obj.find("span:last").addClass("text_green");
    },
    changeTitle: function () {
        var title;
        title = $("#left_menu ul.sec_nav li.selected a span").text();

        if ("" === title) {
            title = $("#left_menu li.selected a span").text();
        }

        $("#title").html(title);

        $("#back").unbind("click").css("display", "none");
    },
    changeContent: function (url, params, isCoverParam, contentObj) {
        // url: relative path from web root dir, default is default.php.
        // params: object or string([name0:value0�name1:value1, ...]).
        // isAddParam: boolean, default is false
        // contentObj: html document object, default is utils.page.contentObj.

        var callback;

        // handle url
        if (!url) {
            url = "default.do";
        }

        // handle params
        utils.param.setParams(params, isCoverParam);

        // handle contentObj
        if (!contentObj) {
            contentObj = utils.page.contentObj;
        }

        if (contentObj[0]["id"] == "dialog") {
            $('#dialog').css("padding", "0 1em 2em");
        }

        callback = function (data) {
            var errorObj, e_message;
            if (data.indexOf("{\"errorCode\":") == 0) {
                contentObj.empty();

                errorObj = JSON.parse(data);
                switch (errorObj["errorCode"]) {
                    case 0XFFFD:
                        e_message = lang.tip.session_expired;
                        break;
                    case 0XFFFF:
                    case 0XFFEF:
                    case 0XFFEE:
                    case 0XFFED:
                        e_message = lang.tip.no_access;
                        break;
                    case 0XFFFE:
                        e_message = lang.tip.unknown;
                        break;
                    default:
                        e_message = data["errorMsg"];
                        break;
                }
                utils.dialog.warning2(e_message, function () {
                    utils.common.tologin();
                });
            }
        };

        url = url + (url.indexOf("?") > 0 ? "&" : "?") + "_=" + new Date().getTime();

        if (params) {
            if (typeof params === "object") {
                params = { "json": JSON.stringify(params) };
            }
        }

        contentObj.empty().load(url, params, callback);
    },
    changeTab: function (tabObj, paramObj) {
        var url, controller;

        if (!tabObj) {
            tabObj = $(".tab .tabPage a:first");
        }

        $(".tab .tabPage a").removeClass("selected");
        tabObj.addClass("selected");

        // set controller;
        controller = tabObj.attr("controller");
        if (controller) {
            utils.param.setParams({ controller: controller });
        }

        url = tabObj.attr("url");
        // utils.param.delParams("action"); // delete action param
        if (url) {
            utils.page.changeContent(url, paramObj, true, $("#tab_content"));
        }

    },
    water: function (ctl_id, tip) {
        $("#" + ctl_id).attr("placeholder", tip);
    },
    bindEnter: function (ctl_id, enter_btn_id, obj) {
        if (obj == undefined) {
            obj = utils.page.contentObj;
        }
        obj.find("#" + ctl_id).unbind("keydown").bind("keydown", function (event) {
            if (event.keyCode == "13") {
                $("#" + enter_btn_id).click();
            }
        });
    },
    resizeDialog: function (dialog_ids) {
        var left, top, body_width, body_height, ui_dialog;

        if (!$.isArray(dialog_ids)) {
            return;
        }

        body_width = $("body").width();
        body_height = $("body").height();

        for (var i = 0; i < dialog_ids.length; i++) {
            ui_dialog = $("#" + dialog_ids[i]).parents(".ui-dialog");
            left = (body_width - ui_dialog.width()) / 2;
            top = (body_height - ui_dialog.height()) / 2;
            ui_dialog.css({ "left": left + "px", "top": top + "px" });
        }
    }
};

// table functions
utils.table = {
    fillContent: function (tbody, data, callback) {
        var trs, cls;
        if (!tbody) {
            return;
        }

        trs = "";
        if (data) {

            for (var i = 0; i < data.length; i++) {
                cls = (i % 2 === 0) ? "bg01" : "bg02";
                trs += '<tr class="' + cls + '">';
                trs += callback.call(this, data[i], i, (i === data.length - 1));
                trs += '</tr>';
            }
            trs = trs.replace(/<td><\/td>/g, "<td>&nbsp;</td>");
        }
        tbody.empty().append(trs);
    },
    genPager: function (total_record, currPage, callback, contentObj, rowNumber, delivery) {
        var total_page, pager_str, min, max, left, right, jumpToOtherPageFun;

        if (!contentObj) {
            contentObj = utils.page.contentObj;
        }

        if (isNaN(total_record)) {
            total_record = 0;
        }
        currPage = currPage ? parseInt(currPage) : 1; // default page is 1.

        // calc total page
        total_page = Math.ceil(total_record / (rowNumber || utils.maxRowNum));
        total_page = Math.max(1, total_page);

        // get page str
        pager_str = '<span class="pl">';
        pager_str += lang.common.total_rec_l + ' <strong>' + total_record + '</strong> ' + lang.common.total_rec_r;
        if (rowNumber) {
            pager_str += lang.common.total_rowNumber_l + '<select class="rowNumber">';
            pager_str += '<option>' + lang.common.rowNumber_10 + '</option>';
            if (total_record > 10) {
                pager_str += '<option>' + lang.common.rowNumber_30 + '</option>';
            }
            if (total_record > 30) {
                pager_str += '<option>' + lang.common.rowNumber_50 + '</option>';
            }
            pager_str += '</select>' + lang.common.total_rowNumber_r;
            // pager_str += lang.common.total_rec_l + ' <strong>' + total_record + '</strong> ' + lang.common.total_rec_r;
        }
        pager_str += '</span>';

        pager_str += '<span class="pr">';

        if (!delivery) {
            /*if (currPage > 1) {
                pager_str += '<a value="1">' + lang.action.first + '</a>';
                pager_str += '<a value="-1">' + lang.action.prev + '</a>';
            }*/

            if (total_page > 1) {
                if (currPage > 1) {
                    pager_str += '<a value="1">' + lang.action.first + '</a>';
                    pager_str += '<a value="-1">' + lang.action.prev + '</a>';
                }

                // calc min and max
                min = Math.max(1, currPage - 4);
                max = Math.min(total_page, currPage + 4);
                left = currPage - min;
                if (left < 4) {
                    max = Math.min(total_page, max + (4 - left)); // calibrate max
                }
                right = max - currPage;
                if (right < 4) {
                    min = Math.max(1, min - (4 - right)); // calibrate min
                }
                for (var i = min; i <= max; i++) {
                    pager_str += "<a";
                    pager_str += (i === currPage) ? ' class="selected"' : "";
                    pager_str += ' value="' + i + '">' + i + '</a>';
                }
            }

            if (currPage < total_page) {
                pager_str += '<a value="+1">' + lang.action.next + '</a>';
                pager_str += '<a value="' + total_page + '">' + lang.action.last + '</a>';
            }
        }

        if (total_page > 1) {
            pager_str += lang.common.total_rec_l + ' <strong>' + total_page + '</strong> ' + lang.common.total_page;
            pager_str += '<input id="jump" maxlength="30"/>';
            pager_str += '<button class="jump_btn">' + lang.action.jump + '</button>';
        }
        pager_str += '</span>';

        // add pager content
        contentObj.find(".page").empty().append(pager_str);

        if (rowNumber) {
            if (total_record <= 10) {
                contentObj.find(".page .pl .rowNumber").val("10");
            } else {
                contentObj.find(".page .pl .rowNumber").val(rowNumber);
            }
        }

        // bind a click event
        contentObj.find(".page").find("a[class!='disabled']").bind("click", function () {
            var toPage;
            toPage = $(this).attr("value");
            switch (toPage) {
                case "-1":
                    toPage = currPage - 1;
                    break;
                case "+1":
                    toPage = currPage + 1;
                default:
                    break;
            }

            callback.call(this, toPage);
        });

        jumpToOtherPageFun = function () {
            var jumpToPage;
            jumpToPage = contentObj.find(".page").find("#jump").val();

            if (!utils.form.verifyData.empty(jumpToPage, lang.action.jump_page_num)) {
                //is empty
                return false;
            } else if (jumpToPage < 1) {
                //less than 1
                utils.dialog.alert(lang.action.jump_page_num + lang.action.less1);
                return false;
            } else if (jumpToPage > total_page) {
                //more than total_page
                utils.dialog.alert(lang.action.jump_page_num + lang.action.moreTotal);
                return false;
            } else if (/^\D+$/ig.test(jumpToPage)) {
                utils.dialog.alert(lang.action.jump_page_num + lang.action.number);
                return false;
            } else if (jumpToPage % 1 !== 0) {
                utils.dialog.alert(lang.action.jump_page_num + lang.action.integers);
                return false;
            }

            callback.call(contentObj.find(".page").find("#jump"), jumpToPage);
        };

        contentObj.find(".page").find(".jump_btn").unbind("click").bind("click", jumpToOtherPageFun);

        // utils.page.bindEnter("jump", "jump_btn", contentObj);
        contentObj.find(".page").find("#jump").unbind("keyup").bind("keyup", function (event) {
            if (event.keyCode == "13") {
                jumpToOtherPageFun();
            }
        });
    },
    genPagerLeft: function (total_record, contentObj) {
        var pager_str;

        if (!contentObj) {
            contentObj = utils.page.contentObj;
        }

        if (isNaN(total_record)) {
            total_record = 0;
        }

        // get page str
        pager_str = '<span class="pl">';
        pager_str += lang.common.total_rec_l + ' <strong>' + total_record + '</strong> ' + lang.common.total_rec_r;
        pager_str += '</span>';

        contentObj.find(".page").empty();
        contentObj.find(".page").append(pager_str);
    },
    genCheckboxTd: function (value, name, isChecked, isDisabled) {
        var td;

        td = '<td><input type="checkbox" value="' + value + '"';
        if (name) {
            td += ' name="' + name + '"';
        }

        if (isChecked) {
            td += ' checked';
        }

        if (isDisabled) {
            td += ' disabled';
        }

        td += " /></td>";
        return td;
    },
    genRadioTd: function (value, name, isChecked, isDisabled) {
        var td;

        td = '<td><input type="radio" value="' + value + '"';
        if (name) {
            td += ' name="' + name + '"';
        }

        if (isChecked) {
            td += ' checked';
        }

        if (isDisabled) {
            td += ' disabled';
        }

        td += " /></td>";
        return td;
    },
    genOperateTd: function (value, operate_arr, is_img, color) {
        var td, getATag;

        if (operate_arr.length == 0) {
            return '<td>&nbsp;</td>';
        }

        if (undefined === is_img) {
            is_img = true;
        }

        // get one atag;
        getATag = function (value, operate) {
            var aTagName, aTagShow, img_src, img_txt;
            if (utils.common.isString(operate)) {
                aTagShow = aTagName = operate;
            } else if (typeof operate === "object") {
                aTagName = operate["name"];
                aTagShow = undefined !== operate["show"] ? operate["show"] : aTagName;
            } else {
                return "";
            }

            switch (aTagName) {
                case "delete":
                    img_src = "images/icon_del.png";
                    img_txt = lang.action.del;
                    break;
                case "edit":
                    img_src = "images/icon_write.png";
                    img_txt = lang.action.edit;
                    break;
                case "start":
                    img_src = "images/icon_start.png";
                    img_txt = lang.action.start;
                    break;
                case "stop":
                    img_src = "images/icon_stop.png";
                    img_txt = lang.action.stop;
                    break;
                case "pause":
                    img_src = "images/icon_pause.png";
                    img_txt = lang.action.pause;
                    break;
                case "restart":
                    img_src = "images/icon_restart.png";
                    img_txt = lang.action.restart;
                    break;
                case "shutdown":
                    img_src = "images/icon_stop.png";
                    img_txt = lang.action.shutdown;
                    break;
                case "resume":
                    img_src = "images/icon_recover_blue.png";
                    img_txt = lang.action.resume;
                    break;
                case "upgrade":
                    img_src = "images/icon_upgrade.png";
                    img_txt = lang.action.upgrade;
                    break;
                case "detail":
                    img_src = "images/icon_detail.png";
                    img_txt = lang.action.detail;
                    break;
                case "rollback":
                    img_src = "images/icon_recover_blue.png";
                    img_txt = lang.action.rollback;
                    break;
                case "bind":
                    img_src = "images/icon_bind.png";
                    img_txt = aTagShow || lang.action.bind;
                    break;
                case "sync":
                    img_src = "images/icon_sync.png";
                    img_txt = lang.action.sync;
                    break;
                case "unbind":
                    img_src = "images/icon_unbind.png";
                    img_txt = lang.action.unbind;
                    break;
                case "resetpwd":
                    img_src = "images/reset_pwd.png";
                    img_txt = lang.action.resetpwd;
                    break;
                case "copy":
                    img_src = "images/icon_copy.png";
                    img_txt = lang.action.copy;
                    break;
                case "up":
                    img_src = "images/icon_up.png";
                    img_txt = lang.action.up;
                    break;
                case "down":
                    img_src = "images/icon_down.png";
                    img_txt = lang.action.down;
                    break;
                case "download":
                    img_src = "images/icon_download.png";
                    img_txt = lang.action.download;
                    break;
                case "setVisible":
                    img_src = "images/icon_visible.png";
                    img_txt = lang.action.setVisibleWide;
                    break;
                case "userUrl":
                    img_src = "images/ico_url.png";
                    img_txt = lang.userInfo.userUrl;
                    break;
                case "install":
                    img_src = "images/ico_install.png";
                    img_txt = lang.app_list.install;
                    break;
                case "history":
                    img_src = "images/ico_history.png";
                    img_txt = lang.device.history;
                    break;
                case "track":
                    img_src = "images/ico_track.png";
                    img_txt = lang.device.route;
                    break;
                case "permission":
                    img_src = "images/icon_power.png";
                    img_txt = lang.app_list.permission;
                    break;
                case "isViolation":
                    img_src = "images/icon_compliance.png";
                    img_txt = lang.action.isViolation;
                    break;
                case "delivery":
                    img_src = "images/icon_delivery.png";
                    img_txt = lang.action.push;
                    break;
                case "fasten":
                    img_src = "images/icon_fasten.png";
                    img_txt = lang.action.fasten;
                    break;
                case "check":
                    img_src = "images/icon_check.png";
                    img_txt = lang.action.check;
                    break;
                case "movestore":
                    img_src = "images/icon_movestore.png";
                    img_txt = lang.action.moveToStore;
                    break;
                case "+":
                    img_src = "images/icon_line_add.png";
                    img_txt = lang.action.lineAdd;
                    break;
                case "-":
                    img_src = "images/icon_line_del.png";
                    img_txt = lang.action.lineDel;
                    break;
                case "setting":
                    img_src = "images/icon_setting.png";
                    img_txt = lang.action.setting;
                    break;
                default:
                    img_txt = aTagShow;
                    break;
            }
            if (is_img && undefined != img_src) {
                img_src = utils.common.sprintf('<img src="%s" width="16" height="16" />', img_src);
            }

            return utils.common.sprintf('<a name="%s" title="%s" value="%s" class="%s" style="color:' + color + '">%s</a>', aTagName, img_txt, value,
                (is_img && img_src ? "" : "orange"), (is_img && img_src ? img_src : img_txt));
        };

        td = '<td>';
        td += '<span class="' + (is_img ? "icon" : "operation") + '">';
        for (var i = 0; i < operate_arr.length; i++) {
            td += getATag(value, operate_arr[i], is_img);
        }
        td += '</span>';
        td += '</td>';
        return td;
    },
    genSnTd: function (i) {
        return "<td>" + (i + 1) + "</td>";
    },
    genEmptyTd: function () {
        return "<td>&nbsp;</td>";
    },
    genTd: function (text, showTitle, encode, color) {
        var td, reg;

        if (text === undefined || text === null) {
            text = "";
        }

        td = "<td>";
        if (showTitle === true) {
            if (encode === true) {
                text = utils.page.htmlEncode(text);
                if (typeof text == "string") {
                    reg = / /g;
                    text = text.replace(reg, "&nbsp;");
                }
            }

            if (color) {
                td += '<label title="' + text + '" style="color:' + color + '">' + text + '</label>';
            } else {
                td += '<label title="' + text + '">' + text + '</label>';
            }
        } else {
            td += text;
        }

        td += "</td>";

        return td;
    },
    genHiddenTd: function (text, name) {
        var td;
        td = "<td style='display: none'";
        if (name != undefined) {
            td += " name=\"" + name + "\"";
        }
        td += ">" + text + "</td>";
        return td;
    }
};

// form functions
utils.form = {
    setData: function (data, name_prefix, contentObj) {
        var name_arr, name, objs, setCtlValue;
        if (data
            && typeof data === "object") {
            if (!name_prefix) {
                name_prefix = "";
            }

            if (!contentObj) {
                contentObj = utils.page.contentObj;
            }

            // set value to control
            setCtlValue = function (objs, value) {
                var obj_type, obj_name, i;
                if (objs &&
                    objs.length > 0 &&
                    undefined !== value) {
                    obj_name = objs[0].name;
                    switch (objs[0].tagName.toLowerCase()) {
                        case "input":
                            obj_type = objs.attr("type");
                            if (obj_type) {
                                obj_type = obj_type.toLowerCase();
                            }
                            if (obj_type === "radio") {
                                contentObj.find("input[name='" + obj_name + "'][value='" + value + "']").prop("checked", true);
                            } else if (obj_type === "checkbox") {
                                if (objs.length == 1) {
                                    value = (value && value != "0" && value != "false");
                                    objs.prop("checked", value);
                                } else {
                                    if (!$.isArray(value)) {
                                        value = value.split(",");
                                    }
                                    for (i = 0; i < value.length; i++) {
                                        contentObj.find("input[name='" + obj_name + "'][value='" + value[i] + "']").prop("checked", true);
                                    }
                                }
                            } else if (obj_type !== "password") {
                                objs.val(value);
                            }
                            break;
                        case "password":
                        case "textarea":
                            objs.val(value);
                            break;
                        case "select":
                            if (!objs.attr("multiple")) {
                                objs.val(value);
                            } else {
                                for (i = 0; i < value.length; i++) {
                                    objs.find("option[value='" + value[i] + "']").attr("selected", true);
                                }
                            }
                            break;
                        case "span":
                            objs.html(value);
                            break;
                        default:
                            break;
                    }
                }
            };

            name_arr = utils.common.getOwnPropNames(data);
            //name_arr = Object.getOwnPropertyNames(data);
            for (var i = 0; i < name_arr.length; i++) {
                name = name_arr[i];
                if ((typeof data[name] === "object")
                    && !(data[name] instanceof Array)) {
                    name_prefix += name + ".";
                    utils.form.setData(data[name], name_prefix, contentObj);
                    name_prefix = name_prefix.substr(0, name_prefix.length - name.length - 1);
                } else {
                    objs = contentObj.find("*[name='" + name_prefix + name + "']");
                    if (objs.length > 0) {
                        setCtlValue(objs, data[name]);
                    }
                }
            }
        }
    },
    getData: function (contentObj) {
        var controls, data, control_name, last_control_name, control_name_arr, control_value, newObj,
            params, param_name_arr, param_name, getValue;
        data = {};
        getValue = function (control) {
            var value, control_type, children, ctls;
            if (control) {
                switch (control["tagName"].toLowerCase()) {
                    case "input":
                        control_type = control["type"];
                        if (control_type) {
                            control_type = control_type.toLowerCase();
                        }

                        if (control_type === "radio") {
                            value = utils.page.contentObj.find("input[type='radio'][name='" + control_name + "']:checked").attr("value");
                        } else if (control_type === "checkbox") {
                            ctls = utils.page.contentObj.find("input[type='checkbox'][name='" + control_name + "']");
                            if (ctls.length == 1) {
                                if (ctls[0]["value"] !== "on") {
                                    value = ctls[0]["value"];
                                } else {
                                    value = control["checked"] ? "1" : "0";
                                }

                            } else {
                                value = [];
                                ctls.each(function () {
                                    if ($(this).is(":checked")) {
                                        value.push($(this).attr("value"));
                                    }
                                });
                                value = value.join(",");
                            }
                        } else {
                            value = $.trim(control["value"]);
                        }
                        break;
                    case "textarea":
                        value = control["value"];
                        break;
                    case "select":
                        if (!control.multiple) {
                            value = control["value"];
                        } else {
                            value = [];
                            children = control.children;
                            for (var i = 0; i < children.length; i++) {
                                if (children[i].selected) {
                                    value.push(children[i].value);
                                }
                            }
                        }
                        break;
                    default:
                        value = "";
                        break;
                }
            }
            return value;
        };

        // handle page input


        if (!contentObj) {
            contentObj = utils.page.contentObj;
        }
        controls = contentObj.find("input,textarea,select");
        for (var i = 0; i < controls.length; i++) {
            control_name = controls[i]["name"];
            if (control_name) {
                if (control_name == last_control_name) {
                    continue;
                }
                last_control_name = control_name;
                control_value = getValue(controls[i]);

                newObj = data;
                control_name_arr = control_name.split(".");
                for (var j = 0; j < control_name_arr.length; j++) {
                    if (control_name_arr[j]) {
                        if (j === control_name_arr.length - 1) {
                            if (undefined === newObj[control_name_arr[j]]) {
                                newObj[control_name_arr[j]] = control_value;
                            } else if (newObj[control_name_arr[j]] instanceof Array) {
                                newObj[control_name_arr[j]] = newObj[control_name_arr[j]].concat(control_value);
                            }
                        } else {
                            if (undefined === newObj[control_name_arr[j]]) {
                                newObj[control_name_arr[j]] = {};
                            }
                            newObj = newObj[control_name_arr[j]];
                        }
                    }
                }
            }
        }

        // handle params
        params = utils.param.getParams();
        param_name_arr = utils.common.getOwnPropNames(params);
        //param_name_arr = Object.getOwnPropertyNames(params);
        for (i = 0; i < param_name_arr.length; i++) {
            param_name = param_name_arr[i];
            if (param_name && undefined === data[param_name]) {
                data[param_name] = params[param_name];
            }
        }

        return data;
    },
    getlabel: function (obj) {
        var spanObj, label;

        if (obj) {
            spanObj = obj.parents("div[class='group']").find("label span");
            if (spanObj.length > 0) {
                label = spanObj.html().replace(/：|:/g, "");
            }
            return label;
        }
    },
    verifyData: {
        htmlObj: undefined,
        empty: function (value, label) {
            value = $.trim(value);
            if (value.length === 0) {
                utils.dialog.alert(lang.tip.enter(label));
                return false;
            }
            return true;
        },
        empty2: function (value, tip) {
            value = $.trim(value);
            if (value.length === 0) {
                utils.dialog.alert(tip);
                return false;
            }
            return true;
        },
        num: function (value, label, min, max) {
            if (!/^(0|[1-9][0-9]*)$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isNum);
                return false;
            }

            value = parseInt(value);
            if ((undefined !== min)
                && (undefined !== max)) {
                if ((min > value) || (max < value)) {
                    utils.dialog.alert(label + lang.tip.min_max(min, max));
                    return false;
                }
            } else {
                if ((undefined !== min) && (min > value)) {
                    utils.dialog.alert(label + lang.tip.minVal(min));
                    return false;
                }

                if ((undefined !== max) && (max < value)) {
                    utils.dialog.alert(label + lang.tip.maxVal(max));
                    return false;
                }
            }
            return true;
        },
        num2: function (value, label) {
            // not care is true number.
            if (!/^\+?[0-9]*$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isNum);
                return false;
            }

            return true;
        },
        num3: function (value, label) {
            // not care is true number.
            if (!/^[0-9]{4}$/.test(value)) {
                utils.dialog.alert(lang.tip.isFourNum);
                return false;
            }

            return true;
        },
        enlow: function (value, label) {
            if (!/^[a-z]+$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isEnLow);
                return false;
            }
            return true;
        },
        enlownum: function (value, label) {
            if (!/^[a-z0-9]+$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isEnLow);
                return false;
            }
            return true;
        },

        enennum: function (value, label) {
            if (!/^[A-Za-z][A-Za-z0-9]+$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isEnEnNum);
                return false;
            }
            return true;
        },
        ennum: function (value, label) {
            if (!/^[A-Za-z0-9]+$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isEnNum);
                return false;
            }
            return true;
        },
        ennumul: function (value, label) {
            if (!/^[A-Za-z0-9_]+$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isEnNum_);
                return false;
            }
            return true;
        },
        enennumul: function (value, label) {
            if (!/^[A-Za-z][A-Za-z0-9_]*$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isEnEnNum_);
                return false;
            }
            return true;
        },
        email: function (value, label) {
            if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isEmail);
                return false;
            }
            return true;
        },
        "multi-email": function (value, label) {
            var email_arr, email, i;
            email_arr = value.split(";");
            for (i = 0; i < email_arr.length; i++) {
                email = $.trim(email_arr[i]);
                if (!utils.form.verifyData.email(email, label)) {
                    return false;
                }
            }
            return true;
        },
        telephone: function (value, label) {
            if (!/^1(3|4|5|7|8)\d{9}$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isPhone);
                return false;
            }
            return true;
        },
        phone: function (value, label) {
            if (!/^1\d{10}$/.test(value) &&
                !/^\d{3}-\d{8}/.test(value) &&
                !/^\d{4}-\d{7}/.test(value)) {
                utils.dialog.alert(label);
                return false;
            }
            return true;
        },
        "multi-phone": function (value, label, split) {
            split = split || ",";
            var email_arr, email, i;
            email_arr = value.split(split);
            for (i = 0; i < email_arr.length; i++) {
                email = $.trim(email_arr[i]);
                if (!utils.form.verifyData.phone(email, label)) {
                    return false;
                }
            }
            return true;
        },
        ipv4: function (value, label) {
            var ip_arr, ip;

            if (!/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(value)) {
                if (undefined !== label) {
                    utils.dialog.alert(label);
                }
                return false;
            }

            ip_arr = value.split('.');
            for (var i = 0; i < 4; i++) {
                ip = parseInt(ip_arr[i]);
                if (ip > 255) {
                    if (undefined !== label) {
                        utils.dialog.alert(label);
                    }
                    return false;
                }

                if ((i == 0) && (ip === 0 || ip === 127 || ip > 223)) {
                    if (undefined !== label) {
                        utils.dialog.alert(label);
                    }
                    return false;
                }
            }
            return true;
        },
        "multi-ipv4": function (value, label, split) {
            split = split || "\n";
            var ipv4sArr = value.split(split);

            if (utils.common.isRepeat(ipv4sArr)) {
                utils.dialog.alert(lang.policy.networkAccessBW + lang.policy.networkAccessBWList_DESC);
                return false;
            }

            for (var i = 0; i < ipv4sArr.length; i++) {
                if (!utils.form.verifyData.ipv4(ipv4sArr[i], label)) {
                    return false;
                }
            }
            return true;
        },
        ipv6: function (value, label) {
            if (!value.match(/:/g)) {
                if (undefined !== label) {
                    utils.dialog.alert(label + lang.tip.isIpv6);
                }
                return false;
            } else if (value.match(/:/g).length <= 7
                && /::/.test(value)
                ? /^([\da-f]{1,4}(:|::)){1,6}[\da-f]{1,4}$/i.test(value)
                : /^([\da-f]{1,4}:){7}[\da-f]{1,4}$/i.test(value)) {
                return true;
            } else {
                if (undefined !== label) {
                    utils.dialog.alert(label + lang.tip.isIpv6);
                }
                return false;
            }
        },
        ip: function (value, label) {
            // verify ipv4
            if (/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/g.test(value)) {
                if (RegExp.$1 < 256 && RegExp.$2 < 256 && RegExp.$3 < 256 && RegExp.$4 < 256) {
                    return true;
                }
            }

            // verify ipv6
            if (!value.match(/:/g)) {
                utils.dialog.alert(label + lang.tip.isIp);
                return false;
            } else if (value.match(/:/g).length <= 7
                && /::/.test(value)
                ? /^([\da-f]{1,4}(:|::)){1,6}[\da-f]{1,4}$/i.test(value)
                : /^([\da-f]{1,4}:){7}[\da-f]{1,4}$/i.test(value)) {
                return true;
            } else {
                utils.dialog.alert(label + lang.tip.isIp);
                return false;
            }
        },
        url: function (url, label) {
            if (url == null && url.trim().length === 0) {
                utils.dialog.alert(lang.tip.enter(label));
                return false;
            }
            if (url.trim().length > 253) {
                utils.dialog.alert(lang.policy.domain_desc);
                return false;
            }

            /*var reg = /^(http|https):\/\//;*/
            var reg = /^(?=^.{3,255}$)(http(s)?:\/\/)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
            var r = url.match(reg);
            if (r == null) {
                utils.dialog.alert(label);
                return false;
            }
            return true;
        },
        "multi-url": function (value, label, split) {
            split = split || "\n";
            var urlArr = value.split(split);

            if (utils.common.isRepeat(urlArr)) {
                utils.dialog.alert(lang.policy.networkAccessBW + lang.policy.networkAccessBWList_DESC);
                return false;
            }

            for (var i = 0; i < urlArr.length; i++) {
                if (!utils.form.verifyData.url(urlArr[i], label)) {
                    return false;
                }
            }
            return true;
        },
        minLength: function (value, label, len) {
            var ret;

            ret = value.length >= len;
            if (!ret) {
                utils.dialog.alert(label + lang.tip.minlength(len));
            }
            return ret;
        },
        notSame: function (value, label, compareId) {
            var ret;

            ret = value === $("#" + compareId).val();
            if (!ret) {
                utils.dialog.alert(label + lang.tip.notSame);
            }

            return ret;
        },
        port: function (value, label) {
            return utils.form.verifyData.num(value, label, 1, 65535);
        },
        estor_port: function (value, label) {
            return utils.form.verifyData.num(value, label, 47000, 47999);
        },
        path: function (value, label) {
            if (!/^[A-Za-z0-9_/]+$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isPath);
                return false;
            }
            return true;
        },
        folder: function (value, label) {
            if (/[\/:*\?"<>|]/.test(value)) {
                utils.dialog.alert(label + lang.tip.isFolder);
                return false;
            }
            return true;
        },
        fullname: function (value, label) {
            if (/[!@:]/.test(value)) {
                utils.dialog.alert(label + lang.tip.isFullname);
                return false;
            }
            return true;
        },
        length: function (value, label, len) {
            var ret;
            value = $.trim(value);
            ret = (value.length == len);
            if (!ret) {
                utils.dialog.alert(label + lang.tip.length(len));
            }
            return ret;
        },
        password: function (value, label) {
            if (/[^(\x20-\x7F)]/.test(value)) {
                utils.dialog.alert(label + lang.tip.isPassword);
                return false;
            }
            return true;
        },
        isPassword: function (value, noTip) {
            if (!/((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))^.{8,32}$/.test(value)) {
                if (false !== noTip) {
                    utils.dialog.alert(lang.tip.violationPassword);
                }

                return false;
            }
            return true;
        },
        "mac": function (value, label, split) {
            var mac_cell_arr;

            split = split || ":";

            mac_cell_arr = value.split(split);

            if (value.trim().length > 30) {
                utils.dialog.alert(lang.policy.WifiBWList + lang.policy.BW_DESC);
                return false;
            }

            if (mac_cell_arr.length !== 6) {
                utils.dialog.alert(label);
                return false;
            }

            for (var i = 0; i < 6; i++) {
                if (!/[0-9a-f]{2}/i.test(mac_cell_arr[i])) {
                    utils.dialog.alert(label);
                    return false;
                }
            }
            return true;
        },

        "multi-mac": function (value, label, split, split1) {
            if (!utils.form.verifyData.empty(value, lang.policy.WifiBWList1)) {
                return false;
            }

            split = split || "\n";
            split1 = split1 || "-";
            var mac_arr = value.split(split);

            if (utils.common.isRepeat(mac_arr)) {
                utils.dialog.alert(lang.policy.WifiBWList + lang.policy.WifiBWList_DESC);
                return false;
            }

            for (var i = 0; i < mac_arr.length; i++) {
                if (!utils.form.verifyData.mac(mac_arr[i], label, split1)) {
                    return false;
                }
            }
            return true;
        },

        "ssid": function (value, label) {
            if (value.trim().length > 30) {
                utils.dialog.alert(lang.policy.WifiBWList + lang.policy.BW_DESC);
                return false;
            }

            var reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
            if (!reg.test(value)) {
                utils.dialog.alert(label);
                return false;
            }
            return true;
        },

        "multi-ssid": function (value, lable, split) {
            var ssids;
            if (!utils.form.verifyData.empty(value, lang.policy.WifiBWList1)) {
                return false;
            }

            split = split || "\n";
            ssids = value.split(split);

            if (utils.common.isRepeat(ssids)) {
                utils.dialog.alert(lang.policy.WifiBWList + lang.policy.WifiBWList_DESC);
                return false;
            }

            for (var i = 0; i < ssids.length; i++) {
                if (!utils.form.verifyData.ssid(ssids[i], lable)) {
                    return false;
                }
            }
            return true;
        },



        float: function (value, label, min, max) {
            if (!/^(-?\d+)(\.\d+)?$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isNum);
                return false;
            }

            value = parseFloat(value);
            if ((undefined !== min)
                && (undefined !== max)) {
                if ((min > value) || (max < value)) {
                    utils.dialog.alert(label + lang.tip.min_max(min, max));
                    return false;
                }
            } else {
                if ((undefined !== min) && (min > value)) {
                    utils.dialog.alert(label + lang.tip.minVal(min));
                    return false;
                }

                if ((undefined !== max) && (max < value)) {
                    utils.dialog.alert(label + lang.tip.maxVal(max));
                    return false;
                }
            }
            return true;
        },
        imei: function (value, label) {
            if (!/^\d{14,15}$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isIMEI);
                return false;
            }
            return true;
        },
        udid: function (value, label) {
            if (!/^[a-f0-9]{40}$/.test(value) && !/^[a-fA-F0-9]{8}-[a-fA-F0-9]{16}$/.test(value)) {
                utils.dialog.alert(label + lang.tip.isUDID);
                return false;
            }
            return true;
        },
        version: function (verion, label) {
            if (!/^([1-9]\d|[1-9])(\.([1-9]\d|\d)){1,2}$/.test(versionName)) {
                utils.dialog.alert(label + lang.tip.isVersion);
                return false;
            }
            return true;
        },
        name: function (name, label, maxLen) {
            if (name.length >= maxLen) {
                utils.dialog.alert(label + lang.tip.maxLength(maxLen));
                return false;
            }

            if (name.indexOf(" ") > 0 ||
                /[·！#￥（——）：；“”‘、，|《。》？、【】`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im.test(name)) {
                utils.dialog.alert(label + lang.tip.isName);
                return false;
            }
            return true;
        }
    },
    verifyHtmlObj: function (obj, prefix) {
        var allowempty, limit, value, forId, label, range, min, max, minlength, ret, maxlength;

        prefix = utils.common.checkParamValue(prefix, "string");

        // get label
        label = prefix + utils.form.getlabel(obj);

        // get value
        value = obj.val();

        // verify data
        ret = true;

        allowempty = obj.attr("allowempty");
        limit = obj.attr("limit");
        if (undefined === allowempty && undefined !== limit) {
            ret = utils.form.verifyData.empty(value, label);
        }

        if (ret) {
            // forId
            forId = obj.attr("forId");
            if (forId) {
                ret = utils.form.verifyData.notSame(value, label.replace(lang.common.again, ""), forId);
            }
        }

        if (ret) {
            // min length
            minlength = obj.attr("minlength");
            if (minlength) {
                maxlength = obj.attr("maxlength");
                if (minlength === maxlength) {
                    ret = utils.form.verifyData.length(value, label.replace(lang.common.again, ""), minlength);
                } else {
                    ret = utils.form.verifyData.minLength(value, label.replace(lang.common.again, ""), minlength);
                }
            }
        }

        if (ret) {
            // verify input data rule
            limit = obj.attr("limit");
            if (limit) {
                ret = (undefined !== allowempty) && ($.trim(value).length === 0);
                if (!ret) {
                    if (limit === "num") {
                        range = obj.attr("range");
                        if (range) {
                            range = range.split(",");
                            min = !isNaN(range[0]) ? parseInt($.trim(range[0])) : undefined;
                            max = !isNaN(range[1]) ? parseInt($.trim(range[1])) : undefined;
                        }
                    }
                    ret = utils.form.verifyData[limit](value, label, min, max);
                }
            }
        }

        return ret;
    },
    syncPwd: function (obj) {
        if (!obj) {
            obj = utils.page.contentObj;
        }

        obj.find("input[type='password'][forId]").each(function () {
            var forId;
            forId = $(this).attr("forId");
            $(this).val($("#" + forId).val());
        });
    }
};

// dialog functions
utils.dialog = {
    general: function (title, btns, ctl_id) {
        var win, width, height, ui_dialog, win_width, option;

        if ($("#" + ctl_id).parents(".ui-dialog").length > 0) {
            utils.dialog.close(ctl_id);
        }

        // check param;
        title = title || "";

        if ($.trim(ctl_id).length > 0) {
            win = $("#" + ctl_id);

            width = win.attr("width") ? win.attr("width") : "auto";
            height = win.attr("height") ? win.attr("height") : "auto";

            width = width.replace("px", "");
            height = height.replace("px", "");

            if (ctl_id === "dialog" ||
                ctl_id === "tip") {
                win.removeAttr("width");
                win.removeAttr("height");
            }

            option = {
                width: width,
                height: height,
                title: title,
                autoOpen: true,
                resizable: false,
                position: "center",
                modal: true,
                open: function (event, ui) {
                    $(".ui-dialog-titlebar-close", $(this).parent()).show().attr("title", lang.action.close);

                    $("#" + ctl_id).parents(".ui-dialog").find(".ui-dialog-titlebar-close").unbind("click").bind("click", function () {
                        $("#" + ctl_id).dialog("close");
                    });

                    ui_dialog = win.parents(".ui-dialog");
                    if (ctl_id === "tip") {
                        // fix overlay bug
                        ui_dialog.css("z-index", 2050);
                        $(".ui-widget-overlay:eq(1)").css("z-index", 2049);
                    }
                    win_width = parseFloat(ui_dialog.width());
                    win_width = Math.ceil(win_width) + 5;
                    ui_dialog.css({ "width": win_width });
                }
            };

            if (btns) {
                option["buttons"] = btns;
            }
            // show dialog
            win.dialog(option);
            return false;
        }
    },
    openDialog: function (callback, title, btnText, width) {
        var btnset;

        // check params
        callback = utils.common.checkParamValue(callback, "function");
        if (!width) {
            width = 350;
        }

        // set btns
        btnset = {};
        if (!btnText) {
            btnText = lang.action.ok;
        }
        btnset[btnText] = function () {
            callback.call();
        };

        $("#dialog").attr("width", width);

        utils.dialog.general(title, btnset, "dialog");
    },
    confirm: function (text, callback) {
        var img, btnset, dlg_id;

        // check params
        callback = utils.common.checkParamValue(callback, "function");

        dlg_id = "dialog";

        // set btns
        btnset = {};
        btnset[lang.action.ok] = function () {
            callback.call();
            utils.dialog.close(dlg_id);
            clearTimeout(utils.common.autoExec_timeoutId);
        };

        btnset[lang.action.cancel] = function () {
            utils.dialog.close(dlg_id);
            clearTimeout(utils.common.autoExec_timeoutId);
        };

        // set content
        img = '<span class="fl"><img src="images/tip/confirm.png" height="32px" width="32px" /></span>';
        $("#" + dlg_id).empty().css("padding", "1.5em 1em 2em").removeAttr("width").html(img + '<span class="fl_mlt"><strong>' + text + "</strong></span>");

        if (utils.common.isIE7()) {
            $("#" + dlg_id).attr("width", (32 + text.length + 200) + "px");
        }

        utils.dialog.general(lang.dialog_title.confirm, btnset, dlg_id);
        //utils.common.autoExec(btnset[lang.action.cancel]);
    },
    confirm2: function (text, callback) {
        var img, btnset, dlg_id;

        // check params
        callback = utils.common.checkParamValue(callback, "function");

        dlg_id = "dialog";

        // set btns
        btnset = {};
        btnset[lang.action.yes] = function () {
            callback.call(this, "yes");
            utils.dialog.close(dlg_id);
            //clearTimeout(utils.common.autoExec_timeoutId);
        };

        btnset[lang.action.no] = function () {
            callback.call(this, "no");
            utils.dialog.close(dlg_id);
            //clearTimeout(utils.common.autoExec_timeoutId);
        };

        btnset[lang.action.cancel] = function () {
            utils.dialog.close(dlg_id);
            //clearTimeout(utils.common.autoExec_timeoutId);
        };

        // set content
        img = '<span class="fl"><img src="images/tip/confirm.png" height="32px" width="32px" /></span>';
        $("#" + dlg_id).empty().css("padding", "1.5em 1em 2em").removeAttr("width").html(img + '<span class="fl_mlt"><strong>' + text + "</strong></span>");

        if (utils.common.isIE7()) {
            $("#" + dlg_id).attr("width", (32 + text.length + 200) + "px");
        }

        utils.dialog.general(lang.dialog_title.confirm, btnset, dlg_id);
    },
    generalTip: function (text, callback, title, btnText, autoClose, longText) {
        var img, btnset, imgSrc, dlg_id, autoCloseTimeout;

        if (!text) {
            return;
        }

        // check params
        callback = utils.common.checkParamValue(callback, "function");

        dlg_id = "tip";

        // set btns
        btnset = {};
        if (!btnText) {
            btnText = lang.action.ok;
        }
        btnset[btnText] = function () {
            clearTimeout(autoCloseTimeout);
            utils.dialog.close(dlg_id);
            callback.call();
        };

        // set content
        switch (title) {
            case lang.dialog_title.tip:
                imgSrc = "images/tip/info.png";
                break;
            case lang.dialog_title.success:
                imgSrc = "images/tip/success.png";
                break;
            case lang.dialog_title.error:
                imgSrc = "images/tip/error.png";
                break;
            case lang.dialog_title.warn:
                imgSrc = "images/tip/warn.png";
                break;
            default:
                imgSrc = undefined;
                break;
        }
        img = imgSrc ? '<span class="fl"><img src="' + imgSrc + '" height="32px" width="32px" /></span>' : "";
        if (!longText) {
            $("#" + dlg_id).empty().css("padding", "1.5em 1em 2em").removeAttr("width").html(img + '<span class="fl_mlt"><strong>' + text + "</strong></span>");
        } else {
            $("#" + dlg_id).empty().removeAttr("width").attr("width", "288px").html(img + '<div class="fl_mlt" style="margin-left:55px;">' + text + "</div>");
        }

        if (utils.common.isIE7()) {
            $("#" + dlg_id).attr("width", (32 + text.length + 200) + "px");
        }

        utils.dialog.general(title, btnset, dlg_id);

        if (autoClose) {
            var i = 5;
            var countDown = function () {
                if (i > 0) {
                    $("#" + dlg_id).parents(".ui-dialog").find(".ui-dialog-buttonset .ui-button-text").text(btnText + " (" + i + ")");
                    i--;
                    autoCloseTimeout = setTimeout(countDown, 1000);
                } else {
                    btnset[btnText]();
                }
            };
            countDown();
        }
    },
    alert: function (text, callback) {
        utils.dialog.generalTip(text, callback, lang.dialog_title.tip);
    },
    alert2: function (text, callback) {
        utils.dialog.generalTip(text, callback, lang.dialog_title.tip);
        $("#tip").dialog({ closeOnEscape: false, position: "center" });
        $(".ui-dialog-titlebar-close").hide();
    },
    warning: function (text, callback) {
        utils.dialog.generalTip(text, callback, lang.dialog_title.warn);
    },
    warning2: function (text, callback) {
        utils.dialog.generalTip(text, callback, lang.dialog_title.warn);
        $("#tip").dialog({ closeOnEscape: false, position: "center" });
        $(".ui-dialog-titlebar-close").hide();
    },
    warningByCode: function (text, error_code) {
        if (undefined !== lang.errormsg[error_code]) {
            utils.dialog.warning(text.replace(/[！!]/, "") + lang.common.comma +
                lang.errormsg[error_code]);
        } else {
            utils.dialog.warning(text);
        }
    },
    error: function (text, callback) {
        utils.dialog.generalTip(text, callback, lang.dialog_title.error);
    },
    success: function (text, callback) {
        utils.dialog.generalTip(text, callback, lang.dialog_title.success, null, true);
    },
    running: function (text, width) {
        var img;

        if (!text) {
            return;
        }

        if (!width) {
            if (utils.common.isIE7()) {
                width = (32 + text.length + 200) + "px";
            } else {
                width = "auto";
            }
        }

        img = '<img src="images/tip/running.gif" height="32px" width="32px" style="float: left; margin-right: .3em;" />';
        text = '<span class="fl_mlt"><strong>' + text + "</strong></span>";
        $("#tip").empty().css("padding", "1.5em 1em 2em").html(img + text).dialog({
            width: width,
            title: lang.dialog_title.running,
            closeOnEscape: false,
            autoOpen: true,
            resizable: false,
            modal: true,
            position: "center",
            buttons: undefined,
            open: function () {
                $("#tip").css("min-height", "none");
                $(".ui-dialog-titlebar-close", $(this).parent()).hide();
                $(this).parent().css("z-index", 2050);
                $(".ui-widget-overlay:eq(1)").css("z-index", 2049);
            }
        });
        return false;
    },
    close: function () {
        var argument, ui_dialog;
        for (var i = 0; i < arguments.length; i++) {
            argument = arguments[i];
            if ($.trim(arguments[i]).length > 0) {
                ui_dialog = $("#" + argument).parents(".ui-dialog");
                if (ui_dialog.length > 0 && ui_dialog.is(":visible")) {
                    $("#" + argument).dialog("close");
                }
            }
        }
    },
    reviseOffset: function (dlg_id, offset_top, offset_left) {
        var top, left, ui_dialog;
        ui_dialog = $("#" + dlg_id).parents(".ui-dialog");
        if (offset_top) {
            top = ui_dialog.css("top");
            top = parseInt(top);
            ui_dialog.css("top", top + offset_top);
        }

        if (offset_left) {
            left = ui_dialog.css("left");
            left = parseInt(left);
            ui_dialog.css("left", left + offset_left);
        }
    }
};

// text functions
utils.text = {
    limitNum: function (ctl_id, max_num, min_num) {
        var range;
        range = [];
        range.push(min_num ? min_num : "1");
        range.push(max_num ? max_num : "");
        $("#" + ctl_id).attr({ limit: "num", range: range.join(",") });
    },
    limitQuota: function (pool_list_id, quota_id, unit_id) {
        var pool_size_str, selPoolSize, unit_ctl;
        unit_ctl = $("#" + unit_id);
        pool_size_str = $("#" + pool_list_id).find("option:selected").text().split(": ")[1];
        selPoolSize = parseFloat(pool_size_str);
        if (pool_size_str.indexOf('T') !== -1) {
            selPoolSize *= 1024;  //set pool size to gb
        }
        if (selPoolSize < 1024) {
            if (unit_ctl.val() === "T") {
                unit_ctl.val("G");
            }
            utils.select.removeOptByValue(unit_id, "T");
        } else {
            if (unit_ctl.find("option[value='T']").length === 0) {
                unit_ctl.append('<option value="T">TB</option>');
            }
        }

        switch (unit_ctl.val()) {
            case "M":
                selPoolSize *= 1024;
                break;
            case "T":
                selPoolSize /= 1024;
                break;
        }
        selPoolSize = Math.floor(selPoolSize);
        utils.text.limitNum(quota_id, selPoolSize, undefined);
    }
};

// checkbox functions
utils.checkbox = {
    checked: function (checkObj) {
        if (checkObj) {
            if (checkObj.is(":checked")) {

            } else {

            }
        }
    },
    checkAll: function (checkAllObj, tbody) {
        if (!checkAllObj || !tbody) {
            return;
        }

        utils.checkbox.checked(checkAllObj);
        tbody.find("input[type='checkbox']").prop("checked", checkAllObj.is(":checked"));
        if (checkAllObj.is(":checked")) {
            tbody.find("tr input").parents("tr").addClass("selected");
        } else {
            tbody.find("tr").removeClass("selected");
        }

        // tbody.find("input[type='checkbox']:first").change();
    },
    checkOne: function (checkObj, tbody, checkAllObj) {
        if (!checkObj || !tbody) {
            return;
        }

        utils.checkbox.checked(checkObj);

        if (!checkAllObj) {
            checkAllObj = tbody.parents("table").find("#check_all");
        }

        // checkObj.parents("tr").toggleClass("selected");
        if (checkObj.is(":checked")) {
            if (tbody.find("input[type='checkbox']:not(:checked)").length === 0) {
                checkAllObj.prop("checked", true);
            }
            checkObj.parents("tr").addClass("selected");
        } else {
            if (tableset) {
                tableset.checkAllType = "0";
            }
            checkAllObj.prop("checked", false);
            checkObj.parents("tr").removeClass("selected");
        }
    },
    getCheckedItems: function (tbody) {
        var item_values;
        if (tbody) {
            item_values = [];
            tbody.find("input[type='checkbox']:checked").each(function () {
                item_values.push($(this).prop("value"));
            });
            return item_values;
        }
    },
    enableControls: function (chechObj, ctl_id) {
        var objId, disabled, contentObj;
        if (chechObj) {
            objId = chechObj.prop("id");
            disabled = !chechObj.is(":checked");
            if (ctl_id) {
                contentObj = $("#" + ctl_id);
            } else {
                contentObj = utils.page.contentObj;
            }
            contentObj.find("input[id!='" + objId + "'],textarea,select")
                .prop("disabled", disabled);
        }
    }
};

// select functions
utils.select = {
    selectOptions: function (control_id, values) {
        var control;
        if (control_id) {
            control = $("#" + control_id);
            if (control.multiple &&
                values instanceof Array) {
                for (var i = 0; i < values.length; i++) {
                    control.find("option[value='" + values[i] + "']").attr("selected", true);
                }
            } else {
                control.val(values);
            }
        }
    },
    removeOptByValue: function (ctl_id, value) {
        var control;
        if (ctl_id) {
            $("#" + ctl_id).find("option[value='" + value + "']").remove();
        }
    }
};

// button functions
utils.button = {
    disabled: function (ctl_id) {
        var argument;
        for (var i = 0; i < arguments.length; i++) {
            argument = arguments[i];
            if ($.trim(argument).length > 0) {
                $("#" + argument).attr("disabled", true).addClass("ui-button-disabled");
            }
        }
    },
    enable: function (ctl_id) {
        var argument;
        for (var i = 0; i < arguments.length; i++) {
            argument = arguments[i];
            if ($.trim(argument).length > 0) {
                $("#" + argument).attr("disabled", false).removeClass("ui-button-disabled");
            }
        }
    }
};

// common functions
utils.common = {
    autoExec_timeoutId: undefined,
    error: function (module, method) {
        if (window.console) {
            console.log("error: " + module + " -> " + method);
        }
    },
    download: function (filePath, fileName) {
        var downloadObj, event, url, ts, nonce;

        ts = "" + new Date().getTime();
        nonce = utils.common.randomStr(32, "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ");

        if (!url) {
            url = "/download.do?ts=" + ts;
            url += "&nonce=" + nonce;
            // url += "&filepath=" + $.sm4Nt(fileP、ath, nonce, ts);

            // url = "/download.do?filepath=" + encodeURI(encodeURI(filePath));
        }

        if (undefined != fileName) {
            // url += "&filename=" + $.sm4Nt(fileName, nonce, ts);
            // url += "&filename=" + encodeURI(encodeURI(fileName));
        }

        downloadObj = document.getElementById("download");
        downloadObj.setAttribute("href", url);

        if (/msie/i.test(navigator.userAgent)) {
            // ie browser
            downloadObj.click();
        } else {
            event = document.createEvent('MouseEvent');
            event.initEvent('click', false, false);
            downloadObj.dispatchEvent(event);
        }
    },
    checkParamValue: function (param, types) {
        // param: parameter.
        // types: param types.
        var isMatch, paramType, param_value;

        if (undefined !== param) {
            isMatch = false;
            for (var i = 1; i < arguments.length; i++) {
                if (typeof param === arguments[i].toLowerCase()) {
                    isMatch = true;
                    break;
                }
            }
        }

        if ((undefined === param) && !isMatch) {
            paramType = arguments[1];
            switch (paramType) {
                case "string":
                    param_value = "";
                    break;
                case "array":
                    param_value = [];
                    break;
                case "function":
                    param_value = function () {
                    };
                    break;
                case "object":
                    param_value = {};
                    break;
                case "number":
                    param_value = 0;
                    break;
                default:
                    break;
            }
            param = param_value;
        }
        return param;
    },
    changeLanguage: function (lang) {
        var postData;

        postData = {
            controller: "language",
            action: "setLang",
            lang: lang
        };

        utils.doAjaxAction(postData, function (data) {
            if (data && data["success"]) {
                location.href = location.href;
            } else {
                if (window.console) {
                    console.error("changeLang", JSON.stringify((data)));
                }
            }
        });
    },
    autoExec: function (callback, time) {
        var func;
        func = function () {
            // check params
            callback = utils.common.checkParamValue(callback, "function");

            callback.call();

            clearTimeout(utils.common.autoExec_timeoutId);
        };

        if (!time) {
            time = 10 * 1000;
        }
        utils.common.autoExec_timeoutId = setTimeout(func, time); // dialog will close after 10s.
    },
    getOwnPropNames: function (obj) {
        var ownPropNames;
        if (null != obj && typeof obj === "object") {
            if (Object.getOwnPropertyNames) {
                // brower support  getOwnPropertyNames.
                ownPropNames = Object.getOwnPropertyNames(obj);
            } else {
                ownPropNames = [];
                for (var prop in obj) {
                    if (obj.hasOwnProperty(prop)) {
                        ownPropNames.push(prop);
                    }
                }
            }

            return ownPropNames;
        } else {
            return [];
        }
    },
    tologin: function () {
        utils.doAjaxAction("/logout.do", null, function () {
            window.location.href = "/login.do";
        });
    },
    upload: function (form_id, file_ctl_id, callback, fileId) {
        var file;
        utils.dialog.running(lang.tip.file_uploading);
        if (!file_ctl_id || !document.getElementById(file_ctl_id)) {
            return;
        }
        file = document.getElementById(file_ctl_id).files[0];
        if (file === undefined) {
            if (typeof callback === "function") {
                utils.dialog.close("tip");
                callback.call();
            }
            return;
        }
        if (file !== undefined && file.size == 0) {
            utils.dialog.alert(lang.tip.fileIsEmpty);
            return;
        }

        if (!utils.common.inArray(utils.common.getFileExt(file.name), ["apk", "ipa", "doc", "docx", "zip",
            "mobileprovision", "p12", "xls", "xlsx", "ppt", "pptx", "pdf", "txt", "png", "jpg", "jpeg", "jks"])) {
            console.error("file error!!!");
            return;
        }

        $("#" + form_id).ajaxSubmit({
            type: 'post',
            url: "/upload.do",
            resetForm: true,
            timeout: 6000000,
            data: {
                fileId: file_ctl_id
            },
            success: function (data) {
                utils.dialog.close("tip");
                $("#" + file_ctl_id).val("");
                if (!data["errorCode"]) {
                    if (fileId != undefined) {
                        $("#" + fileId).val(data["data"][0].join(";"));
                    }
                    if (typeof callback === "function") {
                        callback.call();
                    }
                } else {
                    utils.common.error("utils.common.upload", data);
                }
            },
            error: function (XmlHttpRequest, textStatus, errorThrown) {
                utils.dialog.close("tip");
                $("#" + file_ctl_id).val("");
                utils.common.error("utils.common.upload", errorThrown.toString());
            },
            beforeSend: function (xhr, o) {
                // TODO: init progress bar
                var ts, nonce, sessid, sg;
                ts = "" + new Date().getTime();
                nonce = utils.common.randomStr(32);

                sessid = utils.sessid || "";
                sg = $.sign(ts, nonce, sessid, "", file.name);
                xhr.setRequestHeader("sign", utils.common.sprintf("ts=%s;nonce=%s;udid=%s;sg=%s", ts, nonce, sessid, sg));

            },
            uploadProgress: function (event, position, total, percentComplete) {
                // TODO: handle progress bar
            },
            complete: function (XmlHttpRequest, textStatus) {
                // TODO: reset progress bar
            }
        });
    },
    getFileName: function (file_path, splitChar) {
        var name_arr;
        if (file_path) {
            if (!splitChar) {
                splitChar = "\\";
            }
            name_arr = file_path.split(splitChar);
            return name_arr[name_arr.length - 1];
        }
        return "";
    },
    getFileExt: function (file_path) {
        var matchs, exts, regx;

        exts = [".tar.gz", ".tar.bz2"];
        for (var i = 0; i < exts.length; i++) {
            regx = new RegExp(exts[i] + "$");
            if (regx.test(file_path)) {
                return exts[i].substr(1);
            }
        }

        matchs = file_path.split(".");
        return (matchs.length > 1) ? matchs[matchs.length - 1] : "";
    },
    checkFileType: function (file_path) {
        var isMatch, file_ext;

        isMatch = false;
        file_ext = utils.common.getFileExt(file_path);
        if (undefined !== file_ext) {
            file_ext = file_ext.toLowerCase();
            for (var i = 1; i < arguments.length; i++) {
                if (file_ext === arguments[i].toLowerCase()) {
                    isMatch = true;
                    break;
                }
            }
        }

        return isMatch;
    },
    isIE7: function () {
        return navigator.appVersion.indexOf("MSIE 7") >= 0;
    },
    isIE: function () {
        return (!!window.ActiveXObject || "ActiveXObject" in window) &&
            (navigator.appVersion.indexOf("LBBROWSER") < 0);
    },
    isIEVerNs: function () {
        var argument;
        if (arguments.length === 0) {
            return utils.common.isIE();
        }

        for (var i = 0; i < arguments.length; i++) {
            argument = $.trim(arguments[i]);
            if (argument.length > 0) {
                if (navigator.appVersion.indexOf("MSIE " + argument) >= 0) {
                    return true;
                }
            }
        }
        return false;
    },
    filterData: function (data_arr, filterdata, key) {
        if ($.isArray(data_arr)) {
            if (!$.isArray(filterdata)) {
                filterdata = [filterdata];
            }

            $.each(data_arr, function (i, data) {
                if (data) {
                    if (key) {
                        data = data[key];
                    }
                    for (var j = 0; j < filterdata.length; j++) {
                        if (data === filterdata[j]) {
                            data_arr.splice(i, 1);
                        }
                    }
                }
            });
        }
    },
    verifyQuota: function () {
        var quota_value, quota_unit, size, OneK;

        quota_value = $("#quota").val();
        quota_unit = $("#unit option:selected").val();
        OneK = 1024;
        size = quota_value;
        switch (quota_unit) {
            case "P":
                size = size * OneK;
            case "T":
                size = size * OneK;
            case "G":
                size = size * OneK;
            case "M":
                size = size * OneK * OneK;
                break;
        }
        if (size > 1000 * OneK * OneK * OneK * OneK * OneK) {
            utils.dialog.alert(lang.dataset.quota + lang.tip.set_execeed_uplimit);
            return false;
        }
        return true;
    },
    sprintf: function (format) {
        for (var i = 1; i < arguments.length; i++) {
            format = format.replace("%s", arguments[i]);
        }
        return format.replace("/%s/g", "");
    },
    getContentHeight: function () {
        var clientHeight;
        clientHeight = document.documentElement.clientHeight;
        return clientHeight - (148 + 31);
    },
    getSelectHeight: function () {
        var selectHeight;
        selectHeight = 0;
        if ($(".wrap .SelectBy .c1").length > 0) {
            selectHeight += + 35;
        }
        if ($(".wrap .SelectBy .c2").length > 0) {
            selectHeight += 43;
        }
        if ($(".page").length > 0) {
            selectHeight += 60;
        }
        return selectHeight;
    },
    inArray: function (val, arr, compareType) {
        if ($.isArray(arr)) {
            for (var i = 0; i < arr.length; i++) {
                if (compareType && val === arr[i]) {
                    return true;
                } else if (val == arr[i]) {
                    return true;
                }
            }
        }
        return false;
    },
    isString: function (str) {
        return str != null && (typeof str.indexOf === "function");
    },
    ucFirst: function (str) {
        if (str && str.length > 0) {
            return str.substring(0, 1).toUpperCase() + str.substr(1);
        }
        return str;
    },
    // 加密-----------------------------------------------------------------------------------
    encrypt: function (str, type, secretKey, ivKey) {
        if (str === "" || undefined === str) {
            return "";
        }
        if (undefined === type) {
            type = "SM3";
        }

        if ("MD5" === type) {
            return $.md5(str);
        } else if ("SM3" === type) {
            return $.sm3(str);
        } else if ("SM4" === type) {
            return $.sm4(str, secretKey, ivKey);
        }

    },
    // 解密---------------------------------------------------------------
    decrypt: function (str, secretKey) {
        if (str === "" || undefined === str) {
            return "";
        }
        var secretkeys;
        if(secretKey == ""){
            secretkeys = '1111111111111111';
            // console.info("secretKey" + secretkeys);
        }else{
            secretkeys = $.sm3(secretKey);
            secretkeys = secretkeys.substring(0, 16);
        }

         return $.sm4(str, secretkeys, undefined, true); 
    },
    checkInput: function (require, value, keyCode, shift) {
        if (!require) {
            return true;
        }

        // check '-'
        if (require[0] === "+" && (keyCode == 109 || keyCode == 189)) {
            return false;
        }

        value = value.trim();
        if (require.indexOf("float") >= 0) {
            if ((keyCode == 190) || (keyCode == 110)) {
                if (value == '-' || value.indexOf(".") != -1) {
                    return false;
                }
            }
        }

        if (require.indexOf("float") >= 0 || require.indexOf("number") >= 0) {
            if ((keyCode < 48) || (keyCode > 57)) {
                if ((keyCode < 96) || (keyCode > 105)) {
                    if (!utils.common.inArray(keyCode, [8, 9, 37, 39])) {
                        return false;
                    }
                }
            }
        }

        return true;
    },
    formatDate: function (format, date) {
        var str;

        format = format || "yyyy-MM-dd";

        date = date || new Date();

        function add0(val) {
            return (val < 10) ? ("0" + val) : val;
        }

        str = format;
        str = str.replace("yyyy", date.getFullYear());
        str = str.replace("MM", add0(date.getMonth() + 1));
        str = str.replace("dd", add0(date.getDate()));
        str = str.replace("HH", add0(date.getHours()));
        str = str.replace("mm", add0(date.getMinutes()));
        str = str.replace("ss", add0(date.getSeconds()));

        str = str.replace("M", date.getMonth() + 1);
        str = str.replace("d", date.getDate());
        str = str.replace("H", date.getHours());
        str = str.replace("m", date.getMinutes());
        str = str.replace("s", date.getSeconds());

        return str;
    },
    formatTime: function (second) {
        var str, remainder, quotient;
        str = "";
        if (second == "0") {
            return "0" + lang.common.second;
        }

        remainder = parseInt(second);
        quotient = parseInt(remainder / (60 * 60 * 24));
        str += quotient > 1 ? (quotient + lang.common.date) : "";

        remainder %= (60 * 60 * 24);
        quotient = parseInt(remainder / (60 * 60));
        str += quotient > 1 ? (quotient + lang.common.hour) : "";

        remainder %= 3600;
        quotient = parseInt(remainder / 60);
        str += quotient > 1 ? (quotient + lang.common.minute_s) : "";

        remainder %= 60;
        str += remainder > 0 ? (remainder + lang.common.second) : "";
        return str;
    },
    getSecond: function (str) {
        var strs, date;
        if (!str) {
            return 0;
        }
        strs = str.split(/[-:\s]/);
        if (strs.length === 3) {
            date = new Date(strs[0], strs[1] - 1, strs[2]);
        } else if (strs.length === 6) {
            date = new Date(parseInt(strs[0]), parseInt(strs[1]) - 1, parseInt(strs[2]), parseInt(strs[3]), parseInt(strs[4]), parseInt(strs[5]));
        } else {
            return 0;
        }
        return parseInt((new Date().getTime() - date.getTime()) / 1000);
    },
    isEmpty: function (str) {
        if (str.match(/^\s+$/)) {
            return true;
        }
    },
    toCompareVersion: function (v1, v2) {
        var flag = false;
        var pie = v1.split(".");
        var pie2 = v2.split(".");
        if (parseInt(pie[0]) > parseInt(pie2[0])) {
            flag = true;
        } else if (parseInt(pie[0]) == parseInt(pie2[0]) && parseInt(pie[1]) > parseInt(pie2[1])) {
            flag = true;
        } else if (parseInt(pie[0]) == parseInt(pie2[0]) && parseInt(pie[1]) == parseInt(pie2[1]) && pie.length > pie2.length) {
            flag = true;
        } else if (parseInt(pie[0]) == parseInt(pie2[0]) && parseInt(pie[1]) == parseInt(pie2[1]) && pie.length == pie2.length && parseInt(pie[2]) > parseInt(pie2[2])) {
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    },
    isRepeat: function (arr) {
        arr = arr.sort();
        for (var i = 0; i < arr.length - 1; i++) {
            if (arr[i] === arr[i + 1]) {
                return true;
            }
        }

        return false;
    },
    getDate: function (n, isneed_hms) {
        var date = new Date();
        date.setDate(date.getDate() - n);
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        if (isneed_hms == undefined) {
            return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        } else {
            return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
        }
    },
    PhoneFilter: val => {
        console.log(val);
          if (val) {
           return (
            val.substring(0, 3) +
            " **** " +
            val.substring(val.length - 4, val.length)
           );
          }
          return val;
         },
    hideMail: val => {
        if(val== null || val.length < 1 || !val.contains("@")) {
            return val
        }
        let atIndex = mail.indexOf('@');
        let mailSuffix = "****" + mail.substring(atIndex);
        return atIndex <= 1 ? mailSuffix : mail.charAt(0) + mailSuffix;
    },
    //add n month
    getMonthBefore: function (n) {
        var zero = "0";
        var date, newDate;
        date = new Date();
        date.setMonth(date.getMonth() - n);
        newDate = [date.getFullYear(), date.getMonth() < 9 ? (zero + (date.getMonth() + 1)) : (date.getMonth() + 1), date.getDate() < 10 ? (zero + date.getDate()) : date.getDate()].join('-');
        return newDate;
    },
    trimDate: function (str) {
        return utils.common.trimEnd(str, ".0");
    },
    trimEnd: function (str, endStr) {
        if (str && endStr) {
            var subLen = str.length - endStr.length;
            if (str.substr(subLen) === endStr) {
                return str.substr(0, subLen);
            }
        }
        return str;
    },
    timestampToTime: function (timestamp) {
        var date = new Date(timestamp * 1);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return Y + M + D + h + m + s;
    },
    isObject: function (str) {
        if (str instanceof Object) {
            return true;
        }
        return false;
    },
    question: function (obj) {
        if (obj.parents(".question").find(".detail").length === 0) {
            obj.parents(".question").append('<div class="detail"><div class="triangle1 triangle2"></div>' +
                '<div class="triangle1"></div><span>' + obj.attr("desc") + '</span></div>');
        }
    },
    getLimitStr: function (str, limitLen, obj) {
        var reg = /[\u4e00-\u9fa5]{1}/g, resultCn = [], temp;
        resultCn = str.match(reg) || [];

        obj.attr("maxLength", limitLen);

        var len = str.length + resultCn.length;
        var returnStr = "";
        if (len >= limitLen) {
            for (var i = 1; i <= limitLen; i++) {
                var singleStr = str.substring(i - 1, i);
                reg.lastIndex = 0;
                var a1 = reg.test(singleStr.trim());
                if (a1) {
                    limitLen--;
                }
                returnStr += singleStr;
                temp = limitLen;
            }
            obj.attr("maxLength", limitLen);
        } else {
            returnStr = str;
        }
        return returnStr;
    },
    randomStr: function (len, str) {
        var retStr = "", strLen;
        if (!str) {
            str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }

        strLen = str.length - 1;
        for (var i = 0, pos; i < len; i++) {
            pos = Math.ceil(Math.random() * strLen);
            retStr += str[pos];
        }
        return retStr;
    }
};

utils.echarts = {
    draw: function (containerId, chartTypes, option, clickFunction, require) {
        var draw;
        draw = function (ec) {
            var container, chart, ecConfig;

            container = document.getElementById(containerId);
            if (null == container) {
                return;
            }

            chart = ec.init(container, {
                noDataLoadingOption: {
                    text: lang.common.empty_data,
                    effect: 'bubble',
                    effectOption: {
                        effect: {
                            n: 0
                        }

                    },
                    textStyle: {
                        fontSize: 32,
                        fontWeight: 'bold'
                    }
                }
            });

            if (!container.hasOwnProperty("_echarts_instance_")) {
                chart.showLoading({ text: lang.common.loading_data });
                chart.hideLoading();
                chart.clear();
            }

            chart.setOption(option);

            window.onresize = chart.resize;

            if (clickFunction && typeof clickFunction === "function") {
                ecConfig = require('echarts/config');
                chart.on(ecConfig.EVENT.CLICK, function (param) {
                    clickFunction.call(this, param);
                });
            }
        };

        require.config({
            paths: {
                echarts: 'js/echarts'
            }
        });

        if (!$.isArray(chartTypes)) {
            chartTypes = [chartTypes];
        }

        var requireTypes = ['echarts'];
        $.each(chartTypes, function (i, type) {
            requireTypes.push('echarts/chart/' + type);
        });

        require(requireTypes, draw);
    },
    drawPie: function (containerId, data, circular, calculable, clickFunction) {
        var option;

        /*if (!containerId || !data ||
            !data["legend"] || !data["series"]) {
            return;
        }*/
        if (!containerId || !data || !data["series"]) {
            return;
        }

        option = {
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c} ({d}%)",
                position: function (p) {
                    return [p[0] + 20, p[1] + 30];
                }
            },
            calculable: false,
            series: [
                /*{
                    name:'',
                    type:'pie',
                    hoverAnimation:false,
                    clickable: false,
                    radius : [0, '40%'],
                    center: ['50%', '50%'],
                    funnelAlign: 'right',
                    max: 1548,
                    itemStyle : {
                        normal : {
                            label : {
                                show: false
                            },
                            labelLine : {
                                show : false
                            },
                            color: '#263346'
                        },
                        emphasis: {
                            label : {
                                show: false
                            },
                            labelLine : {
                                show : false
                            },
                            color: '#263346'
                        }
                    },
                    data:[
                        {value:335, name:'aaa'}
                    ]
                },*/
                {
                    name: data[name] || "",
                    type: 'pie',
                    radius: ['45%', '55%'],
                    funnelAlign: 'left',
                    center: ['50%', '50%'],
                    data: data["series"],
                    itemStyle: {
                        normal: {
                            labelLine: {
                                show: true,
                                length: 7
                            }
                        }
                    }
                }
            ]

        };

        if (circular) {
            option.series[0].radius = ['30%', '50%'];
        }

        if (false === calculable) {
            option.calculable = false;
        }

        utils.echarts.draw(containerId, "pie", option, clickFunction);
    },
    drawBar: function (containerId, data, saveAsImage, clickFunction) {
        var option;
        if (!containerId || !data ||
            !data["xAxis"] || !data["series"]) {
            return;
        }

        if (!$.isArray(data["legend"]) || !$.isArray(data["series"])) {
            return;
        }

        option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: data["legend"]
            },
            grid: {
                y: 30,
                x: 50,
                x2: 50
            },
            animation: false,
            calculable: false,
            xAxis: [
                {
                    data: data["xAxis"],
                    axisLabel: {
                        rotate: 25
                    },
                    splitLine: {
                        show: true
                    }
                }
            ],
            toolbox: {
                show: false,
                feature: {
                    saveAsImage: { show: true }
                }
            },
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: true
                    }
                }
            ],
            series: []
        };

        if (option.legend.data.length === 1) {
            option.legend.show = false;
        }

        if (data["color"]) {
            option["color"] = data["color"];
        }

        if (data["interval"]) {
            option.xAxis[0].axisLabel.interval = data["interval"];
        }

        if (data["unit"]) {
            option.yAxis[0].axisLabel.formatter = '{value}' + data["unit"];
        }

        if (saveAsImage) {
            option.toolbox.show = true;
        }

        for (var i = 0; i < data["series"].length; i++) {
            option.series.push({
                name: data["legend"][i],
                type: 'bar',
                symbolSize: 0,
                /*itemStyle: {
                    normal:{label:{show:true, textStyle: {color: '#dfdfdf'}},barBorderRadius: 6},
                    emphasis:{label:{show:true, textStyle: {color: '#dfdfdf'}},barBorderRadius: 6}
                },*/
                data: data["series"][i]
            });

        }
        utils.echarts.draw(containerId, "bar", option, clickFunction);
    },
    drawCycloneBar: function (containerId, data, clickFunction) {
        var option;
        /* if (!containerId || !data ||
             !data["xAxis"] || !data["series"]) {
             return;
         }

         if (!$.isArray(data["legend"]) || !$.isArray(data["series"])) {
             return;
         }*/

        option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function (params) {
                    var relVal = params[0].name + "<br/>";
                    relVal += params[0].seriesName + ' : ' + (-params[0].value) + "<br/>";
                    relVal += params[1].seriesName + ' : ' + params[1].value + "<br/>";
                    return relVal;
                }
            },
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    axisTick: { show: false },
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#485163'
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#dfdfdf'
                        }
                    },
                    data: data["xAxis"]
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ['#485163']
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#485163'
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#dfdfdf'
                        },
                        formatter: function (value) {
                            if (value < 0) {
                                return -value;
                            } else {
                                return value;
                            }
                        }
                    }
                }
            ],
            series: [
                {
                    name: '',
                    type: 'bar',
                    stack: 'TOP10',
                    itemStyle: {
                        normal: {
                            label: { show: false, textStyle: { color: '#dfdfdf' } },
                            color: '#ed5400'
                        }
                    },
                    data: data["topData"]
                },
                {
                    name: '',
                    type: 'bar',
                    stack: 'TOP10',
                    itemStyle: {
                        normal: {
                            label: {
                                show: false,
                                position: 'top',
                                textStyle: { color: '#dfdfdf' },
                                formatter: function (value) {
                                    return value.data;
                                }
                            },
                            color: '#4c63f2'
                        }
                    },
                    data: data["bottomData"]
                },
                {
                    name: '',
                    type: 'line',
                    stack: 'TOP10',
                    itemStyle: {
                        normal: {
                            color: 'rgba(0,0,0,0)',
                            label: {
                                show: true,
                                position: 'top',
                                textStyle: { color: '#dfdfdf' },
                                formatter: function (value) {
                                    return value.data;
                                }
                            }
                        }
                    },
                    data: data["all"]
                }
            ]
        };
        utils.echarts.draw(containerId, "bar", option, clickFunction);
    },
    drawGauge: function (containerId, data) {
        option = {
            tooltip: {
                show: false,
                formatter: "{c}%"
            },
            toolbox: {
                show: false
            },
            series: [
                {
                    name: "",
                    type: 'gauge',
                    center: ['50%', '50%'],
                    radius: [0, '75%'],
                    startAngle: 160,
                    endAngle: 20,
                    min: 0,
                    max: 100,
                    precision: 0,
                    splitNumber: 5,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: [[0.2, '#009e0b'], [0.4, '#7ccb00'], [0.6, '#ffb400'], [0.8, '#f46127'], [1, '#ed342e']],
                            width: 30
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        length: 30,
                        lineStyle: {
                            color: '#15202f',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    pointer: {
                        length: '60%',
                        width: 6,
                        color: '#dfdfdf'
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        show: false
                    },
                    data: data["risk"]
                },
                {
                    name: '',
                    type: 'gauge',
                    center: ['50%', '50%'],
                    radius: ['83%', '84%'],
                    startAngle: 160,
                    endAngle: 20,
                    min: 0,
                    max: 100,
                    precision: 0,
                    splitNumber: 5,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: [[0.2, '#009e0b'], [0.4, '#7ccb00'], [0.6, '#ffb400'], [0.8, '#f46127'], [1, '#ed342e']],
                            width: 7
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: true,
                        length: 30,
                        lineStyle: {
                            color: '#15202f',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    pointer: {
                        show: false
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        show: false
                    },
                    data: data["risk"]
                },
                {
                    name: '',
                    type: 'gauge',
                    center: ['50%', '50%'],
                    radius: ['120%', '121%'],
                    startAngle: 160,
                    endAngle: 20,
                    min: 0,
                    max: 100,
                    precision: 0,
                    splitNumber: 5,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        distance: -18,
                        formatter: function (v) {
                            switch (v + '') {
                                case '0': return '0';
                                case '20': return '20';
                                case '40': return '40';
                                case '60': return '60';
                                case '80': return '80';
                                case '100': return '100';
                                default: return '';
                            }
                        },
                        textStyle: {
                            color: '#dfdfdf',
                            fontWeight: 'bold'
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    pointer: {
                        show: false
                    },
                    title: {
                        show: false
                    },
                    detail: {
                        show: false
                    },
                    data: data["risk"]
                }
            ]
        };
        utils.echarts.draw(containerId, "gauge", option);
    },
    drawLine: function (containerId, data, clickFunction) {
        var option;
        option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                show: true,
                selectedMode: false,
                textStyle: { color: '#dfdfdf' },
                data: data["legend"]
            },
            grid: {
                y: 30, x: 50, x2: 50
            },
            calculable: false,
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        lineStyle: {
                            color: '#485163'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#dfdfdf'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ['#485163']
                        }
                    },
                    data: data["xAxis"]
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#dfdfdf'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#485163'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ['#485163']
                        }
                    }
                }
            ],
            series: []
        };
        for (var i = 0; i < data["series"].length; i++) {
            option.series.push({
                name: data["legend"][i],
                type: 'line',
                // symbolSize: 0,
                legendHoverLink: false,
                smooth: true,
                itemStyle: {
                    normal: {
                        lineStyle: {
                            color: data["color"][i]
                        },
                        color: data["color"][i]
                    }
                },
                data: data["series"][i]
            });

        }

        utils.echarts.draw(containerId, "line", option, clickFunction);
    }
};

/**
 * 数组操作对象
 * @type {{sortObject: Function}}
 */
utils.array = {
    /**
     * 对对象按值进行排序
     * @param obj 对象, {"010802":9,"010803":322,"010805":115,"010811":174,"010812":3,"010899":24}
     * @param desc 排序规则，true-从大到小排序(default)；false-从小到大排序
     * @returns [{"name": "010803", "value":322},
     *           {"name": "010811", "value":174},
     *           {"name": "010805", "value":115},
     *           {"name": "010899", "value":24},
     *           {"name": "010802", "value":9},
     *           {"name": "010812", "value":3}
     *          ]
     */
    "sortObject": function (obj, desc) {
        var keys, values;

        if (obj == null || (typeof obj !== "object")) {
            return;
        }


        keys = utils.common.getOwnPropNames(obj);
        values = [];
        $.each(keys, function (i, key) {
            values.push({ "name": key, "value": obj[key] });
        });

        if (desc == undefined) {
            desc = true;
        }

        values = values.sort(function (a, b) {
            return desc ? b.value - a.value : a.value - b.value;
        });

        return values;
    },
    splice: function (arr, delVal, delKey) {
        var checkVal;
        if ($.isArray(arr)) {
            $.each(arr, function (j, jtem) {
                checkVal = delKey ? jtem[delKey] : jtem;
                if (delVal == checkVal) {
                    arr.splice(j, 1);
                    return false;
                }
            });
        }
    }
};

utils.oneTimeParam = {
    param: {},
    set: function (key, value) {
        if (key) {
            this.param[key] = value;
        }
    },
    get: function (key) {
        var value = this.param[key];
        if (value) {
            delete this.param[key];
        }
        return value;
    }
}

export default utils
