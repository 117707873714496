export const getUrl = function(url, params) {
  if (!params) return url
  if (typeof params === 'string' || typeof params === 'number') {
    url =  url.endsWith('/') ? url + params : url + '/' + params
  } else if (params.constructor === Object) {
    const keys = Object.entries(params)
    for (let i = 0; i < keys.length; i++ ) {
      const [key, val] = keys[i]
      url += i === 0 ? `?${key}=${val}` : `${key}=${val}`
      url += i === keys.length - 1 ? '' : '&'
    }
  }
  return url
}
