export default {
    el: {
        colorpicker: {
          confirm: '確認',
          clear: '清空'
        },
        datepicker: {
          now: '現在',
          today: '今天',
          cancel: '取消',
          clear: '清空',
          confirm: '確認',
          selectDate: '選擇日期',
          selectTime: '選擇時間',
          startDate: '開始日期',
          startTime: '開始時間',
          endDate: '結束日期',
          endTime: '結束時間',
          prevYear: '前一年',
          nextYear: '後一年',
          prevMonth: '上個月',
          nextMonth: '下個月',
          year: '年',
          month1: '1 月',
          month2: '2 月',
          month3: '3 月',
          month4: '4 月',
          month5: '5 月',
          month6: '6 月',
          month7: '7 月',
          month8: '8 月',
          month9: '9 月',
          month10: '10 月',
          month11: '11 月',
          month12: '12 月',
          // week: '周次',
          weeks: {
            sun: '日',
            mon: '一',
            tue: '二',
            wed: '三',
            thu: '四',
            fri: '五',
            sat: '六'
          },
          months: {
            jan: '一月',
            feb: '二月',
            mar: '三月',
            apr: '四月',
            may: '五月',
            jun: '六月',
            jul: '七月',
            aug: '八月',
            sep: '九月',
            oct: '十月',
            nov: '十一月',
            dec: '十二月'
          }
        },
        select: {
          loading: '加載中',
          noMatch: '無匹配資料',
          noData: '無資料',
          placeholder: '請選擇'
        },
        cascader: {
          noMatch: '無匹配資料',
          loading: '加載中',
          placeholder: '請選擇',
          noData: '無資料'
        },
        pagination: {
          goto: '前往',
          pagesize: '項/頁',
          total: '共 {total} 項',
          pageClassifier: '頁'
        },
        messagebox: {
          title: '提示',
          confirm: '確定',
          cancel: '取消',
          error: '輸入的資料不符規定!'
        },
        upload: {
          deleteTip: '按 delete 鍵可刪除',
          delete: '刪除',
          preview: '查看圖片',
          continue: '繼續上傳'
        },
        table: {
          emptyText: '暫無資料',
          confirmFilter: '篩選',
          resetFilter: '重置',
          clearFilter: '全部',
          sumText: 'Sum' // to be translated
        },
        tree: {
          emptyText: '暫無資料'
        },
        transfer: {
          noMatch: '無匹配資料',
          noData: '無資料',
          titles: ['List 1', 'List 2'], // to be translated
          filterPlaceholder: 'Enter keyword', // to be translated
          noCheckedFormat: '{total} items', // to be translated
          hasCheckedFormat: '{checked}/{total} checked' // to be translated
        },
        image: {
          error: '加載失敗'
        },
        pageHeader: {
          title: '返回'
        },
        popconfirm: {
          confirmButtonText: 'Yes', // to be translated
          cancelButtonText: 'No' // to be translated
        },
        empty: {
          description: '暫無資料'
        }
    },
    top: {
        deviceSum: "設備總數",
        on_line: "在綫",
        off_line: "離綫",
        userSum: "用戶總數",
        average: "正廠",
        lock: "鎖定",
        dead : "停用",
        vioTotal: "違規總數",
        todayViolationCount: "當天",
        violation: "違規",
        total: '總數',
        few: "個",
        bench: '台',
        throughs: '次'
    },
    // 中间
    middle: {
        SystemUsage: '系統使用情況',
        DeviceActiveTrend: '設備活躍趨勢',
        PlatformContentStatistics: '平臺内容統計',
        cpuUseRate: 'CPU使用率',
        used: '已使用',
        gross: '總量',
        MemoryUsage: '内存使用率',
        DiskUsage: '硬盤使用率',
        appSum: '應用總數',
        strategySum: '策略總數',
        documentsSum: '文檔總數',
        noticeSum: '通知總數',
        choose:'請選擇'
    },
    third: {
        CompStatus: '合規狀態分佈',
        ViolationTrend: '違規行爲趨勢',
        DistributionViola: '違規行爲分佈',
    }
}