import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import temsys from "./system.js";
import ditor from "./auditor.js";

Vue.use(VueRouter);
const routes = [{
    path: "/home",
    name: "Home",
    component: Home,
    children: [{
        path: "/SourceHome",
        name: "SourceHome",
        meta: {
          title: "首页",
          icon: "",
        },
        component: () => import("../views/shouye/SourceHome.vue"),
      },
      {
        path: "/User",
        name: "User",
        meta: {
          title: "用户",
          icon: "",
        },
        component: () => import("../views/usermangement/usermanagment.vue"),
        children: [
          // {
          //   path:"/UserInfoList",
          //   redirect:"/User",
          // },

          {
            path: "/UserInfoList",
            name: "User",
            component: () => import("../views/usermangement/usermanagment.vue"),
            meta: {
              title: "用户管理",
              icon: "",
            },
            children: [{
                path: "/User",
                name: "User",
                component: () =>
                  import("../views/usermangement/three-usermangement/user.vue"),
                meta: {
                  title: "用户",
                  icon: "",
                },
              },
              {
                path: "/Groups",
                name: "Groups",
                component: () =>
                  import(
                    "../views/usermangement/three-usermangement/department.vue"
                  ),
                meta: {
                  title: "部门",
                  icon: "",
                },
              },
              {
                path: "/UserGroup",
                name: "UserGroup",
                meta: {
                  title: "用户组",
                  icon: "",
                },
                component: () =>
                  import(
                    "../views/usermangement/three-usermangement/UserGroup.vue"
                  ),
              },
            ],
          },
          {
            path: "/Admin",
            name: "Admin",
            meta: {
              title: "管理员管理",
              icon: "",
            },
            component: () => import("../views/usermangement/administrator.vue"),
            children: [{
                path: "/Administrator",
                name: "Administrator",
                component: () =>
                  import(
                    "../views/usermangement/three-usermangement/peoplo.vue"
                  ),
                meta: {
                  title: "管理员",
                  icon: "",
                },
              },
              {
                path: "/Role",
                name: "Role",
                component: () =>
                  import("../views/usermangement/three-usermangement/role.vue"),
                meta: {
                  title: "角色",
                  icon: "",
                },
              },
            ],
          },
        ],
      },
      {
        path: "/Device",
        name: "Device",
        meta: {
          title: "设备",
          icon: "",
        },
        component: () => import("../views/sbmanagement/sbmanagement.vue"),
        children: [{
            path: "/DeviceList",
            name: "Device",
            component: () => import("../views/sbmanagement/sbmanagement.vue"),
            meta: {
              title: "设备管理",
              icon: "",
            },
            children: [{
                path: "/Device",
                name: "Device",
                meta: {
                  title: "设备",
                  icon: "",
                },
                component: () => import("../views/sbmanagement/index.vue"),
              },
              {
                path: "/DeviceGroup",
                name: "DeviceGroup",
                meta: {
                  title: "设备组",
                  icon: "",
                },
                component: () => import("../views/sbmanagement/DeviceGroup.vue"),
              },
            ]
          },

          {
            path: "/DevicePolicy",
            name: "DevicePolicy",
            meta: {
              title: "设备策略",
              icon: "",
            },
            component: () => import("../views/sbmanagement/sbstrategy.vue"),
          },
          {
            path: "/ClientPolicy",
            name: "ClientPolicy",
            meta: {
              title: "合规策略",
              icon: "",
            },
            component: () =>
              import("../views/sbmanagement/compliancepolicy.vue"),
          },
          {
            path: "/SafetyAnalysis",
            name: "SafetyAnalysis",
            meta: {
              title: "安全事件",
              icon: "",
            },
            component: () => import("../views/sbmanagement/SafetyAnalysis.vue"),
          },
          {
            path: "/TimeFence",
            name: "TimeFence",
            meta: {
              title: "时间围栏",
              icon: "",
            },
            component: () => import("../views/sbmanagement/timefence.vue"),
          },
          {
            path: "/GeoFence",
            name: "GeoFence",
            meta: {
              title: "地理围栏",
              icon: "",
            },
            component: () =>
              import("../views/sbmanagement/geographicalfence.vue"),
          },
          {
            path: "/Version",
            name: "Version",
            meta: {
              title: "终端版本",
              icon: "",
            },
            component: () =>
              import("../views/sbmanagement/terminalversion.vue"),
          },
          {
            path: "/Behavior",
            name: "Behavior",
            meta: {
              title: "审计信息",
              icon: "",
            },
            component: () =>
              import("../views/sbmanagement/behaviormanagement.vue"),
          },
        ],
      },
      {
        path: "/App",
        name: "App",
        meta: {
          title: "应用管理",
          icon: "",
        },
        component: () => import("../views/appmanagement/appstore.vue"),
        children: [{
            path: "/App",
            name: "App",
            meta: {
              title: "应用商店",
              icon: "",
            },
            component: () => import("../views/appmanagement/index.vue"),
          },
          {
            path: "/AppCategory",
            name: "AppCategory",
            meta: {
              title: "应用类别",
              icon: "",
            },
            component: () => import("../views/appmanagement/appcategory.vue"),
          },
          {
            path: "/AppLib",
            name: "AppLib",
            meta: {
              title: "应用库",
              icon: "",
            },
            component: () => import("../views/appmanagement/appku.vue"),
          },
          {
            path: "/PolicyConfig",
            name: "PolicyConfig",
            meta: {
              title: "策略配置",
              icon: "",
            },
            component: () => import("../views/appmanagement/PolicyConfig.vue"),
            children: [{
                path: "/AppPolicy",
                name: "AppPolicy",
                meta: {
                  title: "应用策略",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/PolicyConfig/appstrategy.vue"),
              },
              {
                path: "/ThreatDefinition",
                name: "ThreatDefinition",
                meta: {
                  title: "威胁定义",
                  icon: "",
                },
                component: () =>
                  import(
                    "../views/appmanagement/PolicyConfig/ThreatDefinition.vue"
                  ),
              },
              {
                path: "/SecurityEvent",
                name: "SecurityEvent",
                meta: {
                  title: "安全事件",
                  icon: "",
                },
                component: () =>
                  import(
                    "../views/appmanagement/PolicyConfig/SecurityEvent.vue"
                  ),
              },
              {
                path: "/SDKMonitor",
                name: "SDKMonitor",
                meta: {
                  title: "SDK监控",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/PolicyConfig/SDKMonitor.vue"),
              },
              {
                path: "/BannedConfig",
                name: "BannedConfig",
                meta: {
                  title: "封禁配置",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/PolicyConfig/BannedConfig.vue"),
              },
              {
                path: "/WhiteList",
                name: "WhiteList",
                meta: {
                  title: "白名单",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/PolicyConfig/whiteList.vue"),
              },
              {
                path: "/WaterConfig",
                name: "WaterConfig",
                meta: {
                  title: "界面水印",
                  icon: "",
                },
                component: () =>
                  import(
                    "../views/appmanagement/PolicyConfig/pagewatermark.vue"
                  ),
              },
              {
                path: "/WaterResolution",
                name: "WaterResolution",
                meta: {
                  title: "暗水印解析",
                  icon: "",
                },
                component: () =>
                  import(
                    "../views/appmanagement/PolicyConfig/waterResolution.vue"
                  ),
              },
              {
                path: "/UrlLibrary",
                name: "UrlLibrary",
                meta: {
                  title: "网址库",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/PolicyConfig/UrlLi.vue"),
              },
            ],
          },
          {
            path: "/SensitiveWords",
            name: "SensitiveWords",
            meta: {
              title: "敏感词库",
              icon: "",
            },
            component: () => import("../views/appmanagement/thesaurus.vue"),
          },
          {
            path: "/Scan&Fasten",
            name: "Scan&Fasten",
            meta: {
              title: "检测加固",
              icon: "",
            },
            component: () => import("../views/appmanagement/reinforcing.vue"),
          },
          {
            path: "/SafetyScan",
            name: "SafetyScan",
            meta: {
              title: "安全检测",
              icon: "",
            },
            component: () =>
              import("../views/appmanagement/SafetyInspection/SafetyScan.vue"),
            children: [{
                path: "/Scan",
                name: "Scan",
                meta: {
                  title: "应用检测",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/appdetection.vue"),
              },
              {
                path: "/SdkScan",
                name: "SdkScan",
                meta: {
                  title: "SDK检测",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/SafetyInspection/SdkScan.vue"),
              },
              {
                path: "/H5Scan",
                name: "H5Scan",
                meta: {
                  title: "H5检测",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/SafetyInspection/H5Scan.vue"),
              },
              {
                path: "/ScanAnalysis",
                name: "ScanAnalysis",
                meta: {
                  title: "检测分析",
                  icon: "",
                },
                component: () =>
                  import(
                    "../views/appmanagement/SafetyInspection/ScanAnalysis.vue"
                  ),
              },
              {
                path: "/ScanLevel",
                name: "ScanLevel",
                meta: {
                  title: "检测等级",
                  icon: "",
                },
                component: () =>
                  import(
                    "../views/appmanagement/SafetyInspection/ScanLevel.vue"
                  ),
              },
            ],
          },
          {
            path: "/SafetyReinforce",
            name: "SafetyReinforce",
            meta: {
              title: "安全加固",
              icon: "",
            },
            component: () =>
              import(
                "../views/appmanagement/SafetyStrengthening/SafetyStrengthening.vue"
              ),
            children: [{
                path: "/AppReinforce",
                name: "AppReinforce",
                meta: {
                  title: "应用加固",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/appstrengthening.vue"),
              },
              {
                path: "/SDKReinforce",
                name: "SDKReinforce",
                meta: {
                  title: "SDK加固",
                  icon: "",
                },
                component: () =>
                  import(
                    "../views/appmanagement/SafetyStrengthening/sdkStrengthening.vue"
                  ),
              },
              {
                path: "/H5Reinforce",
                name: "H5Reinforce",
                meta: {
                  title: "H5加固",
                  icon: "",
                },
                component: () =>
                  import("../views/appmanagement/H5strengthening.vue"),
              },
            ],
          },
          {
            path: "/CertificateManage",
            name: "CertificateManage",
            meta: {
              title: "证书管理",
              icon: "",
            },
            component: () =>
              import("../views/appmanagement/CertificateManage.vue"),
          },
        ],
      },
      {
        path: "/contentmanage",
        name: "contentmanage",
        meta: {
          title: "内容管理",
          icon: "",
        },
        component: () => import("../views/contentmanagement/contentmanage.vue"),
        children: [{
            path: "/document",
            name: "document",
            meta: {
              title: "文档管理",
              icon: "",
            },
            component: () => import("../views/contentmanagement/document.vue"),
          },

          {
            path: "/documentcategory",
            name: "documentcategory",
            meta: {
              title: "文档类别",
              icon: "",
            },
            component: () =>
              import("../views/contentmanagement/documentcategory.vue"),
          },
          {
            path: "/Notice",
            name: "Notice",
            meta: {
              title: "通知管理",
              icon: "",
            },
            component: () =>
              import("../views/contentmanagement/Informmanagement.vue"),
          },
        ],
      },
      {
        path: "/Delivery",
        name: "Delivery",
        meta: {
          title: "分配管理",
          icon: "",
        },
        component: () => import("../views/distributionmanagement/index.vue"),
        children: [{
            path: "/Delivery",
            name: "Delivery",
            meta: {
              title: "分配管理",
              icon: "",
            },
            component: () =>
              import(
                "../views/distributionmanagement/distributionmanagement.vue"
              ),
          },
          {
            path: "/DeliveryHistory",
            name: "DeliveryHistory",
            meta: {
              title: "分配历史",
              icon: "",
            },
            component: () =>
              import("../views/distributionmanagement/distributionhistory.vue"),
          },
          {
            path: "/ExecResult",
            name: "ExecResult",
            meta: {
              title: "执行结果",
              icon: "",
            },
            component: () =>
              import("../views/distributionmanagement/enforcementresult.vue"),
          },
        ],
      },
      {
        path: "/situational",
        name: "situational",
        component: () => import("../views/situational.vue"),
      },
      {
        path: "/configuration",
        name: "configuration",
        component: () => import("../views/configuration"),
      },
      {
        path: "/workFlowSet",
        name: "workFlowSet",
        meta: {
          title: "流程管理",
          icon: ""
        },
        component: () => import("../views/workFlowManagement/index.vue"),
        children: [{
            path: "/workFlowSet",
            name: "workFlowSet",
            meta: {
              title: "流程设置",
              icon: ""
            },
            component: () => import("../views/workFlowManagement/workFlowSet/workFlowSet.vue"),
          },
          {
            path: "/ApplyForapproval",
            name: "ApplyForapproval",
            meta: {
              title: "申请审批",
              icon: ""
            },
            component: () => import("../views/workFlowManagement/applyForapproval/index.vue"),
            children: [{
                path: "/MyApplication",
                name: "MyApplication",
                meta: {
                  title: "我的申请",
                  icon: ""
                },
                component: () => import("../views/workFlowManagement/applyForapproval/myapplication.vue"),

              },
              {
                path: "/MyForapproval",
                name: "MyForapproval",
                meta: {
                  title: "我的审批",
                  icon: ""
                },
                component: () => import("../views/workFlowManagement/applyForapproval/myForapproval.vue"),
              },
            ]
          }
        ],
      },
      {
        path: "/Home2",
        name: "Home2",
        meta: {
          title: "安全检测",
          icon: "",
        },
        component: () =>
          import("../views/safetymonitoring/safetymonitoring.vue"),
        children: [{
            path: "/Home2",
            name: "Home2",
            meta: {
              title: "告警信息",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/securityposture.vue"),
          },
          {
            path: "/WarningInfo",
            name: "WarningInfo",
            meta: {
              title: "预警信息",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/warningInfo.vue"),
          },
          {
            path: "/ViolationMonitor",
            name: "ViolationMonitor",
            meta: {
              title: "违规行为",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/illegalbehavior.vue"),
          },

          {
            path: "/AppAttackMonitor",
            name: "AppAttackMonitor",
            meta: {
              title: "威胁行为",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/aggressivebehaviour.vue"),
          },
          {
            path: "/AppSensitiveMonitor",
            name: "AppSensitiveMonitor",
            meta: {
              title: "敏感行为",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/sensitivebehavior.vue"),
          },
          {
            path: "/none1",
            name: "none1",
            component: () => import("../views/none1.vue"),
          },
          {
            path: "/SDKBehaviour",
            name: "SDKBehaviour",
            meta: {
              title: "SDK行为",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/SDKBehaviour.vue"),
          },
          {
            path: "/Interdiction",
            name: "Interdiction",
            meta: {
              title: "封禁行为",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/Interdiction.vue"),
          },
          {
            path: "/DangerIP",
            name: "DangerIP",
            meta: {
              title: "风险IP",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/DangerIP.vue"),
          },

          {
            path: "/RunInfo",
            name: "RunInfo",
            meta: {
              title: "运行信息",
              icon: "",
            },
            component: () => import("../views/safetymonitoring/RunInfo.vue"),
          },
          {
            path: "/Accessbehavior",
            name: "Accessbehavior",
            meta: {
              title: "访问行为",
              icon: "",
            },
            component: () => import("../views/safetymonitoring/Accessbehavior.vue"),
          },
          {
            path: "/VirusInfo",
            name: "VirusInfo",
            meta: {
              title: "病毒信息",
              icon: "",
            },
            component: () => import("../views/safetymonitoring/VirusInfo.vue"),
          },
          {
            path: "/DeviceEvents",
            name: "DeviceEvents",
            meta: {
              title: "设备事件",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/DeviceEvents.vue"),
          },
          {
            path: "/SafetyEvent",
            name: "SafetyEvent",
            meta: {
              title: "安全事件",
              icon: "",
            },
            component: () =>
              import("../views/safetymonitoring/SafetyEvent.vue"),
          },
        ],
      },

      //移动运维
      {
        path: "/MobileOperations",
        name: "MobileOperations",
        meta: {
          title: "移动运维",
          icon: "",
        },
        component: () => import("../views/mobileoperations/mobile.vue"),
        children: [{
            path: "/GDment",
            name: "GDment",
            meta: {
              title: "工单管理",
              icon: "",
            },
            component: () => import("../views/mobileoperations/GDment.vue"),
          },
          {
            path: "/CrashAnalysis",
            name: "CrashAnalysis",
            component: () =>
              import("../views/mobileoperations/CrashAnalysis.vue"),
            meta: {
              title: "崩溃信息",
              icon: "",
            },
          },
          {
            path: "/ClientHelp",
            name: "ClientHelp",
            meta: {
              title: "用户帮助配置",
              icon: "",
            },
            component: () => import("../views/mobileoperations/ClientHelp.vue"),
          },

          {
            path: "/deviceClientLog",
            name: "deviceClientLog",
            meta: {
              title: "客户端日志",
              icon: "",
            },
            component: () =>
              import("../views/OperationsSupport/deviceClientLog.vue"),
          },
        ],
      },
      //运营管理
      // {
      //   path: "/RunManagement",
      //   name: "RunManagement",
      //   meta: {
      //     title: "运营管理",
      //     icon: ""
      //   },
      //   component: () => import("../views/OperationsManagement/OperationsManagement.vue"),
      //   children: [
      //     {
      //       path: "/ArtResult",
      //       name: "ArtResult",
      //       meta: {
      //         title: "技术成果",
      //         icon: ""
      //       },
      //       component: () => import("../views/OperationsManagement/ArtResult.vue"),
      //     },
      //     {
      //       path: "/InstitutionalNorm",
      //       name: "InstitutionalNorm",
      //       meta: {
      //         title: "制度规范",
      //         icon: ""
      //       },
      //       component: () => import("../views/OperationsManagement/InstitutionalNorm.vue"),
      //     },
      //     {
      //       path: "/ResultsCategory",
      //       name: "ResultsCategory",
      //       meta: {
      //         title: "成果类别",
      //         icon: ""
      //       },
      //       component: () => import("../views/OperationsManagement/ResultsCategory.vue"),
      //     },
      //     {
      //       path: "/SpecificationCategory",
      //       name: "SpecificationCategory",
      //       meta: {
      //         title: "规范类别",
      //         icon: ""
      //       },
      //       component: () => import("../views/OperationsManagement/SpecificationCategory.vue"),
      //     },
      //     {
      //       path: "/developmentCenter",
      //       name: "developmentCenter",
      //       meta: {
      //         title: "开发中心",
      //         icon: ""
      //       },
      //       component: () => import("../views/OperationsManagement/developmentCenter.vue"),
      //     },
      //     {
      //       path: "/developmentCategory",
      //       name: "developmentCategory",
      //       meta: {
      //         title: "开发类别",
      //         icon: ""
      //       },
      //       component: () => import("../views/OperationsManagement/developmentCategory.vue"),
      //     },
      //   ]
      // },
      //报表管理
      {
        path: "/ReportManagement",
        name: "ReportManagement",
        meta: {
          title: "报表管理",
          icon: "",
        },
        component: () => import("../views/reportManagement/index.vue"),
        children: [{
            path: "/DataReport",
            name: "DataReport",
            meta: {
              title: "数据报表",
              icon: "",
            },
            component: () => import("../views/reportManagement/dataReport.vue"),
          },
          {
            path: "/ReportTemplate",
            name: "RportTemplate",
            meta: {
              title: "创建报表",
              icon: "",
            },
            component: () =>
              import("../views/reportManagement/reportTemplate.vue"),
          },
        ],
      },
      {
        path: "/AccessConfiguration",
        name: "AccessConfiguration",
        meta: {
          title: "访问管理",
          icon: "",
        },
        component: () => import("../views/AccessConfiguration/index.vue"),
        children: [{
            path: "/GatewayResourceManagement",
            name: "GatewayResourceManagement",
            meta: {
              title: "网关配置",
              icon: "",
            },
            component: () =>
              import(
                "../views/AccessConfiguration/GatewayResourceManagement.vue"
              ),
          },
          {
            path: "/ServiceResourceManagement",
            name: "ServiceResourceManagement",
            meta: {
              title: "业务资源",
              icon: "",
            },
            component: () =>
              import(
                "../views/AccessConfiguration/ServiceResourceManagement.vue"
              ),
          },
        ],
      },
      // 运维
      // {
      //   path: "/OperationsSupport",
      //   name: "OperationsSupport",
      //   meta: {
      //     title: "移动运维",
      //     icon: ""
      //   },
      //   component: () => import("../views/OperationsSupport/OperationsSupport.vue"),
      //   children: [{
      //     path: "/CrashAnalysis",
      //     name: "CrashAnalysis",
      //     component: () =>
      //       import("../views/OperationsSupport/CrashAnalysis.vue"),
      //     meta: {
      //       title: "崩溃分析",
      //       icon: ""
      //     },

      //   }, {
      //     path: "/deviceClientLog",
      //     name: "deviceClientLog",
      //     meta: {
      //       title: "客户端日志",
      //       icon: ""
      //     },
      //     component: () => import("../views/OperationsSupport/deviceClientLog.vue")
      //   }, ]
      // },

      // system
      {
        path: "/Running",
        name: "Running",
        meta: {
          title: "系统配置",
          icon: "",
        },
        component: () => import("../views/system/index.vue"),
        children: [{
            path: "/Running",
            name: "Running",
            meta: {
              title: "运行配置",
              icon: "",
            },
            component: () => import("../views/system/Running.vue"),
          },
          {
            path: "/ClientLogs",
            name: "ClientLogs",
            meta: {
              title: "客户端配置",
              icon: "",
            },
            component: () => import("../views/system/ClientLog/Khconfig.vue"),
            children: [{
                path: "/HttpUrl",
                name: "HttpUrl",
                meta: {
                  title: "服务器地址",
                  icon: "",
                },
                component: () =>
                  import("../views/system/ClientLog/HttpUrl.vue"),
              },
              {
                path: "/AndroidConfig",
                name: "AndroidConfig",
                meta: {
                  title: "Android配置",
                  icon: "",
                },
                component: () =>
                  import("../views/system/ClientLog/AndroidConfig.vue"),
              },
              {
                path: "/IosConfig",
                name: "IosConfig",
                meta: {
                  title: "iOS配置",
                  icon: "",
                },
                component: () =>
                  import("../views/system/ClientLog/IosConfig.vue"),
              },
              {
                path: "/WindowsConfig",
                name: "WindowsConfig",
                meta: {
                  title: "Windows配置",
                  icon: "",
                },
                component: () =>
                  import("../views/system/ClientLog/WindowsConfig.vue"),
              },
              {
                path: "/LinuxConfig",
                name: "LinuxConfig",
                meta: {
                  title: "Linux配置",
                  icon: "",
                },
                component: () =>
                  import("../views/system/ClientLog/LinuxConfig.vue"),
              },
            ],
          },
          {
            path: "/BehaviorLevel",
            name: "BehaviorLevel",
            meta: {
              title: "行为级别配置",
              icon: "",
            },
            component: () => import("../views/system/BehaviorLevel.vue"),
          },
          {
            path: "/S6000Config",
            name: "S6000Config",
            meta: {
              title: "对外接口配置",
              icon: "",
            },
            component: () => import("../views/system/S6000Config.vue"),
          },
          {
            path: "/SystemWarning",
            name: "SystemWarning",
            meta: {
              title: "系统预警配置",
              icon: "",
            },
            component: () => import("../views/system/systemWarning.vue"),
          },
          {
            path: "/SecurityConfig",
            name: "SecurityConfig",
            meta: {
              title: "安全配置",
              icon: "",
            },
            component: () => import("../views/system/SecurityConfig.vue"),
          },
          {
            path: "/CustomizedConfig",
            name: "CustomizedConfig",
            meta: {
              title: "定制化配置",
              icon: "",
            },
            component: () => import("../views/system/CustomizedConfig.vue"),
          },

          {
            path: "/Syslog",
            name: "Syslog",
            meta: {
              title: "系统日志",
              icon: "",
            },
            component: () => import("../views/system/Syslog.vue"),
          },
          {
            path: "/Upgrade",
            name: "Upgrade",
            meta: {
              title: "系统升级",
              icon: "",
            },
            component: () => import("../views/system/Upgrade.vue"),
          },
          {
            path: "/BackupAndRestore",
            name: "BackupAndRestore",
            meta: {
              title: "备份与恢复",
              icon: "",
            },
            component: () => import("../views/system/BackupAndRestore.vue"),
          },
          {
            path: "/License",
            name: "License",
            meta: {
              title: "授权激活",
              icon: "",
            },
            component: () => import("../views/system/License.vue"),
          },
          {
            path: "/Contact",
            name: "Contact",
            meta: {
              title: "联系我们",
              icon: "",
            },
            component: () => import("../views/system/Contact.vue"),
          },
          {
            path: "/About",
            name: "About",
            meta: {
              title: "关于",
              icon: "",
            },
            component: () => import("../views/system/About.vue"),
          },
        ],
      },
      {
        path: "/AccessConfiguration",
        name: "AccessConfiguration",
        meta: {
          title: "访问配置",
          icon: "",
        },
        component: () =>
          import("../views/system/AccessConfiguration/index.vue"),
        children: [{
            path: "/GatewayResourceManagement",
            name: "GatewayResourceManagement",
            meta: {
              title: "网关资源配置",
              icon: "",
            },
            component: () =>
              import(
                "../views/system/AccessConfiguration/GatewayResourceManagement.vue"
              ),
          },
          {
            path: "/ServiceResourceManagement",
            name: "ServiceResourceManagement",
            meta: {
              title: "业务资源管理",
              icon: "",
            },
            component: () =>
              import(
                "../views/system/AccessConfiguration/ServiceResourceManagement.vue"
              ),
          },
        ],
      },

      // auditor
      {
        path: "/ManageLogs",
        name: "ManageLogs",
        meta: {
          title: "日志审计",
          icon: "",
        },
        component: () => import("../views/auditor/ManageLogs.vue"),
        children: [{
            path: "/ManageLog",
            name: "ManageLog",
            meta: {
              title: "管理日志",
              icon: "",
            },
            component: () => import("../views/auditor/ManageLog.vue"),
          },
          {
            path: "/ClientLog",
            name: "ClientLog",
            meta: {
              title: "终端日志",
              icon: "",
            },
            component: () => import("../views/auditor/ClientLog.vue"),
          },
          {
            path: "/AlarmLog",
            name: "AlarmLog",
            meta: {
              title: "告警日志",
              icon: "",
            },
            component: () => import("../views/auditor/AlarmLog.vue"),
          },
          {
            path: "/ArchiveLog",
            name: "ArchiveLog",
            meta: {
              title: "日志归档",
              icon: "",
            },
            component: () => import("../views/auditor/ArchiveLog.vue"),
          },
          // {
          //   path: "/GatewayAccessLog",
          //   name: "GatewayAccessLog",
          //   meta: {
          //     title: "网关访问日志",
          //     icon: "",
          //   },
          //   component: () => import("../views/auditor/GatewayAccessLog.vue"),
          // },
        ],
      },
      // 自助服务台 普通用户菜单
      {
        path: "/SelfServiceDesk",
        name: "SelfServiceDesk",
        meta: {
          title: "自助服务台",
          icon: "",
        },
        component: () => import("../views/SelfServiceDesk/SelfService.vue"),
        children: [{
          path: "/equipmentManagement",
          name: "equipmentManagement",
          meta: {
            title: "设备管理",
            icon: "",
          },
          component: () => import("../views/SelfServiceDesk/equipmentManagement.vue"),
        }],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  // {
  //   path: "/situational",
  //   name: "situational",
  //   component: () => import("../views/situational.vue")
  // },
  // {
  //   path: "/configuration",
  //   name: "configuration",
  //   component: () => import("../views/configuration")
  // },
  {
    path: "/forgetPwd",
    name: "forgetPwd",
    component: () => import("../views/forgetPwd.vue"),
  },
  {
    path: "/blankBock",
    name: "blankBock",
    component: () => import("../views/blankBock.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: () => import("../views/About.vue")
  // },
  {
    path: "/login1",
    name: "login1",
    component: () => import("../views/login1.vue"),
  },
  {
    path: "/",
    redirect: "/SourceHome",
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/vue/web",
  // base: process.env.BASE_URL,
  routes: [...routes, ...ditor, ...temsys],
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
