import zh from './zh'
import en from './en'
import tw from './tw'
import { log } from 'console'
export default {
    locale(key,language) {
        // console.log(key,'key!!!!');
        var arr
        //  language;
        if (!key) {
            return "";
        }
        arr = key.split(".");
        if (arr.length < 2) {
            return "";
        }

        // language = window.localStorage.getItem('language') || "zh";
        switch (language) {
            case "en":
                return en[arr[0]][arr[1]];
            case "tw":
                return tw[arr[0]][arr[1]];
            case "zh":
                return zh[arr[0]][arr[1]];
        }
        // return zh[arr[0]][arr[1]];
    },
}